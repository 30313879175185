<template>
  <div class="trp-overview reporting">
    <div class="d-flex justify-content-between">
        <div>
            <h2>{{title}}</h2>  
            <div v-if="isReady && lastEdited.length > 0" class="trp-last-edited"><br>Last Updated: {{lastEdited}}</div>
        </div>
        
        <span class="sub-setting">

            <el-tooltip v-if="editable" content="Edit" placement="top">
              <span @click="edit" :class="['icon-edit sub-setting-icon', isEditing ? 'sub-setting-icon-editing' :'']">
                <i class="tim-icons icon-pencil"></i>
              </span>
            </el-tooltip>
            <slot name="action-buttons"></slot>
        </span>

        
    </div>
    <div ref="nice">
        <div v-show="isReady && !isEditing" class="row">
            <div class="col-md-8">
                <bar-chart :key="upKey" v-if="isReady"
                    :chart-data="trpBarChart.chartData"
                    :gradient-color="trpBarChart.gradientColors"
                    :gradient-stops="trpBarChart.gradientStops"
                    :extra-options="trpBarChart.extraOptions"
                    ref="trpBarChart"
                    @chart:render="render"
                >
                </bar-chart>
            </div>

            <div class="col-md-4">
                <bar-chart :key="upKey" v-if="isReady"
                    :chart-data="trpTotalBarChart.chartData"
                    :gradient-color="trpTotalBarChart.gradientColors"
                    :gradient-stops="trpTotalBarChart.gradientStops"
                    :extra-options="trpTotalBarChart.extraOptions"
                    ref="trpTotalBarChart">
                </bar-chart>
            </div>

            <!-- <div v-if="barChartRows.length > 0" class="col-md-12">
                <div class="sub-legend">
                    <div class="legend-holder">
                        <div class="reporting-legend-outer">
                            <span class="reporting-legend"></span> <p>TRP Planned</p>

                            <span class="reporting-legend"></span> <p>TRP Actual Reach</p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="reporting-chart-holder" v-if="isEditing">
            <div class="bar-chart-rows">
                <el-table
                    :data="barChartRows"
                    style="padding-top: 23px">
                    
                    <el-table-column label="Date">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.date"></el-input>
                    </template>
                    </el-table-column>

                    <el-table-column label="Planned TRP">
                    <template slot-scope="scope">
                        <el-input type="number" v-model="scope.row.plannedTrp"></el-input>
                    </template>
                    </el-table-column>

                    <el-table-column label="Actual TRP">
                    <template slot-scope="scope">
                        <el-input type="number" v-model="scope.row.actualTrp"></el-input>
                    </template>
                    </el-table-column>

                    <el-table-column width="45px">
                    <template slot-scope="scope">
                        <i v-on:click="removeRow(scope.$index)" class="tim-icons icon-simple-remove other-media-edit"></i>
                    </template>
                    </el-table-column>
                </el-table>

                <b-button v-on:click="addRow" class="btn btn-primary add-row-button">+ Add row</b-button>
                </div>

                <b-button v-on:click="save" class="btn btn-primary" style="float: right">Save</b-button>
            </div>
    </div>
    </div>
</template>

<script>
import BarChart from 'src/components/Charts/BarChart'
import KeyLearnings from '@/components/KeyLearnings/KeyLearnings.vue'

export default {
    name: 'reporting-bar-chart',
    lastEdited: '',
    components: {
        KeyLearnings,
        BarChart,
    },
    props: {
        value:{
            type: Object ,
            default: function () {
                return {
                    tvKeyFigures: [],
                    otherMedia: [],
                    trpOverview: []
                }
            },
        },
        editable: false,
        title: {
            type: String,
            default: 'Bar Chart',
            description: 'Card title'
        },
        tableData: {
            type: Array,
            default: function () {
                return []
            },
        },
        campaignTitle: {
            type: String,
            default: '',
            description: 'Campaign title'
        },
        help: {
            type: String,
            description: 'Help text'
        },
        legendColor:{
            type: String,
            default: "#FFF"
            
        },
    },
    data() {
        return {
            renderPromiseResolve: null,
            upKey: 0,
            barChartRows: [],
            isEditing: false,
            isReady: false,
            barChartColumns: [{
                    minWidth: 100,
                    width: 80,
                    prop: 'date',
                    label: 'Date'
                },
                {
                    minWidth: 100,
                    width: 100,
                    prop: 'plannedTrp',
                    label: 'Planned TRP'
                },
                {
                    minWidth: 100,
                    width: 100,
                    prop: 'actualTrp',
                    label: 'Actual TRP'
                },
            ],
            trpBarChart: {
                extraOptions: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    tooltips: {
                        backgroundColor: '#f5f5f5',
                        titleFontColor: '#333',
                        bodyFontColor: '#666',
                        bodySpacing: 4,
                        xPadding: 12,
                        mode: "nearest",
                        intersect: 0,
                        position: "nearest"
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                                color: '#454545',
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                                beginAtZero: true,
                                maxTicksLimit: 12,
                                suggestedMax: 100,
                                suggestedMin: 100,
                                padding: 20,
                                fontColor: "#FFF",
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false,
                                drawBorder: false,
                                color: 'rgba(29,140,248,0.1)',
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                padding: 20,
                                fontColor: "#FFF",
                                fontSize: '14',
                                fontWeight: '900'
                            }
                        }]
                    }
                },
                chartData: {
                    labels: [],
                    datasets: [{
                            label: "TRP Planned",
                            fill: true,
                            borderColor: '#626D80',
                            backgroundColor: '#626D80',
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            data: [],
                        },
                        {
                            label: "TRP Actual Reach Actual 1+",
                            fill: true,
                            borderColor: '#2A34FF',
                            backgroundColor: '#2A34FF',
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            data: [],
                        },
                    ]
                },
                gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
                gradientStops: [1, 0.4, 0],
            },
            trpTotalBarChart: {
                extraOptions: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    tooltips: {
                        backgroundColor: '#f5f5f5',
                        titleFontColor: '#333',
                        bodyFontColor: '#666',
                        bodySpacing: 4,
                        xPadding: 12,
                        mode: "nearest",
                        intersect: 0,
                        position: "nearest"
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                                color: '#454545',
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                                beginAtZero: true,
                                maxTicksLimit: 12,
                                suggestedMax: 100,
                                suggestedMin: 100,
                                padding: 20,
                                fontColor: "#FFF",
                            }
                        }],
                        xAxes: [{

                            gridLines: {
                                display: false,
                                drawBorder: false,
                                color: 'rgba(29,140,248,0.1)',
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                padding: 20,
                                fontColor: "#FFF",
                                fontSize: '14',
                                fontWeight: '900'
                            }
                        }]
                    }
                },
                chartData: {
                    labels: [],
                    datasets: [{
                            label: "TRP Planned",
                            fill: true,
                            borderColor: '#626D80',
                            backgroundColor: '#626D80',
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            data: [],
                        },
                        {
                            label: "TRP Actual Reach Actual 1+",
                            fill: true,
                            borderColor: '#2A34FF',
                            backgroundColor: '#2A34FF',
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            data: [],
                        },
                    ]
                },
                gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
                gradientStops: [1, 0.4, 0],
            },
        }
    },    
    mounted() {
        if (this.value && this.value.trpOverview) {
            this.barChartRows = this.value["trpOverview"].length > 0 ? this.value["trpOverview"] : [];
            this.lastEdited = this.value["lastEditedTrpOverview"] ? this.value["lastEditedTrpOverview"] : [];
            var plannedTrpTotal = 0;
            var actualTrpTotal = 0;

            for (var i = 0; i < this.barChartRows.length; i++) {
                this.trpBarChart.chartData.labels.push(this.barChartRows[i].date)
                this.trpBarChart.chartData.datasets[0].data.push(this.barChartRows[i].plannedTrp);
                this.trpBarChart.chartData.datasets[1].data.push(this.barChartRows[i].actualTrp);


                plannedTrpTotal += parseInt(this.trpBarChart.chartData.datasets[0].data[i]);
                actualTrpTotal += parseInt(this.trpBarChart.chartData.datasets[1].data[i]);
            }

            if (this.value["trpOverview"].length > 1) {
                this.trpTotalBarChart.chartData.labels.push("Total")
            }
            this.trpTotalBarChart.chartData.datasets[0].data.push(plannedTrpTotal);
            this.trpTotalBarChart.chartData.datasets[1].data.push(actualTrpTotal);

            this.isReady = true;
        }
    },
    watch:{
        barChartRows: {
            deep: true,
            handler(){
                let val = JSON.parse(JSON.stringify(this.value));
                val.trpOverview = this.barChartRows;
                val.lastEditedTrpOverview = this.lastEdited;
                this.$emit("input", val);
            }
        },
    },
    methods: {
        render(){
            if(typeof this.renderPromiseResolve === "function"){
                this.renderPromiseResolve();
            }
        },
        forceRerender(){
            this.$refs["trpBarChart"].forceRerender();
            this.$refs["trpTotalBarChart"].forceRerender();
        },
        setChartLegendColor(colorHex){
            const that = this;
            return new Promise(function(resolve, reject){
                that.trpBarChart.extraOptions.scales.yAxes[0].ticks.fontColor = colorHex;
                that.trpBarChart.extraOptions.scales.xAxes[0].ticks.fontColor = colorHex;
                that.trpTotalBarChart.extraOptions.scales.yAxes[0].ticks.fontColor = colorHex;
                that.trpTotalBarChart.extraOptions.scales.xAxes[0].ticks.fontColor = colorHex;
                that.renderPromiseResolve = resolve;
                that.forceRerender();
            });
        },
        edit() {
            this.isEditing = !this.isEditing;
        },
        save() {
            this.upKey++;
            var plannedTrpTotal = 0;
            var actualTrpTotal = 0;

            this.trpBarChart.chartData.labels = [];
            this.trpBarChart.chartData.datasets[0].data = [];
            this.trpBarChart.chartData.datasets[1].data = [];
            this.trpTotalBarChart.chartData.datasets[0].data = [];
            this.trpTotalBarChart.chartData.datasets[1].data = [];

            for (var i = 0; i < this.barChartRows.length; i++) {
                this.trpBarChart.chartData.labels.push(this.barChartRows[i].date)
                this.trpBarChart.chartData.datasets[0].data.push(this.barChartRows[i].plannedTrp);
                this.trpBarChart.chartData.datasets[1].data.push(this.barChartRows[i].actualTrp);

                plannedTrpTotal += parseInt(this.trpBarChart.chartData.datasets[0].data[i]);
                actualTrpTotal += parseInt(this.trpBarChart.chartData.datasets[1].data[i]);
            }

            if (this.trpTotalBarChart.chartData.labels.length < 1) {
                this.trpTotalBarChart.chartData.labels.push("Total")
            }
            this.trpTotalBarChart.chartData.datasets[0].data.push(plannedTrpTotal);
            this.trpTotalBarChart.chartData.datasets[1].data.push(actualTrpTotal);

            this.isReady = true;
            this.saveEdits();
        },
        addRow() {
            this.barChartRows.push({
                date: '',
                plannedTrp: '',
                actualTrp: ''
            })
            this.upKey++;
        },
        removeRow(index) {
            this.barChartRows.splice(index, 1);
            this.trpTotalBarChart.chartData.datasets[0].data.splice(index, 1);
            this.trpTotalBarChart.chartData.datasets[1].data.splice(index, 1);

            this.trpTotalBarChart.chartData.labels = [];

            if (this.trpTotalBarChart.chartData.datasets[0].data.length < 2) {
                this.trpTotalBarChart.chartData.labels = [];
            }
            this.upKey++;
        },
        saveEdits() {
            this.$emit("save");
        },
    },
};
</script>

<style lang="scss" scoped>

.bar-chart-rows .el-table__header thead tr th {
    border: 1px solid white;
}

.bar-chart-rows .el-table__body .el-table__row td {
    border: 1px solid white;
}

/* .bar-chart-rows .el-table .cell {

} */

/* .bar-chart-rows .el-table__headernth-child(3) {
    border: none;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.reporting-chart-holder {
    margin-top: 18px; 
}

.trp-overview .add-row-button {
    margin-top: -1px;
    margin-left: -0px;
    border: 2px solid white;
    background-color: none;
    background: none;
    border-radius:0;
    height: 65px;
    max-width: 120px;
}
.trp-overview .add-row-button:hover {
  color: #1A1A1A;
  background-color: white;
  margin-top: 0px;
}

.trp-overview .add-row-button:active {
  color: #1A1A1A;
  background-color: white;
  margin-top: 0px;
}

.bar-chart-learning-holder {
    margin-top: 0px;
}

.trp-last-edited {
    color: #7c7c7c;
    margin-top: -46px;
    margin-bottom: 12px;
}

.trp-overview .el-table__body {
  border-spacing: 0;
  border-radius: 0;
}

.trp-overview .el-table__body .el-table__row td{
  border-radius: 0 !important;
}

.trp-overview .el-table__body .el-table__row td {
  background-color: transparent;
  text-indent: -4px;
}

.trp-overview .el-table__header thead tr th{
  background-color: #3F3F3F;
  height: 65px;
  color: white;
  font-size: 14px;
  text-indent: 4px;
  border: 2px solid white;
}

.trp-overview .el-table__header thead tr th:last-child {
  background-color: transparent;
  border: none;
  width: 10px;
}

.trp-overview .el-table__body .el-table__row td:last-child {
  border: none;
  text-indent: -16px;
  color: #737373;
}
</style>