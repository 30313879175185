<template>

    <card class="card comments mb-0" no-footer-line>
        <template slot="header">
            <div class="card-title mb-0">
                <h3 class="mb-0">Comments</h3>
            </div>
        </template>

        <div>
            <div class="row comments-row">
                <div class="col col-12 col-lg-6">
                    <comment-input :objectOfOriginType="objectOfOriginType" :objectOfOrigin="objectOfOrigin" :getChat="getComments" />
                </div>
                <div class="col col-12 col-lg-6">
                    <comments-log :comments="comments" />
                </div>
            </div>
        </div>
    </card>
</template>

<script>
import CommentInput from "./CommentInput.vue";
import CommentsLog from "./CommentsLog.vue";

export default {
    name: "Comments",
    components: {
        [CommentInput.name]: CommentInput,
        [CommentsLog.name]: CommentsLog,
    },
    data() {
        return {
            comments: [],
        };
    },
    methods: {
        getComments(){
            let path = this.$constants.apiPaths.comments(
                this.objectOfOriginType,
                this.objectOfOrigin.marathonCountryID,
                this.objectOfOrigin.clientID,
                this.objectOfOrigin.campaignID
            );
            this.axios.get(path)
                .then((res) => {
                    if(res.data){
                        this.comments = res.data.reverse();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    mounted(){
        this.getComments();
    },
    computed:{
        objectOfOriginType(){
            return this.$route.meta.routeGroup;
        },
        objectOfOrigin(){
            if(this.$route.params.planID){
                return {
                    marathonCountryID: this.$route.params.marathonCountryID,
                    clientID: this.$route.params.clientID,
                    campaignID: this.$route.params.campaignID,
                    planID: this.$route.params.planID,
                };
            }
            return {
                marathonCountryID: this.$route.params.marathonCountryID,
                clientID: this.$route.params.clientID,
                campaignID: this.$route.params.campaignID
            };
        }
    }
};
</script>