<template>
    <b-modal ref="userModal" id="user-modal" size="lg" :centered="true" hide-footer>
        <b-form class="w-100">
            <b-container>
                <div class="row">
                    <div class="col-12">
                        <h2>{{ user.userID ? "" : "New " }}Connect User <span style="font-weight: 200;">{{ user.name ? ' | ' + user.name : '' }}</span></h2>
                    </div>
                    <div class="col">
                        <TextInput v-model="user.name" label="Name" :required="true" />
                        <TextInput v-model="user.email" label="Email" />
                        <base-select v-model="user.companyName" :required="false" label="Company" >
                          <option v-for="company in companies" :value="company" :key="company">{{company}}</option>
                        </base-select>
                        <!-- <TextInput v-model="user.type" :required="true" label="Type" /> -->
                        <base-select v-model="user.type" required label="Type">
                            <option v-for="type in userTypes" :value="type" :key="type">{{type}}</option>
                        </base-select>
                        <TextInput v-model="user.employeeID" :required="false" label="Marthon Username" maxlength="4" />

                    </div>
                    <div class="col group-select">
                        <el-select multiple v-if="groups" collapse-tags v-model="user.groupIDS" placeholder="No groups selected">
                            <el-option v-for="group in groups" class="select-primary" :value="group._id" :label="group.groupName" :key="group.groupName">
                                {{group.groupName}}
                            </el-option>
                        </el-select>
                    </div>

                    <div class="col-12 d-flex justify-content-between">

                        <base-button :loading="isLoadingSave" :disabled="isLoadingSave" class="animation-on-hover user-modal-buttons" v-on:click="submitUser"
                            type="primary">Save user changes</base-button>

                        <base-button :loading="isLoadingPassword" :disabled="isLoadingPassword" class="animation-on-hover user-modal-buttons"
                            v-on:click="resetPassword" type="info">Reset Password</base-button>

                        <base-button v-if="!isLoadingInvitation" class="animation-on-hover user-modal-buttons" v-on:click="sendInvitationEmail" type="success">
                            Send invitation</base-button>
                        <base-button v-if="isLoadingInvitation" loading class="animation-on-hover user-modal-buttons" type="success">Send invitation
                        </base-button>

                        <base-button v-if="!isLoadingDeleteUser" class="animation-on-hover user-modal-buttons" v-on:click="deleteConfirmation" type="danger">
                            Delete User</base-button>
                        <base-button v-if="isLoadingDeleteUser" loading class="animation-on-hover user-modal-buttons" type="danger">Delete User</base-button>
                    </div>
                </div>
            </b-container>
        </b-form>
    </b-modal>
</template>

<script>
import {BaseButton} from 'src/components'
import TextInput from "@/components/Inputs/TextInput.vue";
import {Select, Option} from 'element-ui'
import { mapState } from 'vuex'

export default {
    props: ['user', 'postUser'],
    data() {
        return {
            isLoadingSave: false,
            isLoadingPassword: false,
            isLoadingInvitation: false,
            isLoadingDeleteUser: false,
            showAlert: false,
            userMessage: '',
            userStatus: [
                { value: 'active', text: 'Active' },
                { value: 'inactive', text: 'Inactive' }],
        };
    },
    components: {
        Select,
        Option,
        BaseButton,
        TextInput
    },
    computed:{
        ...mapState([
            'groups',
            'isLoadingGroups',
        ]),
        companies() { return [ 'Nordisk Film','Publicis','Kindred','Sydbank']},
        userTypes() { return [ 'EXTERNAL','INTERNAL']},
    },
    methods: {
        getUsers(force){
            this.$store.dispatch("getUsers", [this.axios, force]);
        },
        deleteConfirmation(){
            this.$swal.fire({
                title: 'Are you sure you want to delete ' + this.user.name + '?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete user!'
            }).then((result) => {
                if (result.value) {
                    this.deleteUser();
                }
            });

        },
        deleteUser() {
            this.isLoadingDeleteUser = true;
            const url = this.$constants.apiPaths.administration.users+'?userID='+this.user.userID;
            this.axios.delete(url).then((result) => {
                this.isLoadingDeleteUser = false;
                //this.getUsers();
                this.$swal.fire(
                    'Deleted!',
                    'User ' + this.user.name + ' deleted.',
                    'success').then(() => {
                    this.$emit("close");
                });
            })
                .catch((error) => {
                    this.isLoadingDeleteUser = false;
                });
        },
        resetPassword() {
            this.isLoadingPassword = true;
            const url = this.$constants.apiPaths.emails.passwordReset;
            const payload = {
                email: this.user.email
            };

            this.axios.post(url, payload).then((result) => {
            }).then((result) => {
                this.isLoadingPassword = false;
                this.$swal.fire(
                    'Email sent!',
                    'A reset password email has been sent to ' + this.user.name,
                    'success').then(() => {
                });
            })
                .catch((error) => {
                    this.isLoadingPassword = false;
                });
        },
        sendInvitationEmail() {
            this.isLoadingInvitation = true;
            const url = this.$constants.apiPaths.emails.invitation;
            const payload = {
                email: this.user.email
            };

            this.axios.post(url, payload).then((result) => {
                this.isLoadingInvitation = false;
                this.$swal.fire(
                    'Email sent!',
                    'A new invitation email has been sent to ' + this.user.name,
                    'success').then(() => {
                });
            })
                .catch((error) => {
                    this.isLoadingInvitation = false;
                });
        },
        submitUser(evt) {
            evt.preventDefault();
            this.postUser(this.user);
        },
        // sendUserRequest(user) {
        //     const path = this.$constants.apiPaths.administration.users;
        //     const payload = user;
        //     this.isLoadingSave = true;
        //     if(user.userID==null){
        //         this.axios.post(path, payload)
        //             .then((res) => {
        //                 document.querySelector('#user-modal').classList.remove("show")
        //                 this.isLoadingSave = false;
              
        //                 this.$swal.fire(
        //                     'User has been saved',
        //                     'A new user has been created with name: ' + this.user.name,
        //                     'success').then(() => {
        //                 });

        //             })
        //             .catch((error) => {
              
        //                 this.isLoadingSave = false;
        //                 console.log(error);
        //             });
        //     } else {
        //         this.axios.put(path, payload)
        //             .then((res) => {
        //                 this.isLoadingSave = false;
              
        //                 this.$swal.fire(
        //                     'User has been saved',
        //                     this.user.name + ' has been saved',
        //                     'success').then(() => {
        //                 });
        //             })
        //             .catch((error) => {
        //                 this.isLoadingSave = false;
              
        //                 console.log(error);
        //             });


        //     }
        // },
        getGroups(force){
            this.$store.dispatch("getGroups", [this.axios, force]);
        },
    },
    created() {
        this.getGroups();

    },
};
</script>

<style>
.group-select .select-primary .el-tag, .select-primary .el-tag.el-tag--info {
  background-color: #00B7A6;
}

.group-select .el-input__inner {
  background-color: transparent;
  min-height: 47px;
  border-color: #333333;
  border-radius: 6px;
}

.user-modal-buttons {
  margin-top: 18px;
  margin-right: 12px;
}
</style>
