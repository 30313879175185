<template>
    <div :class="[asCard? 'card graph' : 'graph', selectOptions && selectOptions.length > 0 ? 'with-select': '']">
        <div class="d-flex justify-content-between">
            <h2 v-if="title" class="graph-title">{{title}}</h2>
            <span class="sub-setting">

                <el-tooltip :content="help" placement="top" v-if="help">
                    <span class="sub-setting-icon">
                        <i class="tim-icons tim-icons-text">?</i>
                    </span>
                </el-tooltip>

            </span>
        </div>
        <div class="card-subheader" v-if="chartData">
            <div class="header-item-wrapper settings-wrapper" v-if="selectOptions && selectOptions.length > 0">
                <span class="label">{{ selectLabel ? selectLabel : 'Metrics:'}}</span> 
                <el-select v-model="selectedValue" placeholder="Metrics" v-on:change="updateValue">
                    <el-option v-for="(item, index) in selectOptions" :key="index" :value="item.value" :label="item.label">
                            {{ item.label }}
                    </el-option>
                </el-select>
            </div>
        </div>
        <div v-else class="d-flex justify-content-center">No data</div>
        <div class="content-wrapper" v-if="chartData || additionalInfo">
            <div class="row chart-row">
                <div v-if="additionalInfo" :class="[additionalInfo ? 'col-8' : 'col-12', 'chart-col']">
                    <div class="chart">
                        <bar-chart :chart-data="sort ? sortedChartData: chartData" :extra-options="options" v-if="chartData && chartData.labels" :style="style" />
                        <!-- <div v-else>No data</div> -->
                    </div>
                </div>
                <div v-if="additionalInfo" class="col-4 chart-col">
                    <div class="additional-info">
                        <div v-for="(item,index) in additionalInfo" :key="index" class="item">
                            <img :src="item.icon" class="icon" />
                            <div class="label">{{item.value}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
</template>

<script>
//import RoundedBarChart from '@/components/Charts/RoundedBarChart.js'  //TODO - implment RoundedBarChart
//import RoundedRectangle from '@/components/Charts/RoundedRectangle.js'
import HorizontalBarChart from '@/components/Charts/HorizontalBarChart.js'

export default {
    name: 'horizontal-bar-chart',
    inject: ['formatLargeNumber'],
    props: ['title', 'chartData', 'asCard', 'help', 'value', 'selectOptions', 'additionalInfo', 'selectLabel', 'sort'],
    data() {
        return {
            options: {
                layout: {
                    padding: {
                        right: 70,
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            display: false,
                            autoSkip: false,
                            maxRotation: 90,
                            minRotation: 90,
                            fontSize: 14,
                            fontColor: '#FFFFFF',
                            fontFamily: 'Poppins',
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            color: "transparent",
                            zeroLineColor: 'transparent',
                            drawBorder: false,
                        },
                        stacked: false,
                        ticks: {
                            display: true,
                            beginAtZero:true,
                            autoSkip: false,
                            maxRotation: 0,
                            minRotation: 0,
                            fontSize: 14,
                            fontColor: '#FFFFFF',
                            fontFamily: 'Poppins',
                        }
                    }]
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                        padding: 30,
                        fontSize: 12,
                        fontColor: '#FFF',
                        fontStyle: 'bold',
                        fontFamily: 'Poppins',
                        usePointStyle: true,
                    }
                },
                plugins: {
                    datalabels: {
                        display: true, //media
                        color: 'white',
                        textAlign: 'start',
                        align: 'right',
                        font: {
                            size: 14
                        },
                        clamp: true,
                        anchor: 'end',
                        padding: {
                            left: 15
                        },
                        formatter: this.formatLargeNumber
                    }
                }
            },
            selectedValue: this.value,
        
        }
    },
    methods:{
        updateValue: function (value) {
            this.$emit('input', value)
        }
    },
    computed: {
        sortedChartData(){
            var dataSort = [];
            for(var i = 0; i < this.chartData.labels.length && i < this.chartData.datasets[0].data.length; i++ ){
                dataSort.push(
                    { label: this.chartData.labels[i], value: this.chartData.datasets[0].data[i]}
                )
            }
            if(this.sort && this.sort === 'dsc'){
                dataSort.sort((a,b) => (b.value - a.value ));
            }
            if(this.sort && this.sort === 'asc'){
                dataSort.sort((a,b) => (a.value - b.value));
            }
            var chartDataCopy = JSON.parse(JSON.stringify(this.chartData));
            
            chartDataCopy.labels = [];
            chartDataCopy.datasets[0].data = [];
            for(var i = 0; i < dataSort.length; i++ ){
                chartDataCopy.labels.push(
                    dataSort[i].label
                )
                chartDataCopy.datasets[0].data.push(
                    dataSort[i].value
                )
            }
            
            return chartDataCopy;
        },
        style(){
            return  'height: ' +  (this.sort ? (this.sortedChartData.labels.length * 30) : (this.chartData.labels.length * 30)) + 'px; position: relative;';
        }
    },
    components: {
    //[RoundedBarChart.name]: RoundedBarChart,
        [HorizontalBarChart.name]: HorizontalBarChart
    },
}
</script>