<template>
    <div :class="[asCard ? 'card graph' : 'graph', selectOptions && selectOptions.length > 0 ? 'with-select' : '']">
        <div class="d-flex justify-content-between">
            <h2 v-if="title" class="graph-title">{{title}}</h2>
            <span class="sub-setting">

                <el-tooltip :content="help" placement="top" v-if="help">
                    <span class="sub-setting-icon">
                        <i class="tim-icons tim-icons-text">?</i>
                    </span>
                </el-tooltip>

            </span>
        </div>
        <div class="card-subheader" v-if="chartData">
            <div class="header-item-wrapper settings-wrapper" v-if="selectOptions && selectOptions.length > 0">
                <span class="label">{{ selectLabel ? selectLabel : 'Metrics:'}}</span> 
                <el-select v-model="selectedValue" placeholder="Metrics" v-on:change="updateValue">
                    <el-option v-for="(item, index) in selectOptions" :key="index" :value="item.value" :label="item.label">
                            {{ item.label }}
                    </el-option>
                </el-select>
            </div>
        </div>
        <div v-else class="d-flex justify-content-center">No data</div>
        <line-chart :chart-data="chartData" :extra-options="extraOptions" :options="extraOptions" class="chart" v-if="chartData" />
    </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart.js'

export default {
    name: 'styled-line-chart',
    props: {
        title: {
            type: String,
            default: ''
        },
        chartData: Object,
        asCard: {
            type: Boolean,
            default: false
        },
        help: String,
        selectOptions: Array,
        value: {
            type: String,
            default: null
        },
        selectLabel: {
            type: String,
            default: 'Metrics:'
        }
    },
    data() {
        return {
            extraOptions: {
                scales: {
                    scaleLineColor: 'transparent',
                    xAxes: [{
                        gridLines: {
                            color: "#454545",
                            drawBorder: false,
                        },
                        ticks: {
                            autoSkip: false,
                            maxRotation: 90,
                            minRotation: 90,
                            fontSize: 10,
                            fontColor: '#FFFFFF',
                            fontFamily: 'Poppins'
                        },
                    }],
                    yAxes: [
                        {
                            gridLines: {
                                drawBorder: true,
                                color: "transparent",
                            },
                            ticks: {
                                fontSize: 10,
                                fontColor: '#FFFFFF',
                                fontFamily: 'Poppins'
                            }
                        },
                    ]
                },
                legend: {
                    display: false,
                },
                
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        display: false,
                    }
                }
            },
            selectedValue: this.value,
        }
    },
    methods:{
        updateValue: function (value) {
            this.$emit('input', value)
        }
    },
    components: {
        [LineChart.name]: LineChart
    },
}
</script>