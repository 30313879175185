<template>
 <modal
        :show.sync="newUserModalVisible"
        class="modal"
        id="newUserModal"
        :animation-duration="1000"
        :centered="false"
        :show-close="false"
        >
        <div class="row"
              v-if="tokenExpired">
              <div class="col-12">
                Link has expired or is invalid. Reach out to Publicis to get a new link.
                <br>
                <a href="/">Return to frontpage</a>
              </div>
            </div>
         <div class="row"
              v-if="!tokenExpired">
              <div class="col-12">
                <span style="text-align: center">
                    <h2>Create User</h2>
                </span>

                <form @submit.prevent>
                    <base-input label="Enter Name"
                    v-model="user.name"
                    type="text"
                    placeholder="Enter name">
                    </base-input>
                    <base-input label="Enter Name"
                    v-model="user.password"
                    type="password"
                    placeholder="Enter Password">
                    </base-input>
                    <base-input label="Re-enter new password"
                    v-model="reEntereredPassword"
                    type="password"
                    placeholder="Re-enter password">
                    </base-input>
                    <div class="d-flex justify-content-center">

                        <button class="btn primary" @click="editUser($route.query.token)">Create user</button>
                    </div>
                </form>
              </div>
            </div>
            <div class="row"
              v-if="userCreated">
              <div class="col-12">
                User succesfully created.
                <br>
                <a href="/">Return to frontpage</a>
              </div>
            </div>
</modal>
</template>

<script>
import axios from 'axios';
import { Modal } from '@/components';
import config from '@/config';

export default {
    data () {
        return {
            reEntereredPassword: '',
            errorMsg: '',
            user: {name: '',
                password: ''},
            userCreated: false,
            tokenExpired: true,
        }
    },
    props: ['newUserModalVisible'],
    components: {
        Modal,
    },
    methods: {
        allowUser(token){
            const path = config.apiUrl + '/resetPassword';
            this.axios.get(path,{ headers: { Authorization: `Bearer: ${token}` } })
                .then((res) => {
                    this.tokenExpired = false;
                    // eslint-disable-next-line
        })
                .catch((error) => {
                    // eslint-disable-next-line
          this.tokenExpired = true
                    console.log(error);
                });
        },
        editUser(token){
            console.log(token);
            const path = config.apiUrl + '/users';
            const payload = this.user;
            this.axios.put(path, payload,{ headers: { Authorization: `Bearer: ${token}` } })
                .then((res) => {
                    this.userCreated = true
                    // eslint-disable-next-line
        })
                .catch((error) => {
                    // eslint-disable-next-line

                    console.log(error);
                });
        },
    },
    mounted () {
        if(this.newUserModalVisible){
            this.allowUser(this.$route.query.token);
        }
    }
}
</script>
<style lang="scss" scoped>

.close
{
  display: none;
}
</style>
