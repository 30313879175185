<template>
    <div :class="['col' , 'card', 'brief-info-card', 'h-100', disabled ? 'disabled' : '']">
        
        <div class="card-header" v-if="$slots.header || (title !== null && title !== undefined)" :class="headerClasses">
            <slot name="header">
                <h2>{{ title }}</h2>
                <slot name="title" v-if="!title">
                </slot>
                
            </slot>
        </div>
        <div class="card-body" :class="bodyClasses">
            <div class="info-table" cellspacing="0" cellpadding="0">
                <div class="row header">
                    <!-- <div class="col">{{ brief && brief.submittedBy ? 'Submitted by' : 'Edited by'}} {{brief.submittedBy}} </div> -->
                    <div class="col">{{ brief && brief.submittedBy ? 'Submitted by' : 'Edited by'}}</div>
                    <div class="col">Date {{ brief && brief.submittedBy ? 'submitted' : 'edited'}} </div>
                    <div class="col">Country </div>
                    <div class="col">Unit </div>
                </div>
                <div class="row body">
                    <div class="col">
                        <el-popover class="custom-popper" trigger="hover" placement="top" v-if="brief && (brief.submittedBy || brief.editedBy)">
                            <div class="popover-wrapper">
                                <div class="popover-body">
                                    <div >{{ brief.updateUser.name }}</div>
                                </div>
                            </div>
                            <span slot="reference">
                                <span class="table-icon" :style="'background: ' + brief.updateUser.color + ';'">
                                    <span class="table-icon-popover">{{ brief.updateUser.initials }}</span>
                                </span>
                            </span>
                        </el-popover>
                        <span v-else>---</span>
                    </div>
                    <div class="col">
                        <span v-if="brief && brief.submittedBy">
                            {{brief.submitted}}
                        </span>
                        <span v-else-if="brief && brief.editedBy">
                            {{brief.lastEdited}}
                        </span>
                        <span v-else>---</span>
                    </div>
                    <div class="col">
                        <span v-if="brief && brief.marathonCountryID">
                            {{brief.marathonCountryID}}
                        </span>
                        <span v-else>---</span>
                        </div>
                    <div class="col">
                    <span v-if="brief && brief.clientID">
                        {{brief.clientID}}
                    </span>
                    <span v-else>---</span>
                    </div>
                </div>
            </div>
            <router-link :to="route" >
                <base-button class="mt-3">Open brief</base-button>
            </router-link>
        </div>
        <div class="card-footer" :class="footerClasses" v-if="$slots.footer">
            <hr v-if="showFooterLine" />
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
import BaseButton from '../BaseButton.vue';

export default {
    components: { BaseButton },
    name: 'brief-info-card',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        title: {
            type: [String, Number],
            default: 'Brief information'
        },
        brief: {
            type: Object
        },
        headerClasses: {
            type: [String, Object, Array],
            default: '',
            description: 'Card header css classes'
        },
        bodyClasses: {
            type: [String, Object, Array],
            default: '',
            description: 'Card body css classes'
        },
        footerClasses: {
            type: [String, Object, Array],
            default: '',
            description: 'Card footer css classes'
        },
    },
    computed:{
        route(){
            return {
                name: this.$constants.routeNames.briefs.preview,
                params: {
                    marathonCountryID: this.brief.marathonCountryID,
                    clientID: this.brief.clientID,
                    campaignID: this.brief.campaignID,
                },
            };
        }
    }
};
</script>
<style lang="scss" scoped>
    .card-header h2{    
        margin-bottom: 15px;
    }

    .card-body{
        padding-top: 0;
    }

    .info-table{
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        .header{
            font-weight: 600;
            font-size: 9px;
            height: 10px;
            line-height: 1;
            text-align: center;
            margin-bottom: 10px;
        }

        .body{
            font-weight: 600;
            font-size: 15px;
            line-height: 1;
            height: 18px;
            text-align: center;

            .col .custom-popper{
                display: inline-block;
            }
        }

        .col:first-child{
            text-align: left;
        }

        &.icon-cell{
            .cell{
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .table-icon{
            color: #000;
            background-color: #AFAFAF;
            font-size: 12px;
            font-weight: 600;
            width: 35px;
            height: 35px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
            cursor: default;
        }
    }
</style>