<template>
    <div class="header-item-wrapper filter-wrapper with-label">
        <el-dropdown :hide-on-click="false" trigger="click" placement="bottom-start">
           <base-button class="btn btn-round btn-gray btn-long btn-small" size="sm" color="grey" >
                <i class="icon-connect icon-connect-filter"></i>&nbsp;Filters <i class="el-icon el-icon-caret el-icon-caret-bottom"></i>
            </base-button>
                <el-dropdown-menu slot="dropdown" class="filter-dropdown-menu">
                <div class="row">
                    <div class="col list-container">
                        <checkbox-group label="Show rows" :options="rowsOptions" v-model="localRows" />
                    </div>
                        <div class="col list-container">
                            <checkbox-group label="Show Columns" :options="columnsOptions" v-model="localColumns" />
                        </div>
                </div>
                <div class="row pt-3">
                    <div class="col d-flex justify-content-end">
                        <BaseButton type="success" @click="saveFilter">Save filter</BaseButton>
                    </div>
                </div>
            </el-dropdown-menu>
        </el-dropdown>
            <!-- <BaseButton tag="button" :round="true" type="gray" :class="[active ? 'active' : '', 'btn-long']" @click="active = !active">
                <i class="icon-connect icon-connect-filter"></i>&nbsp;Filters
            </BaseButton>
            <Card class="filter_container">
                <div>
                    <span>Show rows</span>
                    <label><input type="checkbox" name="rows_all" @change="rowsAll($event)" /> All</label>
                    <hr />
                    <div class="list-container" ref="containerRows">
                        <ul>
                            <li v-for="(label, key) in rowsMap" :key="key">
                                <span>
                                    <input
                                        type="checkbox"
                                        name="rows"
                                        v-model="localRows"
                                        :value="key"
                                        :checked="localRows.includes(key)"
                                    />{{ label }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <span>Show Columns</span>
                    
                    <label><input type="checkbox" name="columns_all" @change="columnsAll($event)" /> All</label>
                    <hr />
                    <div class="list-container" ref="containerColumns">
                        <ul>
                            <li v-for="(label, key) in columnsMap" :key="key">
                                <label
                                    ><input
                                        type="checkbox"
                                        name="rows"
                                        v-model="localColumns"
                                        :value="key"
                                        :checked="localColumns.includes(key)"
                                    />{{ label }}</label
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <BaseButton type="success" @click="saveFilter">Save filter</BaseButton>
            </Card> -->
    </div>
</template>
<script>
import Card from "@/components/Cards/Card.vue";
import BaseButton from "@/components/BaseButton.vue";
import CheckboxGroup from '@/components/Inputs/CheckboxGroup';

export default {
    name: "TableFilter",
    components: {
        Card,
        BaseButton,
        [CheckboxGroup.name]: CheckboxGroup,
    },
    props: {
        rows: {
            type: Array,
            default: () => ["*"],
        },
        columns: {
            type: Array,
            default: () => ["*"],
        },
        rowsMap: {
            type: Object,
            default: () => ({}),
        },
        columnsMap: {
            type: Object,
            default: () => ({}),
        },
        storageKey: {
            type: String,
            default: "TABLE_FILTER",
        },
    },
    data() {
        return {
            active: false,
            localRows: this.rows,
            localColumns: this.columns,
        };
    },
    methods: {
        updateRows() {
            this.$emit("updateRows", this.localRows);
        },
        updateColumns() {
            this.$emit("updateColumns", this.localColumns);
        },
        rowsAll(ev) {
            const all = ev.target.checked;
            this.localRows = all ? Object.keys(this.rowsMap) : [];
        },
        columnsAll(ev) {
            const all = ev.target.checked;
            this.localColumns = all ? Object.keys(this.columnsMap) : [];
        },
        emitFilters() {
            this.updateRows();
            this.updateColumns();
        },

        saveFilter() {
            this.emitFilters();
            this.active = false;
            this.storeData();
        },

        storeData() {
            const data = {
                rows: this.localRows,
                columns: this.localColumns,
            };

            sessionStorage.setItem(this.storageKey, JSON.stringify(data));
        },
    },
    watch:{
        rows(newVal){
            this.localRows = newVal;
        }
    },

    mounted() {
        const dataFromStorage = sessionStorage.getItem(this.storageKey);
        if (dataFromStorage) {
            const { rows, columns } = JSON.parse(dataFromStorage);
            this.localRows = rows;
            this.localColumns = columns;

            this.emitFilters();
        }
    },
    computed:{
        rowsOptions(){
            let options = []
            for (const [key, value] of Object.entries(this.rowsMap)) {
                options.push({value: key, label: value});
            }
            return options;
        },
        columnsOptions(){
            let options = []
            for (const [key, value] of Object.entries(this.columnsMap)) {
                options.push({value: key, label: value});
            }
            return options;
        }
    },
};
</script>
