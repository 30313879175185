<template>
    <div :class="['universal-list', showClickable ? '' : 'not-clickable', notCard ? '': 'card', ...listClasses]">
        <div class="d-flex justify-content-between">
            <h2 class="universal-list-title">{{title}}</h2>
            <span class="sub-setting">
                <slot name="action-buttons"></slot>
                <el-tooltip :content="help" placement="top" v-if="help">
                    <span class="icon-help sub-setting-icon">
                        <i class="tim-icons tim-icons-text">?</i>
                    </span>
                </el-tooltip>

            </span>
        </div>
        <el-table :data="tableData" @row-click="handleRowClick">

            <el-table-column align="left" label="" :width="50" v-if="showClickable">
                <template slot-scope="scope">
                    <base-button class="edit btn-link" type="success" size="sm"
                        @click="goToDetail(scope.row.marathonCountryID, scope.row.clientID, scope.row.campaignID)" icon>
                        <i class="tim-icons icon-double-right"></i>
                    </base-button>
                </template>

            </el-table-column>

            <el-table-column :width="20" label="" className="status" v-if="showStatus">
                <template slot-scope="scope">
                    <el-popover class="custom-popper" trigger="hover" placement="right">
                        <div class="popover-wrapper">
                            <h3 class="popover-header">{{ scope.row.status.toLowerCase() }}</h3>
                        </div>
                        <span slot="reference">
                            <span :class="['state', 'without-text', scope.row.status.toLowerCase()]"> </span>
                        </span>
                    </el-popover>
                </template>
            </el-table-column>

            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :width="column.width" :prop="column.prop"
                :label="column.label" :className="column.prop" :sortable="true">
            </el-table-column>

        </el-table>

    </div>
</template>

<script>
import {
    BasePagination
} from 'src/components';
export default {
    name: 'universal-list',
    components: {
        BasePagination,
    },
    props: {
        help: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'List',
            description: 'List title'
        },
        tableData: {
            type: Array,
            default: function () {
                return []
            },
        },
        tableColumns: {
            type: Array,
            default: function () {
                return []
            },
        },
        listClasses: {
            type: Array,
            default: function () {
                return []
            },
        },
        showStatus: {
            type: Boolean,
            default: true
        },
        showClickable: {
            type: Boolean,
            default: true
        },
        notCard: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            pagination: {
                perPage: 10,
                currentPage: 1,
            },
        }
    },
    computed: {
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.tableData.length;
        },
    },
    methods: {
        handleRowClick(row, index) {
            this.$emit('row-click', row, index);
        },
    },
};
</script>

