<template>
    <div v-if="data">
       <div class="insights-wrapper insights-sticky-wrapper row no-inside-gutter">
            <div class="col col-12">
                <div class="card settings">
                    <div ref="datepickerWrapper" style="position: relative;">
                        <div class="header-item-wrapper datepicker-wrapper settings-wrapper">
                            <div class="datepicker-opener" @click.self="toggleDatePicker()" />
                            <span class="label">Date:</span> 
                            <date-picker v-model="dateRange" range 
                                format="YYYY/MM/DD" 
                                valueType="YYYY-MM-DD"
                                @change="selectDates"   
                                range-separator=" to " 
                                popup-class="connect-datepicker"
                                :clearable="false"
                                prefix-class="xmx"
                                :editable="false"
                                :multiple="true"
                                :lang="vue2pickerOptions"
                                :open="pickerOpen"
                                ref="datepicker"
                                @blur="pickerOpen = false"
                                :disabled-date="isFutureDate"
                                placeholder="Start date to End date"  :append-to-body="false"  :popup-style="{ left: '0px', top: '30px'}"

                            >
                            <template v-slot:icon-calendar><span></span></template>
                            <template v-slot:input >
                                        <span class="from-to">from:</span>
                                        <input name="date" type="text" autocomplete="off" placeholder="Start date" class="xmx-input" v-model="dateRange[0]">
                                        <span class="from-to">to:</span>
                                        <input name="date" type="text" autocomplete="off" placeholder="End date" class="xmx-input" v-model="dateRange[1]">
                            </template>
                            </date-picker>
                            <i class="el-icon el-icon-caret el-icon-caret-bottom"></i>   
                        </div>
                    </div>
                    
                    <div class="header-item-wrapper settings-wrapper with-label">
                        <el-dropdown :hide-on-click="false" trigger="click" placement="bottom-start">
                            <base-button class="btn-link" size="sm">
                                <span class="label">Genre:</span>{{ selectedGenre }} <i class="el-icon el-icon-caret el-icon-caret-bottom"></i>
                            </base-button>
                            <el-dropdown-menu slot="dropdown" class="sort-dropdown-menu" v-if="genreFilterOptions && genreFilterOptions.length > 0" :append-to-body="false">
                                <h4 class="menu-title">Choose genre(s)</h4> 
                                <el-radio-group v-model="selectedGenre" class="sort-dropdown-menu">
                                    <el-radio label="All"> All</el-radio>
                                    <el-radio :label="genre" v-for="(genre, index) in genreFilterOptions" :key="index">{{ genre }}</el-radio>
                                </el-radio-group>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="header-item-wrapper settings-wrapper with-label">
                        <el-dropdown :hide-on-click="false" trigger="click" placement="bottom">
                            <base-button class="btn-link" size="sm">                                              
                                <span class="label">Movies:</span>{{ selectedMoviesLabel}} <i class="el-icon el-icon-caret el-icon-caret-bottom"></i>
                            </base-button>
                            <el-dropdown-menu slot="dropdown" class="filter-dropdown-menu" :append-to-body="false">
                                <h4 class="menu-title">Choose movie(s)</h4> 
                                <div class="row">
                                    <div class="col">
                                        <checkbox-group optionValueKey="imdbPageID" optionLabelKey="movie" :options="moviesFilterOptions" v-model="selectedMovies" />
                                    </div>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="row card-row" v-if="data">
            <cta-card icon="/img/icons/icon-user.svg" :title="selectedGenre" subtitle="Genre" class="title" />
            <cta-card icon="/img/icons/icon-eye.svg" :title="formatLargeNumber(data.impressions)" subtitle="Impressions"  :value-delta="data.impressionsDelta"/>
            <cta-card icon="/img/icons/icon-click.svg" :title="formatLargeNumber(data.clicks)" subtitle="Clicks"  :value-delta="data.clicksDelta"/>
            <cta-card icon="/img/icons/icon-money.svg" :title="formatLargeNumber(data.spend)" subtitle="Spend" :value-delta="data.spendDelta" />
            <cta-card icon="/img/icons/icon-target.svg" :title="formatLargeNumber(data.ctr)" subtitle="CTR" :value-delta="data.ctrDelta" />
            <cta-card icon="/img/icons/icon-expense.svg" :title="formatLargeNumber(data.cpm)" subtitle="CPM" :value-delta="data.cpmDelta"  :value-delta-reverse="true" />
            <cta-card icon="/img/icons/icon-cpc.svg" :title="formatLargeNumber(data.cpc)" subtitle="CPC" :value-delta="data.cpcDelta" :value-delta-reverse="true" />
        </div>
        <div class="row no-inside-gutter">
            <div class="col col-12">
                <styled-line-chart :chart-data="timelineChartData" title="Timeline" :as-card="true" :help="timelineSelectedKey+' pr. day. Data is extracted from DCM and DV360. Keep in mind that all cost metrics does not include cost data from DCM.'" :select-options="timelineDataOptions" v-model="timelineSelectedKey" />
            </div>
        </div>
        <div class="row">
             <div class="col col-12 col-lg-6 insights-col">
                <selectable-list title="Movies" :table-data="data.movies" v-model="moviesSelectedKey" :select-options="moviesDataOption"  :list-classes="['insights-card',]" :help="moviesSelectedKey+' pr. Movie. Movies are based on a mapping between campaigns ran and a imdb. Data is extracted from DCM and DV360. Keep in mind that all cost metrics does not include cost data from DCM.'" title-prop="title" />
            </div>
             <div class="col col-12 col-lg-6 insights-col">
                <horizontal-bar-chart sort="dsc" :chart-data="mediaChartData" title="Media" :as-card="true" :help="mediaSelectedKey+' pr. media advertised on. Data is extracted from DCM and DV360. Keep in mind that all cost metrics does not include cost data from DCM. This graph is not affected by the time filter, only the genre and movie filter.'" :select-options="mediaDataOptions" v-model="mediaSelectedKey" class="insights-card media-chart" />
            </div>
        </div>
          <div class="row">
             <div class="col col-12 col-lg-6 insights-col">
                <selectable-list title="Audience" :table-data="audiencesData" v-model="audiencesSelectedKey" :select-options="audiencesDataOptions" :list-classes="['insights-card',]" :help="audiencesSelectedKey+' pr. Google Programmatic Affinity Audience. For more information see: https://support.google.com/displayvideo/answer/6021489?hl=en. Data is extracted from DV360. This graph is not affected by the time filter, only the genre and movie filter.'"  title-prop="audienceType"/>
            </div>
             <div class="col col-12 col-lg-6 insights-col">
                <horizontal-bar-chart :chart-data="ageChartData" v-model="ageGenderSelectedKey" :select-options="agesDataOptions" title="Age & gender performance" :as-card="true" :help="ageGenderSelectedKey+' pr. Google Programmatic Demographic Classification. Data is extracted from DV360. This graph is not affected by the time filter, only the genre and moive filter.'" class="insights-card" :additional-info="genderData"/>
            </div>
        </div>
    </div>
</template>

<script>
import ClientCountrySelect from "@/components/ClientCountrySelect/ClientCountrySelect.vue";

import CTACard from '@/components/Cards/CTACard.vue'
import SelectableList from '@/components/SelectableList/SelectableList.vue'
import HorizontalBarChart from '@/components/HorizontalBarChart/HorizontalBarChart.vue'
import StyledLineChart from '@/components/StyledLineChart/StyledLineChart'
import CheckboxGroup from '@/components/Inputs/CheckboxGroup';
import DatePicker from 'vue2-datepicker';
import dayjs from 'dayjs';

//hack on opening calendar to show prev+current month
//but if range is selected still prev + current
const updateCalendars = DatePicker.CalendarRange.methods.updateCalendars;
DatePicker.CalendarRange.methods.updateCalendars = function (...args) {
    const firstDayOfRange = this.innerValue[0];
    // we need today and diff because if range is in current month only
    // then we dont want to show prev + current month
    const today = new Date();
    const diff = firstDayOfRange.getMonth() + firstDayOfRange.getYear() - today.getMonth() - today.getYear();
    updateCalendars.apply(this, args);
    const rangeFits = !!firstDayOfRange.getTime();
    if (args.length === 1 && !rangeFits || diff === 0) {
        this.calendars = this.calendars.map((v) => {
            const next = new Date(v);
            next.setMonth(next.getMonth() - 1);
            return next;
        });
    }
};
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
    if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
            _arr.push(_s.value);

            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally {
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally {
            if (_d) throw _e;
        }
    }

    return _arr;
}

function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;

    for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

    return arr2;
}

function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

const getRangeClasses = function getRangeClasses(cellDate, currentDates, classnames) {
    var classes = [].concat(this.getClasses(cellDate, currentDates, classnames));
    //if (/disabled|active/.test(classnames)) return classes;

    var inRange = function inRange(data, range) {
        var fn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (v) {
            return v.getTime();
        };
        var value = fn(data);

        var _range$map = range.map(fn),
            _range$map2 = _slicedToArray(_range$map, 2),
            min = _range$map2[0],
            max = _range$map2[1];

        if (min > max) {
            var _ref = [max, min];
            min = _ref[0];
            max = _ref[1];
        }

        return value >= min && value <= max;
    };

    if (currentDates.length === 2 && inRange(cellDate, currentDates)) {
        classes.push('in-range');
    }

    if (currentDates.length === 1 && this.hoveredValue && inRange(cellDate, [currentDates[0], this.hoveredValue])) {
        classes.push('hover-in-range');
    }

    if (currentDates.length > 0 && currentDates[0].getTime() == cellDate.getTime()) {
        classes.push('start-date'); 
    }

    if (currentDates.length > 1 && currentDates[1].getTime() == cellDate.getTime()) {
        classes.push('end-date'); 
    }

    return classes;
}
DatePicker.CalendarRange.methods.getRangeClasses = getRangeClasses;

export default {
    props: ['userSelectedMultipleClients', 'selectedClient', 'userSelectedCountry'],
    inject: ['handleRequestError', 'capitalizeFirstLetter', 'formatFullNumber', 'formatLargeNumber'],
    data() {
        return {
            apiData: null,
            data: null,
            loading: false,
            timelineSelectedKey: 'impressions',
            moviesSelectedKey: 'impressions',
            mediaSelectedKey: 'impressions',
            ageGenderSelectedKey: 'impressions',
            audiencesSelectedKey: 'impressions',
            selectedGenre: 'All',
            selectedMovies: [],
            dateRange: [],
            pickerOpen: false,
            moviesFilterOptions: [],
            genreFilterOptions: [],

            selection: {
                dateRange: null,
                genre: null,
                movies: [],
            }
        }
    },
    mounted(){
        const today = dayjs().format('YYYY-MM-DD');
        this.dateRange = [null, today]
        this.getData(this.dateRange);
    },
    methods: {
        selectDates(){
            this.pickerOpen = false;
            this.selection.dateRange = this.dateRange;
        },
        toggleDatePicker() {
            this.pickerOpen = !this.pickerOpen;
        },
        getData(){
            var movies = this.selection.movies;
            if(this.selectedMovies.length === 0 || this.selectedMovies.length === this.moviesFilterOptions.length){
                movies = null;
            }
            this.fetchData(this.selection.dateRange, this.selection.genre, movies)
                .then((data) => {
                    if (data) {
                        if(data.moviesFilter && data.moviesFilter.length > 0 && data.moviesFilter.length !== this.moviesFilterOptions.length){
                            this.moviesFilterOptions = data.moviesFilter;
                        }
                        if(data.genresFilter && data.genresFilter.length > 0 && data.genresFilter.length !== this.genreFilterOptions.length){
                            var options = [];
                            var that = this;
                            data.genresFilter.forEach(function(item,index){
                                options.push(that.capitalizeFirstLetter(item));
                            })
                            this.genreFilterOptions = options;
                        }
                        this.data = data;
                        if(this.$refs.datepicker){
                            this.reappendPicker();
                        }

                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.handleRequestError(error);
                    this.loading = false;
                });
        },
        fetchData(dateRange, genre, movies) {
            this.loading = true;
            const params = new URLSearchParams();
            
            if(this.userSelectedCountry){
                var list = [this.userSelectedCountry]
                params.append('marathonCountryID', JSON.stringify(list));
            }
            if(this.selectedClient){
                var list = [this.selectedClient.value]
                params.append('clientID', JSON.stringify(list));
            }
            else if(this.userSelectedMultipleClients && this.userSelectedMultipleClients.length > 0){
                params.append('clientID', JSON.stringify(this.userSelectedMultipleClients))
            }

            if(dateRange && dateRange.length === 2){
                params.append('startDate', dateRange[0]);
                params.append('endDate', dateRange[1]);
            }
            if(genre){
                params.append('genre', genre);
            }
            if(movies && movies.length > 0 && movies.length !== this.moviesFilterOptions.length){
                var moviesString = JSON.stringify(movies)
                params.append('imdbPageID', moviesString);
            }

            //const path = this.$constants.apiPaths.liveDashboard.insights;
            const path = this.$constants.apiPaths.insights;
            return this.axios(path, {
                params: params
            })
                .then((res) => {
                    if (res.data) {
                        //this.apiData = res.data
                        return res.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.handleRequestError(error);
                    this.loading = false;
                    return null;
                });
        },
        goBack() {
            this.$router.go(-1);
        },
        reappendPicker(){
            this.$nextTick(()=>{
                const el = this.$refs.datepicker.$refs.popup.$el;
                this.$refs.datepickerWrapper.appendChild(el);
            });
         
        },
        isFutureDate(date){
            return date.getTime() > new Date().getTime();
        }
    },
    watch:{
        selection:{
            deep: true,
            handler(newVal){
                this.getData();
            }
        },
        userSelectedCountry(){
            this.getData();
        },
        selectedClient:{
            deep: true,
            handler(){
                this.getData();
            }
        },
        selectedMovies (newVal, oldVal) {
            if(newVal !== oldVal && 
            !(newVal.length === this.moviesFilterOptions.length && oldVal.length === 0) &&
            !(oldVal.length === this.moviesFilterOptions.length && newVal.length === 0) ){
                this.selection.movies = newVal;
            }
        },
        selectedGenre(newVal){
            if(newVal !== 'All'){
                this.selection.genre = newVal;
            }
            else{
                this.selection.genre = null;
            }
        },
    },
    computed: {
        ageData(){
            if(this.data && this.data.ages && this.data.ages.length > 0){
                var ageData = {};
                for(var i = 0; i < this.data.ages.length; i++){
                    Object.entries(this.data.ages[i]).forEach(function transformKeys(agesObject, index){
                        if(!ageData[agesObject[0]]){
                            ageData[agesObject[0]] = [];
                        }
                        ageData[agesObject[0]].push(agesObject[1]);
                    })
                }
                return ageData;
            }
            return undefined;
        },
        agesDataOptions(){
            if(this.data && this.data.ages && this.data.ages.length > 0){
                var agesOptions = [];
                Object.keys(this.data.ages[0]).forEach((key) => {
                    if(agesOptions.indexOf(key) < 0  && key !== 'audienceSubType'){
                        if(this.$constants.optionsDictionary[key]){
                            agesOptions.push({ label: this.$constants.optionsDictionary[key], value: key})
                        }
                        else{
                            agesOptions.push({ label: key.toUpperCase(), value: key})
                        }
                    }
                })
                return agesOptions;
            }
            return undefined;
        },
        ageChartData(){
            if(this.ageData){
                return  {
                    labels: this.ageData.audienceSubType ? this.ageData.audienceSubType : [],
                    datasets: [
                        {
                            label: '',
                            backgroundColor: '#2A34FF',
                            hoverBackgroundColor: '#2A34FF',
                            data: this.ageData[this.ageGenderSelectedKey],
                            
                            barThickness: 20,
                            maxBarThickness: 20,
                        }, 
                    ]
                }
            }
            return undefined;
        },
        audiencesData(){
            return this.data.audiences
        },
        audiencesDataOptions(){
            if(this.audiencesData && this.audiencesData.length > 0){
                var audienceOptions = [];
                Object.keys(this.audiencesData[0]).forEach((key) => {
                    if(audienceOptions.indexOf(key) < 0 && key !== "audienceType" && key !== "audienceCategory" && key !== "audienceSubType" ){
                        if(this.$constants.optionsDictionary[key]){
                            audienceOptions.push({ label: this.$constants.optionsDictionary[key], value: key})
                        }
                        else{
                            audienceOptions.push({ label: key.toUpperCase(), value: key})
                        }
                    }
                })
            }
            return audienceOptions;
        },
        genderData() {
            if(this.data && this.data.genders && this.data.genders.length > 0){
                var genderData = []
                for(var i=0; i < this.data.genders.length; i++){
                    genderData.push({ 
                        icon: '/img/icons/icon-gender-'+ this.data.genders[i].audienceSubType.toLowerCase() +'.svg', 
                        value: this.formatLargeNumber(this.data.genders[i][this.ageGenderSelectedKey]),
                    });
                }
                return genderData;
            }
            return undefined;
        },
        moviesDataOption(){
            var moviesOptions = [];
            if(this.data && this.data.movies && this.data.movies.length > 0){
                Object.keys(this.data.movies[0]).forEach((key) => {
                    if(moviesOptions.indexOf(key) < 0 && key !== 'title' && key !== 'imdbPageID'){
                        if(this.$constants.optionsDictionary[key]){
                            moviesOptions.push({ label: this.$constants.optionsDictionary[key], value: key})
                        }
                        else{
                            moviesOptions.push({ label: key.toUpperCase(), value: key})
                        }
                    }
                })
            }
            return moviesOptions;
        },
        mediaData(){
            var mediaData = {};
            if(this.data && this.data.sites && this.data.sites.length > 0){
                for(var i = 0; i < this.data.sites.length; i++){
                    Object.entries(this.data.sites[i]).forEach(function transformKeys(siteObject, index){
                        if(!mediaData[siteObject[0]]){
                            mediaData[siteObject[0]] = [];
                        }
                      
                        mediaData[siteObject[0]].push(siteObject[1]);
                    })
                }
            }
            return Object.keys(mediaData).length > 0 ? mediaData : null;
        },
        mediaDataOptions(){
            if(this.data && this.data.sites && this.data.sites.length > 0){
                var mediaOptions = [];
                Object.keys(this.data.sites[0]).forEach((key) => {
                    if(mediaOptions.indexOf(key) < 0  && key !== 'site'){
                        if(this.$constants.optionsDictionary[key]){
                            mediaOptions.push({ label: this.$constants.optionsDictionary[key], value: key})
                        }
                        else{
                            mediaOptions.push({ label: key.toUpperCase(), value: key})
                        }
                    }
                })
            }
            return mediaOptions;
        },
        mediaChartData(){
            if(this.mediaData){
                return  {
                    labels: this.mediaData.site,
                    datasets: [
                        {
                            label: "",
                            backgroundColor: '#2A34FF',
                            hoverBackgroundColor: '#2A34FF',
                            data: this.mediaData[this.mediaSelectedKey],
                            
                            barThickness: 20,
                            maxBarThickness: 100,
                        }, 
                    ]
                }
            }
            return undefined;
        },
        timelineEmptyLables(){
            if(this.timelineData && this.timelineData.date){
                return Array.from({length: this.timelineData.date.length}, (v, i) => '') 
            }
            return undefined;
        },
        timelineData(){
            if(this.data && this.data.timeline){
                var timelineData = {};
                for(var i = 0; i < this.data.timeline.length; i++){
                    Object.entries(this.data.timeline[i]).forEach(function transformKeys(timelineObject, index){
                        if(!timelineData[timelineObject[0]]){
                            timelineData[timelineObject[0]] = [];
                        }
                      
                        timelineData[timelineObject[0]].push(timelineObject[1]);
                    })
                }
                return timelineData;
            }
            return undefined;
        },
        timelineDataOptions(){
            var timelineOptions = [];
            if(this.timelineData){
                Object.keys(this.timelineData).forEach((key) => {
                    if(timelineOptions.indexOf(key) < 0 && key !== 'date'){
                        if(this.$constants.optionsDictionary[key]){
                            timelineOptions.push({ label: this.$constants.optionsDictionary[key], value: key});
                        }
                        else{
                            timelineOptions.push({ label: this.capitalizeFirstLetter(key), value: key});
                        }
                    
                    }
                })
            }
            return timelineOptions.length > 0 ? timelineOptions : null;
        },
        timelineChartData(){
            if(this.timelineSelectedKey && this.timelineData && this.timelineData.date && this.timelineData[this.timelineSelectedKey]){
                var lineData = {
                    labels: this.timelineData.date.length <= 100 ? this.timelineData.date : this.timelineEmptyLables,
                    datasets: [
                        { 
                            label: this.timelineSelectedKey,
                            data: this.timelineData[this.timelineSelectedKey],
                            borderColor: '#2A34FF',
                            pointRadius: 25,
                            pointStyle: 'line',
                            pointBorderColor: 'transparent',
                            fill: false
                        },
                    ]
                };
                return lineData;
            }
            return undefined;               
        },
        selectedMoviesLabel(){
            if(this.selectedMovies.length === 1){
                var result = this.moviesFilterOptions.filter(item => item.imdbPageID === this.selectedMovies[0]);
                if(result.length > 0){
                    return result[0].movie;
                }
                else{
                    return this.selectedMovies[0];
                }
            }
            else if(this.selectedMovies.length === this.moviesFilterOptions.length || this.selectedMovies.length === 0){
                return 'All'
            }
            else{
                var result = this.moviesFilterOptions.filter(item => item.imdbPageID === this.selectedMovies[0]);
                if(result.length > 0){
                    return  result[0].movie + ' + ' +  (this.selectedMovies.length - 1);
                }
                else{
                    return this.selectedMovies.length + " items";
                }
            }
        },
        vue2pickerOptions(){
            return  {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: true,
            };
        },
    },
    components: {
        [HorizontalBarChart.name]: HorizontalBarChart,
        [StyledLineChart.name]: StyledLineChart,
        [SelectableList.name]: SelectableList,
        [CTACard.name]: CTACard,
        [ClientCountrySelect.name]: ClientCountrySelect,
        [CheckboxGroup.name]: CheckboxGroup,
        DatePicker,
    },
}
</script>
