<template>
    <div class="client-campaign-overview">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body campaings-table">
                        <div class="d-flex justify-content-between">
                            <h2 class="campaings-table-title">Campaign overview</h2>
                            <div v-if="$route.name === $constants.routeNames['campaigns'].root">
                                <!-- <el-popover class="custom-popper" trigger="hover" placement="left" v-if="userSelectedClient === null">
                                    <div class="popover-wrapper">
                                        <h3 class="popover-header">Please select unit and market</h3>
                                        <div class="popover-body" style="font-size:10px">
                                            You have to choose a business unit and a market before you can create a campaign brief.<br />
                                            Click on the drop down next to “All” in the unit and country pane to select a business
                                            unit.<br />
                                            Click on the country flags to select a market.
                                        </div>
                                    </div>
                                    <span slot="reference">
                                        <base-button type="success" class="create-button"
                                        @click="createBrief" v-if="$route.name === $constants.routeNames['campaigns'].root"
                                        :disabled="userSelectedClient === null">Create campaign brief</base-button>
                                    </span>
                                </el-popover> -->
                                <base-button type="success" class="create-button" v-on:click="createBrief">
                                    Create campaign brief
                                </base-button>
                            </div>
                        </div>
                        <div class="campaings-table-header">
                            <div class="col-12 d-flex justify-content-start no-gutters">
                                <div class="row">
                                    <div class="header-item-wrapper sort-wrapper with-label">
                                       <el-dropdown :hide-on-click="false" trigger="click" placement="bottom-start">
                                            <base-button class="btn-link" size="sm">
                                                Sort by <i class="el-icon el-icon-caret el-icon-caret-bottom"></i>
                                            </base-button>
                                            <el-dropdown-menu slot="dropdown" class="sort-dropdown-menu">
                                                <h4 class="menu-title">Sort by</h4> 
                                                <el-radio-group v-model="sortBy" class="sort-dropdown-menu">
                                                    <el-radio :label="option.value" v-for="(option, index) in sortOptions" :key="index">{{ option.label }}</el-radio>
                                                </el-radio-group>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                    <div class="header-item-wrapper filter-wrapper with-label" v-if="$route.name !== $constants.routeNames.dashboards.root || internalUser">
                                       <el-dropdown :hide-on-click="false" trigger="click" placement="bottom">
                                            <base-button class="btn-link" size="sm">                                              
                                                Filter <i class="el-icon el-icon-caret el-icon-caret-bottom"></i>
                                            </base-button>
                                            <el-dropdown-menu slot="dropdown" class="filter-dropdown-menu">
                                                <h4 class="menu-title">Filter</h4> 
                                                <div class="row">
                                                    <div class="col" v-if="$route.name !== $constants.routeNames.dashboards.root">
                                                         <checkbox-group label="Status" :options="filterOptions" v-model="filterValues"/>
                                                    </div>
                                                    <div class="col" v-if="internalUser">
                                                        <checkbox-group label="Columns" :options="tableColumns" v-model="chosenColumns" @change="filterColumns" optionValueKey="prop"/>
                                                    </div>
                                                </div>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                    <div class="header-item-wrapper pagination-wrapper mr-2">
                                        <el-select class="pagination-select" v-model="pagination.perPage" popper-class="pagination-dropdown"
                                            placeholder="Per page">
                                            <el-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="header-item-wrapper search-wrapper">
                                        <el-input type="search" class="search-input" clearable prefix-icon="el-icon-search" placeholder="Search"
                                            v-model="searchQuery" aria-controls="datatables">
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <transition name="collapse">
                                <simple-gantt-table :ganttData="queriedData" nameLabel="Title" :nameMinWidth="250" :loading="loading" link-type="campaign"
                                    v-if="!internalUser" :hideStatusHover="hideStatusHover" :bus="bus" :permissions="permissions"  @edit-item="editCampaign" :default-sort="defaultSort" :pagination.sync="pagination" :get-route="getRoute"/>
                                <gantt-table :ganttData="queriedData" nameLabel="Title" :nameMinWidth="250" :loading="loading" v-else  link-type="campaign"
                                    :additional-columns="activeTableColumns" :showStatusLabel="false" @edit-item="editCampaign" :hideStatusHover="hideStatusHover" :bus="bus" :default-sort="defaultSort" :pagination.sync="pagination" :get-route="getRoute"/>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)
dayjs.extend(utc)

import {
    CollapseTransition,
} from 'vue2-transitions';

import Fuse from 'fuse.js';

import SimpleGanttTable from '@/components/SimpleGanttTable/SimpleGanttTable.vue';
import GanttTable from '@/components/GanttTableView/GanttTableView';
import CheckboxGroup from '@/components/Inputs/CheckboxGroup';
import { mapActions, mapState, mapGetters } from 'vuex'

import Vue from 'vue';

export default {
    props: ['getRoute', 'campaigns', 'client', 'country', 'loading', 'clientsFilter', "hideStatusHover", "permissions"],
    data() {
        return {
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            sortBy: null,
            searchQuery: '',
            accessType: 0,
            userType: null,
            searchedData: [],
            filterShown: false,
            bus: new Vue(),
            sortOptions: [
                { label: 'Name (A-Z)', value: {prop: 'name', order: 'ascending'}},
                { label: 'Name (Z-A)', value: {prop: 'name', order: 'descending'}},
                { label: 'Campaign start date', value: {prop: 'startDate', order: 'descending'}},
                { label: 'Campaign end date', value: {prop: 'endDate', order: 'descending'}},
                { label: 'Last created', value: {prop: 'created', order: 'descending'}},
                { label: 'Last edited', value: {prop: 'lastEdit', order: 'descending'}},
            ],
            filterValues: Object.values(this.$constants.campaignStates).map((item) => {
                return item.value
            }),
            chosenColumns: ['maxCTC', 'net', 'ctc', 'startDate', 'endDate'],
            activeTableColumns: [
                {
                    width: 80,
                    prop: 'clientID',
                    label: 'Client ID'
                },
                {
                    width: 90,
                    prop: 'marathonCountryID',
                    label: 'Country ID'
                },
                {
                    width: 160,
                    minWidth: 160,
                    prop: 'maxCTC',
                    label: 'Budget'
                },
                {
                    width: 110,
                    prop: 'net',
                    label: 'Net'
                },
                {
                    width: 110,
                    prop: 'ctc',
                    label: 'CTC'
                },
                {
                    width: 120,
                    prop: 'startDate',
                    label: 'Start Date'
                },
                {
                    width: 120,
                    prop: 'endDate',
                    label: 'End Date'
                },
                {
                    width: 120,
                    prop: 'created',
                    label: 'Created'
                },
                {
                    width: 120,
                    prop: 'lastEdit',
                    label: 'Edited'
                },
            ],
            tableColumns: [{
                width: 80,
                prop: 'clientID',
                label: 'Client ID'
            },{
                width: 90,
                prop: 'marathonCountryID',
                label: 'Country ID'
            },{
                minWidth: 80,
                width: 80,
                prop: 'maxCTC',
                label: 'Budget'
            },
            {
                width: 110,
                prop: 'net',
                label: 'Net'
            },
            {
                width: 110,
                prop: 'ctc',
                label: 'CTC'
            },
            {
                width: 120,
                prop: 'startDate',
                label: 'Start Date'
            },
            {
                width: 120,
                prop: 'endDate',
                label: 'End Date'
            },
            {
                width: 120,
                prop: 'lastEdit',
                label: 'Edited'
            },
            {
                width: 120,
                prop: 'created',
                label: 'Created'
            },
            ],
            marathonCountryID: null,
            showChat: false,
            fuseSearch: null,
            sortFunction: null,
        };
    },
    components: {
        CollapseTransition,
        [SimpleGanttTable.name]: SimpleGanttTable,
        [CheckboxGroup.name]: CheckboxGroup,
        GanttTable
    },
    computed: {
        ...mapGetters([
            'internalUser',
        ]),
        ...mapActions([
            'updateCampaignName',
            'clearCampaignName',
        ]),
        defaultSort(){
            if(this.routePath === "/reporting"){
                return {
                    prop: "endDate",
                    order: "descending",
                };
            }
            return {
                prop: "lastEdit",
                order: "descending",
            };
        },
        filterOptions(){
            return Object.values(this.$constants.campaignStates);
        },
        routePath() {
            const {
                path
            } = this.$route;
            return path;
        },
        queriedData() {
            let result = this.ganttData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result;
            //return result.slice(this.from, this.to);
        },
        ganttData(){
            var campaigns = JSON.parse(JSON.stringify(this.campaigns));
            var ganttData = [];
            var clientID = this.client ? this.client.clientID : null;
            var marathonCountryID = this.country;
            var clientIdsList = this.clientsFilter;
            var dataTest = function () {};
            var stateFilter = this.filterValues;
            if (clientIdsList && clientIdsList.length > 0 && marathonCountryID) {
                dataTest = function (item, clientID, marathonCountryID, clientIdsList) {
                    return clientIdsList.some(idFromList => item.clientID === idFromList && item.marathonCountryID === marathonCountryID);
                }
            } else if (clientIdsList && clientIdsList.length > 0) {
                dataTest = function (item, clientID, marathonCountryID, clientIdsList) {
                    return clientIdsList.some(idFromList => item.clientID === idFromList);
                }
            }
            else if (marathonCountryID) {
                dataTest = function (item, clientID, marathonCountryID) {
                    return item.marathonCountryID === marathonCountryID;
                }
            } else {
                dataTest = function () {
                    return true;
                }
            }
            for (var i = 0; i < campaigns.length; i++) {
                if (dataTest(campaigns[i], clientID, marathonCountryID, clientIdsList)) {
                    if(stateFilter && stateFilter.length > 0){
                        if(stateFilter.length === this.filterOptions || stateFilter.includes(this.campaigns[i].status)){
                            ganttData.push({
                                marathonCountryID: this.campaigns[i].marathonCountryID,
                                clientID: this.campaigns[i].clientID,
                                status: this.campaigns[i].status,
                                id: this.campaigns[i].campaignID,
                                name: this.campaigns[i].campaign,


                                startDate: this.campaigns[i].startDate,
                                endDate: this.campaigns[i].endDate,

                                length:  dayjs.utc(this.campaigns[i].endDate).diff(dayjs.utc(this.campaigns[i].startDate), 'd') ,

                                maxCTC: this.campaigns[i].maxCTC,
                                net: this.campaigns[i].net,
                                ctc: this.campaigns[i].ctc,

                                timestampChanged: this.campaigns[i].timestampChanged,
                                timestampSubmitted: this.campaigns[i].timestampSubmitted,
                                timestampCreated: this.campaigns[i].timestampCreated,
                                lastEdit: (this.campaigns[i].timestampChanged && this.campaigns[i].timestampChanged.length >= 10) ? this.campaigns[i].timestampChanged.substring(0, 10) : this.campaigns[i].timestampChanged,
                                submit: (this.campaigns[i].timestampSubmitted && this.campaigns[i].timestampSubmitted.length >= 10) ? this.campaigns[i].timestampSubmitted.substring(0, 10) : this.campaigns[i].timestampSubmitted,
                                created: (this.campaigns[i].timestampCreated && this.campaigns[i].timestampCreated.length >= 10) ? this.campaigns[i].timestampCreated.substring(0, 10) : this.campaigns[i].timestampCreated,
                            });
                        }
                    }
                }
            }
            return ganttData;
        },
        propsToSearch(){
            return ['countryID', 'name', 'clientID', 'id'];
        },
    },
    methods: {
        createBrief(){
            this.$router.push({ name: this.$constants.routeNames['briefs'].root });
        },
        editCampaign(index, row){
            this.$router.push({ name: this.$constants.routeNames['briefs'].edit, params: {clientID: row.clientID, marathonCountryID: row.marathonCountryID, campaignID: row.id} });
        },
        filterColumns(chosenColumns) {
            this.activeTableColumns = [];
            for (var i = 0; i < chosenColumns.length; i++) {
                for (var k = 0; k < this.tableColumns.length; k++) {
                    if (this.tableColumns[k].prop === chosenColumns[i]) {
                        this.activeTableColumns[i] = this.tableColumns[k];
                    }
                }
            }
        },
        search(array, attribute, value) {
            var elements = []
            for (var i = 0; i < array.length; i++) {
                if (array[i][attribute] === value) {
                    elements.push(array[i])
                }
            }
            return elements;
        },
    },

    watch: {
        sortBy:{
            immediate: true,
            handler(newVal){
                this.bus.$emit("sort", newVal);
            },
        },
        searchQuery(newVal) {
            let result = this.ganttData;
            if (newVal !== '') {
                result = this.fuseSearch.search(newVal).map(x => x.item);
            }
            this.searchedData = result;

            this.fuseSearch = new Fuse(this.ganttData, {
                keys: this.propsToSearch,
                threshold: 0.0
            });
        },
    },
    mounted() {
        this.fuseSearch = new Fuse(this.ganttData, {
            keys: this.propsToSearch,
            threshold: 0.0
        });
    },
};
</script>
<style lang="scss">
.el-table__header th.status .cell {
    display: none;
}
</style>
