<template>
    <div class="brief-detail">
        <div class="row">
            <div class="col">
                <nordisk-film-brief-preview vref="form" v-if="campaign" v-model="campaign" :clientSelectOptions="clientSelectOptions" :countriesOptions="countriesOptions" :steps="steps" :currentStep="currentStep">
                    <template v-slot:controls>
                        <div class="row">
                            <div class="col d-flex justify-content-end control-row">
                                <base-button v-if="internalUser || !briefed" type="neutral" size="regular" @click="editBrief" >Edit brief</base-button>
                                <base-button :style="{opacity: currentStep > 0 ? '1' : '0', cursor: currentStep > 0 ? 'pointer' : 'default'}" :disable="currentStep > 0"
                                    @click="previousPage()" size="sm" class="btn-prev btn-half">
                                    <i class="tim-icons icon-double-left" aria-hidden="true"></i>
                                    Prev
                                </base-button>
                                <base-button :style="{opacity: currentStep < 4 ? '1' : '0', cursor: currentStep < 4 ? 'pointer' : 'default'}"  @click="nextPage()"
                                    class="btn-next btn-half" size="sm">
                                    Next
                                    <i class="tim-icons icon-double-right" aria-hidden="true"></i>
                                </base-button>
                            </div>
                        </div>
                    </template>
                </nordisk-film-brief-preview>
                <!--<publicis-brief-detail v-else-if="campaign" :campaign="campaign"/>-->
            </div>
        </div>
    </div>
</template>

<script>
import NordiskFilmBriefPreview from './NordiskFilm/NordiskFilmBriefPreview.vue';
import { mapGetters } from 'vuex'

export default {
    props: ['clientSelectOptions', 'countriesOptions', 'client', 'steps', 'currentStep', 'campaign', 'eventBus'],
    mounted(){
        if(this.internalUser !== null && this.internalUser === false){
            this.$swal.fire({
                title: 'Remember!',
                html: '<p>This brief have been submitted to the agency and you can therefore not make any more changes.</p><p>If you have important changes please reach out to your contact at Publicis and they will help you.</p>',
                showCancelButton: false,
                reverseButtons: false,
                showCloseButton: true,
                confirmButtonColor: '#059513',
                cancelButtonColor: '#333',
                confirmButtonText: 'Okay, thanks!',
                cancelButtonText: "No, take me back",
                customClass: "swal2-popup-dark brief-preview-note",
            }).then((result) => {})
        }
        
    },
    methods: {
        nextPage() {
            if (this.currentStep < 4) {
                this.eventBus.$emit('next-page');
            }
        },
        previousPage() {
            if (this.currentStep > 0) {
                this.eventBus.$emit('previous-page');
            }
        },
        
        editBrief(){
            this.$swal.fire({
                imageUrl: '/img/icons/icon-warning.svg',
                imageWidth: 43,
                imageHeight: 43,
                title: 'Are you sure you want to\nunsubmit this brief?',
                showCancelButton: true,
                reverseButtons: false,
                showCloseButton: true,
                confirmButtonColor: '#376EE2',
                cancelButtonColor: '#333',
                confirmButtonText: 'Yes, unsubmit',
                cancelButtonText: "No, take me back",
                customClass: "swal2-popup-dark brief-preview-warning",
            }).then((result) => {

                const agreed = result.value;

                if (agreed) {
                    this.campaign.briefStatus = this.$constants.campaignStates.BRIEF_IN_PROGRESS.value;

                    const path = this.$constants.apiPaths.planning.campaign(this.campaign.marathonCountryID, this.campaign.clientID, this.campaign.campaignID);
                    const payload = this.campaign;
                        
                    this.axios.put(path, payload)
                        .then((res) => {
                            if(res && res.data){
                                this.campaign.campaignID = res.data.campaignID;
                                this.campaign.marathonCountryID = res.data.marathonCountryID;

                                this.$router.push({ 
                                    name: this.$constants.routeNames.briefs.edit,
                                    params: { 
                                        marathonCountryID: this.campaign.marathonCountryID,
                                        clientID: this.campaign.clientID,
                                        campaignID: this.campaign.campaignID,
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            this.$swal.fire({
                                title: 'Operation failed',
                                icon: 'error',
                                allowOutsideClick: false,
                                text: error,
                            });
                            console.log(error);
                        });
                }
            });            
        },
        handleRequestError(error) {
            console.error(error);
        },
    },
    computed:{
        ...mapGetters([
            'internalUser',
        ]),
        briefed() {
            return this.campaign !== null && this.campaign.briefStatus === this.$constants.campaignStates.BRIEFED.value;
        },
    },
    components: {
        [NordiskFilmBriefPreview.name]: NordiskFilmBriefPreview,
    }
};
</script>
