<template>
    <div class="card brief-detail-content">
        <div class="row brief-group-title">
            <div class="col">
                <h2>{{ steps[briefIndex].groupA }}</h2>
            </div>
            <div class="col">
                <h2>{{ steps[briefIndex].groupB }}</h2>
            </div>
        </div>
        <form @submit="checkForm" class="brief-form">
            <div v-show="briefIndex == 0">
                <div class="row">
                    <div class="col col-6 col-lg-3">
                        <base-select
                            id="market"
                            v-model="campaign.marathonCountryID"
                            required
                            label="Market"
                            :isValid.sync="validation.marathonCountryID"
                            disabled
                        >
                            <option
                                v-for="country in countriesOptions.filter(item => item.value !== null)"
                                :value="country.value"
                                :key="country.value"
                                >{{ country.label }}</option
                            >
                        </base-select>
                        <base-select
                            id="brief-unit-select"
                            v-model="campaign.clientID"
                            required
                            label="Unit"
                            :isValid.sync="validation.clientID"
                            disabled
                        >
                            <option
                                v-for="client in clientSelectOptions.filter(item => item.value !== null)"
                                :value="client.value"
                                :key="client.clientId"
                                >{{ client.label }}</option
                            >
                        </base-select>
                        <form-input
                            label="Campaign"
                            :disabled="briefed"
                            v-model="campaign.campaign"
                            required
                            :isValid.sync="validation.campaign"
                        />
                        <!-- <div class="form-spacer height-1" /> -->
                        <form-input
                            label="Deadline for media plan"
                            type="date"
                            v-model="campaign.deadlineMediaplan"
                            required
                            :isValid.sync="validation.deadlineMediaplan"
                        />
                        <form-input
                            label="Release date"
                            type="date"
                            v-model="campaign.releaseDate"
                            required
                            :isValid.sync="validation.releaseDate"
                        />
                        <form-input
                            label="Campaign start date"
                            type="date"
                            v-model="campaign.startDate"
                            required
                            :isValid.sync="validation.startDate"
                        />
                        <form-input
                            label="Campaign end date"
                            type="date"
                            v-model="campaign.endDate"
                            required
                            :isValid.sync="validation.endDate"
                        />
                        <form-input
                            label="TVOD Release"
                            type="date"
                            v-model="campaign.tvodRelease"
                        />
                    </div>
                    <div class="col col-6 col-lg-3">
                        <form-input
                            label="EST"
                            v-model="campaign.est"
                        />
                       <form-input
                            label="Admissions / Sales Target"
                            type="text"
                            required
                            v-model="campaign.salesTarget"
                        />
                        <div class="joined-input asymmetric">
                            <form-input
                                label="Budget"
                                type="number"
                                inputmode="numeric"
                                required
                                v-model="campaign.maxCTC"
                                :formatter="currencyFormatter"
                                :parser="currencyParser"
                            />
                            <base-select
                                v-model="campaign.campaignCurrency"
                                required
                                label="Currency"
                                :isValid.sync="validation.campaignCurrency"
                            >
                                <option v-for="(currency, index) in currencies" :key="index" :value="currency">
                                    {{ currency }}
                                </option>
                            </base-select>
                        </div>
                        <form-input
                            label="Internal Social Budget"
                            v-model="campaign.soMeBudget"
                            type="number"
                            inputmode="numeric"
                            required
                            :isValid.sync="validation.soMeBudget"
                            :formatter="currencyFormatter"
                            :parser="currencyParser"
                        />
                        <!-- disabled until backend/marathon problem solved -->
                        <form-input
                            label="PO number"
                            v-model="campaign.poNumber"
                            :isValid.sync="validation.poNumber"
                        />
                        <text-area
                            :disabled="briefed"
                            label="Budget allocation (Frontload / Sustain)"
                            v-model="campaign.budgetAllocation"
                            required
                            :height="3"
                            :isValid.sync="validation.budgetAllocation"
                        />
                    </div>
                    <div class="col movie-info col-6 col-lg-3">
                        <div class="joined-input asymmetric">
                            <form-input label="IMDB PageID" v-model="campaign.imdbPageID" type="text" />
                            <base-button
                                :loading="isLoadingIMDB"
                                size="regular"
                                type="primary"
                                @click="getMovieInformation(campaign.imdbPageID)"
                                class="form-button"
                                :disabled="!campaign.imdbPageID"
                                >Fetch info</base-button
                            >
                        </div>
                        <div
                            class="movie-poster"
                            :style="[movie && movie.posterURL ? { backgroundImage: `url('${movie.posterURL}')` } : {}]"
                        >
                            <span class="placeholder" v-if="!movie || ! movie.posterURL">Poster</span>
                        </div>
                    </div>
                    <div class="col movie-info col-6 col-lg-3">
                        <div v-if="movie">
                            <form-input label="Title" v-model="movie.title" type="text" disabled />
                            <form-input label="Director " v-model="movie.director" type="text" disabled />
                            <form-input label="Genres" v-model="movie.genre" type="text" disabled />
                            <text-area
                                label="Cast list (top 5)"
                                v-model="movie.cast"
                                :height="5"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="briefIndex == 1">
                <div class="row">
                    <div class="col col-6 col-lg-3">
                        <base-multiselect
                            :disabled="briefed"
                            id="form-genre-select"
                            v-model="campaign.genre"
                            required
                            collapse-tags
                            label="Genre"
                            :isValid.sync="validation.genre"
                            :options="genres"
                        >
                           
                        </base-multiselect>
                        <text-area
                            :disabled="briefed"
                            label="Genre Comment"
                            v-model="campaign.genreComment"
                            :height="2"
                            :isValid.sync="validation.genreComment"
                        />
                        <text-area
                            :disabled="briefed"
                            label="Preceding Awareness"
                            :height="2"
                            v-model="campaign.precedingAwareness"
                            :isValid.sync="validation.precedingAwareness"/>
                        <form-input label="Link to trailer" v-model="campaign.linkToTrailer" />
                        <text-area
                            :disabled="briefed"
                            label="Cast"
                            :height="2"
                            v-model="campaign.cast"
                            :isValid.sync="validation.cast"/>
                    </div>
                    <div class="col col-6 col-lg-3">
                        <text-area
                            :disabled="briefed"
                            label="About the title"
                            :height="8"
                            v-model="campaign.aboutTheTitle"
                            :isValid.sync="validation.aboutTheTitle"
                            required
                        />
                    </div>
                    <div class="col col-6 col-lg-3">
                        <div class="joined-input-vertical height-6">
                            <autoresize-text-area
                                label="Positioning"
                                :height="2"
                                v-model="campaign.positioning"
                            ></autoresize-text-area>
                            <div class="bullet-list-input">
                                <label class="bullet-list-label">USPs</label>
                                <editor-content :editor="editor" class="bullet-list-editor" ref="uspsEditor"/>
                                <ul @click="addUSP()" class="placeholder">
                                    <li>Click to add USP</li>
                                </ul>
                            </div>
                        </div>
                        <text-area
                            :disabled="briefed"
                            label="Benchmark titles"
                            v-model="campaign.benchmarkTitles"
                            :height="2"
                        ></text-area>
                    </div>
                    <div class="col col-6 col-lg-3">
                        <text-area
                            :disabled="briefed"
                            label="Competitor titles in the same period"
                            v-model="campaign.competitorTitlesInTheSamePeriod"
                            :height="4"
                        ></text-area>
                        <text-area
                            :disabled="briefed"
                            label="Other events in the same period"
                            v-model="campaign.eventsInTheSamePeriod"
                            :height="4"
                        ></text-area>
                    </div>
                </div>
            </div>
            <div v-show="briefIndex == 2">
                <div class="row">
                    <div class="col col-6 col-lg-3">
                        <text-area
                            :disabled="briefed"
                            label="Primary target group (remember segment)"
                            v-model="campaign.primaryTargetGroup"
                            :height="4"
                            :required="true"
                            :isValid.sync="validation.primaryTargetGroup"
                        ></text-area>
                        <text-area
                            :disabled="briefed"
                            label="Secondary target group (remember segment)"
                            v-model="campaign.secondaryTargetGroup"
                            :height="4"
                        ></text-area>
                    </div>
                    <div class="col col-6 col-lg-3">
                        <text-area
                            :disabled="briefed"
                            label="Additional Target Group Information"
                            v-model="campaign.additionalTargetGroupInformation"
                            placeholder="E.g. “Want-to-see” test or Teaser trailer results"
                            :height="8"
                        ></text-area>
                    </div>
                    <div class="col col-6 col-lg-3">
                        <base-select
                            :disabled="briefed"
                            id="objective"
                            v-model="campaign.objective"
                            :isValid.sync="validation.objective"
                            required
                            label="Overall campaign objective"
                            @change="campaign.kpiMetrics = null"
                        >
                            <option v-for="(objective, index) in campaignObjectives" :key="index" :value="objective.value">
                                {{ objective.label }}
                            </option>
                        </base-select>
                        <base-multiselect
                            :disabled="briefed || !campaign.objective"
                            id="metrics"
                            v-model="campaign.kpiMetrics"
                            required
                            label="KPI Metrics"
                            :height="3"
                            :isValid.sync="validation.kpiMetrics"
                            :options="kpiOptions[campaign.objective]"
                        >
                            <!-- <option v-for="(kpi, index) in kpiOptions[campaign.objective]" :key="index" :value="kpi">
                                {{ kpi }}
                            </option> -->
                        </base-multiselect>
                        <text-area
                            :disabled="briefed"
                            label="Objective elaborated"
                            v-model="campaign.objectiveElaborated"
                            :height="4"
                        ></text-area>
                    </div>
                    <div class="col col-6 col-lg-3">
                        <text-area
                            maxlength="500"
                            :disabled="briefed"
                            label="Proposed Media Strategy"
                            v-model="campaign.proposedMediaStrategy"
                            :height="8"
                        ></text-area>
                    </div>
                </div>
            </div>
            <div v-show="briefIndex == 3">
                <div class="row">
                    <div class="col col-6 col-lg-3">
                        <text-area
                            maxlength="500"
                            :disabled="briefed"
                            label="List of content available"
                            v-model="campaign.listOfContentStrategies"
                            :height="140"
                        ></text-area>
                        <text-area
                            :disabled="briefed"
                            label="Video content"
                            v-model="campaign.videoContent"
                            :height="140"
                            placeholder="Number of videos incl. copy length, sequencing etc."
                        ></text-area>
                        <text-area
                            :disabled="briefed"
                            label="Digital requirements"
                            v-model="campaign.digitalRequirements"
                            :height="140"
                            placeholder="E.g. URL, UTM codes"
                        ></text-area>
                    </div>
                    <div class="col col-6 col-lg-3">
                        <text-area
                            :disabled="briefed"
                            label="Special requirements"
                            v-model="campaign.specialRequirements"
                            :height="3"
                        ></text-area>
                        <text-area
                            :disabled="briefed"
                            label="PR Activities"
                            v-model="campaign.prActivities"
                            :height="5"
                            placeholder="Pre-screening or other arrangements"
                        ></text-area>
                    </div>
                    <div class="col col-12 col-lg-6">
                        <text-area
                            maxlength="500"
                            :disabled="briefed"
                            label="Data Strategies"
                            v-model="campaign.dataStrategies"
                            :height="8"
                        ></text-area>
                    </div>
                </div>
            </div>
            <div v-if="briefIndex == 4">
                <div class="row">
                    <div class="col col-12 col-lg-6">
                        <text-area
                            :disabled="briefed"
                            label="Additional comments"
                            v-model="campaign.additionalComments"
                            :height="8"
                        ></text-area>
                    </div>
                    <div class="col col-12 col-lg-6">
                        <connect-file-upload :campaign="campaign" v-if="campaign" />
                    </div>
                </div>
            </div>
        </form>
        <slot name="controls"></slot>
        <div class="row">
            <div class="col">
                <div class="fields-note">(*) Mandatory fields</div>
            </div>
        </div>
    </div>
</template>

<script>
import ConnectFileUpload from "@/components/ConnectFileUpload/ConnectFileUpload.vue";
import AutoResizeTextArea from "@/components/Inputs/AutoResizeTextArea.vue";
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { BulletList, ListItem } from 'tiptap-extensions';
export default {
    name: "nordisk-film-home-entertaiment-brief-edit",
    model: {
        prop: "campaign",
    },
    props: {
        currentStep: {
            type: Number,
            default: 0,
        },
        steps: {
            type: [Array],
        },
        campaign: {
            type: [Object],
            default: function data() {
                return null;
            },
        },
        countriesOptions: {
            type: Array,
        },
        clientSelectOptions: {
            type: Array,
        },
        eventBus: {
            type: Object,
        },
    },
    inject: ['currencyFormatter', 'currencyParser'],
    data() {
        return {
            briefIndex: this.currentStep,
            isLoadingIMDB: false,
            fromErrors: [],
            movie: null,
            // clientCampaignCustomOwnField: {
            //     IMDBPageID: "",
            // },
            requiredKeys: [
                "campaign",
                "clientID",
                "marathonCountryID",
                "campaignCurrency",
                "deadlineMediaplan",
                "releaseDate",
                "startDate",
                "endDate",
                "campaignCurrency",
                "maxCTC",
                "soMeBudget",
                "budgetAllocation",
                "genre",
                "aboutTheTitle",
                "primaryTargetGroup",
                "objective",
                "kpiMetrics",
            ],
            campaignStatus: [
                {
                    text: "Active",
                    value: true,
                },
                {
                    text: "Inactive",
                    value: false,
                },
            ],
            validation: {
                campaignCurrency: true,
                campaign: true,
                clientID: true,
                marathonCountryID: true,
                deadlineMediaplan: true,
                releaseDate: true,
                startDate: true,
                endDate: true,
                maxCTC: true,
                soMeBudget: true,
                budgetAllocation: true,
                genre: true,
                aboutTheTitle: true,
                primaryTargetGroup: true,
                objective: true,
                kpiMetrics: true,
            },
            json: null,
            editor: new Editor({
                extensions: [
                    new BulletList(),
                    new ListItem(),
                ],
                content: {
                    type: "doc",
                    content: [{
                        type: "bullet_list",
                        content: []
                    }
                    ]
                },
                onUpdate: ({ getJSON, getHTML }) => {
                    var content = getJSON();
                    this.transferUSPs(content);
                },
            }),
        };
    },
    watch: {
        currentStep(newVal) {
            this.briefIndex = newVal;
        },
        tags(newVal) {
            this.campaign.usps = [];
            if (Array.isArray(newVal)) {
                newVal.forEach((element) => this.campaign.usps.push(element.text));
            }
        },
        imdbMovieID:{
            immediate: true,
            handler(newVal){
                if(newVal){
                    this.getMovieInformation(newVal);
                }
            }
        },
        usps(newVal){
            this.importUSP(newVal);
        }
    },
    components: {
        ConnectFileUpload,
        "autoresize-text-area": AutoResizeTextArea,
        EditorContent,
        EditorMenuBar,
    },
    methods: {
        // currencyFormatter(val) {
        //     if(val){
        //         var value = parseInt(val);
        //         if (value === "NaN") {
        //             value = 0;
        //         }
        //         var formatter = new Intl.NumberFormat('da-DK', {
        //             style: 'currency',
        //             currency: 'DKK',
        //             minimumFractionDigits: 0,
        //             currencyDisplay: 'code',
        //         });
        //         val = formatter.format(value);
        //         val = val.slice(0,-4);
        //     }
        //     return val;
        // },
        // currencyParser(val){
        //     if(val && val.length > 0){
        //         return val.replace(/[ ,.]/g, "");
        //     }
        //     else {
        //         return val;
        //     }
        // },
        //TODO move all usp related to separate component (including tiptap_enter_handler.js)
        addUSP(){
            var bulletList = this.editor.getJSON().content[0];
            if(bulletList){
                if(bulletList.content === undefined){
                    bulletList.content = []
                }
                
                bulletList.content.push({"type":"list_item","content":[{"type":"paragraph"}]})
                this.editor.setContent(bulletList, true);
                this.$refs.uspsEditor.$el.classList.add('visible');
            }
        },
        importUSP(usps){
            var bulletList = this.editor.getJSON().content[0];
            if(bulletList){
                if(bulletList.content === undefined){
                    bulletList.content = []
                    if(usps && usps.length > 0){
                        for(var i = 0; i < usps.length; i++){
                            bulletList.content.push({"type":"list_item","content":[{"type":"paragraph", "content":[{"type":"text","text":usps[i]}]} ]})
                        }
                        
                        this.editor.setContent(bulletList, false);
                        this.$refs.uspsEditor.$el.classList.add('visible');
                    }
                }
            }
        },
        transferUSPs(jsonContent){
            var items = jsonContent.content[0].content;
            if(items && items !== undefined){
                var usps = [];
                for(var i = 0; i< items.length; i++){
                    if(items[i].content[0] && items[i].content[0].content && items[i].content[0].content.length > 0){
                        usps.push(items[i].content[0].content[0].text);
                    }
                }
                this.campaign.usps = usps;
            }
            else{
                this.editor.setContent( {
                    type: "doc",
                    content: [{
                        type: "bullet_list",
                        content: []
                    }
                    ]
                }, false);
                this.$refs.uspsEditor.$el.classList.remove('visible');
            }
        },
        getMovieInformation(imdbMovieID) {
            const re = new RegExp('tt\\d{3,8}');
            const pageID = imdbMovieID.match(re)[0];
            if(pageID){
                imdbMovieID = pageID;
            }
            this.isLoadingIMDB = true;             
            const path =  this.$constants.apiPaths.movieInfo(imdbMovieID);
            this.axios
                .get(path)
                .then((res) => {
                    if (res.data) {
                        this.isLoadingIMDB = false;
                        if (res.data) {
                            this.movie = this.simplifyMovie(res.data);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoadingIMDB = false;
                });
        },
        simplifyMovie(movie){
            var mov = JSON.parse(JSON.stringify(this.movieModel));
            if(movie.Director){
                mov.director = movie.Director;
            }
            if(movie.Poster){
                mov.posterURL = movie.Poster;
            }
            if(movie.Poster){
                mov.posterURL = movie.Poster;
            }
            if(movie.Title){
                mov.title = movie.Title;
            }
            if(movie.Released){
                mov.releaseDate = movie.Released;
            }
            if(movie.Genre){
                mov.genre = movie.Genre;
            }
            if(movie.Actors){
                mov.cast = movie.Actors;
            }
            return mov;
        },
        checkForm() {
            var formValid = true;
            if (!this.campaign) {
                formValid = false;
            }
            if (this.campaign) {
                for (var i = 0; i < this.requiredKeys.length; i++) {
                    if (!this.campaign[this.requiredKeys[i]] || (this.campaign[this.requiredKeys[i]] && !this.campaign[this.requiredKeys[i]].length > 0)) {
                        formValid = false;
                        this.validation[this.requiredKeys[i]] = false;
                    }
                    else{
                        this.validation[this.requiredKeys[i]] = true;
                    }
                }
            }
            //this.$emit('form-validation', formValid);
            this.eventBus.$emit('form-validation', formValid);
            return formValid;
        },
        checkAutosave(fields) {
            var formValid = true;
            if (!this.campaign) {
                formValid = false;
            }
            if (this.campaign) {
                for (var i = 0; i < fields.length; i++) {
                    if (!this.campaign[fields[i]] || !this.campaign[fields[i]].length > 0) {
                        formValid = false;
                        this.validation[fields[i]] = false;
                    }
                }
                var invalidFields = Object.entries(this.validation).filter((item) => item[1] === false);
                for(var i = 0; i < invalidFields.length; i++){
                    var key = invalidFields[i][0];
                    if (!this.campaign[key] || (this.campaign[key] && !this.campaign[key].length > 0)) {
                        this.validation[key] = false;
                    }
                    else{
                        this.validation[key] = true;
                    }
                }
            }
            return formValid;
        },
    },
    mounted() {
        if(this.eventBus){
            this.eventBus.$on("checkAutosave", this.checkAutosave);
            this.eventBus.$on("checkForm", this.checkForm);
        }
        this.movie = JSON.parse(JSON.stringify(this.movieModel));
    },
    computed: {
        movieModel(){
            return {
                castList: "", 
                director: "", 
                genres: "", 
                posterURL: "", 
                releaseDate: "", 
                title: ""
            } 
        },
        usps(){
            return this.campaign.usps;
        },
        currencies() {
            var list = [];
            for (var i = 0; i < this.countriesOptions.length; i++) {
                if (this.countriesOptions[i].currency !== null) {
                    list.push(this.countriesOptions[i].currency);
                }
            }

            return list;
        },
        genres() {
            var imdbList = "Action|Adventure|Animation|Comedy|Documentary|Drama|Family|Romance|Thriller|Science Fiction";
            return imdbList.split('|');
        },
        campaignObjectives() {
            return [{value:"AWARENESSS", label:"Awareness"},
                {value:"INTEREST", label:"Interest"},
                {value:"ACTION", label:"Action"}];

        },
        kpiOptions() {
            var options = { 
                AWARENESSS: ["CPM","VTR","CPV","Unique Impressions","LTR"],
                INTEREST: ["CTR","VTR"],
                ACTION:["CTR","CPA"],
            }
            return options;
        },
        briefed() {
            return this.campaign.briefStatus === this.$constants.campaignStates.BRIEFED.value;
        },
        imdbMovieID(){
            return this.campaign.imdbPageID;
        }
    },
    beforeDestroy() {
        this.editor.destroy()
    },
};
</script>
