<template>
    <div class="live-dashboards programmatic-pacing">
        <portal to="page-title_action" class="page-title_action">
            <template v-if="campaignName">
                <el-tooltip content="Download as PDF" placement="top">
                    <img class="download-icon icon" :src="'/img/icons/icon-download.svg'" v-on:click="downloadAsPDF()" alt="Download as PDF" />
                </el-tooltip>
            </template>
        </portal>
        <div class="row card-row" :ref="cardsDefinitions.overview.ref">
            <cta-card icon="/img/icons/icon-user.svg" :title="detailName" subtitle="Format" class="title" :dontResize="true"/>
            <!-- <cta-card icon="/img/icons/icon-target.svg" title="Awareness, Want-to-see" subtitle="Objectives" /> -->
            <cta-card icon="/img/icons/icon-money.svg" :title="formatFullNumber(data.spend)" subtitle="Spend" />
            <cta-card icon="/img/icons/icon-eye.svg" :title="formatFullNumber(data.impressions)" subtitle="Impressions" />
            <cta-card icon="/img/icons/icon-click.svg" :title="formatFullNumber(data.clicks)" subtitle="Clicks" />
            <cta-card icon="/img/icons/icon-cpc.svg" :title="data.performanceGoalValue" :subtitle="data.performanceGoalType" />
        </div>
        <div class="row no-inside-gutter">
            <div class="col col-12 col-lg-6" :ref="cardsDefinitions.dailyPacing.ref">
                <styled-bar-chart :chart-data="cardsDefinitions.dailyPacing.chartData" :title="cardsDefinitions.dailyPacing.title" :as-card="true" :help="cardsDefinitions.dailyPacing.info" />
            </div>
            <div class="col col-12 col-lg-6" :ref="cardsDefinitions.kpiPerformance.ref">
                <styled-line-chart :chart-data="cardsDefinitions.kpiPerformance.chartData" :title="cardsDefinitions.kpiPerformance.title" :as-card="true" :help="cardsDefinitions.kpiPerformance.info"/>
            </div>
        </div>
        <div class="row">
            <div class="col" :ref="cardsDefinitions.audienceStrategies.ref">
                <universal-list :title="cardsDefinitions.audienceStrategies.title" :table-data="cardsDefinitions.audienceStrategies.data" :table-columns="cardsDefinitions.audienceStrategies.columns"
                :list-classes="['dashboard-list',]" :showStatus="false" :showClickable="false" :help="cardsDefinitions.audienceStrategies.info" />
            </div>
        </div>
    </div>
</template>

<script>
import CTACard from '@/components/Cards/CTACard.vue'
import UniversalList from '@/components/UniversalList/UniversalList.vue'
import StyledBarChart from '@/components/StyledBarChart/StyledBarChart.vue'
import StyledLineChart from '@/components/StyledLineChart/StyledLineChart'
import { mapState, mapActions } from 'vuex'
import { generatePDF } from '@/helpers/pdf.helpers.js'

export default {
    name: 'programmatic-detail',
    props: ['data'],
    inject: ['formatLargeNumber', 'formatFullNumber', 'currencyFormatter'],
    data() {
        return {
            clientID: null,
            marathonCountryID: null,
            campaignID: null,
            pacingType: null,
            siteID: null,
            insertionOrderID: null,
            dcmCampaignID: null,
        }
    },
    mounted(){
        this.handleRoutes();
    },
    computed: {
        cardsDefinitions(){
            return {
                overview: {
                    ref: "overview",
                    title: "Overview",
                    info: null
                },
                dailyPacing: {
                    ref: "dailyPacing",
                    title: "Daily pacing",
                    info: "Daily planned spend compared with daily realized spend. Daily plannes spend is calculated as total budget, divided by budget length.",
                    chartData: this.chartData,
                },
                kpiPerformance: {
                    ref: "kpiPerformance",
                    title: "KPI Performance",
                    info: "Realized KPI compared with KPI target. KPI target is set when insertion order is booked.",
                    chartData: this.lineData,
                },
                audienceStrategies: {
                    ref: "audienceStrategies",
                    title: "Audience strategies",
                    info: "Performance pr. audience strategy, which is called line items in the system.",
                    columns: this.lineItemsColumns,
                    data: this.lineItems,
                },
            };
        },
        lineData(){
            var lineData = {
                labels: [],
                datasets: [
                    { 
                        label: "A",
                        data:[],
                        borderColor: '#2A34FF',
                        pointRadius: 25,
                        pointStyle: 'line',
                        pointBorderColor: 'transparent',
                        fill: false
                    },
                    { 
                        lineTension: 0,
                        label: "B",
                        data: [],
                        borderColor: '#FFFFFF',
                        borderDash: [10,10],
                        pointStyle: 'line',
                        pointRadius: 25,
                        pointColor: 'transparent',
                        pointBorderColor: 'transparent',
                        fill: false
                    }, //graph middle line
                ]
            };
            

            if(this.data.timeline){
                for(var i = 0; i < this.data.timeline.length; i++){
                    var date = this.data.timeline[i].date;
                    lineData.labels.push(date);
                    var performanceType = this.data.timeline[i].performanceGoalType.replace("% ", "");
                    lineData.datasets[0].data.push(this.data.timeline[i][performanceType.toLowerCase()]);
                    lineData.datasets[0].label = performanceType;
                    lineData.datasets[1].data.push(this.data.timeline[i].performanceGoalValue); //graph middle line
                    lineData.datasets[1].label = "Performance Goal"; //graph middle line
                }
            }
            return lineData;
        },
        chartData(){
            
            var chartData = {
                labels: [],
                datasets: [
                    {
                        maxBarThickness: 19,
                        label: "Actual Spend",
                        backgroundColor: '#2A34FF',
                        hoverBackgroundColor: '#2A34FF',
                        data: [],
                    }, 
                    {
                        maxBarThickness: 19,
                        label: "Planned Spend",
                        backgroundColor: '#626D80',
                        hoverBackgroundColor: '#626D80',
                        data: [],
                    },
                ]
            };
            
            if(this.data.timeline){
                for(var i = 0; i < this.data.timeline.length; i++){
                    var date = this.data.timeline[i].date;
                    chartData.labels.push(date);
                    chartData.datasets[0].data.push(this.data.timeline[i].spend);
                    chartData.datasets[1].data.push(this.data.timeline[i].plannedDailyBudget);
                }
            }

            return chartData;

        },

        lineItems(){
            var items = [];
            if(this.data.lineItems){
                for(var i = 0; i < this.data.lineItems.length; i++){
                    items.push(this.data.lineItems[i])
                    items[i].impressions = this.formatFullNumber(this.data.lineItems[i].impressions);
                    //items[i].cpm = this.formatLargeNumber(this.data.lineItems[i].cpm);
                    items[i].cpm = this.currencyFormatter(this.data.lineItems[i].cpm, 0, this.currency, true);
                    //items[i].spend = this.formatLargeNumber(this.data.lineItems[i].spend);
                    items[i].spend = this.currencyFormatter(this.data.lineItems[i].spend, 0, this.currency, true);
                    items[i].ctr = this.formatFullNumber(this.data.lineItems[i].ctr) + "%";
                }
            }
            return items;
        },
        lineItemsColumns(){
            return  [{
                minWidth: 240,
                prop: 'lineItem',
                label: 'AUDIENCE STRATEGY'
            },
            {
                width: 130,
                prop: 'impressions',
                label: 'IMPRESSIONS'
            },
            {
                width: 130,
                prop: 'clicks',
                label: 'CLICKS'
            },
            {
                width: 130,
                prop: 'spend',
                label: 'SPEND'
            },
            {
                width: 130,
                prop: 'cpm',
                label: 'CPM'
            },
            {
                width: 130,
                prop: 'ctr',
                label: 'CTR'
            }]
        },
        ...mapState([
            'detailName',
            'campaignName',
        ]),
        currency(){
            if(this.$route.params.marathonCountryID){
                return this.$route.params.marathonCountryID.split("M")[1] + 'K';
            }
            return 'DKK';
        },
    },
    methods: {
        downloadAsPDF(cardDefinition) {
            this.$swal.fire({
                title: 'Generating PDF',
                text: 'Creating export file',
                allowOutsideClick: false,
                showConfirmButton: false,
                backdrop: false
            });

            if(cardDefinition){
                generatePDF([cardDefinition], this).then(
                    (result) => { 
                        this.$swal.close();
                    },
                    (error) => { 
                        console.error(error);
                        this.$swal.close();
                    }
                );
            }
            else{
                generatePDF(Object.values(this.cardsDefinitions), this).then(
                    (result) => { 
                        this.$swal.close();
                    },
                    (error) => { 
                        console.error(error);
                        this.$swal.close();
                    }
                );
            }
        },
        handleRequestError(error) {
            console.error(error);
        },
        handleRoutes(){
            if (this.$route.params.campaignID) {
                this.campaignID = this.$route.params.campaignID;
            }
            if (this.$route.params.clientID) {
                this.clientID = this.$route.params.clientID;
            }
            if (this.$route.params.marathonCountryID) {
                this.marathonCountryID = this.$route.params.marathonCountryID;
            }
            if (this.$route.params.type) {
                this.pacingType = this.$route.params.type;
            }
            if (this.$route.params.insertionOrderID) {
                this.insertionOrderID = this.$route.params.insertionOrderID;
            }
            if (this.$route.params.siteID) {
                this.siteID = this.$route.params.siteID;
            }
            if (this.$route.params.dcmCampaignID) {
                this.dcmCampaignID = this.$route.params.dcmCampaignID;
            }
        },
        ...mapActions([
            'updateCampaignName',
            'updateDetailName'
        ]),
    },
    components: {
        [StyledBarChart.name]: StyledBarChart,
        [StyledLineChart.name]: StyledLineChart,
        [UniversalList.name]: UniversalList,
        [CTACard.name]: CTACard,
    },

};
</script>

<style lang="scss" scoped>

.download-icon{
    cursor: pointer;    
    position: relative;
    bottom: 5px;
}
</style>
