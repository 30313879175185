<template>
    <div class="checkbox-group">
        <h5 v-if="label" class="checkbox-group-label">{{ label }} </h5>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkedAll">All</el-checkbox>
        <hr>
        <el-checkbox-group v-model="groupValue" v-on:change="handleChange" class="list-container">
            <el-checkbox v-for="(option, index) in options" :label="option[optionValueKey]" :key="index">{{option[optionLabelKey]}}</el-checkbox>
        </el-checkbox-group>
    </div>
</template>

<script>
export default {
    name: 'checkbox-group',
    props: {
        label: String,
        options: Array,
        optionValueKey: {
            type: String,
            default: 'value'
        },
        optionLabelKey: {
            type: String,
            default: 'label'
        },
        value: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },
    data() {
        return {
            checkAll: this.value.length === this.options.length,
            groupValue: this.value,
        };
    },
    methods: {
        handleCheckAllChange(val) {
            var allOptionsValues = this.options.map((item) => {
                return item[this.optionValueKey]
            });
            var value = val ? allOptionsValues : []
            this.$emit('input', value);
            this.$emit('change', value);
        },
        handleChange(value) {
            this.$emit('change', value);
            this.$emit('input', value)
        }
    },
    computed:{
        isIndeterminate(){
            return this.value.length > 0 &&  this.value.length < this.options.length
        },
        checkedAll: {
            get () {
                return this.value.length === this.options.length 
            },
            set (val) {   
                this.handleCheckAllChange(val)                    
            }
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal){
                this.groupValue = newVal
            }
        }
    }
};
</script>
<style lang="scss" scoped>

.list-container{
    max-height: 400px;
    //overflow: auto;

    scrollbar-color: #BDBDBD #252525 !important; 

    &::-webkit-scrollbar{
        background-color: #252525 !important; 
    }

    &::-webkit-scrollbar-thumb{
        background-color: #BDBDBD !important; 
    }

    &::-webkit-scrollbar-corner{
        background-color: #252525 !important; 
    }
}
</style>