<template>
    <label :class="[ 'label-input', 'textarea' , required ? 'required' : '', disabled ? 'disabled' : '', value != null && value.toString().length > 0 ? 'active' : '', height > 1 ? 'height-' + height : '']">
        <textarea
                ref="autoResize"
            :required="required"
            :disabled="disabled"
            :aria-required="required"
            :class="[ required ? 'required' : '', value != null && value.toString().length > 0 ? 'active' : '', height > 1 ? 'height-' + height : '']"
            :value="value"
            v-bind="$attrs"
            :onInput="resize"
            v-on="listeners" />
        <span>{{ label }}{{ required ? ' (*)' : ''}}</span>
    </label>
</template>
<script>
export default {
    inheritAttrs: false,
    name: 'autoresize-text-area',
    props: {
        label: {
            type: String
        },
        value: {
            type: [String, Object]
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 1
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            focused: false,
            touched: false,
        };
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
                change: this.onChange
            };
        },
    },
    methods: {
        onInput(evt) {
            this.resize(evt)
            if (!this.touched) {
                this.touched = true;
            }
            this.$emit('input', evt.target.value);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
        },
        onChange(evt) {
            this.$emit('change', evt)
        },
        resize(event) {
            event.target.style.height = "auto";
            event.target.style.height = `${event.target.scrollHeight + 10}px`;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.autoResize.setAttribute(
                "style",
                "height",
                `${this.$refs.autoResize.scrollHeight}px`
            );
        });
    },
};
</script>