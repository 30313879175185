<template>
    <div>
        <div class="campaign-detail-wrapper">
            <div class="campaign-detail">
                <div class="tabs-wrapper / dragscroll">
                    <div class="campaign-versions-tabs" v-if="planVersions.length > 0">
                        <div :class="['version-tab', selectedVersionID == item.planVersionID ? 'active' : '']" v-for="(item, index) in planVersions" :key="index" @click="selectVersion(item, planVersions.length - index)">
                            <div class="version-name">Media plan v{{ planVersions.length - index }}</div>
                            <!-- <div class="version-planID">Plan ID {{ planVersions[index].planID }}</div>
                            <div class="version-planVersionID">Plan ID version {{ planVersions[index].planVersionID }}</div> -->
                            <div class="version-date">{{ item.timestampChanged ? item.timestampChanged : item.timestampCreated }}</div>
                        </div>
                    </div>
                </div>
                <div :class="['campaign-version', 'card', planVersions.length > 0 ? 'tabs' : '']">
                    <div class="campaign-version-header d-flex justify-content-between">
                        <div>
                            <h2>Media plan{{ selectedVersionNumber ? ' version ' + selectedVersionNumber : '' }}</h2>
                            <button class="btn btn-round btn-long btn-gray" @click="openFullMediaPlan">
                                Open full media plan
                                <div class="icon-connect icon-connect-excel icon-right" />
                            </button>
                            <b-button class="btn btn-round btn-long btn-gray" v-b-modal.modal-download-rationale>
                                Download rationale
                                <div class="icon-connect icon-connect-pdf icon-right" />
                            </b-button>
                        </div>
                        <div>
                            <base-button v-if="selectedVersion && !selectedVersion.planApprovedByClient" :disabled="approveIsDisabled" type="success" @click="approvePlan">
                                Approve</base-button>
                            <base-button v-if="selectedVersion && selectedVersion.planApprovedByClient" type="neutral" style="width: max-content">
                                Plan approved&nbsp;<i class="tim-icons icon-check-2"></i>
                            </base-button>

                        </div>
                    </div>
                    <div class="campaign-table-wrapper dragscroll" ref="tableWrapper">
                        <div class="campaign-table-border">
                            <table class="campaign-table" :style="{width: (calendarTableData.length*23)+1162 + 'px'}" v-if="calendarTableData.length > 0">
                                <thead>
                                    <tr v-if="calendarTableMonths.length > 0">
                                        <th :colspan="numberOfMainColumns" class="invisible month"></th>
                                        <th :colspan="month.colspan" class="month" v-for="(month, index) in calendarTableMonths" :key="index">
                                            {{ month.colspan > month.name.length ? month.name : month.colspan > 3 ? month.name.substring(0,3) : ''}}
                                        </th>
                                    </tr>
                                    <tr v-if="calendarTableWeeks.length > 0">

                                        <th :colspan="numberOfMainColumns" class="invisible week">
                                        <th :colspan="week.colspan"
                                            class="month"
                                            v-for="(week, index) in calendarTableWeeks"
                                            :key="index">
                                            {{ week.colspan > 4 ? 'Week ' : week.colspan > 3 ? 'W ' : ''}}{{ week.number }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="data-column media">Media</th>
                                        <th rowspan="2" class="data-column site">Site</th>
                                        <th rowspan="2" class="data-column placement">Placement</th>
                                        <th rowspan="2" class="data-column strategies">Strategies</th>
                                        <th rowspan="2" class="data-column creative-sizes">Creative sizes</th>
                                        <th rowspan="2" class="data-column units">units</th>
                                        <th rowspan="2" class="data-column net">net price</th>
                                        <th rowspan="2" class="data-column ctc">cost to client (ctc)</th>
                                        <th rowspan="2" class="data-column cops">Client own programmatic spend</th>
                                        <!-- <th rowspan="2" class="data-column">startDate</th>
                                        <th rowspan="2" class="data-column">endDate</th> -->
                                        <th v-for="(date, index) in calendarTableData" :key="index" class="day half">{{ date.date() }}</th>
                                    </tr>
                                    <tr>
                                        <th v-for="(date, index) in calendarTableData" :key="index" class="day half">{{ getDayLetter(date)}}</th>
                                    </tr>
                                </thead>
                                <tbody v-if="composedData">
                                    <template v-for="(group, groupIndex) in composedData">
                                        <tr :key="groupIndex">
                                            <td :colspan="numberOfMainColumns+calendarTableData.length" class="media-channel-title">
                                                {{ group[0].mediaChannel }}
                                            </td>
                                        </tr>
                                        <tr v-for="item in group" :key="item.orderID" class="order">
                                            <td class="data-column media">{{ item.media }}</td>
                                            <td class="data-column site">{{ item.mediaPlanInformation ? item.mediaPlanInformation.site : ""}}</td>
                                            <td class="data-column placement">{{ item.placement }}</td>
                                            <td class="data-column strategies">{{ item.mediaPlanInformation ? item.mediaPlanInformation.strategies  : ""}}</td>
                                            <td class="data-column creative-sizes">{{ item.formats }}</td>
                                            <td class="data-column units">{{ item.mediaPlanInformation ? item.mediaPlanInformation.units  : "" }}</td>
                                            <td class="data-column net">{{ item.net}}</td>
                                            <td class="data-column ctc">{{ item.ctc}}</td>
                                            <td class="data-column cops">{{ item.clientOwnProgrammaticSpend ? item.clientOwnProgrammaticSpend : "---" }}</td>
                                            <!-- <td class="data-column">{{ item.startDate}}</td>
                                            <td class="data-column">{{ item.endDate}}</td> -->
                                            <td v-for="(date, index) in calendarTableData" :key="index" class="day"
                                                :class="{'fill' : activeForDay(date, item.startDate, item.endDate)}">
                                                <!-- {{index+1}} -->
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="100%">Plan version has no orders</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row" v-else>
                                <div class="col d-flex justify-content-center align-items-center">
                                    No data
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-4 brief-informations" v-if="briefInfo">
                    <brief-info-card v-if="briefInfo" :brief="briefInfo" v-on:click="openBrief" />
                </div>
                <div class="col-8">
                    <Comments />
                </div>
            </div>
           
        </div>
        <b-modal id="modal-download-rationale" :hide-footer="true" :centered="true">
            <template #modal-header="{ close }">
                <b-button-close @click="close()" />
                <h2>Download rationale</h2>
            </template>

            <connect-box-file-download :type="'campaign'" :params="{'campaignID':boxValues.id, 'clientID':boxValues.client, 'marathonCountryID':boxValues.country}"/>
        </b-modal>

    </div>

</template>

<script>
import DragScrool from 'dragscroll';
import Comments from '@/components/Comments/Comments.vue'
import ConnectBoxFileDownload from '@/components/ConnectFileDownload/ConnectBoxFileDownload.vue';
import BriefInfoCard from '@/components/Cards/BriefInfoCard.vue';
import UserHelpers from '@/helpers/UserHelpers.js';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

import { mapActions, mapState } from 'vuex'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

export default {
    props: [],
    data() {
        return {
            campaignID: null,
            marathonCountryID: null,
            clientID: null,
            campaign: null,
            orders: [],
            selectedVersion: null,
            planVersions: [],
            composedData: [],
            calendarTableStart: 0,
            calendarTableEnd: 0,
            calendarTableLengthDays: 0,
            calendarTableLengthMonths: 0,
            calendarTableData: [],
            calendarTableMonths: null,
            calendarTableWeeks: null,
            selectedVersionID: null,
            numberOfMainColumns: 9,
            checkList: [],
            selectedVersionNumber: null,
            briefInfo: null,
        };
    },
    methods: {
        getOrders() {
            if (this.campaignID) {
                const path = this.$constants.apiPaths.planning.orders(this.marathonCountryID, this.clientID, this.campaignID);
                this.axios.get(path)
                    .then((res) => {
                        if (res.data) {
                            this.orders = res.data;
                        }
                    })
                    .catch((error) => {
                        this.handleRequestError(error);
                    });
            } else {
                console.error("cannot fetch data without campaignID")
            }
        },
        getPlanVersions(){
            if (this.campaignID) {
                const params = new URLSearchParams();
                params.append('campaignID', this.campaignID);
                params.append('marathonCountryID', this.marathonCountryID);
                params.append('metaDataOnly', 'True');
                const path = this.$constants.apiPaths.planning.planVersions;
                let latestVersion =
                {
                    "_id": "latest",
                    "planVersionID": "latest", 
                    "timestampCreated": "LATEST"
                };
                return this.axios.get(path, {
                    params: params
                })
                    .then((res) => {
                        if (res.data && res.data.plans) {
                            var versions = res.data.plans.reverse();

                            const planVersions = [];
                            for (var i = 0; i < versions.length; i++) {
                                if (versions[i].planVersionID) {
                                    planVersions.push(versions[i]);
                                }
                            }

                            planVersions.unshift(latestVersion);
                            this.planVersions = planVersions;
                        }
                        else{
                            this.planVersions = [];
                            this.planVersions.push(latestVersion);
                        }
                    })
                    .catch((error) => {
                        this.planVersions = [];
                        this.handleRequestError(error);
                        this.planVersions.push(latestVersion);
                    });
            } else {
                console.error("cannot fetch data without campaignID")
            }
        },
        getPlanVersion(planVersionID){
            if(planVersionID) {
                const params = new URLSearchParams();
                params.append('planVersionID', planVersionID);
                params.append('marathonCountryID', this.marathonCountryID);
                const path = this.$constants.apiPaths.planning.planVersions;
                const that = this;
                return this.axios.get(path, {
                    params: params
                })
                    .then((res) => {
                        if (res.data && res.data.plans && res.data.plans.length > 0) {
                            return res.data.plans[0];
                        }
                    })
                    .catch((error) => {
                        that.handleRequestError(error);
                        return error;
                    });
            } else {
                console.error("cannot fetch data without planVersionID");
            }
        },
        approvePlan(){
            this.$swal.fire({
                title: 'Are you sure you want to approve this plan?',
                showCancelButton: true,
                reverseButtons: true,
                showCloseButton: true,
                confirmButtonColor: '#059513',
                cancelButtonColor: '#333',
                confirmButtonText: 'Yes, approve',
                cancelButtonText: "No, not yet",
                customClass: "swal2-popup-dark",
            }).then((result) => {
                const value = result.value;

                if(value && this.campaignID !== null) {

                    // const path = this.$constants.apiPaths.planning.campaigns();
                    // const params = new URLSearchParams();
                    // params.append("campaignID", this.campaignID);
                    // params.append("marathonCountryID", this.campaign.marathonCountryID);
                    // this.axios
                    //     .get(path, {
                    //         params: params,
                    //     })
                    const path = this.$constants.apiPaths.planning.campaign(this.campaign.marathonCountryID, this.campaign.clientID, this.campaignID);
                    this.axios.get(path)
                        .then((res) => {
                            if(res.data){
                                //console.log(res.data)
                                this.campaign = res.data;

                                this.campaign.campaignApprovedByClient = true;
                                this.sendCampaignRequest(this.campaign).then((success) => {
                                    if(success){
                                        this.$swal.fire({
                                            imageUrl: "/img/big_green_check.png",
                                            title: '<span>Plan approved</span>',
                                            showCloseButton: true,
                                            showConfirmButton: false,
                                            customClass: "swal2-popup-dark",
                                            timer: 1500,
                                        });
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            this.handleRequestError(error);
                        });
                }

            })

        },
        downloadRationale(){
            this.$swal.fire({
                title: 'Operation failed',
                icon: 'error',
                allowOutsideClick: true,
            });
        },
        openFullMediaPlan(){            
            //console.log(this.$constants.apiPaths.planning.exportCampaignToExcel)
            const path = this.$constants.apiPaths.planning.exportCampaignToExcel(this.marathonCountryID, this.clientID, this.campaignID);
            //console.log(path)
            this.axios
                .get(path, {responseType: 'blob'})
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'mediaplan_campaign_' + this.campaign.campaign + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.handleRequestError(error);
                });
        },
        sendCampaignRequest(campaign) {
            
            const path = this.$constants.apiPaths.planning.campaign(campaign.marathonCountryID, campaign.clientID, campaign.campaignID);
            //const path = this.$constants.apiPaths.planning.campaigns();
            //campaign.campaignApprovedByClient = true;
            const payload = campaign;

            return this.axios.put(path, payload)
                .then((res) => {
                    if(res && res.data){                        
                        this.marathonResponse =  res.data.code + '-' + res.data.message;
                        return true;
                    }
                })
                .catch((error) => {
                    if(error.data){
                        this.marathonResponse =  error.data.code + '-' + error.data.message;
                    }
                    this.$swal.fire({
                        title: 'Operation failed',
                        icon: 'error',
                        allowOutsideClick: false,
                        text: error,
                    });
                    console.error(error);
                    return false;
                });

        },
        getCampaign(marathonCountryID, clientID, campaignID) {
            if(campaignID && clientID && marathonCountryID){
                // const params = new URLSearchParams();
                // params.append("campaignID", campaignID);
                // params.append("marathonCountryID", marathonCountryID);
                // this.axios
                //     .get(this.$constants.apiPaths.planning.campaigns(), {
                //         params: params,
                //     })


                this.axios(this.$constants.apiPaths.planning.campaign(marathonCountryID, clientID, campaignID))                           
                    .then((res) => {
                        if(res.data){
                            this.campaign = res.data;
                            if(res.data.campaign){
                                this.updateCampaignName(this.campaign.campaign);
                            }
                            //this.getBriefInfo();
                            
                            this.briefInfo = UserHelpers.enrichUserInfo(res.data);     

                            // if(res.data.length > 0){
                            //     var campaignList = res.data.filter(element => element.campaignID === campaignID);
                            //     if(campaignList.length === 1){
                            //         this.campaign = campaignList[0];
                            //         this.clientID = this.campaign.clientID
                            //     }
                            // }
                            // else{
                            //     this.campaign = res.data[0];
                            // }
                            // if(this.campaign){
                            //     //this.$emit('update:campaignName', this.campaign.campaign);
                            //     this.updateCampaignName(this.campaign.campaign);
                            // }
                            // this.getBriefInfo();
                        }
                    })
                    .catch((error) => {
                        this.handleRequestError(error);
                    });
            }
            else{
                console.error("cannot get data without campaignID & marathonCountryID")
            }
           
        },
        handleRoutes() {
            if (this.$route.params.campaignID) {
                this.campaignID = this.$route.params.campaignID;
            }
            if (this.$route.params.marathonCountryID) {
                this.marathonCountryID = this.$route.params.marathonCountryID;
            }
            if (this.$route.params.clientID) {
                this.clientID = this.$route.params.clientID;
            }
        },
        handleRequestError(error) {
            console.error(error);
        },
        activeForDay(thisDay, startDate, endDate){
            var start = dayjs.utc(startDate);
            var end = dayjs.utc(endDate).add(1, 'day').subtract(1, 'millisecond');
            if(start.isSameOrBefore(thisDay) && end.isSameOrAfter(thisDay)){
                return true;
            }
            return false;
        },
        getDaysInMonth(month,year) {
            var date = new Date(year, month+1, 0);
            return date.getDate();
        },
        getDayLetter(date){
            return date.toString().substring(0,1);
        },
        getMonthName(date){
            var options = { month: 'long'};
            return new Intl.DateTimeFormat('en-US', options).format(date);
        },
        calculateMonthColspanAndName(index){
            var daysOffset = 0;

            for(var i=0; i < index; i++){
                daysOffset += this.getDaysInMonth(this.calendarTableData[daysOffset].month(), this.calendarTableData[daysOffset].year());
            }
            return {
                colspan: this.getDaysInMonth(this.calendarTableData[daysOffset].month(), this.calendarTableData[daysOffset].year()),
                name: this.getMonthName(this.calendarTableData[daysOffset])
            }
        },
        hasElement(className) {
            return document.getElementsByClassName(className).length > 0;
        },
        selectVersion(planVersion, planVersionNumber){
            if(planVersion){
                this.selectedVersion = planVersion;
                this.selectedVersionID = planVersion.planVersionID;
                this.selectedVersionNumber = planVersionNumber;

                if(planVersion.planVersionID === 'latest') {
                    this.getOrders();
                }
                else {
                    const that = this;
                    this.getPlanVersion(planVersion.planVersionID)
                        .then((response) =>{
                            that.selectedVersion = response;
                            that.orders = response.orders;
                            that.getVersionRelatedOrders(that.selectedVersion, this.campaign.planIDS);
                        })
                        .catch((error) => {
                            console.error(error)
                        });
                }
            }
        },
        getVersionRelatedOrders(selectedVersion, campaignPlans){
            if(selectedVersion && selectedVersion.planVersionID !== "latest"){
                const currentPlanID = selectedVersion.planID;
                campaignPlans = campaignPlans.filter(function(id){
                    return id.toString() !== currentPlanID;
                });
                let orders = selectedVersion.orders;
                let currentVersionPosition = this.planVersions.findIndex(function(version){
                    return version.planVersionID === selectedVersion.planVersionID;
                })
                let queue = [];
                
                for(let i = 0; i < campaignPlans.length; i++){
                    for(let j = currentVersionPosition; j < this.planVersions.length; j++){
                        let planID = parseInt(this.planVersions[j].planID);
                        if(campaignPlans[i] === planID && !(queue.some(function(obj) {
                            obj.planID === planID;
                        }))){
                            campaignPlans.splice(i,1);
                            queue.push({
                                planVersionID: this.planVersions[j].planVersionID,
                                planID: planID
                            });
                        }
                    }
                }

                let queuePromises =[];
                let that = this;
                if(queue.length > 0){
                    queue.forEach(function(obj){
                        queuePromises.push(that.getPlanVersion(obj.planVersionID));
                    })
                }
                
                Promise.allSettled(queuePromises)
                    .then((results) => {
                        results.forEach((result) =>{
                            orders.push(...result.value.orders);
                        });
                        this.orders = orders;
                    });
            }

        },
        // getBriefInfo(){
        //     if (this.campaignID) {
        //         this.loading = true;
        //         const path = this.$constants.apiPaths.planning.campaign(this.marathonCountryID, this.clientID, this.campaignID);
                
        //         this.axios(path)
        //             .then((res) => {
        //                 if (res.data && res.data.length > 0) {
        //                     this.briefInfo = UserHelpers.enrichUserInfo(res.data[0]);                           
        //                 }
                        
        //                 this.loading = false;
        //             })
        //             .catch((error) => {
        //                 this.handleRequestError(error);
        //                 this.loading = false;
        //             });
        //     } else {
        //         console.warn("cannot fetch data without campaignID")
        //     }
        // },
        openBrief(){
            this.$emit('open-detail', 'brief', { campaignID: this.campaignID, marathonCountryID: this.marathonCountryID, clientID: this.clientID});
        },
        ...mapActions([
            'updateCampaignName',
        ]),
    },
    watch: {
        orders(newVal) {
            if(newVal && newVal.length > 0){
                this.calendarTableMonths = null;
                this.calendarTableWeeks = null;

                var calendarTableMonthsOffset = 0;
                var calendarTableWeeksOffset = 0
                var oldest = dayjs.utc(newVal[0].startDate);
                var latest = dayjs.utc(newVal[0].endDate);

                for(let i=0; i < newVal.length; i++){
                    var start =  dayjs.utc(newVal[i].startDate);
                    var end = dayjs.utc(newVal[i].endDate);
                    if(start.isBefore(oldest)){
                        oldest = start;
                    }
                    if(end.isAfter(latest)){
                        latest = end;
                    }
                }
                //var lastDay = null;
                this.calendarTableStart = oldest.date(1);
                latest = dayjs.utc(latest);
                latest = latest.date(this.getDaysInMonth(latest.month(), latest.year()))
                this.calendarTableEnd = latest.add(1, 'day').subtract(1, 'millisecond');

                this.calendarTableLengthDays = Math.round(this.calendarTableEnd.diff(this.calendarTableStart, 'days', true));
                this.calendarTableLengthMonths = Math.ceil(this.calendarTableEnd.diff(this.calendarTableStart, 'months', true));

                this.calendarTableData = [];
                for(let i=0; i < this.calendarTableLengthDays; i++){
                    var thisDay = this.calendarTableStart.add(i, 'day');
                    this.calendarTableData.push(thisDay);

                    if(i <= (this.calendarTableLengthDays-7)){
                        //on first sunday (calendarTableWeeks === null), add first week column (not whole week)
                        if(thisDay.day() === 0){
                            if(this.calendarTableWeeks === null){
                                this.calendarTableWeeks = [];
                                this.calendarTableWeeks.push({
                                    number: this.calendarTableStart.isoWeek(),
                                    colspan: i+1
                                });
                                calendarTableWeeksOffset = i+1;
                            }
                        }
                        //on every monday, add whole week column
                        if(thisDay.day() === 1){
                            if(this.calendarTableWeeks === null){
                                this.calendarTableWeeks = [];
                            }
                            this.calendarTableWeeks.push({
                                number: thisDay.isoWeek(),
                                colspan: 7
                            });
                        }
                    }
                    else{
                        var calendarTableWeeksOffsetRest = Math.ceil((this.calendarTableLengthDays - calendarTableWeeksOffset) % 7);
                        if(thisDay.day() === calendarTableWeeksOffsetRest){                        
                            this.calendarTableWeeks.push({
                                number: this.calendarTableStart.add(i-calendarTableWeeksOffsetRest, 'day').add(1, 'day').isoWeek(),
                                colspan: calendarTableWeeksOffsetRest
                            });
                        }
                    }

                    if(thisDay.date() === 1) {
                        if(this.calendarTableMonths === null){
                            this.calendarTableMonths = [];
                            if(i !== 0){
                                this.calendarTableMonths.push({
                                    name: this.getMonthName(this.calendarTableStart),
                                    colspan: i
                                });
                                calendarTableMonthsOffset = i;
                            }

                        }

                        this.calendarTableMonths.push({
                            name: this.getMonthName(thisDay),
                            colspan: this.getDaysInMonth(thisDay.month(), thisDay.year())
                        });
                    }
                }

                this.composedData = newVal.reduce((accumulator, currentObject) => {
                    accumulator[currentObject.mediaChannelID] = [...accumulator[currentObject.mediaChannelID] || [], currentObject];
                    return accumulator;
                }, {});

            }
            else{
                this.composedData = null;
            }

           
        },
    },
    mounted() {
        DragScrool.reset();

        this.handleRoutes();
        //this.getCampaign(this.campaignID, this.marathonCountryID);
        this.getCampaign(this.marathonCountryID, this.clientID, this.campaignID);
        try{
            this.getPlanVersions().then(() => {
                if(this.planVersions && this.planVersions.length > 0){
                    this.selectVersion(this.planVersions[0], this.planVersions.length );
                }
                else{
                    this.getOrders();
                }
            });
            
            DragScrool.reset()
        }catch(err){
            console.error(err);
            this.getOrders();
            DragScrool.reset();
        }
    },
    components: {
        Comments,
        ConnectBoxFileDownload,
        [BriefInfoCard.name]: BriefInfoCard
    },

    computed: {
        boxValues() {
            return {"client":this.$route.path.split('/')[3], "id":this.$route.path.split('/')[4], "country":this.$route.path.split('/')[2]}
        },
        approveIsDisabled(){
            return (
                (this.planVersions.length === 0  && !this.campaign && this.orders.length === 0) ||
                    (this.planVersions.length > 0 && (this.planVersions[0] && this.selectedVersionID !== this.planVersions[0].planVersionID))) ||
                    !this.campaign || ( this.campaign && (this.orders.length === 0 || this.campaign.campaignApprovedByClient)
            )
        },
        rationaleOptions(){
            var options = []
            const regex = /\s/gi;
            for (const [key] of Object.keys(this.composedData)) {
                var mediaChannel = this.composedData[key][0].mediaChannel.replace(regex, '_');

                var campaign = '';
                if(this.campaign && this.campaign.campaign){
                    campaign = this.campaign.campaign.replace(regex, '_');
                }
                options.push({
                    label: mediaChannel + '_' + campaign + '.pdf'
                })
            }

            return options;
        },
        ...mapState([
            'campaignName',            
        ]),

    },

};
</script>

<style scoped>
.campaign-comment-input .ProseMirror {
    min-height: 160px !important;
}
</style>
