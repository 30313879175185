<template>
    <div>
        <div class="row">
            <div class="col">
                <router-view :campaigns="campaigns" :client="selectedClient" :country="userSelectedCountry" :clientsFilter="userSelectedMultipleClients" :hideStatusHover="true" :getRoute="getRoute"/>
            </div>
        </div>
    </div>
</template>

<script>
import ClientCountrySelect from '@/components/ClientCountrySelect/ClientCountrySelect.vue'
import { mapActions, mapState } from 'vuex'

export default {
    inject: ['formatCampaignNumbers'],
    props: [ 'clientSelectOptions', 'internalUser'],
    data() {
        return {
            selectedCampaign: '',
            campaignObjective: '',
            campaigns: [],
            campaign: null,
            reset: null,
        };
    },
    components: {
        [ClientCountrySelect.name]: ClientCountrySelect,
    },
    methods: {
        getRoute(row, linkType) {
            if (linkType === 'campaign') {
                return {
                    name: this.$constants.routeNames.dashboards.campaign,
                    params: {
                        campaignID: row.id,
                        clientID: row.clientID,
                        marathonCountryID: row.marathonCountryID
                    }
                };            
            }
            if (linkType === this.$constants.pacingTypes.PROGRAMMATIC || type === this.$constants.pacingTypes.DIRECT) {
                if(linkType === this.$constants.pacingTypes.PROGRAMMATIC){
                    //this.updateDetailName(row.insertionOrder)
                    return {
                        name: this.$constants.routeNames.dashboards.programmatic,
                        params: {
                            marathonCountryID: this.$route.params.marathonCountryID,
                            campaignID: this.$route.params.campaignID,
                            type: row.type,
                            insertionOrderID: row.insertionOrderID
                        }
                    };
                }
                else{
                    //this.updateDetailName(row.site);
                    return {
                        name: this.$constants.routeNames.dashboards.direct,
                        params: {
                            marathonCountryID: this.$route.params.marathonCountryID,
                            campaignID: this.$route.params.campaignID,
                            type: row.type,
                            siteID: row.siteID,
                            dcmCampaignID: row.dcmCampaignID
                        }
                    };
                }
                
            }
        },
        getCampaigns() {
            const params = new URLSearchParams();
            params.append('status', this.$constants.campaignStates.LIVE.value);
            this.axios(
                this.$constants.apiPaths.planning.campaigns(), {params: params})
                .then((res) => {
                    if (res.data) {
                        this.campaigns = res.data;
                        this.formatCampaignNumbers(this.campaigns);
                        if (this.$route.params.campaignID) {
                            this.setCampaignNameById(this.$route.params.campaignID, this.$route.params.marathonCountryID, this.$route.params.clientID);
                        }
                       
                    }
                })
                .catch((error) => {
                    this.handleRequestError(error);
                });
        },
        handleRequestError(error) {
            console.error(error);
        },
        isCurrentRoute(routePath) {
            return this.$router.currentRoute.path == routePath;
        },
        setCampaignNameById(campaignID, marathonCountryID, clientID) {
            var campaignList = this.campaigns.filter(element => element.campaignID === campaignID && element.clientID === clientID && element.marathonCountryID === marathonCountryID);
            if (campaignList.length === 0)
                campaignList = this.campaigns.filter(element => element.campaignID === campaignID && element.marathonCountryID === marathonCountryID);
            if (campaignList.length === 0)
                campaignList = this.campaigns.filter(element => element.campaignID === campaignID);
            if(campaignList.length > 0){
                this.updateCampaignName(campaignList[0].campaign);
            }
        },
        goToOverview(){
            if(this.$route.name !== this.$constants.routeNames.dashboards.root){
                this.updateSelectedClient({client:null})
                this.$router.push({ name: this.$constants.routeNames.dashboards.root});
            }
        },
        ...mapActions([
            'updateCampaignName',
            'updateDetailName',
            'updateSelectedClient',
        ]),
    },
    computed:{
        ...mapState([
            'campaignName',
            'detailName',
            'selectedClient',
            'userSelectedCountry',
            'userSelectedMultipleClients'
            
        ]),
    },
    mounted() {
        this.reset = () =>{
            this.goToOverview();
        }
        this.$root.$on('reset-route', this.reset);
        this.getCampaigns();
    },
    beforeDestroy() {
        this.$root.$off('reset-route', this.reset);
    },
};
</script>
