

import constants from "@/constants";

const fetchOrders = (axios, campaignId, marathonCountryID, clientID) => {
    const path = constants.apiPaths.planning.orders(marathonCountryID, clientID, campaignId);
    return axios
        .get(path)
        .then((res) => {
            if (res.data)
                return res.data;
        })
        .catch((error) => {
            console.error(error);
        });
}

// const fetchClient = (axios, marathonCountryID, clientID) => {
//     // const path = constants.apiPaths.planning.clients;

//     // return axios
//     //     .get(path, {
//     //         params: {
//     //             marathonCountryID: marathonCountryID,
//     //             clientID: clientID,
//     //         },
//     //     })

    
//     const path = constants.apiPaths.planning.client(marathonCountryID, clientID);

//     return axios
//         .get(path)
//         .then((res) => {
//             if (res.data && res.data.clients && res.data.clients.length > 0) {
//                 return res.data.clients[0] || {};
//             }
//         });
// }


const fetchOrderInformation = (axios, marathonCountryID) => {
    const path = constants.apiPaths.planning.orderInformation(marathonCountryID);
    return axios
        .get(path)
        .then((res) => {

            return {
                medias: res.data.medias,
                allowedMedias: res.data.medias,
                surcharges: res.data.surcharges,

                mediaChannels: res.data.mediaChannels
            }
        })
        .catch((error) => {
            this.handleRequestError(error);
        });
}


// const fetchOrderInformationV1 = (axios) => {
//     const path = constants.apiPaths.planning.orderInformationV1;
//     return axios
//         .get(path)
//         .then((res) => {

//             return {
//                 medias: res.data.medias,
//                 allowedMedias: res.data.medias,
//                 surcharges: res.data.surcharges,

//                 mediaChannels: res.data.mediaChannels
//             }
//         })
//         .catch((error) => {
//             this.handleRequestError(error);
//         });
// }


const fetchSurcharges = (axios) => {
    const path = constants.apiPaths.planning.surcharges();
    return axios
        .get(path)
        .then((res) => {

            return {
                surcharges: res.data,
            }

            // return {
            //     surcharges: res.data.surcharges,
            // }
        })
        .catch((error) => {
            this.handleRequestError(error);
        });
}

const fetchMedia = (axios) => {
    const path = constants.apiPaths.planning.media();
    return axios
        .get(path)
        .then((res) => {

            // return {
            //     medias: res.data.medias,
            // }
            return {
                medias: res.data,
            }
        })
        .catch((error) => {
            this.handleRequestError(error);
        });
}

// const fetchDataModels = (axios, authToken) => {
//     const path = constants.apiPaths.dataModels;
//     return axios.get(path, { headers: { Authorization: `Bearer: ${authToken}` } })
//         .then(({ data }) => {

//             return data;
//         })
//         .catch((err) => {
//             this.handleRequestError(err);
//         });
// }

export {
    fetchOrders,
    //fetchClient,
    //fetchOrderInformationV1,
    fetchOrderInformation,
    fetchMedia,
    fetchSurcharges,
    //fetchDataModels
}