import { render, staticRenderFns } from "./ConnectBoxFileUpload.vue?vue&type=template&id=54182486&scoped=true&"
import script from "./ConnectBoxFileUpload.vue?vue&type=script&lang=js&"
export * from "./ConnectBoxFileUpload.vue?vue&type=script&lang=js&"
import style0 from "./ConnectBoxFileUpload.vue?vue&type=style&index=0&id=54182486&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54182486",
  null
  
)

export default component.exports