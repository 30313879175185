<template>
    <div class="card brief-list">
        <h2>{{ title }}</h2>
        <el-table :data="data" @row-click="handleRowClick" ref="thisTable" @sort-change="customSort">

            <el-table-column align="left" label="" :width="40" v-if="linkType" class-name="linked">
                 <template slot-scope="scope">
                    <router-link :to="getRoute(scope.row, linkType)" >
                        <span>
                            <base-button class="open btn-link" type="success" size="sm" icon >
                                <i class="tim-icons icon-double-right"></i>
                            </base-button>
                        </span>
                    </router-link>
                 </template>
            </el-table-column>
            <el-table-column align="left" label="" :width="40" v-else>
                <base-button class="open btn-link" type="success" size="sm" icon >
                    <i class="tim-icons icon-double-right"></i>
                </base-button>
            </el-table-column>

             <el-table-column ael-table-column :width="20" label="" className="status" v-if="linkType" class-name="linked">
                 <template slot-scope="scope">
                    <router-link :to="getRoute(scope.row, linkType)" >
                        <span>
                            <span slot="reference">
                                <span :class="['state', 'without-text', scope.row.status.toLowerCase()]"> </span>
                            </span>
                        </span>
                    </router-link>
                 </template>
            </el-table-column>

            <el-table-column :width="20" label="" className="status" v-else>
                <template slot-scope="scope">
                    <span slot="reference">
                        <span :class="['state', 'without-text', scope.row.status.toLowerCase()]"> </span>
                    </span>
                </template>
            </el-table-column>

            
            <template v-if="linkType">
                <el-table-column
                    v-for="column in classicTableColumns"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :width="column.width"
                    :prop="column.prop"
                    :label="column.label"
                    :className="column.prop + ' linked'"
                    sortable="custom"
                    :sort-method="disableSortWithoutOtherPages"
                >
                
                    <template slot-scope="scope">
                        <router-link :to="getRoute(scope.row, linkType)"><span>{{scope.row[column.prop]}}</span></router-link>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="column in iconTableColumns"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :width="column.width"
                    :prop="column.prop"
                    :label="column.label"
                    :className="column.prop + ' icon-cell linked'"
                    :sortable="true"
                    align="center"
                >
                    <template slot-scope="scope" v-if="scope.row[column.prop]">
                        <router-link :to="getRoute(scope.row, linkType)">
                            <span>
                               <el-popover class="custom-popper" trigger="hover" placement="top">
                                    <div class="popover-wrapper">
                                        <div class="popover-body">
                                            <div >{{ scope.row[column.prop] ? scope.row[column.prop].name : '' }}</div>
                                        </div>
                                    </div>
                                    <span slot="reference">
                                        <span class="table-icon" :style="'background: ' + scope.row[column.prop].color + ';'">
                                            <span class="table-icon-popover">{{ scope.row[column.prop] ? scope.row[column.prop].initials : '' }}</span>
                                        </span>
                                    </span>
                                </el-popover>
                            </span>
                        </router-link>
                    </template>
                </el-table-column>
            </template>

             <template v-else>
                 <el-table-column
                    v-for="column in classicTableColumns"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :width="column.width"
                    :prop="column.prop"
                    :label="column.label"
                    :className="column.prop"
                    sortable="custom"
                    :sort-method="disableSortWithoutOtherPages"
                >
                </el-table-column>

                <el-table-column
                    v-for="column in iconTableColumns"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :width="column.width"
                    :prop="column.prop"
                    :label="column.label"
                    :className="column.prop + ' icon-cell'"
                    :sortable="true"
                    align="center"
                >
                    <template slot-scope="scope" v-if="scope.row[column.prop]">
                        <el-popover class="custom-popper" trigger="hover" placement="top">
                            <div class="popover-wrapper">
                                <div class="popover-body">
                                    <div >{{ scope.row[column.prop] ? scope.row[column.prop].name : '' }}</div>
                                </div>
                            </div>
                            <span slot="reference">
                                <span class="table-icon" :style="'background: ' + scope.row[column.prop].color + ';'">
                                    <span class="table-icon-popover">{{ scope.row[column.prop] ? scope.row[column.prop].initials : '' }}</span>
                                </span>
                            </span>
                        </el-popover>
                    </template>
                </el-table-column>
            </template>
        </el-table>
        <div class="row">
            <div class="col">
            <div slot="footer" class="table-footer justify-content-sm-between">
                <div class="pagination-info">Showing {{ from + 1 }} - {{ to }} (total {{ total }} )</div>
                <div class="note-info">
                        <span class="note-item" v-for="([key, value], index) in Object.entries($constants.campaignStates)" :key="index">
                            <el-tooltip placement="top">
                                <div slot="content">
                                    {{ value.label }}
                                </div>
                                <span :class="['circle', key.toLowerCase()]"></span>
                            </el-tooltip>
                        </span>
                </div>
                <base-pagination
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                >
                </base-pagination>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BasePagination } from "src/components";
export default {
    name: "brief-list",
    components: {
        BasePagination,
    },
    props: {
        title: {
            type: String,
            default: "Briefs",
            description: "List title",
        },
        tableData: {
            type: Array,
            default: function() {
                return [];
            },
        },
        tableColumns: {
            type: Array,
            default: function() {
                return [];
            },
        },
        defaultSort: {
            type: Object,
            default:  function() {
                return {
                    prop: "campaign",
                    order: "ascending",
                };
            },
        },
        linkType:{
            title: {
                type: String,
                default: ""
            }
        },
        getRoute:{
            type: Function,
            default: function(){
                console.warn("getRoute default")
                return {
                    name: this.$constants.routeNames.campaigns.root,
                };
            }
        }
    },
    data() {
        return {
            pagination: {
                perPage: 10,
                currentPage: 1,
            },
            sortValue: this.defaultSort
        };
    },
    computed: {
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.tableData.length;
        },
        data() {
            return this.sortedTableData.slice(this.from, this.to);
        },
        classicTableColumns(){
            return this.tableColumns.filter(item => !item.displayAsIcon);
        },
        iconTableColumns(){
            return this.tableColumns.filter(item => item.displayAsIcon);
        },
        sortedTableData(){
            var copy = JSON.parse(JSON.stringify(this.tableData));
            var that = this;
            copy.sort(
                function(a,b){
                    var orderAsc = that.sortValue.order ? that.sortValue.order === "ascending" : null;
                    if(orderAsc !== null){
                        if (a[that.sortValue.prop] < b[that.sortValue.prop]) {
                            return orderAsc ? -1 : 1;
                        }
                        if (a[that.sortValue.prop] > b[that.sortValue.prop]) {
                            return orderAsc ? 1 : -1;
                        }
                    }
                       
                    return 0;
                }
            );

            return copy;
        }
    },
    methods: {
        customSort(value){
            this.sortValue = value;
        },
        goToDetail(row) {
            this.$emit('open-detail', row);
        },
        handleRowClick(row, index) {
            //this.$emit('open-detail', row);
        },
        disableSortWithoutOtherPages(a,b){
            return 0;
        },

    },
};
</script>
<style lang="scss" scoped>
.el-table--enable-row-hover .el-table__body tr.el-table__row:hover > td {
    background-color: #fff;
}
</style>
