<template>
  <b-modal ref="clientModal"
             id="client-modal"
             size="xl"
             hide-footer
             :centered="true">
    <div class="row">
        <div class="col-12">
          <h2>Connect Client <span style="font-weight: 200;">{{ client.client ? ' | ' + client.client : '' }}
            <h5>{{client.marathonCountryID}} {{client.clientID}}</h5>
          </span></h2>
        </div>

        <div class="col-12">
          <el-tabs class="client-section" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane class="client-pane" label="General" name="first">

              <div class="row">
                <div class="col-4">
                  <h6>Client Name</h6>
                  <h5>{{client.client}}</h5><br>

                  <h6>External Client Name</h6>
                  <h5>{{client.externalClientName}}</h5><br>

                  <h6>Client ID</h6>
                  <h5>{{client.clientID}}</h5><br>

                  <h6>Country ID</h6>
                  <h5>{{client.marathonCountryID}}</h5>
                </div>
                <div class="col-4">
                  <h6>FHS Information</h6>
                  <h5>--</h5>
                </div>
                <div class="col-4">
                  <div style="float: right">
                    <h6>Status</h6>
                    <h5>{{client.status}}</h5>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane class="client-pane" label="Media Channel Responsible" name="second">
              <clientTeamAssigned :users="users" :client="client"/>
            </el-tab-pane>
            <el-tab-pane class="client-pane" label="External Team" name="third">External Team</el-tab-pane>
            <el-tab-pane class="client-pane" label="Products" name="fourth">Products</el-tab-pane>
            <el-tab-pane class="client-pane" label="Agreements" name="fifth">Agreements</el-tab-pane>
            <el-tab-pane class="client-pane" label="Media Mapping" name="sixth">Media Mapping</el-tab-pane> -->
            <!--<el-tab-pane label="FHS" name="fourth">FHS</el-tab-pane>
            <el-tab-pane label="Programmatic Fee Setup" name="fourth">Programmatic Fee Setup</el-tab-pane>-->

          </el-tabs>
        </div>

        <!-- <div class="col-12" >

          <base-button v-if="!isLoadingSave" class="animation-on-hover client-modal-buttons" @click=submitClient(client) type="primary">Save client changes</base-button>
          <base-button v-if="isLoadingSave" loading class="animation-on-hover client-modal-buttons" type="primary">Save client changes</base-button>

        </div> -->

    </div>

    <!--

    <b-form class="w-100">
    <b-container>
      <div class="row">
          <b-button variant="primary">
                      Products
                    </b-button>
          <b-button variant="primary">
                      Agreements
                    </b-button>

          <b-button variant="primary"
                    @click="showMediaMapping()">
                      Media mapping
                    </b-button>

          <b-button variant="primary"
                        @click="showFHSInformation()">
                      FHS
                   </b-button>
          <b-button variant="primary"
                   @click="showMediaChannelResponsible('INTERNAL')">
                      Team - Internal
                   </b-button>
          <b-button variant="primary"
                   @click="showClientTeam('EXTERNAL')">
                      Team - External
                   </b-button>
          <b-button variant="primary">
                      Programmatic Fee Setup
                   </b-button>
      </div>
      <div class="row">
    <clientTeamAssigned  v-if="showClientMediachannelResponsible" :users="users" :client="client"/>
    <clientMediaMapping v-if="showClientMediaMapping"  :client="client"/>
    <clientFHSInformation v-if="showClientFHSInformation"  :client="client"/>
    </div>
              <b-button variant="primary"
                        @click="submitClient(client)">
                      Submit

                    </b-button>
    </b-container>
    </b-form> -->
    </b-modal>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            // showClientMediachannelResponsible: false,
            // showClientMediaMapping: false,
            // showClientFHSInformation: false,
            showAlert: false,
            userMessage: '',
            viewType: '',
            users: [],
            usersReady: false,
            activeName: 'first',
            isLoadingSave: false,
        };
    },
    props: ['client',],
    
    methods: {
        handleClick(tab, event) {
            //console.log(tab, event);
        },
        // showMediaMapping () {
        //     this.showClientMediaMapping = true ;
        //     this.showClientMediachannelResponsible = false ;
        //     this.showClientFHSInformation = false;
        // },
        // showMediaChannelResponsible(viewType) {
        //     this.showClientMediaMapping = false ;
        //     this.showClientMediachannelResponsible = true ;
        //     this.showClientFHSInformation= false;
        //     this.viewType = viewType;
        //     this.getUsers();
        // },
        // showClientTeam(viewType) {
        //     this.showClientMediaMapping = false ;
        //     this.showClientMediachannelResponsible = false ;
        //     this.showClientFHSInformation= true;
        //     this.viewType = viewType;
        //     this.getUsers();
        // },
        // showFHSInformation() {
        //     this.showClientMediaMapping = false ;
        //     this.showClientMediachannelResponsible = false ;
        //     this.showClientFHSInformation= true;

        // },
        // getUsers() {
        //     const path = this.$constants.apiPaths.users+'?type=INTERNAL';
        //     this.axios.get(path)
        //         .then((res) => {
        //             this.users = [];
        //             this.users = res.data.users;
        //             this.usersReady = true;
        //         })
        //         .catch((error) => {
        //             console.error(error);
        //         });
        // },
        submitClient(client) {
            const path = this.$constants.apiPaths.administration.clients;
            const payload = client;
            delete payload.agreements;
            delete payload.products;
            this.isLoadingSave = true;
            this.axios.put(path, payload)
                .then((res) => {
          
                    this.marathonCallStatus = res.data.status;
                    this.isLoadingSave = false;
                    document.querySelector('#client-modal').classList.remove("show")
                    this.client = undefined;
                    this.$swal.fire(
                        'Client has been saved',
                        'Changes saved to client: ' + this.client.clientID,
                        'success').then(() => {
                    });
                })
                .catch((error) => {
          
                    this.isLoadingSave = false;
                    console.log(error);
                });
        },

    },
    // created() {
    //     this.getUsers();
    // },
};
</script>
<style>
.client-modal-buttons{
  min-width: 175px;
}

#form-users-select {
  color: white !important;
}

.client-section {
  color: white !important;
}

.client-pane {
  min-height: 300px;
}
</style>
