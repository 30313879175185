<template>
    <div>
        <!-- <div class="row administration-header page-header">
            <div class="col">
                <div class="row">
                    <div class="col d-flex justify-content-between align-items-center">
                        <div class="page-title-wrapper">
                            <h1 class="profile-title page-title">
                              {{ routeName }}
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="back-button"
                            v-show="$router.currentRoute.name !== $constants.routeNames.campaigns.root"
                        >
                            <span class="btn btn-link btn-back"
                                ><i class="icon tim-icons icon-double-left" aria-hidden="true"></i>
                                <div class="label">Back</div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col col-lg-3 col-md-6" v-for="(card, index) in statsCards" :key="index">
                <stats-card
                    :title="card.title"
                    :sub-title="card.subTitle"
                    :type="card.type"
                    :icon="card.icon"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <Tabs class="administration-tabs tabs">
                    <Tab label="Users">
                        <user-admin :perPageOptions="perPageOptions" />
                    </Tab>
                    <Tab label="Groups">
                        <group-admin :perPageOptions="perPageOptions" />
                    </Tab>
                    <Tab label="Clients">
                         <client-admin :perPageOptions="perPageOptions" />
                    </Tab>
                    <Tab label="Tokens">
                        <div class="row">
                            <div class="col-12">
                            <token-admin :perPageOptions="perPageOptions" />
                            </div>
                        </div>
                    </Tab>
                    <!-- <Tab label="Errors">
                        <div class="row">
                            <div class="col-12">
                            
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Emails">
                        <div class="row">
                            <div class="col-12">
                           
                            </div>
                        </div>
                    </Tab> -->
                </Tabs>
            </div>
        </div>
    </div>
</template>


<script>
//import { TabPane, Tabs } from 'src/components';
import { Tab, Tabs } from 'src/components';
import StatsCard from 'src/components/Cards/StatsCard';
import UserAdmin from './UserAdmin';
import TokenAdmin from './TokenAdmin';
import GroupAdmin from './GroupAdmin';
import ClientAdmin from './ClientAdmin';
import { mapState } from 'vuex'

export default {
    data() {
        return {
            perPageOptions: [5, 10, 15, 20, 25, 50],
        };
    },
    components: {
        UserAdmin,
        GroupAdmin,
        TokenAdmin,
        ClientAdmin,
        Tabs,
        Tab,
        StatsCard
    },
    created() {
        if (localStorage.authToken) {
            this.authToken = localStorage.authToken;
        } else {
            this.redirectIfNotCurrent('/home');
        }
    },
    computed: {
        routeName() {
            return this.$route.name;
        },
        ...mapState([
            'clients',
            'groups',
            'users',
        ]),
        groupsCount(){
            return this.groups ? String(this.groups.length) : String(0);
        },
        usersCount(){
            return this.users ? String(this.users.length) : String(0);
        },
        clientsCount(){
            return this.clients ? String(this.clients.length) : String(0);
        },
        statsCards(){
            return [
                {
                    title: this.usersCount,
                    subTitle: 'Users',
                    type: 'info',
                    icon: 'tim-icons icon-single-02',
                },
                {
                    title: this.groupsCount,
                    subTitle: 'Groups',
                    type: 'primary',
                    icon: 'tim-icons icon-chat-33',
                },
                {
                    title: this.clientsCount,
                    subTitle: 'Clients',
                    type: 'primary',
                    icon: 'tim-icons icon-shape-star',
                },
                // {
                //     title: '-',
                //     subTitle: 'Notification Emails',
                //     type: 'warning',
                //     icon: 'tim-icons icon-chat-33',
                // },
                // {
                //     title: '--',
                //     subTitle: 'Errors & Exceptions',
                //     type: 'danger',
                //     icon: 'tim-icons icon-molecule-40',
                // }
            ];
        }
    },
    methods:{
        redirectIfNotCurrent(routePath) {
            if (!this.isCurrentRoute(routePath)) {
                this.$router.push(routePath);
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.nav-pills .nav-item i {
  display:none;
}

.spinner {
  opacity: .5;
  margin: 6px auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}

.spinner-text {
  opacity: .5;
  width:100%;
  text-align: center;
  font-weight: bold;
  font-size: 9pt;
  color: #00B7A6;
}

.spinner > div {
  background-color: #00B7A6;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right:3px;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

/* .el-tabs__item {
  color: #E7E7E7;
}
.el-tabs__item:hover {
  color: #00B7A6;
}
.el-tabs__item.is-active {
  color: #00B7A6;
}
.el-tabs__active-bar {
  background-color: #00B7A6;
} */



</style>