<template>
    <div>
        <router-view :briefs="clientBriefs" v-if="dataModels"
            :clientSelectOptions="clientSelectOptions" :countriesOptions="countriesOptions" :client="selectedClient" :countryID="userSelectedCountry"
            :multipleClients="userSelectedMultipleClients" :eventBus="eventBus" :getRoute="getRoute" v-on:close="closeBrief"/>
    </div>
</template>

<script>
import ClientCountrySelect from "@/components/ClientCountrySelect/ClientCountrySelect.vue";
import Vue from 'vue'
import UserHelpers from '@/helpers/UserHelpers.js';
import { mapActions, mapState } from 'vuex'

export default {
    props: ['clientSelectOptions'],
    inject: ["countriesOptions"],
    data() {
        return {
            clientBriefs: [],
            reset: null,
            eventBus: new Vue(),
        }
    },
    methods: {
        closeBrief() {
            this.$router.push({
                name: this.$constants.routeNames.briefs.root
            });
        },
        getBriefs() {
            this.axios(this.$constants.apiPaths.planning.campaigns())
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.clientBriefs = res.data;
                        this.clientBriefs.forEach(element => {
                            element = UserHelpers.enrichUserInfo(element);
                        });
                    }
                })
                .catch((error) => {
                    this.handleRequestError(error);
                });
        },
        getRoute(row, linkType){
            const type = linkType;
            if(type === 'edit'){
                return { name: this.$constants.routeNames.briefs.edit,
                    params: {
                        marathonCountryID: row.marathonCountryID,
                        clientID: row.clientID,
                        campaignID: row.campaignID,
                    },
                };
            }
            else if(type === 'preview'){
                return { name: this.$constants.routeNames.briefs.preview,
                    params: {
                        marathonCountryID: row.marathonCountryID,
                        clientID: row.clientID,
                        campaignID: row.campaignID,
                    },
                };
            }
        },
        handleRequestError(error) {
            console.error(error);
        },
        // goBack() {
        //     this.$router.go(-1);
        // },
        ...mapActions([
            'updateSelectedClient',
        ]),
    },
    computed:{
        ...mapState([
            'selectedClient',
            'userSelectedMultipleClients',
            'userSelectedCountry',
            'dataModels',
            
        ]),
    },
    components: {
        [ClientCountrySelect.name]: ClientCountrySelect,
    },
    mounted() {
        this.getBriefs();
        
        this.eventBus.$on("reload-campaigns", this.getBriefs);
    },
    beforeDestroy() {
        this.eventBus.$off("reload-campaigns", this.getBriefs);
    },
};
</script>
