<template>

    <div class="comments-log">
        <div v-for="(comment, id) in comments" v-bind:key="id" class="row">
            <div class="col-12 comment-post">
                <span class="poster-portrait" :style="getUserColor(comment.userID)">
                    {{ getUserInitials(comment.userName) }}
                </span>
                <span class="poster-name">
                    {{comment.userName}}
                </span>
                <span class="post-time">
                    {{timeDifference(comment.timestamp)}}
                </span>
                <span class="post-number">
                    #{{(comments.length - id)}}
                </span>
                <div class="post-comment">
                    <span v-html="comment.comment"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UserHelpers from '@/helpers/UserHelpers.js';
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
dayjs.extend(relativeTime)
dayjs.extend(utc)

export default {
    name: "comments-log",
    props: {
        comments: Array,
    },
    methods: {
        timeDifference(timestamp) {
            return dayjs.utc(timestamp).fromNow();
        },
        getUserInitials(username) {
            if(username){
                return UserHelpers.initialsFromUsername(username);
            }
            return ""
        },
        getUserColor(userID) {
            return 'background-color: ' + UserHelpers.colorFromId(userID) + ";";
        }
    },
};
</script>
<style lang="scss">

.comments{
    
    .comments-log {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 300px;
    }

    .comment-post {
        margin-top: -12px;
        margin-bottom: 22px;
        transition: .25s;

        &:hover{
            margin-left: 4px;
        }
    }

    // .comment-post:hover {
    //     margin-left: 4px;
    // }

    .poster-portrait {
        color: black;
        margin-right: 16px;
        margin-top: 18px;
        height: 40px;
        width: 40px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        line-height: 40px;
        font-weight: 900;
    }

    .poster-name {
        color: white;
        font-weight: 900;
        margin-right: 16px;
    }

    .post-time {
        color: white;
        margin-right: 12px;
        font-weight: 100;
    }

    .post-number {
        color: white;
        font-weight: 100;
    }

    .post-comment {
        color: white;
        margin-left: 60px;
        margin-top: -14px;
        transition: 0.5s;
    }


    // .from-message {
    //     background: #17a2b8;
    //     color: white;
    //     border-radius: 3px;
    //     padding: 8px 20px 2px;
    //     margin-bottom: 4px;
    // }

    // .to-message {
    //     background: rgb(201, 209, 209);
    //     color: rgb(41, 53, 52);
    //     border-radius: 3px;
    //     padding: 8px 20px 2px;
    //     margin-bottom: 4px;
    // }

    .white-content{
        .poster-name, .post-time, .post-number, .post-comment {
            color: #000;
        }
    }

}

</style>