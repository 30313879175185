<template>
  <div class="reporting tv-figures" ref="nice">
    <div class="d-flex justify-content-between">
        <h2>{{title}}</h2>
        <span class="sub-setting">
            <el-tooltip v-if="editable" content="Edit" placement="top">
              <span @click="edit" :class="['icon-edit sub-setting-icon', isEditing ? 'sub-setting-icon-editing' :'']">
                <i class="tim-icons icon-pencil"></i>
              </span>
            </el-tooltip>
            <slot name="action-buttons"></slot>
        </span>
    </div>

    <div>
        <el-table
          class="tv-figures-list pt-0"
          :data="tvRows">
              <el-table-column label="Key" :sortable="true">
                <template slot-scope="scope">
                  <div class="tvHeaders">{{tvRows[scope.$index].key.toUpperCase()}}</div>
                </template>
              </el-table-column>

              <el-table-column label="Value" :sortable="true">
                <template slot-scope="scope">
                  <el-input :placeholder="isEditing ? '+ Add ' + tvRows[scope.$index].key : ''" class="other-media-input" :disabled="!editable || editable && !isEditing" v-model="tvRows[scope.$index].value"></el-input>
                </template>
              </el-table-column>

              <el-table-column align="right" v-if="editable">

              </el-table-column>
      </el-table>

    </div>

    <slide-x-right-transition :duration="600" :delay="2000">
      <div v-if="isEditing" class="reporting-edit-save">
        <b-button @click="saveEdits" class="btn btn-primary">Save</b-button>
      </div>
    </slide-x-right-transition>
  </div>
</template>

<script>
import { SlideXRightTransition } from 'vue2-transitions';
import KeyLearnings from '@/components/KeyLearnings/KeyLearnings.vue'

export default {
    name: 'reporting-tv-figures',
    components: {
        SlideXRightTransition,
        KeyLearnings,
    },
    props: {
        value:{
            type: Object ,
            default: function () {
                return {
                    tvKeyFigures: [],
                    otherMedia: [],
                    trpOverview: []
                }
            },
        },
        editable: false,
        title: {
            type: String,
            description: 'List title'
        },
        tableColumns: {
            type: Array,
            default: function () {
                return []
            },
        },
        campaignTitle: {
            type: String,
            default: '',
            description: 'Campaign title'
        },
        help: {
            type: String,
            description: 'Help text'
        },
    },
    data() {
        return {
            tvRows: [{
                    key: 'Budget',
                    value: ''
                },
                {
                    key: 'Target Group',
                    value: ''
                },
                {
                    key: 'Station(s)',
                    value: ''
                },
                {
                    key: 'TRP Actuals',
                    value: ''
                },
                {
                    key: 'Reach +1',
                    value: ''
                },
                {
                    key: 'Reach +3',
                    value: ''
                },
                {
                    key: 'Avg. Frequency',
                    value: ''
                },
            ],
            isEditing: false,
        }
    },
    mounted() {
        if (this.value && this.value.tvKeyFigures) {
            for (var i = 0; i < this.value.tvKeyFigures.length; i++) {
                this.tvRows[i].value = this.value.tvKeyFigures[i].value;
            }
        }
    },
    watch:{
        tvRows: {
            deep: true,
            handler(){
                let val = JSON.parse(JSON.stringify(this.value));
                val.tvKeyFigures = this.tvRows
                this.$emit("input", val);
            }
        },
    },
    methods: {
        saveEdits() {
            this.$emit("save");
        },
        edit() {
            this.isEditing = !this.isEditing;
        },
    },
};
</script>

<style lang="scss" scoped>

// DO NOT USE GLOBAL STYLES!!! SCOPED. ALWAYS. IT'S BREAKING OTHER THINGS
//UNLESS IT'S NEEDED IN GLOBAL AND IT'S NOT GENERIC
.tvHeaders {
  font-weight: 900;
}

.el-table td div {
  font-weight: 700;
}

.cell input {
  min-width: 600px;
}

// .brief-list .el-table__body .el-table__row td {
//   min-width: 800px;
// }

.reporting-edit-save {
  margin-bottom: 12px;
}

.white-content{
    .el-input__inner{
        color: #000;
    }
}
</style>