<template>
    <div class="reporting-detail" ref="reporting-detail">
        <portal to="page-title_action" class="page-title_action">
            <template v-if="campaignName">
                <el-tooltip content="Download Reporting as PDF" placement="top">
                    <img class="download-icon icon" :src="'/img/icons/icon-download.svg'" v-on:click="downloadAsPDF()" alt="Download Reporting as PDF" />
                </el-tooltip>
            </template>
        </portal>
        <div class="row" :ref="cardsDefinitions.overview.ref">
            <div class="col">
                <cta-card :icon="'/img/icons/icon-' + statsCards[0].icon + '.svg'" :title="statsCards[0].title" :subtitle="statsCards[0].subtitle" class="title min-w-100" />
            </div>
            <div class="col">
                <cta-card :icon="'/img/icons/icon-' + statsCards[1].icon + '.svg'" :title="statsCards[1].title" :subtitle="statsCards[1].subtitle"
                    class="title min-w-100" />
            </div>
            <div class="col">
                <cta-card :icon="'/img/icons/icon-' + statsCards[2].icon + '.svg'" :title="statsCards[2].title" :subtitle="statsCards[2].subtitle"
                    class="title min-w-100" />
            </div>
            <div class="col">
                <cta-card :icon="'/img/icons/icon-' + statsCards[3].icon + '.svg'" :title="statsCards[3].title" :subtitle="statsCards[3].subtitle"
                    class="title min-w-100" />
            </div>
            <div class="col">
                <cta-card :icon="'/img/icons/icon-' + statsCards[4].icon + '.svg'" :title="statsCards[4].title" :subtitle="statsCards[4].subtitle"
                    class="title min-w-100" />
            </div>
            <div class="col">
                <cta-card :icon="'/img/icons/icon-' + statsCards[5].icon + '.svg'" :title="statsCards[5].title" :subtitle="statsCards[5].subtitle"
                    class="title min-w-100" />
            </div>
        </div>
        <div class="row" v-if="internalUser">
            <div class="col" :ref="cardsDefinitions.mapping.ref">
                <div class="card">
                    <universal-list title="Order mapping" :table-data="mapping" :table-columns="mappingColumns" :show-status="false" :show-clickable="false"
                        :list-classes="['dashboard-list order-mapping not-clickable']" :not-card="true" >
                    </universal-list>
                </div>
            </div>
        </div>

        <div class="row" v-if="medias">
            <div class="col" :ref="cardsDefinitions.digital.ref">
                <div class="card reporting-card">
                    <universal-list :title="cardsDefinitions.digital.title"  :table-data="cardsDefinitions.digital.values" :table-columns="cardsDefinitions.digital.columns" 
                    :show-status="false" :help="cardsDefinitions.digital.info"
                        :list-classes="['reporting-digital not-clickable mb-0 pb-3 p-0']" :not-card="true" :show-clickable="false" >
                          <template #action-buttons>  
                            <el-tooltip content="Download as PDF" placement="top">
                                <span v-on:click="downloadAsPDF(cardsDefinitions.digital)"  class="icon-download sub-setting-icon">
                                    <i class="tim-icons icon-cloud-download-93"></i>
                                </span>
                            </el-tooltip>
                        </template>
                    </universal-list>
                    <key-learnings :objectOfOrigin="objectOfOrigin"
                        :objectOfOriginType="'Key Learning' + cardsDefinitions.digital.title" :editable="internalUser" />
                </div>
            </div>
        </div>

        <!-- <reporting-list class="reporting-section-holder mt-0" v-if="medias" :campaignTitle="campaignName" :editable="internalUser" :info="cardsDefinitions.digital.info"
            :title="cardsDefinitions.digital.title"  :table-data="cardsDefinitions.digital.values" :table-columns="cardsDefinitions.digital.columns" /> -->

        <div class="row" v-if="videos" >
            <div class="col" :ref="cardsDefinitions.videos.ref">
                <div class="card reporting-card">
                    <universal-list :title="cardsDefinitions.videos.title"  :table-data="cardsDefinitions.videos.values" :table-columns="cardsDefinitions.videos.columns" 
                    :show-status="false" :help="cardsDefinitions.videos.info"
                        :list-classes="['reporting-videos not-clickable mb-0 pb-3 p-0']" :not-card="true" :show-clickable="false">
                          <template #action-buttons>  
                            <el-tooltip content="Download as PDF" placement="top">
                                <span v-on:click="downloadAsPDF(cardsDefinitions.videos)"  class="icon-download sub-setting-icon">
                                    <i class="tim-icons icon-cloud-download-93"></i>
                                </span>
                            </el-tooltip>
                        </template>
                    </universal-list>
                    <key-learnings :objectOfOrigin="objectOfOrigin"
                        :objectOfOriginType="'Key Learning' + cardsDefinitions.videos.title" :editable="internalUser" />
                </div>
            </div>
        </div>

        <div class="row" v-if="display">
            <div class="col" :ref="cardsDefinitions.display.ref">
                <div class="card reporting-card">
                    <universal-list :title="cardsDefinitions.display.title" :table-data="cardsDefinitions.display.values" :table-columns="cardsDefinitions.display.columns" 
                    :show-status="false" :help="cardsDefinitions.display.info"
                        :list-classes="['reporting-display not-clickable mb-0 pb-3 p-0']" :not-card="true" :show-clickable="false" >
                          <template #action-buttons>  
                            <el-tooltip content="Download as PDF" placement="top">
                                <span v-on:click="downloadAsPDF(cardsDefinitions.display)"  class="icon-download sub-setting-icon">
                                    <i class="tim-icons icon-cloud-download-93"></i>
                                </span>
                            </el-tooltip>
                        </template>
                    </universal-list>
                    <key-learnings :objectOfOrigin="objectOfOrigin"
                        :objectOfOriginType="'Key Learning' + cardsDefinitions.display.title" :editable="internalUser" />
                </div>
            </div>
        </div>

        <div class="row justify-content-md-center">
            <div v-if="showReportingTVFigures" class="col col-12 col-lg-6" :ref="cardsDefinitions.tvFigures.ref">
                <div class="reporting-card card">
                    <reporting-tv-figures :marathonCountryID="marathonCountryID" v-if="isReady"
                        :campaignTitle="campaignName" :editable="internalUser" class="tv"
                        :title="cardsDefinitions.tvFigures.title"  :table-columns="cardsDefinitions.tvFigures.columns"
                        v-model="manualData" v-on:save="saveManualData" :help="cardsDefinitions.tvFigures.info" >
                          <template #action-buttons>  
                            <el-tooltip content="Download as PDF" placement="top">
                                <span v-on:click="downloadAsPDF(cardsDefinitions.tvFigures)" class="icon-download sub-setting-icon">
                                    <i class="tim-icons icon-cloud-download-93"></i>
                                </span>
                            </el-tooltip>

                            <el-tooltip :content="cardsDefinitions.tvFigures.info" v-if="cardsDefinitions.tvFigures.info" placement="top">
                                <span class="icon-help sub-setting-icon">
                                    <i class="tim-icons tim-icons-text">?</i>
                                </span>
                            </el-tooltip>
                        </template>
                    </reporting-tv-figures>

                    <key-learnings class="p-0 mt-3" :objectOfOrigin="objectOfOrigin"
                            :objectOfOriginType="'Key Learning' + cardsDefinitions.tvFigures.title" :editable="internalUser" />
                </div>
            </div>

            <div v-if="showReportingBarChart" class="col col-12 col-lg-6" :ref="cardsDefinitions.trpOverview.ref">
                <div class="reporting-card card">
                    <reporting-bar-chart :marathonCountryID="marathonCountryID" :clientID="clientID" :campaignID="campaignID" v-if="isReady"  :ref="cardsDefinitions.trpOverview.chart"
                        :campaignTitle="campaignName" :editable="internalUser" v-model="manualData" v-on:save="saveManualData" 
                        :title="cardsDefinitions.trpOverview.title" :help="cardsDefinitions.trpOverview.info" >
                          <template #action-buttons>  
                            <el-tooltip content="Download as PDF" placement="top">
                                <span v-on:click="downloadAsPDF(cardsDefinitions.trpOverview)" class="icon-download sub-setting-icon">
                                    <i class="tim-icons icon-cloud-download-93"></i>
                                </span>
                            </el-tooltip>

                            <el-tooltip :content="cardsDefinitions.trpOverview.info" v-if="cardsDefinitions.trpOverview.info" placement="top">
                                <span class="icon-help sub-setting-icon">
                                    <i class="tim-icons tim-icons-text">?</i>
                                </span>
                            </el-tooltip>
                        </template>
                    </reporting-bar-chart>

                    <key-learnings class="p-0 mt-3" :objectOfOrigin="objectOfOrigin"
                            :objectOfOriginType="'Key Learning' + cardsDefinitions.trpOverview.title" :editable="internalUser"/>
                </div>
            </div>
        
      
            <div v-if="showReportingOtherMedia" class="col col-12 col-lg-6" :ref="cardsDefinitions.otherMedia.ref">
                <div class="reporting-card card">
                    <reporting-other-media v-if="isReady" :campaignTitle="campaignName"  v-model="manualData" v-on:save="saveManualData"  :editable="internalUser"
                    :type="'other_media'" :title="cardsDefinitions.otherMedia.title" :table-columns="cardsDefinitions.otherMedia.columns" :help="cardsDefinitions.otherMedia.info">
                          <template #action-buttons>  
                            <el-tooltip content="Download as PDF" placement="top">
                                <span v-on:click="downloadAsPDF(cardsDefinitions.otherMedia)" class="icon-download sub-setting-icon">
                                    <i class="tim-icons icon-cloud-download-93"></i>
                                </span>
                            </el-tooltip>

                            <el-tooltip :content="cardsDefinitions.otherMedia.info" v-if="cardsDefinitions.otherMedia.info" placement="top">
                                <span class="icon-help sub-setting-icon">
                                    <i class="tim-icons tim-icons-text">?</i>
                                </span>
                            </el-tooltip>
                        </template>
                    </reporting-other-media>

                    <key-learnings class="p-0 mt-3" :objectOfOrigin="objectOfOrigin"
                            :objectOfOriginType="'Key Learning' + cardsDefinitions.otherMedia.title" :editable="internalUser" />
                </div>
            </div>

            <div class="col col-12 col-lg-6">
                <div class="reporting-card card">
                    <div v-if="isReady" class="reporting-file-upload p-0">
                        <div>
                            <h2>File Upload</h2>
                        </div>
                        <connect-file-upload :campaign="{'campaignID':campaignID, 'clientID':clientID, 'marathonCountryID':marathonCountryID}" />
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div v-if="showReportingTVFigures" class="col col-12" :ref="cardsDefinitions.comments.ref">
                
                    <Comments objectOfOriginType="Reporting"/>
            </div>
        </div> -->

        <Comments objectOfOriginType="Reporting" />



    </div>
</template>

<script>

import Comments from '@/components/Comments/Comments.vue'
import ReportingTvFigures from '@/components/Reporting/ReportingTvFigures.vue'
import ReportingOtherMedia from '@/components/Reporting/ReportingOtherMedia.vue'
import ReportingBarChart from '@/components/Reporting/ReportingBarChart.vue'
import ConnectFileUpload from '@/components/ConnectFileUpload/ConnectFileUpload.vue'
import StatsCard from 'src/components/Cards/StatsCard';
import UniversalList from '@/components/UniversalList/UniversalList.vue'
import CTACard from '@/components/Cards/CTACard.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import KeyLearnings from '@/components/KeyLearnings/KeyLearnings.vue'
import { generatePDF } from '@/helpers/pdf.helpers.js'

export default {
    data() {
        return {
            isReady: false,
            queryData: {},
            clientID: null,
            campaignID: null,
            marathonCountryID: null,
        };
    },
    inject: ['currencyFormatter'],
    methods: {
        saveManualData(){
            const payload = JSON.parse(JSON.stringify(this.manualData));

            const path = this.$constants.apiPaths.reporting(this.marathonCountryID, this.clientID, this.campaignID);

            this.axios.post(path, payload)
                .then((res) => {
                    try {
                        this.queryData = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
                    } catch (e) {
                        this.queryData = res.data;
                    }

                    this.isEditing = false;
                    
                    this.getData();

                }).catch((error) => {
                    this.$swal.fire({
                        title: 'Could not save the data',
                        icon: 'error',
                        text: 'Could not save the data for ' + error,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    });
                });
        },
        downloadAsPDF(cardDefinition) {
            this.$swal.fire({
                title: 'Generating PDF',
                text: 'Creating export file',
                allowOutsideClick: false,
                showConfirmButton: false,
                backdrop: false
            });

            if(cardDefinition){
                generatePDF([cardDefinition], this).then(
                    (result) => { 
                        this.$swal.close();
                    },
                    (error) => { 
                        console.error(error);
                        this.$swal.close();
                    }
                );
            }
            else{
                generatePDF(Object.values(this.cardsDefinitions), this).then(
                    (result) => { 
                        this.$swal.close();
                    },
                    (error) => { 
                        console.error(error);
                        this.$swal.close();
                    }
                );
            }
        },
        async addImageToPDF(pdfDoc, cardDefinition, docTitle){
            const elementRef = cardDefinition.ref;
            const imageTitle = cardDefinition.title;


            const chart = this.$refs[elementRef];
            //chart.setChartLegendColor("#000");  //TODO change labels (and possibly others) colors before render
            let el = chart.$refs["nice"];
            let doc = pdfDoc;

            const pageHeight = doc.getPageHeight();
            const pageWidth = doc.getPageWidth();
            const elHeight = el.offsetHeight;
            const elWidth = el.offsetWidth;
            let renderWidth = elWidth;
            let renderHeight = elHeight;

            if(elWidth > pageWidth || elHeight > pageHeight){
                if(elWidth > elHeight){
                    renderWidth = pageWidth - 30
                    renderHeight = Math.floor((elHeight / elWidth) * renderWidth)
                }
                else{
                    renderHeight = pageHeight - 30
                    renderWidth = Math.floor((elWidth / elHeight) * renderHeight)
                }
            }

            let output = await html2canvas(el, {
                scrollY: -window.scrollY
            });
            doc.setFontSize(20);
            doc.setTextColor(40);
            doc.text(imageTitle + docTitle, 15, 22);
            doc.addImage(output.toDataURL('image/jpeg'), 'JPEG', 15, pageHeight - renderHeight, renderWidth, renderHeight, "", "FAST");
            //chart.setChartLegendColor("#FFF");  //TODO change labels (and possibly others) colors back after render (reset)

            let pageFill = Math.floor(pageHeight / renderHeight);

            let keyLearnings = this.$refs[cardDefinition.keyLearningsRef].$el;
            doc.addPage();
            doc.text(imageTitle + " Key Learnings | " + docTitle, 15, 22);

            let learnings = await html2canvas(keyLearnings, {
                scrollY: -window.scrollY
            }).toDataURL('image/jpeg');
            
            doc.addImage(learnings, 'JPEG', 15, 40, 20, 200, "", "FAST");
            
            
            //await this.addHtml(doc, keyLearnings, 15, 40);

            // doc.html(keyLearnings, {
            //     callback: function (doc) {
            //         //doc.save();
            //         console.log("exported")
            //     },
            // x: 15,
            // y: 40
            // });
            // if (pageFill > 1) {
                
               
            //     // for (var i = 0; i < pageCount - 1; i++) {
            //     //     doc.addPage();
            //     //     doc.addImage(output, 'JPEG', 5, -(elHeight - ((elHeight - (doc.getPageHeight() * (i + 1)) + 20))), 200, elHeight)
            //     // }
            // }

        },
        addHtml(doc, html, x, y){
            return new Promise(async (resolve, reject) =>{
                doc.html(html, {
                    callback: function (doc) {
                        resolve()
                    },
                    x: x,
                    y: y
                });
            });
        },
        getCampaign(campaignID, marathonCountryID) {            
            const path = this.$constants.apiPaths.planning.campaign(this.marathonCountryID, this.clientID, this.campaignID);
            this.axios(path)
                .then((res) => {
                    if(res.data && res.data.campaign){
                        this.updateCampaignName(res.data.campaign);
                    }
                })
                .catch((error) => {
                    this.handleRequestError(error);
                });
        },
        goBack(){
            this.$router.replace({ path: '/reporting' })
            this.clearCampaignName();
        },
        getData(){
            
            this.isReady = false;
            const path = this.$constants.apiPaths.reporting(this.marathonCountryID, this.clientID, this.campaignID);

            this.axios.get(path)
                .then((res) => {
                    try{
                        this.queryData = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
                    } catch(e) {
                        this.queryData = res.data;
                    }

                    if (!this.queryData){
                        this.$swal.fire({
                            title: 'Reporting data unavailable',
                            icon: 'info',
                            text: 'There were no reporting data available for ' + this.campaignID,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                        }).then(() => {
                            this.goBack();
                        });
                    } else {
                        this.isReady = true;
                    }
                    
                    this.isReady = true;

                })
                .catch((error) => {
                    var insightsErrorText = "";

                    if (this.campaignID != null){
                        insightsErrorText = "campaign '" + this.campaignID + "'"
                    } else if (this.clientID != null) {
                        insightsErrorText = "client '" + this.clientID + "'"
                    } else if (this.countryID != null) {
                        insightsErrorText = "country '" + this.countryID + "'"
                    }

                    this.$swal.fire({
                        title: 'Data unavailable',
                        icon: 'info',
                        text: 'There were no data available for ' + insightsErrorText,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    });

                    this.clientSelectorValue = '';
                    this.campaignSelectorValue = '';
                    this.client = null;
                    this.clientID = null;
                    //this.campaign = null;
                    this.campaignID = null;

                });
        },
        handleRoutes() {
            if (this.$route.params.campaignID) {
                this.campaignID = this.$route.params.campaignID;
            }

            if (this.$route.params.marathonCountryID) {
                this.marathonCountryID = this.$route.params.marathonCountryID;
            }

            if (this.$route.params.clientID) {
                this.clientID = this.$route.params.clientID;
            }
        },
        handleRequestError(error) {
            console.error(error);
        },
        formatter: function (round, num) {
            return parseFloat(num.toFixed(round)).toLocaleString('da');
        },
        ...mapActions([
            'updateCampaignName',
            'clearCampaignName',
        ]),
    },
    computed:{
        showReportingTVFigures(){
            if (this.internalUser && this.campaignID && this.clientID && this.marathonCountryID) {
                return true;
            } else if (this.manualData && this.manualData["tvKeyFigures"] && this.manualData["tvKeyFigures"][0] && this.manualData["tvKeyFigures"][0].value !== "") {
                return true;
            }
            return false;
        },
        showReportingOtherMedia(){
            if (this.internalUser && this.campaignID && this.clientID && this.marathonCountryID) {
                return true;
            } else if (this.manualData && this.manualData["otherMedia"] && this.manualData["otherMedia"][0] && this.manualData["otherMedia"][0].value !== "") {
                return true;
            }
            return false;
        },
        showReportingBarChart(){
            if (this.internalUser && this.campaignID && this.clientID && this.marathonCountryID) {
                return true;
            } else if (this.manualData && this.manualData["trpOverview"] && this.manualData["trpOverview"][0] && this.manualData["trpOverview"][0].value !== "") {
                return true;
            }
            return false;
        },
        statsCards(){
            return [
                { title: this.campaignName, subtitle: 'Campaign', type: 'primary', icon: 'user',},
                { title: this.objective, subtitle: 'Objectives', type: 'primary', icon: 'target',},
                { title: this.kpiMetrics, subtitle: 'KPI', type: 'info', icon: 'kpi',},
                { title: (this.totalSpend !== null && this.totalSpend !== undefined && typeof(this.totalSpend) == 'number') ? this.formatter(0, this.totalSpend) + ' ' + this.currency : '--', subtitle: 'Spend', type: 'primary', icon: 'money',},
                { title: (this.totalImpressions !== null && this.totalImpressions !== undefined && typeof(this.totalImpressions) == 'number') ? this.formatter(0, this.totalImpressions) : '--', subtitle: 'Impressions', type: 'info', icon: 'eye',},
                { title: (this.totalClicks !== null && this.totalClicks !== undefined && typeof(this.totalClicks) == 'number') ? this.formatter(0, this.totalClicks) : '--', subtitle: 'Clicks', type: 'primary', icon: 'cursor',},
                { title: ' ', subtitle: 'CPC', type: 'info', icon: 'cursormoney',},
            ]
        },
        currency(){
            return this.$route.params.marathonCountryID.split("M")[1] + 'K';
        },
        manualData: {
            get: function () {
                let manualData = this.queryData.manualData;
                //let manualData = JSON.parse(JSON.stringify(this.queryData.manualData));

                if(manualData) {  
            
                    if (manualData["otherMedia"] == undefined){
                        manualData["otherMedia"] = []
                    }
                    if (manualData["trpOverview"] == undefined){
                        manualData["trpOverview"] = []
                    }
                    if (manualData["tvKeyFigures"] == undefined){
                        manualData["tvKeyFigures"] = []
                    }
                }
                return manualData;
            },
            set: function (newVal) {
                this.queryData["manualData"] = newVal;
            }
        },
        objectOfOrigin(){
            return {
                marathonCountryID: this.marathonCountryID,
                clientID: this.clientID,
                campaignID: this.campaignID
            }
        },
        medias(){
            let medias = null;
            if(this.queryData && this.queryData.medias){
                medias = this.queryData.medias;
                try{
                    for (var i = 0; i < medias.length; i++) {
                        if (medias[i].spend && typeof(medias[i].spend) == 'number') {
                            //medias[i].spend = this.formatter(0, medias[i].spend) + ' ' + medias[i].country + 'K';
                            medias[i].spend = this.currencyFormatter(medias[i].spend, 2, this.currency, true);
                        }

                        if (medias[i].impressions && typeof(medias[i].impressions) == 'number') {
                            medias[i].impressions = this.formatter(0, medias[i].impressions);
                        }

                        if (medias[i].clicks && typeof(medias[i].clicks) == 'number') {
                            medias[i].clicks = this.formatter(0, medias[i].clicks);
                        }

                        if (medias[i].cpm && typeof(medias[i].cpm) == 'number') {
                            //medias[i].CPM = medias[i].CPM.toLocaleString('da') + ' ' + medias[i].country + 'K';
                            medias[i].cpm = this.currencyFormatter(medias[i].cpm, 2, this.currency, true);
                        }

                        if (medias[i].cpv && typeof(medias[i].cpv) == 'number') {
                            //medias[i].CPV = medias[i].CPV.toLocaleString('da') + ' ' + medias[i].country + 'K';
                            medias[i].cpv = this.currencyFormatter(medias[i].cpv, 2, this.currency, true);
                        }

                        if (medias[i].cpc && typeof(medias[i].cpc) == 'number') {
                            //medias[i].cpc = medias[i].cpc.toLocaleString('da') + ' ' + medias[i].country + 'K';
                            medias[i].cpc = this.currencyFormatter(medias[i].cpc, 2, this.currency, true);
                        }

                        if (medias[i].ctr && typeof(medias[i].ctr) == 'number') {
                            //medias[i].cpc = medias[i].cpc.toLocaleString('da') + ' ' + medias[i].country + 'K';
                            medias[i].ctr = this.currencyFormatter(medias[i].ctr, 2, this.currency, true);
                        }

                        if (medias[i].vtr && typeof(medias[i].vtr) == 'number') {
                            //medias[i].VTR = medias[i].VTR.toLocaleString('da') + '%';
                            medias[i].vtr = this.formatter(2, medias[i].vtr) + '%';
                        }
                    }
                } catch(e) {
                    console.error(e);
                }

            }
            return medias;
        },
        videos(){
            let videos = null;
            if(this.queryData && this.queryData.videos){
                videos = this.queryData.videos;
                try {
                    for (let i = 0; i < videos.length; i++) {
                        if (videos[i].spend && typeof(videos[i].spend) == 'number') {
                            //videos[i].spend = this.formatter(0, videos[i].spend) + ' ' + videos[i].country + 'K';
                            videos[i].spend = this.currencyFormatter(videos[i].spend, 2, this.currency, true);
                        }

                        if (videos[i].impressions && typeof(videos[i].impressions) == 'number') {
                            videos[i].impressions = this.formatter(0, videos[i].impressions);
                        }

                        if (videos[i].clicks && typeof(videos[i].clicks) == 'number') {
                            videos[i].clicks = this.formatter(0, videos[i].clicks);
                        }

                        if (videos[i].ctr && typeof(videos[i].ctr) == 'number') {
                            //videos[i].CTR = videos[i].CTR.toLocaleString('da') + '%';
                            videos[i].ctr = this.formatter(2, videos[i].ctr) + '%';
                        }

                        if (videos[i].cpc && typeof(videos[i].cpc) == 'number') {
                            //videos[i].cpc = videos[i].cpc.toLocaleString('da') + ' ' + videos[i].country + 'K';
                            videos[i].cpc = this.currencyFormatter(videos[i].cpc, 2, this.currency, true);
                        }

                        if (videos[i].cpm && typeof(videos[i].cpm) == 'number') {
                            videos[i].cpm = this.currencyFormatter(videos[i].cpm, 2, this.currency, true);
                        }

                        if (videos[i].cpv && typeof(videos[i].cpv) == 'number') {
                            //videos[i].CPV = videos[i].CPV.toLocaleString('da');
                            //videos[i].cpv = this.formatter(2, videos[i].cpv);
                            videos[i].cpv = this.currencyFormatter(videos[i].cpv, 2, this.currency, true);
                        }

                        if (videos[i].cpr && typeof(videos[i].cpr) == 'number') {
                            //videos[i].CPR = videos[i].CPR.toLocaleString('da');
                            videos[i].cpr = this.formatter(2, videos[i].cpr);
                        }
                    }
                } catch(e) {
                    console.error(e);
                }
            }
            return videos;
        },
        display(){
            let display = null;
            if(this.queryData && this.queryData.display){
                display = this.queryData.display;
                try{
                    for (let i = 0; i < display.length; i++) {
                        if (display[i].spend && typeof(display[i].spend) == 'number') {
                            //display[i].spend = this.formatter(0, display[i].spend) + ' ' + display[i].country + 'K';
                            display[i].spend = this.currencyFormatter(display[i].spend, 2, this.currency, true);
                        }

                        if (display[i].impressions && typeof(display[i].impressions) == 'number') {
                            display[i].impressions = this.formatter(0, display[i].impressions);
                        }

                        if (display[i].clicks && typeof(display[i].clicks) == 'number') {
                            display[i].clicks = this.formatter(0, display[i].clicks);
                        }

                        if (display[i].cpc && typeof(display[i].cpc) == 'number') {
                            //display[i].cpc = display[i].cpc.toLocaleString('da') + ' ' + display[i].country + 'K';
                            display[i].cpc = this.currencyFormatter(display[i].cpc, 2, this.currency, true);
                        }

                        if (display[i].cpm && typeof(display[i].cpm) == 'number') {
                            //display[i].cpm = display[i].cpm.toLocaleString('da');
                            display[i].cpm = this.currencyFormatter(display[i].cpm, 2, this.currency, true);
                        }

                        if (display[i].ctr && typeof(display[i].ctr) == 'number') {
                            //display[i].ctr = display[i].ctr.toLocaleString('da') + '%';
                            display[i].ctr = this.formatter(2, display[i].ctr) + '%';
                        }
                    }
                } catch(e) {
                    console.error(e);
                }
            }
            return display;
        },
        objective(){
            if(this.queryData && this.queryData.header && this.queryData.header.objective){
                return this.queryData.header.objective;
            }
            return "--"
        },
        kpiMetrics(){
            if(this.queryData && this.queryData.header && this.queryData.header.kpiMetrics){
                if(Array.isArray(this.queryData.header.kpiMetrics)){
                    return this.queryData.header.kpiMetrics.join();
                }
                return this.queryData.header.kpiMetrics;
            }
            return "--"
        },
        totalSpend(){
            if(this.queryData && this.queryData.header && this.queryData.header.totalSpend){
                return this.queryData.header.totalSpend;
            }
            return "--"
        },
        totalImpressions(){
            if(this.queryData && this.queryData.header && this.queryData.header.totalImpressions){
                return this.queryData.header.totalImpressions;
            }
            return "--"
        },
        totalClicks(){
            if(this.queryData && this.queryData.header && this.queryData.header.totalClicks){
                return this.queryData.header.totalClicks;
            }
            return "--"
        },
        totalCPC(){
            if(this.queryData && this.queryData.header && this.queryData.header.totalCPC){
                return this.queryData.header.totalCPC;
            }
            return "--"
        },
        mapping(){
            if(this.queryData && this.queryData.mapping){
                return this.queryData.mapping;
            }
            return []
        },
        ...mapGetters([
            'internalUser',
        ]),
        ...mapState([
            'campaignName',
        ]),
        // canEdit() {
        //     return this.internalUser;
        // },
        mappingColumns(){
            return [{
                prop: 'order',
                label: 'Order'
            },{
                prop: 'media',
                label: 'Media'
            },{
                prop: 'mediaChannel',
                label: 'Media Channel'
            },{
                prop: 'mappingType',
                label: 'Mapping type'
            },{
                prop: 'mappedTo',
                label: 'Mapped to'
            },
            ]
        },
        digitalColumns(){
            return [
                { minWidth: 250, prop: 'media', label: 'Media'},
                { minWidth: 150, prop: 'mediaType', label: 'Format'},
                { minWidth: 120, prop: 'spend', label: 'Media Spend'},
                { minWidth: 120, prop: 'impressions', label: 'Impressions'},
                { minWidth: 105, prop: 'cpm', label: 'CPM'},
                { minWidth: 105, prop: 'vtr', label: 'VTR'},
                { minWidth: 105, prop: 'cpv', label: 'CPV'},
                { minWidth: 95, prop: 'clicks', label: 'Clicks'},
                { minWidth: 105, prop: 'cpc', label: 'cpc'},
                { minWidth: 105, prop: 'ctr', label: 'ctr'},
                { minWidth: 155, prop: '', label: 'Unique Impressions'},
                { minWidth: 140, prop: '', label: 'Avg. Frequency'},
            ]
        },
        videoColumns(){
            return [
                { minWidth: 190, prop: 'lineItem', label: 'Video'},
                { minWidth: 125, prop: 'impressions', label: 'Impressions'},
                { minWidth: 105, prop: 'clicks', label: 'Clicks'},
                { minWidth: 105, prop: 'spend', label: 'Spend'},
                { minWidth: 105, prop: 'cpc', label: 'CPC'},
                { minWidth: 80, prop: 'ctr', label: 'CTR'},
                { minWidth: 155, prop: 'cpm', label: 'CPM'},
            ]
        },
        displayColumns(){
            return [
                { minWidth: 310, prop: 'lineItem', label: 'Display'},
                { minWidth: 120, prop: 'impressions', label: 'Impressions'},
                { minWidth: 95, prop: 'clicks', label: 'Clicks'},
                { minWidth: 105, prop: 'spend', label: 'Spend'},
                { minWidth: 85, prop: 'cpm', label: 'CPM'},
                { minWidth: 95, prop: 'cpc', label: 'CPC'},
                { minWidth: 80, prop: 'ctr', label: 'CTR'},
            ]
        },
        tvFiguresColumns(){
            return [
                { minWidth: 80, prop: 'key', label: 'Key'},
                { minWidth: 200, prop: 'value', label: 'Value'},
            ]
        },
        otherMediaColumns(){
            return [
                { minWidth: 140, prop: 'media', label: 'Media'},
                { minWidth: 100, prop: 'format', label: 'Format'},
                { minWidth: 120, prop: 'spend', label: 'Media Spend'},
                { minWidth: 120, prop: 'units', label: 'Units'},
                { minWidth: 120, prop: 'reach', label: 'Reach'},
                { minWidth: 120, prop: 'frequency', label: 'Frequency'},
            ]
        },
        cardsDefinitions(){
            return {
                overview: {
                    ref: "overview",
                    title: "Overview",
                    info: null
                },
                mapping: {
                    ref: "mapping",
                    //type: "table",
                    title: "Order mapping",
                    //columns: this.mappingColumns,
                    //values: this.mapping,
                    info: null
                },
                digital: {
                    ref: "digital",
                    //type: "table",
                    title: "Overall Digital Performance",
                    columns: this.digitalColumns,
                    values: this.medias,
                    info: "This table shows the performance for all of the Publicis Managed digital ads for this campaign. Data is extracted from Display Campaign Manager API.",
                },
                videos: {
                    ref: "videos",
                    //type: "table",
                    title: "Programmatic Video Performance",
                    columns: this.videoColumns,
                    values: this.videos,
                    info: "This table shows the performance for all of the programmatic video ads for this campaign. Data is extracted from Display & Video 360 API.",
                },
                display: {
                    ref: "display",
                    //type: "table",
                    title: "Programmatic Display Performance",
                    columns: this.displayColumns,
                    values: this.display,
                    info: "This table shows the performance for all of the programmatic display ads for this campaign. Data is extracted from Display & Video 360 API.",
                },
                tvFigures: {
                    ref: "tvFigures",
                    //type: "table",
                    title: "TV Key Figures",
                    columns: this.tvFiguresColumns,
                    values: this.manualData ? this.manualData.tvKeyFigures : null,
                    info: "TV key figures manually input by specialist. The specialist extracts their data from AdvantEdge.",
                },
                trpOverview: {
                    ref: "trpOverview",
                    //keyLearningsRef: "trpOverviewLearnings",
                    chart: "trpOverviewBarChart",
                    type: "barChart",
                    title: "TRP Overview",
                    //values: this.manualData ? this.manualData.trpOverview : null,
                    info: "TRP Performance over time, manually input by specialist. The specialist extracts their data from AdvantEdge.",
                },
                otherMedia: {
                    ref: "otherMedia",
                    //type: "table",
                    title: "Other media",
                    columns: this.otherMediaColumns,
                    values: this.manualData ? this.manualData.otherMedia : null,
                    info: "Custom data table for insertion medias and metrics from systems that are not automatable.",
                },
                comments: {
                    ref: "comments",
                    title: "Comments",
                },

            }
        },
    },
    mounted() {
        this.handleRoutes();
        this.getData();
        if(!this.campaignName){
            this.getCampaign(this.campaignID, this.marathonCountryID);
        }
    },
    components: {
        StatsCard,
        ReportingTvFigures,
        ReportingOtherMedia,
        Comments,
        ReportingBarChart,
        ConnectFileUpload,
        [CTACard.name]: CTACard,
        UniversalList,
        KeyLearnings,
    },

};
</script>


<style lang="scss" scoped>

.download-icon{
    cursor: pointer;    
    position: relative;
    bottom: 5px;
}
.manual-reporting-holder {
  margin-bottom: -14px;
}
.reporting-section-holder {
  margin-top: -12px;
}

.reporting-card-holder {
  padding-right: 0;
}

.reporting-card-holder:last-child {
  padding-right: 14px;
}

.min-w-100{
    min-width: 100%;
}

.reporting-file-upload{
    //height: 100%;
    //margin-bottom: 15px;
    padding: 20px;
}

// /deep/ .sub-setting{
//     display: flex;

//     .icon-edit{
//         order: 1;
//     }

//     .icon-download{
//         order: 2;
//     }

//     .icon-help{
//         order: 3;
//     }
// }
</style>