import ColorHash from 'color-hash';
import Constants from '@/constants';
class UserHelpers
{
    static colorFromId(userID){
        let colorHash = new ColorHash();
        if(!userID){
            return;
        }
        else{
            return colorHash.hex(userID);
        }
    }
    
    static initialsFromUsername(userName){
        var split = userName.split(" ")
        var short = '';
        for(var i = 0; i < split.length; i++){
            if(split[i].length > 0){
                short += split[i].substring(0,1);
            }
        }
        var initials = short.length < 3 ? short : [short[0], short[short.length-1]].join("");
        return initials.toUpperCase();
    }


    //HELPER TO ENRICH USER INFO ON BRIEF OR CAMPAIGN
    static enrichUserInfo(element){
        element['lastEdited'] = element['timestampChanged'];
        element['submitted'] = element['timestampSubmitted'];
        element['created'] = element['timestampCreated'];
        element['countryID'] = element['marathonCountryID'];
        if (element['timestampChanged'] && element['timestampChanged'].length >= 10) {
            element['lastEdited'] = element['timestampChanged'].substring(0, 10);
        }
        if (element['timestampSubmitted'] && element['timestampSubmitted'].length >= 10) {
            element['submitted'] = element['timestampSubmitted'].substring(0, 10);
        }
        if (element['timestampCreated'] && element['timestampCreated'].length >= 10) {
            element['created'] = element['timestampCreated'].substring(0, 10);
        }
        if (element['marathonCountryID'] && element['marathonCountryID'].length > 3) {
            element['countryID'] = element['marathonCountryID'].substring(2, 4);
        }
        if(element['updateUser'] && element['updateUser'].name){
            element['updateUser'].initials =  this.initialsFromUsername(element['updateUser'].name)
            var color = this.colorFromId(element['updateUser'].userID);
            element['updateUser'].color = color;

            element['editedBy'] = {}
            element['editedBy'].name = element['updateUser'].name;
            element['editedBy'].initials =  this.initialsFromUsername(element['updateUser'].name)

            if(element['briefStatus'] === Constants.campaignStates.BRIEFED.value){
                element['submittedBy'] = {}
                element['submittedBy'].name = element['updateUser'].name;
                element['submittedBy'].initials =  this.initialsFromUsername(element['updateUser'].name)
            }
            if(element['updateUser'].userID){
                //var color = this.colorFromId(element['updateUser'].userID);
                if(element['editedBy']){
                    element['editedBy'].color = color;
                    element['editedBy'].color = color;
                }
                if(element['submittedBy']){
                    element['submittedBy'].color = color;
                }
            }
        }
        
        return element;
    }
}



export default UserHelpers