<template>
    <div :class="[asCard? 'card graph' : 'graph']">
        <div class="d-flex justify-content-between">
            <h2 v-if="title" class="graph-title">{{title}}</h2>
            <span class="sub-setting">

                <el-tooltip :content="help" placement="top" v-if="help">
                    <span class="sub-setting-icon">
                        <i class="tim-icons tim-icons-text">?</i>
                    </span>
                </el-tooltip>

            </span>
        </div>
    <bar-chart :chart-data="chartData" :extra-options="options"/>
    </div>
</template>

<script>
//import RoundedBarChart from '@/components/Charts/RoundedBarChart.js'  //TODO - implment RoundedBarChart
//import RoundedRectangle from '@/components/Charts/RoundedRectangle.js'
import BarChart from '@/components/Charts/BarChart.js'

export default {
    name: 'styled-bar-chart',
    props: ['title', 'chartData', 'asCard', 'help'],
    data() {
        return {
            options: {
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            autoSkip: false,
                            maxRotation: 90,
                            minRotation: 90,
                            fontSize: 10,
                            fontColor: '#FFFFFF',
                            fontFamily: 'Poppins',
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            color: "#454545",
                            zeroLineColor: 'transparent',
                            drawBorder: false,
                        },
                        stacked: false,
                        ticks: {
                            display: false,
                            beginAtZero:true
                           
                        }
                    }]
                },
                
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        padding: 30,
                        fontSize: 12,
                        fontColor: '#FFF',
                        fontStyle: 'bold',
                        fontFamily: 'Poppins',
                        usePointStyle: true,
                    }
                },
            },
        }
    },
    components: {
        [BarChart.name]: BarChart
    },
}
</script>