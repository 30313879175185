<template>
    <div :class="['card', 'universal-list', 'selectable-list', 'with-select', ...listClasses]">
        <div class="d-flex justify-content-between">
            <h2 class="universal-list-title">{{title}}</h2>
            <span class="sub-setting">

                <el-tooltip :content="help" placement="top" v-if="help">
                    <span class="sub-setting-icon">
                        <i class="tim-icons tim-icons-text">?</i>
                    </span>
                </el-tooltip>

            </span>
        </div>
         <div class="card-subheader">
            <div class="header-item-wrapper settings-wrapper" v-if="selectOptions && selectOptions.length > 0">
                <span class="label">{{ selectLabel }}</span> 
                <el-select v-model="selectedValue" placeholder="Metrics" v-on:change="updateValue">
                    <el-option v-for="(item, index) in selectOptions" :key="index" :value="item.value" :label="item.label">
                            {{ item.label }}
                    </el-option>
                </el-select>
            </div>
        </div>
        <el-table class="selectable-list-scrolling" :data="tableData" @row-click="handleRowClick">
            <el-table-column :min-width="66" :prop="titleProp"
                :label="title" className="name" :sortable="true">
            </el-table-column>
            <el-table-column :min-width="33" :prop="tableColumn.prop" align="center"
                :label="tableColumn.label" :className="'value ' + tableColumn.prop" :sortable="true">
                <template slot-scope="scope">
                   {{ formatLargeNumber(scope.row[tableColumn.prop]) }}
                </template>
            </el-table-column>

        </el-table>

    </div>
</template>

<script>
import {
    BasePagination
} from 'src/components';
export default {
    inject: ['formatLargeNumber'],
    name: 'selectable-list',
    components: {
        BasePagination,
    },
    props: {
        help: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'List',
            description: 'List title'
        },
        tableData: {
            type: Array,
            default: function () {
                return []
            },
        },
        titleProp: String,
        listClasses: {
            type: Array,
            default: function () {
                return []
            },
        },
        selectOptions: Array,
        value: {
            type: String,
            default: null
        },
        selectLabel: {
            type: String,
            default: 'Metrics:'
        }
    },
    data() {
        return {
            pagination: {
                perPage: 10,
                currentPage: 1,
            },
            selectedValue: this.value,
        }
    },
    computed: {
        tableColumn(){
            return {
                minWidth: 240,
                prop: this.value,
                label: this.value.toUpperCase()
            };
        }
    },
    methods: {
        updateValue: function (value) {
            this.$emit('input', value)
        },
        handleRowClick(row, index) {
            this.$emit('row-click', row, index);
        },
    },
};
</script>

