<template>
    <multiselect :searchable="true" class="vue-multiselect group-multiselect" v-if="extendedOptions" :options="extendedOptions"
        :multiple="true" :group-select="true" :taggable="false" :value="tags"
        :placeholder="changingPlaceholder" track-by="_id" label="label" :closeOnSelect="false" select-label="" selected-label="" deselectGroupLabel="" deselectLabel=""
        :limit="0"
        :limitText="limitText"
        v-on:select="addGroup"
        v-on:remove="removeElement"
        v-on:open="opened = true"
        v-on:close="opened = false"
        :internalSearch="false"
        v-on:search-change="search"
        ref="multiselect">
        <template slot="option" slot-scope="props">
            <div class="group-wrapper">
                <div class="group-option" 
                    :class="{selected: selectedGroupsIds.includes(props.option[groupKey]), partial: partialySelectedGroupsIds.includes(props.option[groupKey])}">
                    <span class="option__wrapper">
                        <i class="selected el-input__icon el-icon-check" ></i>
                        <span>{{props.option.groupName}}</span>
                    </span>
                    <span class="multiselect__select collapse-control group-collapse-control"
                        :class="{shown: openedCollapses.includes('collapse_' + props.option[groupKey])}"
                        >
                    <i class="el-select__caret el-input__icon el-icon-arrow-up" v-on:click.stop="toggleCollapse('collapse_' + props.option[groupKey])"></i>
                    </span>
                </div>
                <div class="group-collapse collapse"  :id="'collapse_' + props.option[groupKey]" :class="{show: openedCollapses.includes('collapse_' + props.option[groupKey])}">
                    <div class="multiselect__option option__wrapper subitem " :class="{selected: selectedItems.some((element) => element[itemKey] === item[itemKey])}"
                        v-for="item in props.option[groupValues]" :key="item[itemKey]" v-on:click.stop="toggleItem(item)">
                        <i class="selected el-input__icon el-icon-check" ></i>
                        <span>{{item[itemLabel]}}</span>
                    </div>
                </div>
            </div>
           
        </template>
        <span class="multiselect__select"
            slot="caret" 
            slot-scope="{ toggle }" 
            @mousedown.prevent.stop="toggle"
            >
            <i class="el-select__caret el-input__icon el-icon-arrow-down"></i>
        </span>
        <span class="multiselect__placeholder" slot="limit" v-if="!opened">{{ selectedItems.length + " " + unit + (selectedItems.length > 1 ? "s" : "") + " selected" }}</span>
    </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
export default {
    name: 'group-multiselect',
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: 'No items selected'
        },
        searchPhrase: {
            type: String,
            default: 'Search items'
        },
        groupLabel: {
            type: String,
            default: 'group'
        },
        groupValues: {
            type: String,
            default: 'values'
        },
        itemLabel: {
            type: String,
            default: 'item'
        },
        unit: {
            type: String,
            default: 'item'
        },
        groupKey: {
            type: String,
            default: '_id'
        },
        itemKey: {
            type: String,
            default: '_id'
        },
    },
    data() {
        return {
            tags: [],
            selectedItems: [],
            openedCollapses: [],
            openedCollapsesBackup: [],
            selectedGroupsIds: [],
            partialySelectedGroupsIds: [],
            opened: false,
            searchQuery: "",
        };
    },
    watch:{
        selectedItems(newVal){
            const that = this;
            let selectedGroups = [];
            let tags = [];
            let partialySelectedGroupsIds = [];
            this.options.forEach((group) => { 
                const groupLength = group[that.groupValues].length;
                
                let selectedItemsForGroupLength = 0;
                group[that.groupValues].forEach((item) => {
                    if(newVal.some((selectedItem) => item[that.itemKey] === selectedItem[that.itemKey])){
                        selectedItemsForGroupLength++; 
                        if(!partialySelectedGroupsIds.includes(group[that.groupKey])){
                            partialySelectedGroupsIds.push(group[that.groupKey]);
                        }
                    }
                });

                if(selectedItemsForGroupLength === groupLength){
                    selectedGroups.push(group[that.groupKey]);
                    tags.push(group);
                }
                else{
                    newVal.forEach((item) => {
                        if(!(tags.some((tag) => item[that.itemKey] === tag[that.itemKey]))){
                            tags = tags.concat(item);
                        }
                    });
                }
            });
            this.partialySelectedGroupsIds = partialySelectedGroupsIds;
            this.tags = tags;
            this.selectedGroupsIds = selectedGroups;
            
            let output = [];
            this.selectedItems.forEach((item) => {
                if(!output.includes(item[that.itemKey])){
                    output.push(item[this.itemKey])
                }
            });
            this.$emit('input', output);
        },
        searchQuery(newVal, oldVal){
            if(newVal && newVal !== "" && oldVal === ""){
                const that = this;
                this.openedCollapsesBackup = JSON.parse(JSON.stringify(this.openedCollapses));
                this.extendedOptions.forEach((optionGroup) => {
                    if(!that.openedCollapses.includes('collapse_' + optionGroup[that.groupKey])){
                        that.openedCollapses.push('collapse_' + optionGroup[that.groupKey]);
                    }
                })
            }
            else if(newVal === "" && oldVal !== ""){
                this.openedCollapses = JSON.parse(JSON.stringify(this.openedCollapsesBackup));
            }
        }
    },
    computed: {
        changingPlaceholder(){
            if(this.opened){
                return this.searchPhrase;
            }
            if(this.selectedItems.length > 0){
                return this.selectedItems.length + " " + this.unit + (this.selectedItems.length > 1 ? "s" : "") + " selected";
            }
            return this.placeholder;
        },
        extendedOptions(){
            const that = this;
            let options = JSON.parse(JSON.stringify(this.options));
            let filteredOptions = [];
            if(options){
                options.forEach(element => {
                    let filteredItems = [];
                    element["type"] = "group";
                    element["label"] = element[that.groupLabel];
                    element[that.groupValues].forEach(item => {
                        item["type"] = "item";
                        item["label"] = item[that.itemLabel];
                        if(item["label"].toLowerCase().includes(that.searchQuery.toLowerCase())){
                            filteredItems.push(item);
                        }
                    });
                    if(filteredItems.length > 0){
                        let changedElement = element;
                        changedElement[that.groupValues] = filteredItems;
                        filteredOptions.push(changedElement);
                    }
                });
                return filteredOptions;
                //return options;
            }
            else{
                return this.options;
            }
           
        },
    },
    components: {
        Multiselect
    },
    methods: {
        search(searchQuery, id){
            this.searchQuery = searchQuery;
        },
        addGroup(element){
            const that = this;
            element[this.groupValues].forEach((item) => {
                if(!that.selectedItems.includes(item)){
                    that.selectedItems.push(item);
                }
            })
        },
        removeElement(element){
            const that = this;
            if(element && element.type && element.type === 'group'){
                element[this.groupValues].forEach((item) => {
                    const idx = this.selectedItems.findIndex(function(selectedItem) { return selectedItem[that.itemKey] === item[that.itemKey]});
                    if(idx >= 0){
                        this.selectedItems.splice(idx,1);
                    }
                });
            }
            
            if(element && element.type && element.type === 'item'){
                const idx = this.selectedItems.findIndex(function(selectedItem) { return selectedItem[that.itemKey] === element[that.itemKey]});
                if(idx >= 0){
                    this.removeItemAt(idx, element);
                }
            }
        },
        toggleCollapse(target){
            if(this.openedCollapses.includes(target)){
                let index = this.openedCollapses.indexOf(target);
                this.openedCollapses.splice(index,1);
            }
            else{
                this.openedCollapses.push(target);
            }
        },
        toggleItem(selectedItem){
            var that = this;

            const index = this.selectedItems.findIndex(function(item) { return item[that.itemKey] === selectedItem[that.itemKey]});

            if(index >= 0){
                this.removeItemAt(index, selectedItem);
            }
            else{
                this.addItem(selectedItem);
            }
        },
        addItem(element){
            this.selectedItems.push(element);
            this.searchQuery = "";
            this.$refs.multiselect.search = "";
            this.$refs.multiselect.$refs.search.value = "";
        },
        removeItemAt(index, element){
            if(index < this.selectedItems.length && this.selectedItems[index]){
                this.selectedItems.splice(index,1);
            }
        },
        limitText(count){
            return "";
        }
    }
};
</script>
<style></style>
