<template>
    <div>
        <div class="campaign-detail-wrapper">
            <div class="campaign-detail" style="max-height: none">
                <div class="tabs-wrapper / dragscroll">
                    <div class="campaign-versions-tabs" v-if="planVersions.length > 0">
                        <div
                            :class="['version-tab', selectedVersion.planVersionID == item.planVersionID ? 'active' : '']"
                            v-for="(item, index) in planVersions"
                            :key="index"
                            @click="selectVersionManually(item, planVersions.length - index)"
                        >
                            <div class="version-name">Media plan v{{ planVersions.length - index }}</div>
                            <div class="version-date">{{ item.timestampChanged ? item.timestampChanged : item.timestampCreated }}</div>
                        </div>
                    </div>
                </div>
                <div :class="['campaign-version', 'card', planVersions.length > 0 ? 'tabs' : '']">
                    <div class="campaign-version-header">
                        <h2 class="title mb-0">Media plan{{ selectedVersionNumber ? ' version ' + selectedVersionNumber : '' }}</h2>
                        <div class="d-flex justify-content-between">
                            <div class="d-flex mt-3">
                                <portal-target name="ordertable_filter"></portal-target>
                                <button class="btn btn-round btn-long btn-gray btn-small" @click="openFullMediaPlan" :disabled="dataState.dataTouched">
                                    Open full media plan
                                    <div class="icon-connect icon-connect-excel icon-right" />
                                </button>
                                <button class="btn btn-round btn-long btn-gray btn-small" v-b-modal.modal-download-rationale>
                                    Download rationale
                                    <div class="icon-connect icon-connect-pdf icon-right" />
                                </button>
                            </div>
                            <div class="d-flex">
                                <div class="total-ctc mr-3">
                                    <h5 class="mb-1">Total CTC</h5>
                                    <h3 class="mb-0">{{ totalCTC }}</h3>
                                </div>
                                <div>
                                    <base-button type="normal" v-on:click="openModalNotifyUsers" :disabled="!isLastPlanVersion || !dataState.dataTouched">Save</base-button>
                                </div>
                            </div>
                        </div>
                       
                    </div>

                    <PlannerOrdersTable
                        v-model="dataState"
                        ref="ordersTable"
                        class="card-table-container"
                        :sourceOrders="orders"
                        :campaignID="campaignID"
                        :planID="planID"
                        :plan="selectedVersion"
                        :isLastPlanVersion="isLastPlanVersion"
                        :marathonCountryID="marathonCountryID"
                        v-on:update-total-ctc="updateTotal"
                        v-on:orders-submitted="ordersSubmitted"
                    />
                </div>
            </div>

            <div class="campaign-comment-input">
                <Comments />
            </div>

            <b-modal id="modal-notify-users" :hide-footer="true" :centered="true" no-close-on-backdrop ref="modalNotifyUsers">
                <template #modal-header>
                    <b-button-close @click="closeModalNotifyUsers" />
                    <h2>Save</h2>
                </template>

                <span class="notify-users-content">
                  Please select users you'd like to notify.<br>
                  Leave empty to notify none.<br><br>
                
                <group-multiselect :options="groups" v-model="selectedUsers" group-values="users" group-label="groupName" item-label="name" itemKey="email" unit="user"
                        placeholder="No users selected" searchPhrase="Search for users"/>
                <br>
                <el-checkbox v-model="saveNewVersion">Save as new version</el-checkbox>

                <base-button class="notify-users-button" v-on:click="submit">{{ selectedUsers.length > 0 ? 'Save & notify users' : 'Save changes'}}</base-button>              
                </span>
            </b-modal>

            <b-modal id="modal-download-rationale" :hide-footer="true" :centered="true">
                <template #modal-header="{ close }">
                    <b-button-close @click="close()" />
                    <h2>Download rationale</h2>
                </template>

                <connect-box-file-download :type="'campaign'" :params="{'campaignID':boxValues.id, 'clientID':boxValues.client, 'marathonCountryID':boxValues.country}"/>
            </b-modal>
        </div>
    </div>
</template>

<script>
import DragScroll from "dragscroll";
import Comments from "@/components/Comments/Comments.vue";

import ConnectBoxFileDownload from '@/components/ConnectFileDownload/ConnectBoxFileDownload.vue'
import PlannerOrdersTable from "@/components/OrdersTable/PlannerOrdersTable.vue";
import { fetchRecipientsGroups } from '@/helpers/fetch.notifications.js';
import GroupMultiselect from '@/components/GroupMultiselect/GroupMultiselect.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    inject: ['currencyFormatter'],
    data() {
        return {
            campaignID: null,
            marathonCountryID: null,
            //client: null,
            clientID: null,
            planID: null,
            selectedVersion: null,
            planVersions: [],
            composedData: [],
            dataState: {
                dataTouched: false,
                validSurcharges: false
            },
            planExists: true,
            orders: [],
            selectedVersionNumber: null,
            groups: null,
            selectedUsers: [],
            totalCTC: this.selectedVersion ? (this.currencyFormatter(this.selectedVersion.ctc) + " " + this.currency) : "",
            saveNewVersion: false,
        };
    },
    beforeRouteLeave (to, from, next) {
        if(this.dataState.dataTouched){
            this.showSaveAlertDialog().then((result) => {
                if (result.isConfirmed) {
                    this.openModalNotifyUsers();
                } else if (result.isDenied) {
                    next();
                }
            });
        }
        else{
            next();
        }
    },
    methods: {
        closeModalNotifyUsers(){
            this.$refs.modalNotifyUsers.hide();
            this.selectedUsers = [];
        },
        openModalNotifyUsers(){
            if(this.dataState.dataTouched && this.dataState.validSurcharges){
                //checkOrders subcharges
                fetchRecipientsGroups(this.axios).then((groups) => {
                    if (groups && groups.length > 0) {
                        this.groups = groups;
                        this.$refs.modalNotifyUsers.show();
                    }}).catch((error) => {
                    console.error("Failed to get recipients")
                    console.error(error)
                });
            }
            else if(!this.dataState.dataTouched){
                this.$swal.fire({
                    title: 'Nothing to save',
                    text: "No changes to the plan were made",
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonColor: '#376EE2',
                    timer: 3000,
                });
            }
            else if(!this.dataState.validSurcharges){
                this.$swal.fire({
                    title: 'Please add at least one fee to the media plan in order to save.',
                    imageUrl: '/img/icons/icon-warning.svg',
                    imageWidth: 43,
                    imageHeight: 43,
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Okay, thanks',
                    confirmButtonColor: '#376EE2',
                    timer: 8000,
                });
            }
        },
        ordersSubmitted(reload, resultsArray){
            this.saveNewVersion = false;
            const resultError = resultsArray.some((item) => {
                return item.status === "rejected";
            });
            
            if(resultError){
                let errorText = "Some orders not saved<br>";
                resultsArray.forEach(element => {
                    errorText += element.reason + "<br>";
                });
                this.$swal.fire({
                    title: '<span>Error</span>',
                    html: "<span>" + errorText + "</span>",
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: false,
                    //timer: 3000,
                }).then(()=> {
                    if(reload){
                        this.getPlanVersions();
                    }
                });
            }
            else{
                this.$swal.fire({
                    imageUrl: "/img/big_green_check.png",
                    title: '<span>Saved</span>',
                    text: "Any selected users have received a notification.",
                    showConfirmButton: false,
                    showCancelButton: false,
                    timer: 3000,
                }).then(()=> {
                    if(reload){
                        this.getPlanVersions();
                    }
                });
            }
            
        },
        updateTotal(value){
            if(!isNaN(value)){
                this.totalCTC = this.currencyFormatter(value) + " " + (this.selectedVersion && this.selectedVersion.currency ? this.selectedVersion.currency : "");
            }
        },
        getRecipientsGroups() {
            fetchRecipientsGroups(this.axios).then((groups) => {
                if (groups && groups.length > 0) {
                    this.groups = groups;
                }}).catch((error) => {
                console.error("Failed to get recipients")
                console.error(error)
            });
        },
        openFullMediaPlan(){
            const path = this.$constants.apiPaths.planning.exportPlanToExcel(this.marathonCountryID, this.clientID, this.campaignID, this.planID);

            this.axios
                .get(path, {responseType: 'blob'})
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'mediaplan_'
                                                    + this.marathonCountryID + '_'
                                                    + this.campaignID + '_'
                                                    + this.planID + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.handleRequestError(error);
                });
        },
        submit(){
            if(this.selectedUsers.length > 0){
                this.submitData(this.saveNewVersion);
                this.notify(this.selectedUsers).then(() => this.closeModalNotifyUsers());
            }
            else{
                this.submitData(this.saveNewVersion);
                this.closeModalNotifyUsers();
            }
        },
        submitData() {
            this.$refs.ordersTable.submitOrders(this.saveNewVersion);
        },
        notify(users) {
            if(users && users.length > 0){
                const url = this.$constants.apiPaths.notifications.notify;
                const content = {
                    users: users,
                    notificationObject: {
                        marathonCountryID: this.$route.params.marathonCountryID,
                        clientID: this.$route.params.clientID,
                        campaignID: this.$route.params.campaignID,
                        campaign: this.campaignName,
                        plan: this.detailName,
                        objectType: "campaigns",
                        notificationType: "saved"
                    }

                }
                return this.axios.post(url, content).then(() => {
                    //console.log("notfication sent")
                }).catch((error) => {
                    console.error(error)
                });
            }
            
        },
        getOrders() {
            if (this.planID) {                
                const path = this.$constants.apiPaths.planning.orders(this.marathonCountryID, this.clientID, this.campaignID, this.planID);
                this.axios
                    .get(path)
                    .then((res) => {
                        if (res.data) {
                            this.orders = res.data;
                        }
                    })
                    .catch((error) => {
                        this.handleRequestError(error);
                    });
            } else {
                console.error("cannot fetch data without planID");
            }
        },
        getPlanVersions() {
            if (this.planID) {
                const params = new URLSearchParams();
                params.append("planID", this.planID);
                params.append("marathonCountryID", this.marathonCountryID);
                params.append("metaDataOnly", "True");
                const path = this.$constants.apiPaths.planning.planVersions;
                
                let latestVersion =
                {
                    "_id": "latest",
                    "planVersionID": "latest", 
                    "timestampCreated": "LATEST"
                };

                return this.axios
                    .get(path, {
                        params: params,
                    })
                    .then((res) => {
                        if (res.data && res.data.plans) {
                            var versions = res.data.plans.reverse();

                            const planVersions = [];
                            for (var i = 0; i < versions.length; i++) {
                                if (versions[i].planVersionID) {
                                    planVersions.push(versions[i]);
                                }
                            }
                            
                            planVersions.unshift(latestVersion);
                            this.planVersions = planVersions;
                        }
                        
                        else{
                            this.planVersions = [];
                            this.planVersions.push(latestVersion);
                        }
                    })
                    .catch((error) => {
                        this.planVersions = [];
                        this.handleRequestError(error);
                        this.planVersions.push(latestVersion);
                    });
            } else {
                console.error("cannot fetch data without planID");
            }
        },
        getPlanById(planID) {
            if (planID) {
                const path = this.$constants.apiPaths.planning.plan(this.marathonCountryID, this.clientID, planID);
                this.axios
                    .get(path)
                    .then((res) => {
                        if (res.data && res.data.plan) {
                            var plan = res.data;
                            this.updateDetailName(plan.plan);
                            let selected = Object.assign(plan, this.selectedVersion);
                            this.selectedVersion = selected;
                        }
                        else{
                            this.planExists = false;
                            console.warn("Plan " + planID + " doesn't exists.")
                            this.$swal.fire({
                                title: 'Plan ' + planID + ' doesn\'t exists.',
                                text: 'You will automatically be redirected to the ‘Plan overview’.'
                            }).then((result) => {
                                this.$router.push({
                                    name: this.$constants.routeNames.campaigns.detail,
                                    params: {
                                        marathonCountryID: this.marathonCountryID,
                                        clientID: this.clientID,
                                        campaignID: this.campaignID,
                                    },
                                });
                            });
                        }
                    })
                    .catch((error) => {
                        this.handleRequestError(error);
                    });
            } else {
                console.error("cannot fetch data without planVersionID");
            }
        },
        getPlanVersion(planVersionID) {
            if (planVersionID) {
                const path = this.$constants.apiPaths.planning.planVersions;
                const params = {
                    planVersionID: planVersionID,
                    marathonCountryID: this.marathonCountryID
                };

                return this.axios
                    .get(path, {
                        params: params,
                    })
                    .then((res) => {
                        if (res.data && res.data.plans && res.data.plans.length > 0) {
                            return res.data.plans[0];
                        }
                    })
                    .catch((error) => {
                        this.handleRequestError(error);
                    });
            } else {
                console.error("cannot fetch data without planID");
            }
        },
        handleRoutes() {
            if (this.$route.params.clientID) {
                this.clientID = this.$route.params.clientID;
            }
            if (this.$route.params.campaignID) {
                this.campaignID = this.$route.params.campaignID;
            }
            if (this.$route.params.marathonCountryID) {
                this.marathonCountryID = this.$route.params.marathonCountryID;
            }
            if (this.$route.params.planID) {
                this.planID = this.$route.params.planID;
            }
        },
        handleRequestError(error) {
            console.error(error);
        },
        hasElement(className) {
            return document.getElementsByClassName(className).length > 0;
        },
        selectVersionManually(planVersion, planVersionNumber){
            if(!this.dataState.dataTouched){
                this.selectVersion(planVersion, planVersionNumber)
            }
            else{
                this.showSaveAlertDialog().then((result) =>{
                    if (result.isConfirmed) {
                        this.openModalNotifyUsers();
                    } else if (result.isDenied) {
                        this.selectVersion(planVersion, planVersionNumber);
                        this.dataState.dataTouched = false;
                    }
                });
            }
        },
        showSaveAlertDialog(){
            return this.$swal.fire({
                title: 'Remember to save your changes',
                html: "<p class=\"mb-4\">Your unsaved changes will be lost if you navigate to another page.</p>",
                
                showDenyButton: true,
                showCancelButton: true,
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonText: `Save & Continue`,
                cancelButtonText: `Cancel`,
                denyButtonText: `Ignore changes`,
                confirmButtonColor: '#376EE2',
                denyButtonColor: '#333333',
                cancelButtonColor: '#333333',
                customClass: {
                    popup: "swal2-popup-dark narrow-width",
                    confirmButton: 'btn btn-round order-3',
                    denyButton: 'btn btn-round mr-3 btn-gray order-1',
                    cancelButton: 'btn btn-round mr-3 btn-gray order-2',
                },
            }).then((result) => {
                return result;
            });
        },
        selectVersion(planVersion, planVersionNumber) {
            if(planVersion){
                this.selectedVersion = planVersion;
                this.selectedVersionNumber = planVersionNumber;

                if(planVersion.planVersionID === 'latest') {
                    this.getPlanById(this.planID);
                    this.getOrders();
                }
                else {
                    const that = this;
                    this.getPlanVersion(planVersion.planVersionID)
                        .then((response) =>{
                            that.selectedVersion = response;
                            that.orders = response.orders;
                            that.updateDetailName(response.plan);
                        })
                        .catch((error) => {
                            console.error(error)
                        });
                }
            }
        },
        ...mapActions([
            'updateDetailName',
            //'updateSelectedClient',
        ]),
    },
    computed: {
        ...mapGetters([
            'internalUser'
        ]),
        ...mapState([
            'campaignName',
            'detailName'
        ]),
        boxValues() {
            return {"client":this.$route.params.clientID, "id":this.$route.params.campaignID, "country":this.$route.params.marathonCountryID}
        },
        isLastPlanVersion() {

            if(this.planVersions.length == 0) return true;

            const firstEntry = this.planVersions[0];

            if(!firstEntry) return false;

            return firstEntry.planVersionID === this.selectedVersion.planVersionID;
        },
        currency(){
            return this.selectedVersion && this.selectedVersion.currency ? this.selectedVersion.currency : this.plan.currency;
        },
    },
    watch: {
        planVersions(newVal) {
            if (newVal && newVal.length > 0) {
                this.selectVersion(newVal[0], newVal.length);
                this.updateDetailName(newVal[0].plan);
            } else {
                this.getPlanById(this.planID);
                this.getOrders();
            }
        },
        // campaignID(newValue, oldValue) {

        //     if(!newValue) return;
        //     if(newValue === oldValue) return;

        //     //this.getOrders();
        // },
        internalUser: {
            immediate: true,
            handler(newVal) {
                this.handleRoutes();
                if(newVal !== null && newVal === false){
                    this.$router.replace({
                        name: this.$constants.routeNames.campaigns.detail,
                        params: {
                            marathonCountryID: this.marathonCountryID,
                            clientID: this.clientID,
                            campaignID: this.campaignID,
                        },
                    });
                }
            }
        },
    },
    mounted() {
        this.handleRoutes();

        try{
            this.getPlanVersions().then(() => {
                DragScroll.reset();
            });
        }catch(err){
            console.error(err);
            this.getOrders();
        }
        
        DragScroll.reset();
    },
    components: {
        Comments,
        PlannerOrdersTable,
        ConnectBoxFileDownload,
        GroupMultiselect,
    },
};
</script>
<style lang="scss" scoped>
.campaign-version-header{
    .total-ctc{
        display: inline-block;
        h5{font-weight: 300;}
        h3{font-size: 18px;}
    }
}

.card-table-container {
    padding: 15px;
}

.notify-users-content {
    color: white;
    margin-top: 500px;

    .notify-users-button {
        float: right;
        min-width: 150px;
        margin-top: 24px;
    }
}

#modal-notify-users___BV_modal_body_ {
    margin-top: -40px;
}

// .select-info.el-select .el-input .el-input__icon{
//     color: white;
// }
</style>
