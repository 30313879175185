 <!-- <template>
    <div id="home">
        <h1 class="text-center">Welcome to <b>Publicis Connect</b></h1>
    </div>
</template> -->


<template>
    <div>
        <div class="row">
            <div class="col d-flex card-row">
                <cta-card :iconClass="'icon-connect icon-connect-brief-active'" :isLink="true" title="Create campaign brief" v-on:click="openPage('briefs')" class="home briefs" :dont-resize="true">
                    <template #title>Create<br>campaign brief</template>
                </cta-card>
                <cta-card :iconClass="'icon-connect icon-connect-campaign-active'" :isLink="true" title="Go to campaign media plan" v-on:click="openPage('campaigns')" class="home" :dont-resize="true">
                    <template #title>Go to<br>campaign<br>media plan</template>
                </cta-card>
                <cta-card :iconClass="'icon-connect icon-connect-dashboard-active'" :isLink="true" title="Go to Live dashboard" v-on:click="openPage('dashboards')" class="home" :dont-resize="true">
                    <template #title>Go to <br>Live dashboard</template>
                </cta-card>
                <cta-card :iconClass="'icon-connect icon-connect-reporting-active'" :isLink="true" title="Go to Reporting" v-on:click="openPage('reporting')" class="home" :dont-resize="true">
                    <template #title>Go to <br>Reporting</template>
                </cta-card>
                <!-- <cta-card icon="/img/icons/icon-bulb.svg" :isLink="true" title="Go to Insights"   v-on:click="openPage('insights')" class="home" :dont-resize="true">
                    <template #title>Go to <br>Insights</template>
                </cta-card> -->
            </div>
        </div>
         
        <div class="row" v-if="!internalUser">
            <div class="col">
                <router-view :campaigns="campaigns" :client="selectedClient" :country="userSelectedCountry" :clientsFilter="userSelectedMultipleClients" :getRoute="getRoute"/>
            </div>
        </div>
        
    </div>

</template>
<script>
import CTACard from '@/components/Cards/CTACard.vue'
import ClientCountrySelect from '@/components/ClientCountrySelect/ClientCountrySelect.vue'
import { mapState, mapActions } from 'vuex'

export default {
    inject: ['formatCampaignNumbers'],
    props: ['clientSelectOptions'],
    components: {
        [ClientCountrySelect.name]: ClientCountrySelect,
        [CTACard.name]: CTACard,
    },
    data() {
        return {
            campaigns: [],
        }
    },
    computed:{
        ...mapState([
            'selectedClient',
            'userSelectedCountry',
            'userSelectedMultipleClients',
            'internalUser'
        ]),
    },
    methods: {
        openPage(type){
            this.$router.push({ name: this.$constants.routeNames[type].root });
        },
        getRoute(row, linkType){
            return {
                name: this.$constants.routeNames.campaigns.detail,
                params: {
                    marathonCountryID: row.marathonCountryID,
                    clientID: row.clientID,
                    campaignID: row.id,
                },
            };
        },
        getCampaigns() {
            this.axios(
                this.$constants.apiPaths.planning.campaigns()
            )
                .then((res) => {
                    if(res.data){
                        this.campaigns = res.data;
                        this.formatCampaignNumbers(this.campaigns);
                    }
                })
                .catch((error) => {
                    this.handleRequestError(error);
                });
        },
        handleRequestError(error){
            console.error(error);
        },
    },
    mounted() {
        if(!this.internalUser){
            this.getCampaigns();
        }
    },
    beforeDestroy() {}
};
</script>
<style></style>

