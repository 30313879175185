<template>
	<div>
		<div class="file-upload">
			<div class="campaign-folder">
				<div>
					<base-progress v-if="isUploading" value-position="none" :animated="true" :value="progress" type="success"></base-progress>
					<div>
						<div class="fileinput">
							<VueFullScreenFileDrop @drop='onDrop' />
							<div v-if="!isUploading" class="fileinput-note text-center" @click="openFileExplorer">
								<span class="icon"></span>
								<span class="text">Drop file here or click to upload</span>
							</div>

							<div v-if="isUploading" class="fileinput-note text-center">
								<div class="spinner">
									<div class="rect1"></div>
									<div class="rect2"></div>
									<div class="rect3"></div>
									<div class="rect4"></div>
								</div>
								<div class="spinner-text">
									Uploading file
								</div>
							</div>
						</div>
					</div>

					<input id="file-input" type="file" ref="filesToUpload" name="name" class="d-none" @change="startUpload" />
					<table class="table file-list" v-if="filesReady">
						<tbody>
							<tr v-for="file in files" :key="file.fileID" class="file-list-item">
								<td class="file-name">
									<el-popover class="custom-popper" trigger="hover" placement="left">
										<div class="popover-wrapper">
											<h3 class="popover-header">Last modified date</h3>
											<div class="popover-body">
												{{file.lastModifiedDateTime}}
											</div>
										</div>
										<span slot="reference">
											{{file.file}}
										</span>
									</el-popover>
								</td>

								<td class="file-preview" v-if="file.file.match(/.(jpg|jpeg|png|gif|svg)$/i)">
									<el-popover class="custom-popper" trigger="hover" placement="left">
										<div class="popover-wrapper">
											<h3 class="popover-header">File preview</h3>
											<div class="popover-body">
												<img style="max-width: 300px" :src="file['downloadURL']"/>
											</div>
										</div>
										<span slot="reference">
											<i class="tim-icons icon-tv-2"></i>
										</span>
									</el-popover>
								</td>
								<td class="file-download">
									<el-tooltip content="Click to download" placement="right" effect="dark">
										<a :href="file['downloadURL']" target="_blank">
                                            <i class="tim-icons icon-cloud-download-93"></i>
                                        </a>
									</el-tooltip>
								</td>
							</tr>
						</tbody>
					</table>

					<div v-if="!filesReady" class="fileinput-note text-center">
                        <div class="spinner">
                            <div class="rect1"></div>
                            <div class="rect2"></div>
                            <div class="rect3"></div>
                            <div class="rect4"></div>
                        </div>
                        <div class="spinner-text">
                            Loading files
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueFullScreenFileDrop from 'vue-full-screen-file-drop';
import {
    BaseProgress
} from 'src/components'
import {
    Tooltip,
} from 'element-ui';


export default {
    name: 'connect-box-file-upload',
    props: ['type','params'],
    data() {
        return {
            isUploading: false,
            filesReady: false,
            selectedFiles: undefined,
            currentFile: undefined,
            showAlert: false,
            filesToUpload: '',
            files: [],
            progress: 0,
        };
    },
    components: {
        BaseProgress,
        VueFullScreenFileDrop,
        Tooltip
    },
    methods: {
        openFileExplorer() {
            document.getElementById('file-input').click()
        },
        startUpload() {
            this.filesToUpload = this.$refs.filesToUpload.files;
            const formData = new FormData();
            for (var i = 0; i < this.filesToUpload.length; i++) {
                formData.append(this.filesToUpload[i].name, this.filesToUpload[i]);
            }
            this.onDrop(formData, this.filesToUpload);
        },
        getFiles() {
            this.filesReady = false;
			
            const queryParams = new URLSearchParams();

            const paramKeys = Object.keys(this.params);
            for(var i=0; i<paramKeys.length; i++){
			    queryParams.append(paramKeys[i],this.params[paramKeys[i]]);
            }
			    queryParams.append('type',this.type);
        	this.axios(this.$constants.apiPaths.boxFolder, {
                params: queryParams,
            })
                .then((res) => {
                    this.files = res.data.files;
                    this.filesReady = true;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        onDrop(formData, files) {
            this.$swal.fire({
                title: 'Upload confirmation',
                text: "Please confirm upload of file: '" + files[0].name + "'",
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Upload <i style="font-weight:heavy;" class="tim-icons icon-cloud-upload-94"></i>'
            }).then((result) => {
                if (!(result.dismiss === 'cancel')) {
                    if(this.canUpload){
                        this.progress = 0;
                        let newFormData = new FormData();
                        for (var i = 0; i < files.length; i++) {
                            newFormData.append("file", files[i]);
                            this.selectedFiles = files;
                            this.currentFile = this.selectedFiles.item(0);
                        }
                        this.uploadFiles(newFormData, event => {
                            this.progress = Math.round((100 * event.loaded) / event.total);
                        });
                    }
                    else{
                        this.$swal.fire({
                            title: 'Upload impossible',
                            text: 'Please save campaign before uploading files',
                            type: 'info',
                        })
                    }
					
                }
            });
        },
        uploadFiles(formData, onUploadProgress) {
            this.isUploading = true;
            const queryParams = new URLSearchParams();

            const paramKeys = Object.keys(this.params);
            for(var i=0; i<paramKeys.length; i++){
			    queryParams.append(paramKeys[i],this.params[paramKeys[i]]);
            }
            queryParams.append('type',this.type);

            this.axios.post(this.$constants.apiPaths.boxFolder, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: queryParams,
                onUploadProgress
            })
                .then((res) => {
                    this.$swal.fire({
                        title: 'Upload successful',
                        icon: "success",
                        position: 'top-end',
                        showCancelButton: false,
                        showConfirmButton: false,
                        //toast: true,
                        timer: 8000
                    })
                    this.getFiles(this.campaign)
                    this.isUploading = false;
                })
                .catch((error) => {
                    this.isUploading = false;
                    this.$swal.fire({
                        title: 'Test upload (no box) failed',
                        icon: "error",
                        position: 'top-end',
                        showCancelButton: false,
                        showConfirmButton: false,
                        //toast: true,
                        timer: 8000
                    })
                    console.error(error);
                });
        },
    },
    created() {
        this.getFiles(this.type,this.params);
    },
    computed:{
        canUpload(){
            return this.type != null &&this.params.marathonCountryID != null && this.params.clientID != null && (this.params.campaignID != null || this.params.projectID );
        }
    }
};
</script>

<style lang="scss" scoped>
.file-list{
	.file-name{
		width: 80%;
	}

	.file-preview, .file-download{
		width: 10%;
		text-align: right;
	}
}
.spinner {
  opacity: .5;
  margin: 6px auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}

.spinner-text {
  opacity: .5;
  width:100%;
  text-align: center;
  font-weight: bold;
  font-size: 9pt;
  color: #FFFFFF;
}

.spinner > div {
  background-color: #FFFFFF;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right:3px;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
</style>
