<template>
    <div :class="['col' , 'card cta-card', isLink ? 'clickable' : '', disabled ? 'disabled' : '']" v-on:click="$emit('click')">
        <div class="card-image cta-icon">
            <i :class="iconClass" v-if="iconClass"/>
            <img :src="icon" v-else />
        </div>
        <div class="card-header" v-if="$slots.header || (title !== null && title !== undefined)" :class="headerClasses">
            <slot name="header">
                <div class="value-delta" :class="[(negative) ? 'negative' : '']" v-if="valueDelta">
                    <i class="el-icon el-icon-caret" :class="[valueDeltaNegative ? 'el-icon-caret-bottom' : 'el-icon-caret-top']" />
                    {{ valueDelta }}{{ typeof valueDelta === 'number' ? '%' : '' }}
                </div>
                <div :class="{ smaller: titleTooLong && !dontResize, 'header-title': true }" ref="cardText">
                    <i class="tim-icons icon-double-right" v-if="isLink"></i>
                    {{ !dontResize || title ? shortenedTitle : '' }}
                    <slot name="title" v-if="dontResize && !title">
                    </slot>
                </div>
                <template v-if="subtitle">
                    <!-- <br/> -->
                    <p class="card-category">{{ subtitle }}</p>
                </template>
            </slot>
        </div>
        <div class="card-body" v-if="$slots.default" :class="bodyClasses">
            <slot></slot>
        </div>
        <div class="card-footer" :class="footerClasses" v-if="$slots.footer">
            <hr v-if="showFooterLine" />
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>

export default {
    name: 'cta-card',
    props: {
        isLink: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: String,
        iconClass: String,
        title: [String, Number],
        subtitle: String,
        headerClasses: {
            type: [String, Object, Array],
            default: '',
            description: 'Card header css classes'
        },
        dontResize: {
            type: Boolean,
            default: false
        },
        bodyClasses: {
            type: [String, Object, Array],
            default: '',
            description: 'Card body css classes'
        },
        footerClasses: {
            type: [String, Object, Array],
            default: '',
            description: 'Card footer css classes'
        },
        valueDelta: {
            type: [String, Number],
            default: '',
        },
        valueDeltaReverse: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        titleTooLong() {
            return this.title.length > 24;
        },
        shortenedTitle() {
            var title = this.title.toString();
            if (title.length > 44 && !this.dontResize) {
                title = title.substring(0, 21) + '...';
            }
            return title;
        },
        valueDeltaNegative(){
            if(this.valueDelta){
                if(typeof this.valueDelta === 'number' &&  this.valueDelta < 0){
                    return true;
                }
                if(typeof this.valueDelta === 'string' && (this.valueDelta.substring(0,1) === '-' ||  this.valueDelta.substring(0,1) === '-')){
                    return true;
                }
            }
            return false;
        },
        negative(){
            if(this.valueDeltaReverse){
                if(this.valueDeltaNegative){
                    return false;
                }
                else{
                    return true;
                }
            }
            else{
                return this.valueDeltaNegative;
            }
        }
    },
};
</script>
