<template>
    <div class="live-dashboards">
        <portal to="page-title_action" class="page-title_action">
            <template v-if="campaignName">
                <el-tooltip content="Download as PDF" placement="top">
                    <img class="download-icon icon" :src="'/img/icons/icon-download.svg'" v-on:click="downloadAsPDF()" alt="Download as PDF" />
                </el-tooltip>
            </template>
        </portal>
        <div class="row card-row" :ref="cardsDefinitions.overview.ref">
            <cta-card icon="/img/icons/icon-user.svg" :title="campaignName" subtitle="Campaign" class="title" />
            <!-- <cta-card icon="/img/icons/icon-target.svg" title="Awareness, Want-to-see" subtitle="Objectives" /> -->
            <!-- <cta-card icon="/img/icons/icon-money.svg" title="54.032" subtitle="Spend" /> -->
            <cta-card icon="/img/icons/icon-eye.svg" :title="impressions" subtitle="Impressions"/>
            <cta-card icon="/img/icons/icon-click.svg" :title="clicks" subtitle="Clicks" />
            <!-- <cta-card icon="/img/icons/icon-cpc.svg" :title="ctr" subtitle="CPC" /> -->
        </div>
        <div class="row" v-if="internalUser">
            <div class="col" :ref="cardsDefinitions.mapping.ref">
                <universal-list :title="cardsDefinitions.mapping.title" :table-data="mapping" :table-columns="cardsDefinitions.mapping.columns" :show-status="false"
                    :list-classes="['dashboard-list order-mapping not-clickable']" />
            </div>
        </div>
        <div class="row">
            <div class="col" :ref="cardsDefinitions.programmaticPacing.ref">
                <universal-list :title="cardsDefinitions.programmaticPacing.title" :table-data="insertionOrders" :table-columns="cardsDefinitions.programmaticPacing.columns"
                    @row-click="openDetail" :list-classes="['dashboard-list']"
                    :help="cardsDefinitions.programmaticPacing.info" />
            </div>
        </div>
        <div class="row">
            <div class="col" :ref="cardsDefinitions.directBuysPacing.ref">
                <universal-list :title="cardsDefinitions.directBuysPacing.title" :table-data="sites"
                :table-columns="cardsDefinitions.directBuysPacing.columns" @row-click="openDetail" :list-classes="['dashboard-list']"
                :help="cardsDefinitions.directBuysPacing.info"  />
            </div>
        </div>
    </div>
</template>

<script>
import CTACard from '@/components/Cards/CTACard.vue'
import UniversalList from '@/components/UniversalList/UniversalList.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { generatePDF } from '@/helpers/pdf.helpers.js'

export default {
    props: ['marathonCountryID', 'campaignID'],
    inject: ['formatLargeNumber', 'formatFullNumber', 'currencyFormatter'],
    data() {
        return {
            client: {
                clientID: null,
                marathonCountryID: null
            },
            campaign: null,
            insertionOrders: [],
            sites: [],
            clicks: 0,
            impressions: 0,
            mapping: []
        }
    },
    watch: {
        briefs: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.filterBriefs(newVal);
                }
            }
        },
        client: {
            deep: true,
            handler(newVal) {
                this.filterBriefs(this.briefs);
            }
        },
    },
    methods: {
        ...mapActions([
            'updateCampaignName',
            'clearCampaignName',
        ]),
        getCampaign() {
            const path = this.$constants.apiPaths.planning.campaign(this.marathonCountryID, this.clientID, this.campaignID);
            this.axios(path)
                .then((res) => {
                    if(res.data && res.data && res.data.campaign){
                        this.updateCampaignName(res.data.campaign);
                    }
                })
                .catch((error) => {
                    this.handleRequestError(error);
                });
        },
        downloadAsPDF(cardDefinition) {
            this.$swal.fire({
                title: 'Generating PDF',
                text: 'Creating export file',
                allowOutsideClick: false,
                showConfirmButton: false,
                backdrop: false
            });

            if(cardDefinition){
                generatePDF([cardDefinition], this).then(
                    (result) => { 
                        this.$swal.close();
                    },
                    (error) => { 
                        console.error(error);
                        this.$swal.close();
                    }
                );
            }
            else{
                generatePDF(Object.values(this.cardsDefinitions), this).then(
                    (result) => { 
                        this.$swal.close();
                    },
                    (error) => { 
                        console.error(error);
                        this.$swal.close();
                    }
                );
            }
        },
        handleRequestError(error) {
            console.error(error);
        },
        handleRoutes(){
            if (this.$route.params.campaignID) {
                this.campaignID = this.$route.params.campaignID;
            }
            if (this.$route.params.clientID) {
                this.clientID = this.$route.params.clientID;
            }
            if (this.$route.params.marathonCountryID) {
                this.marathonCountryID = this.$route.params.marathonCountryID;
            }
        },
        getData() {
            if (this.campaignID && this.clientID && this.marathonCountryID) {
                this.fetchData(this.marathonCountryID, this.clientID, this.campaignID).then((data) => {
                    if(data.header){
                        if(data.header.impressions){
                            this.impressions = this.formatFullNumber(data.header.impressions)
                        }
                        if(data.header.clicks){
                            this.clicks = this.formatFullNumber(data.header.clicks)
                        }
                    }
                    
                    this.composeData(data);
                });
            } else {
                console.warn("cannot fetch data without campaignID and marathonCountryID");
            }
        },
        composeData(data) {
            var insertionOrders = data.insertionOrders;

            if(insertionOrders){
                for(let i=0; i < insertionOrders.length; i++){
                    insertionOrders[i].daysString = this.formatLargeNumber(insertionOrders[i].daysActive) + ' / ' + this.formatLargeNumber(insertionOrders[i].burstLength);
                    insertionOrders[i].status = 'live';
                    insertionOrders[i].impressions = this.formatFullNumber(insertionOrders[i].impressions);
                    insertionOrders[i].clicks = this.formatFullNumber(insertionOrders[i].clicks);
                    //insertionOrders[i].spend = this.formatLargeNumber(insertionOrders[i].spend);
                    insertionOrders[i].spend = this.currencyFormatter(insertionOrders[i].spend, 0, this.currency, true);
                    //insertionOrders[i].plannedBudget = this.formatLargeNumber(insertionOrders[i].plannedBudget);
                    insertionOrders[i].plannedBudget = this.currencyFormatter(insertionOrders[i].plannedBudget, 0, this.currency, true);
                    insertionOrders[i].kpi = this.formatLargeNumber(insertionOrders[i].performanceGoalValue ) + ' ' + insertionOrders[i].performanceGoalType;
                    insertionOrders[i].type = this.$constants.pacingTypes.PROGRAMMATIC;
                }
                this.insertionOrders = insertionOrders;
            }

            var sites = data.sites;

            if(sites){
                for(let i=0; i < sites.length; i++){
                    sites[i].status = 'live';   
                    sites[i].ctr = sites[i].impressions === 0 ? '--' : (this.formatFullNumber((sites[i].clicks / sites[i].impressions) * 100) + "%");              
                    sites[i].impressions = this.formatFullNumber(sites[i].impressions);
                    sites[i].clicks = this.formatFullNumber(sites[i].clicks);
                    sites[i].type = this.$constants.pacingTypes.DIRECT;
                }
                this.sites = sites;
            }
            
            var mapping = data.mapping;            
            this.mapping = mapping;

        },
        fetchData(marathonCountryID, clientID, campaignID){
            const path = this.$constants.apiPaths.liveDashboardV2.campaign(marathonCountryID, clientID, campaignID);
            return this.axios.get(path)
                .then((res) => {
                    return res.data;
                })
                .catch((error) => {
                    this.handleRequestError(error);
                    return error;
                });
        },
        openDetail(row) {
            const type = row.type;
            if (type === this.$constants.pacingTypes.PROGRAMMATIC || type === this.$constants.pacingTypes.DIRECT) {
                if(type === this.$constants.pacingTypes.PROGRAMMATIC){
                    this.updateDetailName(row.insertionOrder)
                    this.$router.push({
                        name: this.$constants.routeNames.dashboards.programmatic,
                        params: {
                            marathonCountryID: this.$route.params.marathonCountryID,
                            campaignID: this.$route.params.campaignID,
                            type: row.type,
                            insertionOrderID: row.insertionOrderID
                        }
                    });
                }
                else{
                    this.updateDetailName(row.site);
                    this.$router.push({
                        name: this.$constants.routeNames.dashboards.direct,
                        params: {
                            marathonCountryID: this.$route.params.marathonCountryID,
                            campaignID: this.$route.params.campaignID,
                            type: row.type,
                            siteID: row.siteID,
                            dcmCampaignID: row.dcmCampaignID
                        }
                    });
                }
                
            }
        },
        ...mapActions([
            'updateDetailName'
        ])
    },
    computed: {
        currency(){
            if(this.$route.params.marathonCountryID){
                return this.$route.params.marathonCountryID.split("M")[1] + 'K';
            }
            return 'DKK';
        },
        cardsDefinitions(){
            return {
                overview: {
                    ref: "overview",
                    title: "Overview",
                    info: null
                },
                mapping:{
                    title: "Order mapping",
                    columns: this.mappingColumns,
                },
                programmaticPacing: {
                    ref: "programmaticPacing",
                    title: "Programmatic pacing",
                    info: "Each format equivalates an insertion order in Display & Video 360. Data is extracted once a day.",
                    columns: this.programmaticPacingColumns,
                },
                directBuysPacing: {
                    ref: "directBuysPacing",
                    title: "Direct buys pacing",
                    info: "Each media represents a digital order from the media plan booked in Doubleclick Campaign Manager.",
                    columns: this.directBuysPacingColumns,
                },
            };
        },
        mappingColumns(){
            return[{
                prop: 'order',
                label: 'Order'
            },{
                prop: 'media',
                label: 'Media'
            },{
                prop: 'mediaChannel',
                label: 'Media Channel'
            },{
                prop: 'mappingType',
                label: 'mapping Type'
            },{
                prop: 'mappedTo',
                label: 'mapped To'
            }]
        },
        programmaticPacingColumns(){
            return [{
                minWidth: 240,
                prop: 'insertionOrder',
                label: 'Format'
            },
            {
                width: 180,
                prop: "daysString",
                label: 'DAYS ACTIVE / TOTAL DAYS'
            },
            {
                width: 110,
                prop: 'impressions',
                label: 'IMPRESSIONS'
            },
            {
                width: 105,
                prop: 'clicks',
                label: 'CLICKS'
            },
            {
                width: 100,
                prop: 'spend',
                label: 'SPEND'
            },
            {
                width: 125,
                prop: 'plannedBudget',
                label: 'PLANNED BUDGET'
            },
            {
                minWidth: 125,
                width: 125,
                prop: 'kpi',
                label: 'KPI'
            }];
        },
        directBuysPacingColumns(){
            return [{
                minWidth: 200,
                prop: 'site',
                label: 'MEDIA'
            },
            {
                minWidth: 150,
                prop: 'impressions',
                label: 'IMPRESSIONS'
            },
            {
                minWidth: 150,
                prop: 'clicks',
                label: 'CLICKS'
            },
            {
                minWidth: 150,
                prop: 'ctr',
                label: 'CTR'
            }]
        },
        ctr(){
            if(this.impressions === 0){
                return 0
            }
            else{
                return this.formatLargeNumber(this.clicks / this.impressions);
            }
        },
        ...mapState([
            'campaignName',
        ]),
        ...mapGetters([
            'internalUser',
        ]),
    },
    mounted(){
        this.handleRoutes();
        this.getData();
        if(!this.campaignName){
            this.getCampaign();
        }
    },
    components: {
        [UniversalList.name]: UniversalList,
        [CTACard.name]: CTACard,
    },

};
</script>

<style lang="scss" scoped>

.download-icon{
    cursor: pointer;    
    position: relative;
    bottom: 5px;
}
</style>
