<template>
    <div>
        <portal to="ordertable_filter">
            <TableFilter
                :rows="filterAllowedRows"
                :columns="filterAllowedColumns"
                @updateRows="updateFilterAllowedRows"
                @updateColumns="updateFilterAllowedColumns"
                :rowsMap="filterAllowedRowsMap"
                :columnsMap="fitlerAllowedColumnsMap"
                storageKey="PLANNER_ORDERS_TABLE"
            />
        </portal>

        <OrdersTable
            :orders="orders"
            :dataColumnsAmount="21 + chosenSurcharges.length"
            :mediaFilterAllowed="filterAllowedRows"
            :headers="headers"
            :sourceMedias="mediaChannels"
        >
            <template v-slot:header>
                <!-- fixed headers - would not change -->
                <th :class="['data-column', hideColumn('order_id')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["order_id"] }}
                </th>
                <th :class="['data-column', hideColumn('order_name')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["order_name"] }}
                </th>
                <th :class="['data-column', hideColumn('agreement')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["agreement"] }}
                </th>
                <th :class="['data-column', hideColumn('media')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["media"] }}
                </th>
                <th :class="['data-column', hideColumn('site')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["site"] }}
                </th>
                <th :class="['data-column', hideColumn('placement')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["placement"] }}
                </th>
                <th :class="['data-column', hideColumn('strategies')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["strategies"] }}
                </th>
                <th :class="['data-column', hideColumn('create_sizes')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["create_sizes"] }}
                </th>
                <th :class="['data-column', hideColumn('kpi')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["kpi"] }}
                </th>
                <th :class="['data-column', hideColumn('start_date')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["start_date"] }}
                </th>
                <th :class="['data-column', hideColumn('end_date')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["end_date"] }}
                </th>
                <th :class="['data-column', hideColumn('currency')]" rowspan="2">{{ fitlerAllowedColumnsMap["currency"] }}</th>
                <th :class="['data-column', hideColumn('buy_type')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["buy_type"] }}
                </th>
                <th :class="['data-column', hideColumn('units')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["units"] }}
                </th>
                <th :class="['data-column', hideColumn('negotiated_unit_price')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["negotiated_unit_price"] }}
                </th>
                <th :class="['data-column', hideColumn('client_own_programmatic_spend')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["client_own_programmatic_spend"] }}
                </th>
                <th :class="['data-column', hideColumn('client_own_tech_cost')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["client_own_tech_cost"] }}
                </th>
                <th :class="['data-column', hideColumn('client_own_adserving')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["client_own_adserving"] }}
                </th>

                <th class="data-column" rowspan="2">
                    <el-select 
                        class="form-surcharge-select" v-model="selectedSurcharge" :disabled="!isLastPlanVersion"
                        filterable placeholder="Surcharges"
                        popper-class="no-arrow">
                        <el-option
                            v-for="(surg, i) in surchargesRaw"
                            :key="'n_i_' + i + '_s_' + surg.surchargeID"
                            :value="surg.surchargeID"
                            :label="surg.internal_name + ' [' + surg.surchargeID + ']' + ' (' + surg.marathonCountryID + ')'">
                        </el-option>
                    </el-select>
                    <!-- <b-form-select class="form-surcharge-select" v-model="selectedSurcharge" :disabled="!isLastPlanVersion">
                        <option
                            v-for="(surg, i) in surchargesRaw"
                            :key="'n_i_' + i + '_s_' + surg.surchargeID"
                            :value="surg.surchargeID"
                        >
                            {{ surg.internal_name }} [{{ surg.surchargeID }}]
                        </option>
                    </b-form-select> -->

                    <span @click="addNewPriceRow" class="add-new-pricerow">&plus; Add pricerow</span>
                </th>
                <template v-for="surg in reorderSurcharges">
                    <th
                        :class="['data-column']"
                        rowspan="2"
                        :key="'a_s_' + surg.surchargeID + currentTime"
                    >
                        {{ getSurchargeName(surg) }}
                    </th>
                </template>
                <!-- dynamic headers - would change based on data in order -->
                <!-- new headers - would be added dynamically during working with table -->

                <th :class="['data-column', hideColumn('net')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["net"] }}
                </th>
                <th :class="['data-column', hideColumn('ctc')]" rowspan="2">
                    {{ fitlerAllowedColumnsMap["ctc"] }}
                </th>
            </template>

            <template v-slot:media="{ media }">
                <div class="dot-menu">
                    <span>&hellip;</span>
                    <ul v-if="isLastPlanVersion">
                        <li><a href="#" @click.prevent="addNewOrder(media)">Add new order</a></li>
                    </ul>
                </div>
                <span>{{ media.mediaChannel }}</span>
            </template>

            <template v-slot:row="{ order, mediaItem }">
                <!-- fixed columns - would not change -->
                <td :class="[hideColumn('order_id')]">{{ order.orderID > 0 ? order.orderID : "" }}</td>
                <td :class="[hideColumn('order_name')]">
                    <TextInput
                        type="string"
                        :value="order.order"
                        @blur="updateOrderEntry(order.orderID, 'order', $event.target.value)"
                        :required="false"
                        :disabled="!isLastPlanVersion"
                    />
                </td>
                <td :class="[hideColumn('agreement')]">
                    <el-select 
                        :id="'form-order-agreement-select_' + order.orderID"
                        class="form-agreement-select"
                        v-model="order.orderAgreementID"
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                        filterable placeholder="Agreement"
                        popper-class="no-arrow">
                        <el-option
                            v-for="agreement in client.agreements"
                            :key="'o_' + order.orderID + '_a_' + agreement.agreementID"
                            :value="agreement.agreementID"
                            :disabled="!isLastPlanVersion"
                            :label="agreement.agreement">
                        </el-option>
                    </el-select>
                    <!-- <b-form-select
                        :id="'form-order-agreement-select_' + order.orderID"
                        class="form-agreement-select"
                        v-model="order.orderAgreementID"
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                    >
                        <option
                            v-for="agreement in client.agreements"
                            :key="'o_' + order.orderID + '_a_' + agreement.agreementID"
                            :value="agreement.agreementID"
                            :disabled="!isLastPlanVersion"
                        >
                            {{ agreement.agreement }}
                        </option>
                    </b-form-select> -->
                </td>
                <td :class="[hideColumn('media')]">
                    <el-select 
                        :id="'form-order-media-select_' + order.orderID"
                        class="form-media-select"
                        v-model="order.mediaID"
                        @change="orderChanged(order.orderID)"
                        required
                        :disabled="!isLastPlanVersion"
                        no-data-text="No media for agreement and media channel combination"
                        filterable placeholder="Media"
                        popper-class="no-arrow">
                        <el-option
                            v-for="(media, i) in getAgreementMedia(order.orderAgreementID, mediaItem.mediaChannelID)"
                            :key="'o_' + order.orderID + '_i_' + i + '_m_' + media.mediaID"
                            :value="media.mediaID"
                            :label="media.media">
                        </el-option>
                    </el-select>
                    <!-- <b-form-select
                        :id="'form-order-media-select_' + order.orderID"
                        class="form-media-select"
                        v-model="order.mediaID"
                        @change="orderChanged(order.orderID)"
                        required
                        :disabled="!isLastPlanVersion"
                    >
                        <option
                            v-for="(media, i) in getAgreementMedia(order.orderAgreementID, mediaItem.mediaChannelID)"
                            :key="'o_' + order.orderID + '_i_' + i + '_m_' + media.mediaID"
                            :value="media.mediaID"
                        >
                            {{ media.media }}
                        </option>
                    </b-form-select> -->
                </td>
                <td :class="[hideColumn('site')]">
                    <TextInput
                        type="string"
                        maxlength="30"
                        :value="order.mediaPlanInformation.site"
                        @blur="updateOrderPlanValue(order.orderID, 'site', $event)"
                        :required="false"
                        :disabled="!isLastPlanVersion"
                    />
                </td>
                <td :class="[hideColumn('placement')]">
                    <TextInput type="string" v-model="order.placement" :required="false" :disabled="!isLastPlanVersion"/>
                </td>
                <td :class="[hideColumn('strategies')]">
                    <TextInput
                        type="string"
                        maxlength="500"
                        :value="order.mediaPlanInformation.strategies"
                        @blur="updateOrderPlanValue(order.orderID, 'strategies', $event)"
                        :required="false"
                        :disabled="!isLastPlanVersion"
                    />
                </td>
                <td :class="[hideColumn('create_sizes')]">
                    <TextInput type="string" v-model="order.formats" :required="false" :disabled="!isLastPlanVersion" />
                </td>
                <td :class="[hideColumn('kpi')]">
                     <el-select 
                        :id="'form-order-media-select_' + order.orderID"
                        class="form-media-select"
                         v-model="order.mediaPlanInformation.kpi"
                        required
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                        filterable
                        placeholder="KPI"
                        popper-class="no-arrow">
                        <el-option
                        v-for="(val, key) in kpiValues"
                            :key="'o_' + order.orderID + '_i_' + key + '_kpi_' + key"
                            :value="key"
                            :label="val"
                            >
                        </el-option>
                    </el-select>
                    <!-- <b-form-select
                        :id="'form-order-kpi-select_' + order.orderID"
                        class="form-media-select"
                        v-model="order.mediaPlanInformation.kpi"
                        required
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                    >
                        <option
                            v-for="(val, key) in kpiValues"
                            :key="'o_' + order.orderID + '_i_' + key + '_kpi_' + key"
                            :value="key"
                        >
                            {{ val }}
                        </option>
                    </b-form-select> -->
                </td>
                <td :class="[hideColumn('start_date')]">
                    <form-input
                        type="date"
                        v-model="order.startDate"
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                    />
                </td>

                <td :class="[hideColumn('end_date')]">
                    <form-input
                        type="date"
                        v-model="order.endDate"
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                    />
                </td>
                <td :class="[hideColumn('currency')]">
                    <el-select 
                        :id="'form-currency-select-' + order.orderID"
                        v-model="order.currency"
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                        placeholder="Currency"
                        filterable
                        popper-class="no-arrow">
                        <el-option
                        v-for="curr in availableCurrencies" :key="curr" :value="curr" :label="curr">
                        </el-option>
                    </el-select>
                    <!-- <b-form-select
                        :id="'form-currency-select-' + order.orderID"
                        v-model="order.currency"
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                    >
                        <option v-for="curr in availableCurrencies" :key="curr" :value="curr">
                            {{ curr }}
                        </option>
                    </b-form-select> -->
                </td>
                <td :class="[hideColumn('buy_type')]">
                    <el-select 
                        :id="'form-buyType-select-' + order.orderID"
                        :value="order.mediaPlanInformation.buyType"
                        v-model="order.mediaPlanInformation.buyType"
                        filterable
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                        placeholder="Buy Type"
                        popper-class="no-arrow">
                        <el-option
                        v-for="buyType in availableBuytypes" :key="buyType" :value="buyType" :label="buyType">
                        </el-option>
                    </el-select>
                    <!-- <b-form-select
                        :id="'form-buyType-select-' + order.orderID"
                        :value="order.mediaPlanInformation.buyType"
                        v-model="order.mediaPlanInformation.buyType"
                        @change="orderChanged(order.orderID)"
                        :disabled="!isLastPlanVersion"
                    >
                        <option v-for="buyType in availableBuytypes" :key="buyType" :value="buyType">
                            {{ buyType }}
                        </option>
                    </b-form-select> -->
                </td>
                <td :class="[hideColumn('units')]">
                    <TextInput
                        type="number"
                        :value="order.mediaPlanInformation.units"
                        @blur="updateOrderPlanValue(order.orderID, 'units', $event)"
                        :required="false"
                        :disabled="!isLastPlanVersion"
                    />
                </td>
                <td :class="[hideColumn('negotiated_unit_price')]"><TextInput
                        type="number"
                        :value="order.mediaPlanInformation.unitPrice"
                        @blur="updateOrderPlanValue(order.orderID, 'unitPrice', $event)"
                        :required="false"
                        :disabled="!isLastPlanVersion"
                    /></td>
                <td :class="[hideColumn('client_own_programmatic_spend')]"><TextInput
                    type="number"
                        v-model="order.clientOwnProgrammaticSpend"
                        @blur="updateCostsValue(order.orderID, 'clientOwnProgrammaticSpend', $event)"
                    :required="false"
                    :disabled="!isLastPlanVersion"
                /></td>
                <td :class="[hideColumn('client_own_tech_cost')]"><TextInput
                    type="number"
                        v-model="order.clientOwnTechCost"
                        @blur="updateCostsValue(order.orderID, 'clientOwnTechCost', $event)"
                    :required="false"
                    :disabled="!isLastPlanVersion"
                /></td>
                <td :class="[hideColumn('client_own_adserving')]"><TextInput
                    type="number"
                        v-model="order.clientOwnAdserving"
                        @blur="updateCostsValue(order.orderID, 'clientOwnAdserving', $event)"
                    :required="false"
                    :disabled="!isLastPlanVersion"
                /></td>

                <!-- dynamic columns - would change based on data in order -->

                <!-- surcharges -->
                <td>&nbsp;</td>
                <template v-for="surg in reorderSurcharges">
                    <td
                        :key="'o_' + order.orderID + '_b_s_' + surg.surchargeID + currentTime"
                     >
                        <TextInput
                            type="number"
                            :value="getSurchargeFromOrder(order, surg.surchargeID).net"
                            @blur="changeSurchargeValue(order.orderID, surg.surchargeID, $event)"
                            :required="false"
                            :disabled="!isLastPlanVersion"
                        />
                    </td>
                </template>

                <td :class="[hideColumn('net')]">{{ formatFullNumber(order.net) }}</td>
                <td :class="[hideColumn('ctc')]">{{ formatFullNumber(order.ctc) }}</td>
            </template>
        </OrdersTable>
    </div>
</template>
<script>
import dayjs from "dayjs";
import OrdersTable from "@/components/OrdersTable/OrdersTable.vue";
import TextInput from "@/components/Inputs/TextInput.vue";
import TableFilter from "@/components/OrdersTable/TableFilter.vue";
import BaseButton from "@/components/BaseButton.vue";
import { 
    //fetchClient,
    fetchOrderInformation,
    //fetchDataModels 
} from "./helpers.fetch";
import {
    addSurchargeToOrder,
    buildSurchargeStructure,
    convertOrders,
    crateOrderArrayMap,

    createSurcharge,
    recountOrderNetCtc,
    createAgreementMediaMap
} from "./helper";
import { mapState, mapActions } from 'vuex'

const tableColumns = {
    order_id: "Order ID",
    order_name: "Order name",
    agreement: "Agreement",
    media: "Media",
    site: "Site",
    currency: "Currency",
    placement: "Placement",
    strategies: "Strategies",
    create_sizes: "Creative sizes",
    kpi: "KPI",
    start_date: "Start date",
    end_date: "End date",
    buy_type: "Buy Type",
    units: "Units",
    negotiated_unit_price: "Negotiated unit price",
    client_own_programmatic_spend: "Client Own Programmatic Spend",
    client_own_tech_cost: "Client Own Tech Cost",
    client_own_adserving: "Client Own Adserving",
    net: "NET",
    ctc: "CTC",
};

const MAX_FEES = 10;

const KPIValues = ["CPM", "CPC", "CPA", "CPV", "Viewabilty(%)"];

const getDefaultHeaders = () => [
    {
        FEES: {
            span: 1,
            offset: 18,
        },
    },
];

export default {
    name: "PlannerOrdersTable",
    components: {
        OrdersTable,
        TextInput,
        TableFilter,
        BaseButton,
    },
    inject: ['formatLargeNumber', 'formatFullNumber'],

    props: {
        campaignID: {
            type: String,
            default: "",
        },
        // client: {
        //     type: Object,
        //     default: () => { return {}},
        // },
        marathonCountryID: {
            type: String,
            default: "",
        },
        planID: {
            type: String,
            default: "",
        },
        plan: {
            type: Object,
        },
        // value: {
        //     type: Boolean,
        //     default: false
        // },
        value: {
            type: Object,
            default: () => ({
                dataTouched: false,
                validSurcharges: false
            })
        },
        sourceOrders: {
            type: Array,
            default: () => ([])
        },
        isLastPlanVersion: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            orders: [],
            client: {},
            medias: [],
            surchargesRaw: [],
            responses: [], // responses after submitting orders
            //dataModels: {},
            headers: getDefaultHeaders(),
            newOrderStartingID: -9999, // new order must have an unique ID (and negative). will be replaced by null before submit one BE

            filterAllowedRows: ["*"],
            filterAllowedColumns: Object.keys(tableColumns), // select all
            filterAllowedRowsMap: {}, // generated
            fitlerAllowedColumnsMap: tableColumns, // static?
            kpiValues: KPIValues,

            surchargesDefinition: {},
            surchargesOrder: [], // ordering of surcharges in table
            ordersMap: {}, // map order id to array key in "orders"
            mediaChannels: [], // Medias used for table

            chosenSurcharges: [], // ony few of the are selected and those we can display in

            selectedSurcharge: null,
            mapAgreementMedias: {},

            touchedOrders: {},

            availableCurrencies: ["DKK", "SEK", "NOK"],
            availableBuytypes: ["CPM", "CPC", "CPA"],

            currentTime: new Date(),
            totalCTC: null,
            touched: false,
            haveSurcharges: false,
        };
    },

    computed: {
        reorderSurcharges() {
            return this.surchargesOrder.map((id) => ({
                surchargeID: id,
            }));
        },
        ...mapState([
            'userSelectedCountry',
            'selectedClient',
            'dataModels'
        ])
    },

    methods: {
        getSurchargeFromOrder(order, surchargeID) {
            if(order && order.surcharges){
                return order.surcharges[surchargeID] || {}
            }
            else{
                return {}
            }
        },

        orderChanged(orderID){
            this.touchedOrders[orderID+""] = true;
        },
        addNewPriceRow() {

            if(!this.isLastPlanVersion) return false;

            const surchargeID = this.selectedSurcharge;

            if(this.surchargesOrder.includes(surchargeID))
            {
                this.$swal.fire({
                    icon: 'info',
                    title: 'This pricerow is already added',
                    showCloseButton: true,
                    confirmButtonText: "Close",
                    customClass: "swal2-popup-dark"
                });

                return;
            }

            if(this.surchargesOrder.length >= MAX_FEES)
            {
                this.$swal.fire({
                    icon: 'info',
                    title: 'You have reached maximum number of fees',
                    showCloseButton: true,
                    confirmButtonText: "Close",
                    customClass: "swal2-popup-dark"
                });

                return;
            }

            const newSurchage = createSurcharge({
                surchargeID: surchargeID,
                surcharge: this.surchargesDefinition[surchargeID].internal_name,
            });

            const surgOrder = this.surchargesOrder.slice(0);
            surgOrder.unshift(newSurchage.surchargeID);

            this.surchargesOrder = surgOrder;

            this.orders = this.orders.map((order) => {
                this.orderChanged(order.orderID);
                return recountOrderNetCtc(addSurchargeToOrder(order, Object.assign({}, newSurchage)));
            });

            this.touched = true;
        },

        setAgreement(orderID, event) {
            this.updateOrderEntry(orderID, "orderAgreementID", event);
        },

        updateOrderEntry(orderID, key, value) {
            const index = this.ordersMap[orderID];
            const order = this.orders[index];

            order[key] = value; // does not call watch!

            this.$forceUpdate();
            this.touched = true;
            this.orderChanged(orderID);
        },

        setMedia(orderID, event) {
            this.updateOrderEntry(orderID, "mediaID", event);
        },

        getSurchargeName(surg) {
            const entry = this.surchargesDefinition[surg.surchargeID];
            return entry ? entry.internal_name : surg.surgage;
        },
        changeSurchargeValue (orderId, surchargeID, event) {
            const value = parseInt(event.target.value, 10);
            const orderIndex = this.ordersMap[orderId];
            const order = this.orders[orderIndex];
            order.surcharges[surchargeID].net = value;

            this.getCTCForSurcharge(order, value, surchargeID)
                .then((calculatedValue) => {
                    if(calculatedValue){
                        calculatedValue = parseFloat(calculatedValue);
                    }

                    if(!isNaN(calculatedValue)){
                        order.surcharges[surchargeID].ctc = calculatedValue;
                    }

                    this.orders[orderIndex] = (order);

                    this.touched = true;
                    
                    //this.orders[orderIndex] = recountOrderNetCtc(order);
                    this.$set(this.orders, orderIndex, recountOrderNetCtc(order));

                    this.orderChanged(orderId);
                    this.currentTime = Date.now();
                    this.$forceUpdate();
                })
                .catch((err) => {
                    console.error(err);
                    
                    this.touched = true;

                    this.orderChanged(orderId);
                    this.currentTime = Date.now();
                    this.$forceUpdate();
                });
        },
        updateOrderPlanValue(orderId, key, event) {
            const value = event.target.value;
            const orderIndex = this.ordersMap[orderId];
            const order = this.orders[orderIndex];

            order.mediaPlanInformation[key] = value;

            this.$forceUpdate(); // explicit
            
            this.touched = true;

            this.orderChanged(orderId);
        },

        updateCostsValue(orderId, key, event) {
            var value = 0;
            if(event.target.value){
                value = parseInt(event.target.value, 10);
            }
            const orderIndex = this.ordersMap[orderId];
            const order = this.orders[orderIndex];

            // if(order){

            //     order[key] = value;

            //     //this.orders[orderIndex] = recountOrderNetCtc(order);
            //     this.$set(this.orders, orderIndex, recountOrderNetCtc(order));

            //     this.$forceUpdate(); // explicit
                
            //     this.touched = true;

            //     this.orderChanged(orderId);
            // }

            
            order[key] = value;

            //this.orders[orderIndex] = recountOrderNetCtc(order);
            this.$set(this.orders, orderIndex, recountOrderNetCtc(order));

            this.$forceUpdate(); // explicit
            
            this.touched = true;

            this.orderChanged(orderId);
           
        },

        getCTCForSurcharge(order, netValue, surchargeID){
            if(order && order.mediaID && netValue !== undefined && surchargeID){
                const netToCTC = {
                    calculationType: "net_to_ctc",
                    marathonCountryID: order.marathonCountryID,
                    calculationSurchargeID: surchargeID, 
                    calculationNet: netValue,
                    agreementID: order.orderAgreementID,
                    mediaID: order.mediaID,
                    clientID: order.clientID
                };
                return this.axios
                    .post(this.$constants.apiPaths.planning.calculateAmount, netToCTC)
                    .then(({ data }) => {
                        return data.ctc;
                    })
                    .catch((err) => {
                        const msg = err.response.data.message;
                        console.error(msg);
                    });
            }
            else{
                return Promise.reject("missing values for calculations")
            }
        },

        hideColumn(key) {
            if (this.filterAllowedColumns[0] == "*") return "";

            return this.filterAllowedColumns.includes(key) ? "" : "hide-column";
        },

        buildFilter() {
            const medias = this.mediaChannels.reduce((arr, curr) => {
                arr[curr.mediaChannelID] = curr.mediaChannel;
                return arr;
            }, {});

            this.filterAllowedRows = Object.keys(medias);
            this.filterAllowedRowsMap = medias;
        },
        updateFilterAllowedRows(data) {
            this.filterAllowedRows = data;
        },

        updateFilterAllowedColumns(data) {
            this.filterAllowedColumns = data;
        },

        addNewOrder(mediaObject) {
            const newOrder = Object.assign({}, this.dataModels.order);
            newOrder["startDate"] = dayjs().format("YYYY-MM-DD");
            newOrder["endDate"] = dayjs().format("YYYY-MM-DD");

            this.newOrderStartingID = this.newOrderStartingID - 1;

            newOrder.planID = this.planID;
            newOrder.marathonCountryID = this.marathonCountryID;
            newOrder.company_id = this.marathonCountryID;
            newOrder.orderStatusID = "P";
            newOrder.orderAgreementID = this.plan ? this.plan.planAgreementID : null;
            newOrder.mediaChannel = mediaObject.mediaChannel;
            newOrder.mediaChannelID = mediaObject.mediaChannelID;
            newOrder.media = mediaObject.media;
            newOrder.mediaID = mediaObject.mediaID;
            newOrder.orderID = this.newOrderStartingID;
            newOrder.clientID = this.client.clientID;

            const newSurcharges = this.chosenSurcharges.reduce((arr, curr) => {
                arr[curr.surchargeID] = createSurcharge({
                    surchargeID: curr.surchargeID,
                    surcharge: curr.surcharge,
                });
                return arr;
            }, {});

            newOrder.surcharges = newSurcharges;
            this.orders.unshift(JSON.parse(JSON.stringify(newOrder))); // deep clone

            this.ordersMap = crateOrderArrayMap(this.orders);

            this.touched = true;
        },
        // move to some helper?
        submitOrders(saveNewVersion) {
            this.responses = [];

            var promises = [];
            this.orders.forEach((order, index) => {
                const isNew = order.orderID < 0; // new orders have id lower than zero!
                const isTouched = this.touchedOrders[order.orderID] && !isNew;
                if(isNew || isTouched){
                    promises.push(this.submitOrder(order, index, saveNewVersion));
                }
            });
            Promise.allSettled(promises).then((values) => {
                
                let reload = saveNewVersion;
                if(!saveNewVersion){
                    values.forEach((val) => reload = reload || (val.status === "fulfilled" && val.value === "ok"));
                }
                
                this.$emit('orders-submitted', reload, values);
            });
        },

        submitOrder(order, index, saveNewVersion){
            const axios = this.axios;
            const campaignID = this.$route.params.campaignID;
            //const path =  this.$constants.apiPaths.planning.orderExpressMode;
            let path =  this.$constants.apiPaths.planning.expressOrder(
                order.marathonCountryID,
                order.clientID,
                order.orderID
            );

            const isNew = order.orderID < 0; // new orders have id lower than zero!
            const isTouched = this.touchedOrders[order.orderID] && !isNew;

            const orderEntry = JSON.parse(JSON.stringify(order));

            const parameters = new URLSearchParams();
            parameters.append("saveNewVersion", saveNewVersion)


            if(isNew){
                path = this.$constants.apiPaths.planning.expressOrder();
            }
            // create and array
            // orderEntry.surcharges = Object.keys(orderEntry.surcharges).map((key) => orderEntry.surcharges[key]);

            if(!order.surcharges || Object.keys(order.surcharges).length === 0){
                this.$swal.fire({
                    title: 'Unable to create a new order without any fees',
                    showCloseButton: true,
                    confirmButtonColor: '#059513',
                    confirmButtonText: "Okay, thanks!",
                    customClass: "swal2-popup-dark",
                    timer: 3000,
                });
                return new Promise.reject('Unable to create a new order ' + order.orderID + " without any fees" );
            }
            else{
                if (isNew && campaignID) {
                    return new Promise((resolve, reject) => {
                        axios
                            .post(path, Object.assign({}, orderEntry, { orderID: null }), { params: parameters})
                            .then(({ data }) => {
                                this.responses.push(data);
                                this.orders[index].orderID = data.orderID;

                                this.touched = false;
                                return resolve("ok");
                            })
                            .catch((err) => {
                                const msg = err.response.data.message;

                                // this.$swal.fire({
                                //     title: 'Unable to create a new order:\n' + msg,
                                //     showCloseButton: true,
                                //     confirmButtonColor: '#059513',
                                //     confirmButtonText: "Okay, thanks!",
                                //     customClass: "swal2-popup-dark",
                                //     //timer: 3000,
                                // });
                                
                                return reject('Unable to create a new order ' + order.orderID + ": " +  msg);

                            });
                    });
                } else if(isTouched && campaignID) {
                    return new Promise((resolve, reject) => {
                        axios
                            .put(path, order,  { params: parameters})
                            .then(({ data }) => {
                                this.responses.push(data);
                                this.touched = false;
                                //return resolve(true);
                                return resolve("ok");
                            })
                            .catch((err) => {
                                const msg = err.response.data.message;

                                // this.$swal.fire({
                                //     title: 'Unable to update existing order:\n' + msg,
                                //     showCloseButton: true,
                                //     confirmButtonColor: '#059513',
                                //     confirmButtonText: "Okay, thanks!",
                                //     customClass: "swal2-popup-dark",
                                //     //timer: 3000,
                                // });
                                return reject('Unable to update existing order ' + order.orderID + ": " + msg);
                                //return reject(false);

                            });
                    });
                }
                else{

                    return new Promise.reject("wont save")
                }
            }
            
        },
        getAgreementMedia(agreementID, mediaChannelID) {
            let medias = this.mapAgreementMedias[agreementID] || []
            medias = medias.filter(item => item.mediaChannelID === mediaChannelID);
            return medias;
        },
        getOrderInformation(){
            if(this.marathonCountryID){
                fetchOrderInformation(this.axios, this.marathonCountryID).then((orderInformation) => {
                    this.medias = orderInformation.medias;
                    this.surchargesRaw = orderInformation.surcharges;
                    this.surchargesDefinition = buildSurchargeStructure(orderInformation.surcharges);

                    this.mediaChannels = orderInformation.mediaChannels;

                    if(this.client && this.client.agreements){
                        this.mapAgreementMedias = createAgreementMediaMap(this.client.agreements || [], this.medias, this.marathonCountryID);
                    }
                });
            }
        }
    },
    watch: {
        value:{
            deep: true,
            handler(newVal){
                if(newVal.dataTouched !== undefined){
                    this.touched = newVal.dataTouched;
                }
            }
        },
        totalCTC(newVal){
            this.$emit('update-total-ctc', newVal);
        },
        touched(newValue) {
            this.$emit("input", {
                dataTouched: newValue,
                validSurcharges: this.haveSurcharges
            });
        },
        haveSurcharges(newValue) {
            this.$emit("input", {
                dataTouched: this.touched,
                validSurcharges: newValue
            });
        },
        chosenSurcharges(selected) {
            //add to every order (if not already added)
            if(selected.length > 0){
                this.haveSurcharges = true;
            }
            const choosen = selected.reduce((acc, curr) => {
                acc[curr.surchargeID] = curr;
                return acc;
            }, {});

            const keys = Object.keys(choosen);

            this.orders.forEach((order) => {
                keys.forEach((key) => {
                    if (!order.surcharges[key])
                        order.surcharges[key] = Object.assign({}, { net: 0, ctc: 0 }, choosen[key]);
                });
            });

            // not a nice part :/
            this.headers[0]["FEES"].span = getDefaultHeaders()[0].FEES.span + keys.length;
        },
        orders: {
            deep: true,
            handler(newOrders) {
                let total = 0;
                newOrders.forEach((ord) => {
                    total += ord.ctc;
                });
                this.totalCTC = total;
                const orderSurcharges = [];
                if(newOrders && newOrders.length > 0){
                    newOrders.forEach((order) => {
                        if(order.surcharges){
                            Object.keys(order.surcharges).forEach((key) => {
                                orderSurcharges.push(order.surcharges[key]);
                            });
                        }
                    });
                }

                // these two thins can be merged to one process
                const chosenSurcharges = orderSurcharges.reduce((acc, cur) => {
                    acc[cur.surchargeID] = cur;
                    return acc;
                }, {});

                this.chosenSurcharges = Object.keys(chosenSurcharges)
                    .map((key) => chosenSurcharges[key])
                    .map((sur) => {
                        return {
                            surchargeID: sur.surchargeID,
                            surcharge: sur.surcharge,
                        };
                    });

                if (this.surchargesOrder.length == 0)
                    this.surchargesOrder = this.chosenSurcharges.map((e) => e.surchargeID);

                this.buildFilter();
            },
        },
        sourceOrders(newValue) {
            const ordersTransformed = convertOrders(newValue);
            this.orders = ordersTransformed;
            this.ordersMap = crateOrderArrayMap(ordersTransformed);
        },
        selectedClient(newValue){
            this.client = newValue;
            if(newValue && newValue.agreements && this.medias.length > 0){
                this.mapAgreementMedias = createAgreementMediaMap(newValue.agreements || [], this.medias, this.selectedCountry);
            }
        },
        selectedCountry(newVal){
            this.marathonCountryID = newVal;
            if(newVal){
                this.getOrderInformation();
            }
        },
        marathonCountryID(newVal){
            if(newVal){
                this.getOrderInformation();
            }
        }
    },
    mounted() {
        if(this.selectedClient){
            this.client = this.selectedClient;
        }
        if(this.marathonCountryID){
            this.getOrderInformation();
        }
    },
};
</script>
<style lang="scss">
.add-new-pricerow {
    cursor: pointer;
}
</style>
