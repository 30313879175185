
import constants from "@/constants";

const fetchRecipientsGroups = (axios, type) => {
    const path = constants.apiPaths.notifications.recipientsGroups;
    // if(!type){
    //     type = "external";
    // }

    return axios
        .get(path, {
            params: {
                type: type,
            },
        })
        .then((res) => {
            if(res && res.data){
                return res.data;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            this.handleRequestError(error);
        });
}

export {
    fetchRecipientsGroups
}