<template>
    <div>
        <div class="row">
            <div class="col">
                <router-view
                    :campaigns="campaigns"
                    :client="selectedClient"
                    :userClients="userClients"
                    :country="userSelectedCountry"
                    :loading="loading"
                    :clientsFilter="userSelectedMultipleClients"
                    :permissions="userContent.clientCampaignOverview"
                    v-if="userContent"
                    :getRoute="getRoute"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ClientCountrySelect from "@/components/ClientCountrySelect/ClientCountrySelect.vue";
import { mapActions, mapState } from 'vuex'

export default {
    inject: ['formatCampaignNumbers'],
    props: ["clientSelectOptions", "userClients", 'userContent'],
    data() {
        return {
            campaigns: [],
            loading: false,
            reset: null,
        };
    },
    components: {
        [ClientCountrySelect.name]: ClientCountrySelect,
    },
    methods: {
        // formatCampaignNumbers() {
        //     for (let i = 0; i < this.campaigns.length; i++) {
        //         var net = this.campaigns[i]["net"]
        //         var maxCTC = this.campaigns[i]["maxCTC"]
        //         var ctc = this.campaigns[i]["ctc"]
        //         var currency = this.campaigns[i]["currency"]
        //         var numbers = [{label:"net", value:net}, {label:"maxCTC", value:maxCTC}, {label:"ctc", value:ctc}]

        //         for (var j = 0; j < numbers.length; j++) {
        //             if (numbers[j].value) {
        //                 numbers[j].value = numbers[j].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        //                 if (parseInt(numbers[j].value) > 0) {
        //                     this.campaigns[i][numbers[j].label] = numbers[j].value + " " + currency
        //                 }
        //             } else {
        //                 numbers[j].value = 0;
        //             }
        //         }
        //     }
        // },
        getCampaigns() {
            this.loading = true;

            this.axios(this.$constants.apiPaths.planning.campaigns(), {params:{unfiltered: true}})
                .then((res) => {
                    if (res.data) {
                        this.campaigns = res.data;
                        this.formatCampaignNumbers(this.campaigns);
                    }
                    if (this.$route.params.campaignID) {
                        this.setCampaignNameById(this.$route.params.campaignID, this.$route.params.marathonCountryID, this.$route.params.clientID);
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.handleRequestError(error);
                    this.loading = false;
                });
        },
        getRoute(row, linkType){
            if (linkType === "campaign") {
                return {
                    name: this.$constants.routeNames.campaigns.detail,
                    params: {
                        marathonCountryID: row.marathonCountryID,
                        clientID: row.clientID,
                        campaignID: row.id,
                    },
                };
            }

            if (linkType === "plan") {
                return {
                    name: this.$constants.routeNames.campaigns.planDetail,
                    params: {
                        marathonCountryID: row.marathonCountryID,
                        clientID: row.clientID,
                        campaignID: row.campaignID,
                        planID: row.id,
                    },
                };
            }
        },
        handleRequestError(error) {
            console.error(error);
        },
        setCampaignNameById(campaignID, marathonCountryID, clientID) {
            var campaignList = this.campaigns.filter(element => element.campaignID === campaignID && element.clientID === clientID && element.marathonCountryID === marathonCountryID);
            if (campaignList.length === 0)
                campaignList = this.campaigns.filter(element => element.campaignID === campaignID && element.marathonCountryID === marathonCountryID);
            if (campaignList.length === 0)
                campaignList = this.campaigns.filter(element => element.campaignID === campaignID);
            if(campaignList.length > 0){
                this.updateCampaignName(campaignList[0].campaign);
            }
        },
        goToOverview(){
            if(this.$route.name !== this.$constants.routeNames.campaigns.root){
                this.updateSelectedClient({client:null})
                this.$router.push({ name: this.$constants.routeNames.campaigns.root});
            }
        },
        ...mapActions([
            'updateCampaignName',
            'updateDetailName',
            'clearCampaignName',
            'clearDetailName',
            'updateSelectedClient',
        ]),
    },
    computed:{
        ...mapState([
            'campaignName',
            'detailName',
            'selectedClient',
            'userSelectedCountry',
            'userSelectedMultipleClients'
            
        ]),
    },
    mounted() {
        this.reset = () =>{
            this.goToOverview();
        }
        this.$root.$on('reset-route', this.reset);
        if (this.campaigns.length === 0) {
            this.getCampaigns();
        }
        
    },
    beforeDestroy() {
        this.$root.$off('reset-route', this.reset);
    },
};
</script>
