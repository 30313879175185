<template>
    <div class="nordisk-film-brief-detail-preview">
        <nordisk-film-theatrical-brief-preview vref="form" v-model="campaign" :clientSelectOptions="clientSelectOptions" :countriesOptions="countriesOptions" :steps="steps" :currentStep="currentStep" v-if="clientTheatrical">
            <slot v-for="(_, name) in $slots" :name="name" :slot="name"  />
        </nordisk-film-theatrical-brief-preview>
            <nordisk-film-home-entertaiment-brief-preview vref="form" v-model="campaign" :clientSelectOptions="clientSelectOptions" :countriesOptions="countriesOptions" :steps="steps" :currentStep="currentStep" v-if="clientHomeEntertaiment">
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        </nordisk-film-home-entertaiment-brief-preview>
    </div>
</template>

<script>
import NordiskFilmTheatricalBriefPreview from './Theatrical/NordiskFilmTheatricalBriefPreview.vue';
import NordiskFilmHomeEntertaimentBriefPreview from './HomeEntertaiment/NordiskFilmHomeEntertaimentBriefPreview.vue';

export default {
    name: "nordisk-film-brief-preview",
    model: {
        prop: "campaign",
        event: "input",
    },
    props: ['clientSelectOptions', 'countriesOptions', 'client', 'steps', 'currentStep', 'campaign'],
    methods:{
        onInput(evt) {
            this.$emit("input", this.campaign);
        },
    },
    computed:{
        clientTheatrical(){
            var theatrical = false;
            if(this.campaign !== null){
                for(var i=0; i< this.$constants.nordiskFilmUnits.theatrical.length; i++){
                    var client = this.$constants.nordiskFilmUnits.theatrical[i].clientID === this.campaign.clientID;
                    var country = this.$constants.nordiskFilmUnits.theatrical[i].marathonCountryID ===  this.campaign.marathonCountryID;
                    if(client && country){
                        theatrical = true;
                    }
                }
            }
            return theatrical;
        },        
        clientHomeEntertaiment(){
            var homeEntertaiment = false;
            if(this.campaign !== null){
                for(var i=0; i< this.$constants.nordiskFilmUnits.homeEntertaiment.length; i++){
                    var client = this.$constants.nordiskFilmUnits.homeEntertaiment[i].clientID === this.campaign.clientID;
                    var country = this.$constants.nordiskFilmUnits.homeEntertaiment[i].marathonCountryID ===  this.campaign.marathonCountryID;
                    if(client && country){
                        homeEntertaiment = true;
                    }
                }
            }
            return homeEntertaiment;
        }
    },
    components: {
        [NordiskFilmTheatricalBriefPreview.name]: NordiskFilmTheatricalBriefPreview,
        [NordiskFilmHomeEntertaimentBriefPreview.name]: NordiskFilmHomeEntertaimentBriefPreview,
    }
};
</script>
