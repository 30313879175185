<template>
    <div>
        <div class="row mb-5">
            <div class="col-12">        
                <h3 class="user-admin-header">User Management</h3>
            </div>
            <div class="col-9 user-admin-description">
                <p>Add and edit system users. 
                From the edit menu you can reset password, resend invitation and update metadata.<br>
                Use the groups window to edit user access rights.</p>
            </div>
            <div class="col-3 d-flex justify-content-end">
                    <base-button type="button"
                        class="btn btn-success"
                        @click="createUser"
                        v-b-modal.user-modal>
                            <i class="el-icon-plus"/>
                            <span>New user</span>
                    </base-button>
            </div>
        </div>
        <div class="row">
             <div class="col-12" v-if="isLoadingUsers">
                <div class="spinner" style="margin-top: 56px">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                </div>
                <div class="spinner-text">
                    Loading users
                </div>
            </div>
            <div class="col-12" v-else>
                <div class="planning-tool-table-header">
                    <div class="col-12 d-flex justify-content-start no-gutters">
                        <div class="row">
                            <div class="header-item-wrapper pagination-wrapper" size="sm" icon>
                                <el-select class="select-primary pagination-select" v-model="pagination.perPage" popper-class="pagination-dropdown"
                                    placeholder="Per page">
                                    <el-option class="select-primary" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="header-item-wrapper search-wrapper">
                                <el-input type="search" class="search-input" clearable prefix-icon="el-icon-search"
                                    placeholder="Search" v-model="searchQuery" aria-controls="datatables">
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <el-table :data="queriedData"
                    class="planning-tool-table" 
                    row-class-name="planning-tool-table-row">

                    <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                    :prop="column.prop" :label="column.label" :className="column.prop" :align="column.align">
                    </el-table-column>
                    
                    <el-table-column :min-width="100" fixed="right" align="right" label=" ">
                        <div slot-scope="props">
                            <span>
                                <base-button
                                    @click.native="handleEdit(props.$index)"
                                    v-b-modal.user-modal
                                    class="edit btn-link"
                                    type="warning"
                                    size="sm"
                                    icon>
                                    <i class="tim-icons icon-pencil"></i>
                                </base-button>
                            </span>
                        </div>
                    </el-table-column>

                </el-table>

                <div slot="footer" class="table-footer justify-content-sm-between">
                    <div class="pagination-info">
                    Showing {{ from + 1 }} - {{ to }} (total {{ total }} )
                    </div>
                    <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
                    :total="total">
                    </base-pagination>
                </div>
            </div>
        </div>
        <userModal :postUser="sendUserRequest" :user="user" v-if="user" v-on:close="close"/>
    </div>
</template>

<script>
import UserModal from './UserModal.vue';
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';

import Fuse from 'fuse.js';
import { mapState } from 'vuex'

export default {
    props: ['perPageOptions'],
    data() {
        return {
            search: '',
            user: null,
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
            searchQuery: '',
            propsToSearch: ['countryID', 'clientID', 'client'],
            searchedData: [],
            fuseSearch: null,
            tableColumns: [
                {
                    prop: 'userID',
                    label: 'User ID',
                    minWidth: 250
                },
                {
                    prop: 'name',
                    label: 'Name',
                    minWidth: 140
                },
                {
                    prop: 'email',
                    label: 'Email',
                    minWidth: 190
                },                
                {
                    prop: 'employeeID',
                    label: 'Marathon user',
                    minWidth: 70
                },
                {
                    prop: 'type',
                    label: 'Type',
                    minWidth: 90
                },
                {
                    prop: 'companyName',
                    label: 'Company Name',
                    minWidth: 90
                },
                {
                    prop: 'groups',
                    label: 'User Groups',
                    minWidth: 280
                },
            ]
        };
    },
    components: {
        userModal: UserModal,
        BasePagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    methods: {
        close(){
            //console.log("close")
            this.user = null;
            this.getUsers(true);
        },
        handleEdit(index) {
            var userIndex = ((this.pagination.currentPage-1)*this.pagination.perPage) + index;
      
            if (this.searchedData.length > 0) {
                for (var i in this.searchedData) {
                    if (this.searchedData[i].userID === this.searchedData[userIndex].userID) {
                        for (var k in this.users) {
                            if (this.users[k].userID === this.searchedData[userIndex].userID) {
                                this.user = this.users[k];
                            }
                        }
                    }
                }
            } else {
                for (let i in this.users) {
                    if (this.users[i].userID === this.users[userIndex].userID) {
                        this.user = this.users[i];
                    }
                }
            }
        },
        createUser(){
            this.user = JSON.parse(JSON.stringify(this.userDataModel));
        },
        // editUser(userID){
        //     if(userID){
        //         for(var i in this.users){
        //             if(this.users[i].userID == userID){
        //                 this.user = this.users[i];
        //                 break;
        //             }
        //         }
        //     }
        // },
        // setUser(userID) {
        //     if (userID === undefined) {
        //         this.user = JSON.parse(JSON.stringify(this.userDataModel));
        //     } else {
        //         for(var i in this.users){
        //             if(this.users[i].userID == userID){
        //                 this.user = this.users[i];
        //                 break;
        //             }
        //         }
        //     }
        // },
        getUsers(force){
            // console.log("getUsers")
            // console.log(force)
            this.$store.dispatch("getUsers", [this.axios, force]);
        },
        sendUserRequest(user) {
            const path = this.$constants.apiPaths.administration.users;
            const payload = user;
            if(user.userID==null){
                this.axios.post(path, payload)
                    .then((res) => {
                        this.getUsers(true);
                        //document.querySelector('#user-modal').classList.remove("show")
                        this.$swal.fire(
                            'User has been saved',
                            'A new user has been created with name: ' + this.user.name,
                            'success').then(() => {
                            this.close();
                        });
                    })
                    .catch((error) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Operation failed',
                            text: error?.response?.data?.message,
                        });
                        console.error(error);
                    });
            } else {
                this.axios.put(path, payload)
                    .then((res) => {
                        this.getUsers(true);
                        this.$swal.fire(
                            'User has been saved',
                            this.user.name + ' has been saved',
                            'success').then(() => {
                            this.close();
                        });
                    })
                    .catch((error) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Operation failed',
                            text: error?.response?.data?.message,
                        });
                        console.error(error);
                    });
            }
        },

    },
    computed: {
        userDataModel(){
            if(this.dataModels && this.dataModels.user){
                return this.dataModels.user;
            }
            return null;
        },
        ...mapState([
            'dataModels',
            'users',
            'groups',
            'isLoadingUsers',
        ]),
        queriedData() {
            let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        },
        tableData: {
            cache: false,
            get() {
                var tableData = [];
                if(this.users){
                    for (var i = 0; i < this.users.length; i++) {
                        let user = JSON.parse(JSON.stringify(this.users[i]))

                        if(this.groups && this.groups.length > 0){
                            var groupsMap = {};
                            for (var k = 0; k < this.groups.length; k += 1) {
                                groupsMap[this.groups[k]._id] = this.groups[k].groupName;
                            }
                            user.groups = []
                            for(var j = 0; j < this.users[i].groupIDS.length; j++){
                                const groupName = groupsMap[this.users[i].groupIDS[j]];
                                if(groupName){
                                    user.groups.push(groupName);
                                    if(j !== (this.users[i].groupIDS.length-1)){
                                        user.groups.push(", ");
                                    }
                                }
                            }
                        }
                        tableData.push(user);
                    }
                }
                return tableData;
            },
        },
        fuseSearchSetup(){
            return {
                keys: ['name', 'email', 'companyName', 'employeeID'],
                threshold: 0.0
            }
        },
    },
    mounted() {
        this.fuseSearch = new Fuse(this.tableData, this.fuseSearchSetup);
        this.getUsers();
    },
    watch: {
        searchQuery(value) {
            let result = this.tableData;
            if (value !== '') {
                result = this.fuseSearch.search(this.searchQuery).map(x => x.item);
            }
            this.searchedData = result;
            this.fuseSearch = new Fuse(this.tableData, this.fuseSearchSetup);
        },
        tableData() {
            let result = this.tableData;
            if (this.searchQuery !== '') {
                result = this.fuseSearch.search(this.searchQuery).map(x => x.item);
            }
            this.searchedData = result;

            this.fuseSearch = new Fuse(this.tableData, this.fuseSearchSetup);
        },
    }
};
</script>