<template>
    <div>
        <div class="row card-row">
            <el-tooltip placement="right" :disabled="userSelectedClient !== null">
                <div slot="content">
                    <!-- <h4>Please select unit and market</h4>
                    You have to choose a business unit and a market before you can create a campaign brief.<br />
                    Click on the drop down next to “All” in the unit and country pane to select a business
                    unit.<br />
                    Click on the country flags to select a market. -->
                     <h4>Please select unit</h4>
                    You have to choose a business unit before you can create a campaign brief.<br />
                    Click on the drop down next to “All” in the unit  to select a business
                    unit.
                </div>
                <cta-card icon="/img/icons/icon-edit.svg" :isLink="true" title="Create campaign brief" v-on:click="createBrief" class="home briefs"
                    :dont-resize="true" :disabled="userSelectedClient === null">
                    <template #title>Create<br>campaign brief</template>
                </cta-card>
            </el-tooltip>
        </div>
        <div class="row">
            <div class="col col-lg-6 col-md-12 mb-3 mb-lg-0">
                <brief-list title="Briefs In Progress" :table-data="briefsInProgress" :table-columns="inProgressBriefsColumns" @open-detail="openEdit"
                    :default-sort="inProgressSort" link-type="edit" :getRoute="getRoute" />
            </div>
            <div class="col col-lg-6 col-md-12">
                <brief-list title="Briefed" :table-data="briefsSubmitted" :table-columns="submittedBriefsColumns" @open-detail="openPreview"
                    :default-sort="submittedSort" link-type="preview" :getRoute="getRoute"/>
            </div>
        </div>
    </div>
</template>

<script>
import CTACard from "@/components/Cards/CTACard.vue";
import BriefList from "@/components/Briefs/BriefList.vue";
import ClientCountrySelect from "@/components/ClientCountrySelect/ClientCountrySelect.vue";
import { mapGetters } from 'vuex'

export default {
    props: ["getRoute", "briefs", "client", 'countryID', "multipleClients"],
    data() {
        return {
            campaign: {},
            inProgressBriefsColumns: [
                {
                    minWidth: 140,
                    prop: "campaign",
                    label: "Title",
                },
                {
                    minWidth: 75,
                    width: 75,
                    prop: "countryID",
                    label: "Market",
                },
                {
                    minWidth: 65,
                    width: 65,
                    prop: "clientID",
                    label: "Unit",
                },
                {
                    minWidth: 95,
                    width: 95,
                    prop: "lastEdited",
                    label: "LAST EDIT",
                },
                {
                    minWidth: 105,
                    width: 105,
                    prop: "created",
                    label: "DATE CREATED",
                },
                {
                    minWidth: 80,
                    width: 80,
                    prop: "editedBy",
                    label: "Edited by",
                    displayAsIcon: true,
                },
            ],
            submittedBriefsColumns: [
                {
                    minWidth: 170,
                    prop: "campaign",
                    label: "Title",
                },               
                {
                    minWidth: 75,
                    width: 75,
                    prop: "countryID",
                    label: "Market",
                },
                {
                    minWidth: 65,
                    width: 65,
                    prop: "clientID",
                    label: "Unit",
                },
                {
                    minWidth: 100,
                    width: 100,
                    prop: "submitted",
                    label: "DATE BRIEFED",
                },
                {
                    minWidth: 105,
                    width: 105,
                    prop: "created",
                    label: "DATE CREATED",
                },
                {
                    minWidth: 100,
                    width: 100,
                    prop: "submittedBy",
                    label: "Submitted by",
                    displayAsIcon: true,
                },
            ],
        };
    },
    methods: {
        closeBrief(briefs) {
            this.$router.push({ path: "/briefs" });
        },
        handleRequestError(error) {
            console.error(error);
        },
        createBrief(){
            if(this.userSelectedClient && this.userSelectedClient.clientID && this.userSelectedClient.marathonCountryID){
                this.$router.push({ name: this.$constants.routeNames.briefs.edit, params: { marathonCountryID: this.userSelectedClient.marathonCountryID, clientID: this.userSelectedClient.clientID}});
            }
        },
        openEdit(row){
            this.$emit('open-detail', row, 'edit');
        },
        openPreview(row){
            this.$emit('open-detail', row, 'preview');
        },
        goToOverview(){
            if(this.$route.name !== this.$constants.routeNames.briefs.root){
                this.$router.push({ name: this.$constants.routeNames.briefs.root});
            }
        },
    },
    computed:{
        filteredBriefs(){
            var filteredBriefs = [];
            var dataTest = function () {};
            if (this.multipleClients && this.multipleClients.length > 0 && this.countryID) {
                dataTest = function (item, marathonCountryID, clientIdsList) {
                    return clientIdsList.some(idFromList => item.clientID === idFromList && item.marathonCountryID === marathonCountryID);
                }
            } else if (this.multipleClients && this.multipleClients.length > 0) {
                dataTest = function (item, marathonCountryID, clientIdsList) {
                    return clientIdsList.some(idFromList => item.clientID === idFromList);
                }
            }
            else if (this.countryID) {
                dataTest = function (item, marathonCountryID, clientIdsList) {
                    return item.marathonCountryID === marathonCountryID;
                }
            } else {
                return this.briefs;
            }
            
            for (var i = 0; i < this.briefs.length; i++) {
                if (dataTest(this.briefs[i], this.countryID, this.multipleClients)) {
                    filteredBriefs.push(this.briefs[i])
                }
            }
            return filteredBriefs;
        },
        briefsInProgress(){
            var briefsInProgress = [];
            for (var i = 0; i < this.filteredBriefs.length; i++) {
                if (this.filteredBriefs[i].status == this.$constants.campaignStates.BRIEF_IN_PROGRESS.value ) {
                    briefsInProgress.push(this.filteredBriefs[i]);
                }
            }
            return briefsInProgress;
        },
        briefsSubmitted(){
            var briefsSubmitted = [];
            for (var i = 0; i < this.filteredBriefs.length; i++) {
                if (this.filteredBriefs[i].briefStatus == this.$constants.campaignStates.BRIEFED.value) {
                    briefsSubmitted.push(this.filteredBriefs[i]);
                }
            }
            return briefsSubmitted;
        },
        submittedSort(){
            return {
                prop: "submitted",
                order: "descending",
            };
        },
        inProgressSort(){
            return {
                prop: "lastEdited",
                order: "descending",
            };
        },
        ...mapGetters([
            'userSelectedClient',
        ]),
    },
    components: {
        [BriefList.name]: BriefList,
        [CTACard.name]: CTACard,
        [ClientCountrySelect.name]: ClientCountrySelect,
    },
};
</script>