import dayjs from "dayjs";

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import WeekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from 'dayjs/plugin/isoWeek';

import { repeatIterate } from "./helper";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(WeekOfYear);
dayjs.extend(isoWeek)


const dayLetter = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];



/**
 * @param {object[]} orderItems
 */
const getBorderDates = (orderItems) => {
    let startDate = dayjs();
    let endDate = dayjs("1970-01-01");

    orderItems.forEach((order) => {
        const sd = dayjs(order.startDate);
        const ed = dayjs(order.endDate);

        if (sd.isSameOrBefore(startDate)) startDate = sd;

        if (ed.isSameOrAfter(endDate)) endDate = ed;
    });

    return {
        startDate: startDate.subtract(1, "w"),
        endDate: endDate.add(1, "w"),
    };
}

const createDaysArray = function (startDate, endDate, order) {
    const orderStartDate = dayjs(order.startDate);
    const orderEndDate = dayjs(order.endDate);

    const days = [];

    let date = startDate;

    while (date.isSameOrBefore(endDate)) {
        let status = 0;

        if (date.isBefore(orderStartDate) || date.isAfter(orderEndDate)) status = 0;
        if (date.isSameOrAfter(orderStartDate) && date.isSameOrBefore(orderEndDate)) status = 1;
        if (date.isSame(orderStartDate) || date.isSame(orderEndDate)) status = 2;

        days.push(status);

        date = date.add(1, "day");
    }

    return days;
}

const getDaysStructure = (startDate, endDate) => {
    const struct = [];
    const tmp = {};

    const sd = dayjs(startDate);
    const ed = dayjs(endDate);

    const getDayLabel = repeatIterate(dayLetter, sd.day());

    let cd = sd;

    while (cd.isSameOrBefore(ed)) {
        const m = cd.month() + 1; // zero-indexed
        const w = cd.isoWeek();

        const m_key = "m_" + m;
        const w_key = "w_" + w;

        const month = tmp[m_key] ? tmp[m_key] : { year: 0, month: {}, weeks: {}, labels: [], items: 0 };
        const week = month.weeks[w_key] ? month.weeks[w_key] : [];
        const labels = month.labels ? month.labels : [];

        week.push(cd.date());
        labels.push(getDayLabel());

        month.month.number = m;
        month.weeks[w_key] = week;
        month.year = cd.year();
        month.items += 1;

        cd = cd.add(1, "d");

        tmp[m_key] = month;
    }

    Object.keys(tmp).forEach((key) => {
        const entry = tmp[key];

        const fd = dayjs()
            .year(entry.year)
            .month(entry.month.number - 1)
            .date(1);

        entry.month.name = fd.format("MMMM");

        struct.push(entry);
    });

    return struct;
}



export {
    getBorderDates,
    createDaysArray,
    getDaysStructure,
}