<template>
    <div>
        <div class="row mb-5">
            <div class="col-12">        
                <h3>Client Management</h3>
            </div>
            <div class="col-9">
                <p>orem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget quam odio.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id sapien auctor, rutrum tellus eget,
                    scelerisque nisl. Interdum et malesuada fames ac ante ipsum primis in faucibus. </p>
            </div>
                <div class="col-3 d-flex justify-content-end">
                <!-- <base-button type="button"
                    class="btn btn-success"
                    @click="setGroup"
                    v-b-modal.group-modal>
                        <i class="el-icon-plus"/>
                        <span>New group</span>
                </base-button> -->
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="isLoadingClients">
                <div class="spinner" style="margin-top: 56px">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                </div>
                <div class="spinner-text">
                    Loading users
                </div>
            </div>
            <div class="col-12" v-else>
                <div class="planning-tool-table-header">
                    <div class="col-12 d-flex justify-content-start no-gutters">
                        <div class="row">
                            <div class="header-item-wrapper pagination-wrapper" size="sm" icon>
                                <el-select class="select-primary pagination-select" v-model="pagination.perPage"
                                    popper-class="pagination-dropdown" placeholder="Per page">
                                    <el-option class="select-primary" v-for="item in perPageOptions"
                                        :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="header-item-wrapper search-wrapper">
                                <el-input type="search" class="search-input" clearable prefix-icon="el-icon-search"
                                    placeholder="Search" v-model="searchQuery" aria-controls="datatables">
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>


                <el-table :data="queriedData" class="planning-tool-table" row-class-name="planning-tool-table-row">

                    <el-table-column v-for="column in tableColumns" :key="column.label"
                        :min-width="column.minWidth" :prop="column.prop" :label="column.label" :className="column.prop"
                        :align="column.align">
                    </el-table-column>

                    <el-table-column :min-width="100" fixed="right" align="right" label=" ">
                        <div slot-scope="props">
                            <span>
                                <base-button @click.native="handleEdit(props.$index)" v-b-modal.client-modal
                                    class="edit btn-link" type="warning" size="sm" icon><i
                                        class="tim-icons icon-pencil"></i></base-button>
                            </span>
                        </div>
                    </el-table-column>

                </el-table>

                <div slot="footer" class="table-footer justify-content-sm-between">
                    <div class="pagination-info">
                        Showing {{ from + 1 }} - {{ to }} (total {{ total }} )
                    </div>
                    <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                        :per-page="pagination.perPage" :total="total">
                    </base-pagination>
                </div>
            </div>
        </div>

        <clientModal :client="client" />
    </div>
</template>

<script>
//import axios from 'axios';
import config from '@/config';
//import swal from 'sweetalert2';
import ClientModal from './ClientModal.vue';
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';

import Fuse from 'fuse.js';
import { mapState } from 'vuex'

export default {
    props: ['perPageOptions'],
    data() {
        return {
            search: '',
            client: '',
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
            searchQuery: '',
            propsToSearch: ['marathonCountryID', 'clientID', 'client'],
            searchedData: [],
            fuseSearch: null,
            tableColumns: [
                {
                    prop: 'clientID',
                    label: 'Client ID',
                    minWidth: 180
                },
                {
                    prop: 'marathonCountryID',
                    label: 'Country ID',
                    minWidth: 180
                },
                {
                    prop: 'client',
                    label: 'Name',
                    minWidth: 140
                },
            ],
        };
    },
    components: {
        clientModal: ClientModal,
        BasePagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    methods: {
        // capitalizeFirstLetter(string) {
        //     return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        // },
        handleEdit(index) {
            var clientIndex = ((this.pagination.currentPage-1)*this.pagination.perPage) + index;
            this.client = this.clients[clientIndex];
            if (this.searchedData.length > 0) {
                for (var i in this.searchedData) {
                    if (this.searchedData[i]._ === this.searchedData[clientIndex]._id) {
                        for (var k in this.clients) {
                            if (this.clients[k]._id === this.searchedData[clientIndex]._id) {
                                this.client = this.clients[k];
                            }
                        }
                    }
                }
            } else {
                for (var i in this.clients) {
                    if (this.clients[i].clientID === this.clients[clientIndex].clientID &&
                    this.clients[i].marathonCountryID === this.clients[clientIndex].marathonCountryID) {
                        this.client = this.clients[i];
                    }
                }
            }
        },
        setClient(_id) {
            if (_id === undefined) {
                this.client = JSON.parse(JSON.stringify(this.clientDataModel));
            } else {
                for(var i in this.clients){
                    if(this.clients[i]._id == _id){
                        this.client = this.clients[i];
                        break;
                    }
                }
            }
        },
        sendClientRequest(client) {
            const path = config.apiUrl.administration.clients;
            const payload = client;
            if(client._id==null){
                console.log("Nothing to post, clientID is null")
            } else {
                this.axios.put(path, payload)
                    .then((res) => {
                        this.getClients(true);
                        this.$swal.fire(
                            'Client has been saved',
                            this.client.client + ' has been saved',
                            'success').then(() => {
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        getClients(force) {            
            this.$store.dispatch("getClients", [this.axios, force]);
        },
    },
    computed: {
        filteredActiveColumns(){
            return this.activeTableColumns.filter(element => element.prop != 'status');
        },
        queriedData() {
            let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        },
        ...mapState([
            'clients',
            'isLoadingClients'
        ]),

        tableData(){
            var tableData = [];
            if(this.clients){
                for (var i = 0; i < this.clients.length; i++) {
                    var length = this.clients[i].mediaChannelResponsible.length;
                    tableData.push({
                        client: this.clients[i].client,
                        clientID: this.clients[i].clientID,
                        marathonCountryID: this.clients[i].marathonCountryID,
                        mediaChannelResponsible: length 
                                        + " user" 
                                        + (length > 1 ? "s" : "")
                                        + (length === 0 ? "s" : "")

                    });
                }
            }
            return tableData;
        },
    },
    created() {
    },
    mounted() {
        this.fuseSearch = new Fuse(this.tableData, {
            keys: ['countryID', 'clientID', 'client'],
            threshold: 0.0
        });
    },
    watch: {
        searchQuery(value) {
            let result = this.tableData;
            if (value !== '') {
                result = this.fuseSearch.search(this.searchQuery).map(x => x.item);
            }
            this.searchedData = result;

            this.fuseSearch = new Fuse(this.tableData, {
                keys: ['name', 'email'],
                threshold: 0.0
            });
        },
    }
};
</script>