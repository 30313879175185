<template>
    <div class="live-dashboards direct-buys-pacing">
        <portal to="page-title_action" class="page-title_action">
            <template v-if="campaignName">
                <el-tooltip content="Download as PDF" placement="top">
                    <img class="download-icon icon" :src="'/img/icons/icon-download.svg'" v-on:click="downloadAsPDF()" alt="Download as PDF" />
                </el-tooltip>
            </template>
        </portal>
         <div class="row" :ref="cardsDefinitions.overview.ref">
            <div class="col d-flex">
                <cta-card icon="/img/icons/icon-user.svg" :title="detailName" subtitle="Format" class="title" />
                <cta-card icon="/img/icons/icon-target.svg" :title="formatFullNumber(data.bookedUnits) + ' ' + data.unitType" subtitle="Booked units" class="big"/>
                <cta-card icon="/img/icons/icon-eye.svg" :title="formatFullNumber(data.impressions)" subtitle="Impressions" class="bigger"/>
                <!-- <cta-card icon="/img/icons/icon-money.svg" :title="formatFullNumber(data.spend)" subtitle="Spend" /> -->
                <cta-card icon="/img/icons/icon-click.svg" :title="formatFullNumber(data.clicks)" subtitle="Clicks" class="bigger"/>
                <cta-card icon="/img/icons/icon-cpc.svg" :title="data.ctr" subtitle="CTR" class="bigger"/>
            </div>
        </div>
        <div class="row no-inside-gutter">
            <div class="col col-12 col-lg-6" :ref="cardsDefinitions.dailyPacing.ref">
                <styled-bar-chart  :title="cardsDefinitions.dailyPacing.title" :as-card="true" :chart-data="cardsDefinitions.dailyPacing.chartData" :help="cardsDefinitions.dailyPacing.info" />
            </div>
            <div class="col col-12 col-lg-6"  :ref="cardsDefinitions.placement.ref">
                <universal-list :title="cardsDefinitions.placement.title" :table-data="cardsDefinitions.placement.data" :table-columns="cardsDefinitions.placement.columns"
                :list-classes="['dashboard-list']" :showStatus="false" :showClickable="false" :help="cardsDefinitions.placement.info" />
            </div>
        </div>
    </div>
</template>

<script>
import CTACard from '@/components/Cards/CTACard.vue'
import UniversalList from '@/components/UniversalList/UniversalList.vue'
import StyledBarChart from '@/components/StyledBarChart/StyledBarChart.vue'
import { mapState } from 'vuex'
import { generatePDF } from '@/helpers/pdf.helpers.js'

export default {
    name: 'direct-buys-detail',
    props: ['data'],
    inject: ['formatLargeNumber', 'formatFullNumber', 'currencyFormatter'],
    data() {
        return {
            clientID: null,
            marathonCountryID: null,
            campaignID: null,
            pacingType: null,
            siteID: null,
            insertionOrderID: null,
            dcmCampaignID: null,
        }
    },
    mounted(){
        this.handleRoutes();
    },
    computed:{
        currency(){
            if(this.$route.params.marathonCountryID){
                return this.$route.params.marathonCountryID.split("M")[1] + 'K';
            }
            return 'DKK';
        },
        cardsDefinitions(){
            return {
                overview: {
                    ref: "overview",
                    title: "Overview",
                    info: null
                },
                dailyPacing: {
                    ref: "dailyPacing",
                    title: "Daily pacing",
                    info: "Booked units compared with realized units. The data is extracted from Doubleclick Campaign Manager.",
                    chartData: this.chartData,
                },
                placement: {
                    ref: "placement",
                    title: "Placement",
                    info: "Performance pr. placment on the chosen site.",
                    columns: this.lineItemsColumns,
                    data: this.placements,
                },
            };
        },
        chartData() {
            var bookedUnits = [];
            var realizedUnits = [];
            var labels = [];

            if(this.data.timeline){
                for(var i = 0; i < this.data.timeline.length; i++){
                    var date = this.data.timeline[i].date;
                    labels.push(date);
                    realizedUnits.push(this.data.timeline[i].realizedUnits);
                    bookedUnits.push(this.data.timeline[i].bookedUnits);
                }
            }
            return  {
                labels: labels,
                datasets: [
                    {
                        label: "Actual impressions",
                        backgroundColor: '#2A34FF',
                        hoverBackgroundColor: '#2A34FF',
                        data: realizedUnits,
                    }, 
                    {
                        label: "Planned impressions",
                        backgroundColor: '#626D80',
                        hoverBackgroundColor: '#626D80',
                        data: bookedUnits,
                    },
                   
                ]
            }
        },
        lineItemsColumns(){
            return [{
                minWidth: 200,
                prop: 'packageOrRoadblock',
                label: 'Placements'
            },
            {
                minWidth: 100,
                prop: 'impressions',
                label: 'IMPRESSIONS'
            },
            {
                minWidth: 100,
                prop: 'clicks',
                label: 'CLICKS'
            },
            {
                minWidth: 100,
                prop: 'ctr',
                label: 'CTR'
            }]
        },
        placements(){
            var placements = [];
            if(this.data.placements){
                for(var i = 0; i < this.data.placements.length; i++){
                    placements.push(this.data.placements[i]);
                    placements[i].impressions = this.formatFullNumber(this.data.placements[i].impressions);
                    placements[i].clicks = this.formatFullNumber(this.data.placements[i].clicks);
                    placements[i].ctr = this.formatFullNumber(this.data.placements[i].ctr) + "%";
                }

            }
            return placements;
        },
        ...mapState([
            'detailName',
            'campaignName',
        ]),
    },
    methods: {
        downloadAsPDF(cardDefinition) {
            this.$swal.fire({
                title: 'Generating PDF',
                text: 'Creating export file',
                allowOutsideClick: false,
                showConfirmButton: false,
                backdrop: false
            });

            if(cardDefinition){
                generatePDF([cardDefinition], this).then(
                    (result) => { 
                        this.$swal.close();
                    },
                    (error) => { 
                        console.error(error);
                        this.$swal.close();
                    }
                );
            }
            else{
                generatePDF(Object.values(this.cardsDefinitions), this).then(
                    (result) => { 
                        this.$swal.close();
                    },
                    (error) => { 
                        console.error(error);
                        this.$swal.close();
                    }
                );
            }
        },
        handleRequestError(error) {
            console.error(error);
        },
        handleRoutes(){
            if (this.$route.params.campaignID) {
                this.campaignID = this.$route.params.campaignID;
            }
            if (this.$route.params.clientID) {
                this.clientID = this.$route.params.clientID;
            }
            if (this.$route.params.marathonCountryID) {
                this.marathonCountryID = this.$route.params.marathonCountryID;
            }
            if (this.$route.params.type) {
                this.pacingType = this.$route.params.type;
            }
            if (this.$route.params.insertionOrderID) {
                this.insertionOrderID = this.$route.params.insertionOrderID;
            }
            if (this.$route.params.siteID) {
                this.siteID = this.$route.params.siteID;
            }
            if (this.$route.params.dcmCampaignID) {
                this.dcmCampaignID = this.$route.params.dcmCampaignID;
            }
        },
    },
    components: {
        [StyledBarChart.name]: StyledBarChart,
        [UniversalList.name]: UniversalList,
        [CTACard.name]: CTACard,
    },
};
</script>

<style lang="scss" scoped>

.download-icon{
    cursor: pointer;    
    position: relative;
    bottom: 5px;
}
</style>
