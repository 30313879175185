<template>
    <div class="brief-detail">
        <div class="row">
            <div class="col">
                <nordisk-film-brief-edit vref="form" :eventBus="eventBus" v-if="campaign" v-model="campaign" :steps="steps" :currentStep="currentStep"
                    :clientSelectOptions="clientSelectOptions" :countriesOptions="countriesOptions"
                    @form-validation="formValidation">
                    <template v-slot:controls>
                        <div class="row">
                            <div class="col d-flex justify-content-end control-row">
                                <base-button v-show="!briefed" :type="campaignTouched ? '' : 'neutral'" size="regular" v-on:click="manualSave"
                                    class="btn-save">Save draft</base-button>
                                <base-button :style="{opacity: currentStep > 0 ? '1' : '0', cursor: currentStep > 0 ? 'pointer' : 'default'}" :disable="currentStep > 0"
                                    v-on:click="previousPage" size="sm" class="btn-prev btn-half">
                                    <i class="tim-icons icon-double-left" aria-hidden="true"></i>
                                    Prev
                                </base-button>
                                <base-button :style="{opacity: currentStep < 4 ? '1' : '0'}" v-show="currentStep != 4" v-on:click="nextPage"
                                    class="btn-next">
                                    Next
                                    <i class="tim-icons icon-double-right" aria-hidden="true"></i>
                                </base-button>
                                <base-button v-show="currentStep == 4 && !briefed" @click="submitBrief" type="success">Submit brief to
                                    agency</base-button>
                            </div>
                        </div>
                    </template>
                </nordisk-film-brief-edit>
                <!--<publicis-brief-detail v-else-if="campaign" :campaign="campaign"/>-->
            </div>
        </div>
    </div>
</template>

<script>
import NordiskFilmBriefEdit from './NordiskFilm/NordiskFilmBriefEdit.vue';

export default {
    props: ['clientSelectOptions', 'countriesOptions', 'steps', 'currentStep', 'campaign', 'eventBus', "campaignTouched"],
    data: function () {
        return {
            isFormValid: false,
            submitting: false,
        }
    },
    mounted() {
        this.eventBus.$on("form-validation", this.formValidation);
        this.eventBus.$on("campaign-data-touched",  this.touchCampaign);
        this.eventBus.$on("step-update",  this.autosave);
    },
    beforeDestroy() {
        this.eventBus.$off("form-validation", this.formValidation);
        this.eventBus.$off("campaign-data-touched", this.touchCampaign);
        this.eventBus.$off("step-update",  this.autosave);
    },
    computed: {
        briefed() {
            return this.campaign !== null && this.campaign.briefStatus === this.$constants.campaignStates.BRIEFED.value;
        },
        autosaveKeys() {
            return ['campaign', 'clientID', 'marathonCountryID']; 
        },
    },
    watch:{
        briefed(newVal) {
            if(newVal){
                this.$router.replace({
                    name: this.$constants.routeNames.briefs.preview,
                    params: {
                        marathonCountryID: this.campaign.marathonCountryID,
                        clientID: this.campaign.clientID,
                        campaignID: this.campaign.campaignID,
                    },
                });
            }
        },
        // campaignTouched(newVal){
        //     console.log("campaignTouched: " + newVal)
        // }
    },
    beforeRouteUpdate(to, from, next) {
        this.handleRouteUpdate(to, from, next);
    },
    beforeRouteLeave(to, from, next) {
        this.handleRouteLeave(to, from, next);
    },
    methods: {
        handleRouteUpdate(to, from, next) {
            if(this.campaignTouched && !this.submitting && this.campaign.campaignID && to.path === from.path){
                this.autosave({fromID: from.params.campaignID}).then(() => {
                    //this.eventBus.$emit("reload-campaigns");
                    next();
                }, reason => {
                    this.eventBus.$emit('set-step', 0);
                });
                // this.autosave();
                // next();
                // this.eventBus.$emit("reload-campaigns");
            }
            else{
                next();
            }
        },
        handleRouteLeave(to, from, next) {
            if(this.campaignTouched && !this.submitting && to.path !== from.path){
                const that = this;
                this.showSaveAlertDialog().then((result) => {
                    if (result.isConfirmed) {
                        that.autosave({fromID: from.params.campaignID}).then(() => {
                            that.eventBus.$emit("reload-campaigns");
                            next();
                        }, reason => {
                            this.eventBus.$emit('set-step', 0);
                        });
                    } else if (result.isDenied) {
                        next();
                    }
                });
            
            }
            else{
                next();
            }
        },
        showSaveAlertDialog(){
            return this.$swal.fire({
                title: 'Remember to save your changes',
                html: "<p class=\"mb-4\">Your unsaved changes will be lost if you navigate to another page.</p>",
                
                showDenyButton: true,
                showCancelButton: true,
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonText: `Save & Continue`,
                cancelButtonText: `Cancel`,
                denyButtonText: `Ignore changes`,
                confirmButtonColor: '#376EE2',
                denyButtonColor: '#333333',
                cancelButtonColor: '#333333',
                customClass: {
                    popup: "swal2-popup-dark narrow-width",
                    confirmButton: 'btn btn-round order-3',
                    denyButton: 'btn btn-round mr-3 btn-gray order-1',
                    cancelButton: 'btn btn-round mr-3 btn-gray order-2',
                },
            }).then((result) => {
                return result;
            });
        },
        manualSave(){
            this.saveCampaign({doNotReloadCampaigns: true, confirmDialog: true});
        },
        touchCampaign(){ 
            this.campaignTouched = true;
        },
        autosave(config) {
            if(this.campaignTouched){                
                const autosaveValid = this.checkAutosave();
                if (autosaveValid) {
                    let autosaveConfig = {
                        doNotReloadCampaigns: true,
                        doNotShowDialog: true
                    };
                    if(config && !config.fromID){
                        autosaveConfig.doNotRedirectNew = true;
                    }
                    return this.saveCampaign(autosaveConfig);
                } else {
                    this.$swal.fire({
                        title: 'Cannot autosave',
                        icon: 'error',
                        allowOutsideClick: false,
                        text: "Please fill mandatory fields"
                    }).then();
                    //this.eventBus.$emit('set-step', 0);
                    console.warn("cannot save without brief name");
                    return Promise.reject("cannot save without brief name");
                }
            }
            //return Promise.reject();
            return Promise.resolve();
        },
        nextPage() {
            if (this.currentStep < 4) {
                this.eventBus.$emit('next-page');
            }
        },
        previousPage() {
            if (this.currentStep > 0) {
                this.eventBus.$emit('previous-page');
            }
        },
        formValidation(valid){
            if(!valid){
                this.formInvalid();
            }
            this.isFormValid = valid;
        },
        formInvalid() {
            this.$swal.fire({
                title: 'Form invalid',
                icon: 'error',
                allowOutsideClick: false,
                text: "Please check all required fields",
                customClass: "swal2-popup-dark min-width-400",
            });
        },
        handleRequestError(error) {
            console.error(error);
        },
        submitBrief() {
            this.eventBus.$emit('checkForm');
            if(this.isFormValid){

                this.$swal.fire({
                    title: 'Are you sure you want to submit brief to agency?',
                    showCancelButton: true,
                    reverseButtons: true,
                    showCloseButton: true,
                    confirmButtonColor: '#059513',
                    cancelButtonColor: '#333',
                    confirmButtonText: 'Yes, submit',
                    cancelButtonText: "No, not yet",
                    customClass: "swal2-popup-dark min-width-400",
                }).then((result) => {

                    const agreed = result.value;
                    this.submitting = agreed;

                    if (agreed) {
                        this.campaign.briefStatus = this.$constants.campaignStates.BRIEFED.value;
                        const that = this;
                        const saveConfig = {
                            doNotReloadCampaigns: false,
                            doNotShowDialog: false,
                            redirectToList: true,
                            submit: true
                        };

                        this.saveCampaign(saveConfig).then(()=>{
                            that.$swal.fire({
                                imageUrl: "/img/big_green_check.png",
                                title: '<span>Brief submitted to agency</span>',
                                showCloseButton: true,
                                showConfirmButton: false,
                                customClass: "swal2-popup-dark min-width-400",
                                timer: 1500,
                            });
                        });
                        

                    }
                })
            }

        },
        errorDialog(errorText){
            this.$swal.fire({
                title: 'Operation failed',
                icon: 'error',
                allowOutsideClick: false,
                text: errorText,
            });
        },
        checkAutosave(){
            let autosaveValid = true;
                
            this.eventBus.$emit('checkAutosave', this.autosaveKeys);
            for (var i = 0; i < this.autosaveKeys.length; i++) {
                if (!this.campaign[this.autosaveKeys[i]] || !this.campaign[this.autosaveKeys[i]].length > 0) {
                    autosaveValid = false;
                }
            }
            return autosaveValid;
        },
        saveCampaign(config) {
            const defaultConfig = {
                doNotReloadCampaigns: false,
                doNotShowDialog: false,
                redirectToList: false,
                doNotRedirectNew: false,
                submit: false,
                confirmDialog: false
            };

            config = Object.assign(defaultConfig, config);

            const path = this.$constants.apiPaths.planning.campaign();
            
            //const path = this.$constants.apiPaths.planning.campaign(this.marathonCountryID, this.clientID, this.campaignID);
            const params = new URLSearchParams();
            if(config.submit){
                params.append('submit', 'True');
            }
            const payload = this.campaign;

            const autosaveValid = this.checkAutosave();

            if (!autosaveValid) {
                this.$swal.fire({
                    title: 'Cannot save brief',
                    icon: 'error',
                    allowOutsideClick: false,
                    text: "Please fill mandatory fields",
                    customClass: "swal2-popup-dark min-width-400"
                }).then();
                this.eventBus.$emit('set-step', 0);
                return Promise.reject();
            }
            const that = this;
            return this.saveCampaignRequest(that, path, payload, params, config);
        },
        saveCampaignRequest(that, path, payload, params, config){
            const newCampaign = payload.campaignID === null || payload.campaignID === undefined
            const method = newCampaign ? "post" : "put";
            return this.axios({
                method: method,
                params: params,
                url: path,
                data: payload
            })
                .then((res) => {
                    if (res && res.data) {
                        that.campaign.campaignID = res.data.campaignID;

                        if(newCampaign && !config.doNotRedirectNew){
                            that.$router.replace({name: that.$constants.routeNames.briefs.edit, params: {campaignID: that.campaign.campaignID, marathonCountryID: that.campaign.marathonCountryID, clientID: that.campaign.clientID}});
                        }

                        that.$emit('update:campaignTouched', false);

                        if(config.confirmDialog){
                            that.$swal.fire({
                                imageUrl: "/img/big_green_check.png",
                                title: '<span>Saved</span>',
                                showCloseButton: true,
                                showConfirmButton: false,
                                customClass: "swal2-popup-dark min-width-400",
                                timer: 1500,
                            });
                        }

                        if (!config.doNotReloadCampaigns) {
                            that.eventBus.$emit("reload-campaigns");
                        }
                        
                        if(config.redirectToList){
                            that.$router.push({name: that.$constants.routeNames.briefs.root});
                        }

                        
                        that.eventBus.$emit("reload-brief");
                    }
                })
                .catch((error) => {
                    that.errorDialog(error);
                    console.error(error);
                });
        },
    },
    components: {
        [NordiskFilmBriefEdit.name]: NordiskFilmBriefEdit,
    }
};
</script>
