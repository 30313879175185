<template>
  <div>
    <div class="row mb-5">
        <div class="col-12">        
                <h3 class="group-admin-header">3rd Party System Tokens</h3>
            </div>
            <div class="col-9 group-admin-description">
                <p> System Tokens are available for third party systems interacting with the connect backend.
                    <br>Contact support for generation of new tokens and entities.</p>
            </div>
                <div class="col-3 d-flex justify-content-end">
                <!-- <base-button type="button"
                    class="btn btn-success"
                    @click="setGroup"
                    v-b-modal.group-modal>
                        <i class="el-icon-plus"/>
                        <span>New entity</span>
                </base-button> -->
            </div>
        </div>
    <div class="row token-admin-holder">
      <div class="col-12">
        <div class="planning-tool-table-header">
        <div class="col-12 d-flex justify-content-start no-gutters">
          <div class="row">
            <div class="header-item-wrapper pagination-wrapper" size="sm" icon>
              <el-select class="select-primary pagination-select" v-model="pagination.perPage" popper-class="pagination-dropdown"
                placeholder="Per page">
                <el-option class="select-primary" v-for="item in perPageOptions" :key="item" :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="header-item-wrapper search-wrapper">
              <el-input type="search" class="search-input" clearable prefix-icon="el-icon-search"
                placeholder="Search" v-model="searchQuery" aria-controls="datatables">
              </el-input>
            </div>
          </div>
        </div>
      </div>

      <el-table :data="queriedData"
        class="planning-tool-table" 
        row-class-name="planning-tool-table-row">

         <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
          :prop="column.prop" :label="column.label" :className="column.prop" :align="column.align">
        </el-table-column>

        <el-table-column min-width="150" label="Retired Tokens" align="left">
            <template slot-scope="scope" v-if="queriedData[scope.$index].retiredTokens && queriedData[scope.$index].retiredTokens.length > 0">
                <el-tooltip :content="queriedData[scope.$index].retiredTokens.toString()" placement="bottom">
                    <i>{{ queriedData[scope.$index].retiredTokens.toString().slice(0, 64) + ' ...' }}</i>
                </el-tooltip>
            </template>
            <span v-else>---</span>
        </el-table-column>

        <el-table-column min-width="200" label="Token">
            <template slot-scope="scope">
                <el-tooltip :content="queriedData[scope.$index].token" placement="bottom">
                    <i>{{ queriedData[scope.$index].token.slice(0, 64) + ' ...' }}</i>
                </el-tooltip>

            </template>
        </el-table-column>

      </el-table>

      <div slot="footer" class="table-footer justify-content-sm-between">
      <div class="pagination-info">
        Showing {{ from + 1 }} - {{ to }} (total {{ total }} )
      </div>
      <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
        :total="total">
      </base-pagination>
    </div></div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';

export default {
    props: ['perPageOptions'],
    data() {
        return {
            search: '',
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
            entities: null,
            searchQuery: '',
            propsToSearch: ['entityID', 'name'],
            searchedData: [],
            fuseSearch: null,
            tableColumns: [
                {
                    prop: 'entityID',
                    label: 'Entity ID',
                    minWidth: 180,
                },
                {
                    prop: 'name',
                    label: 'Name',
                    minWidth: 60,
                    maxWidth: 100
                }
            ],
        };
    },
    components: {
        BasePagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    methods: {
        getEntities() {
            const path = this.$constants.apiPaths.administration.entities;
            this.axios.get(path)
                .then((res) => {
                    this.entities = res.data.entities;
                    this.isLoadingTokens = false;
                })
                .catch((error) => {
                    this.isLoadingTokens = false;
                    console.error(error);
                });
        },
    },
    mounted() {
        this.fuseSearch = new Fuse(this.tableData, {
            keys: ['countryID', 'clientID', 'client'],
            threshold: 0.0
        });
        this.getEntities();
    },
    computed: {
        queriedData() {
            let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        },
        tableData(){
            var tableData = [];
            if(this.entities){
                for (var i = 0; i < this.entities.length; i++) {
                    tableData.push({
                        entityID: this.entities[i].entityID,
                        name: this.entities[i].name,
                        token: this.entities[i].token,
                        retiredTokens: this.entities[i].retiredTokens
                    });
                }
            }
            return tableData;
        },
    },
    watch: {
        searchQuery: {
            immediate: true,
            handler(value) {
                let result = this.tableData;
                if (value !== '') {
                    result = this.fuseSearch.search(this.searchQuery).map(x => x.item);
                }
                this.searchedData = result;

                this.fuseSearch = new Fuse(this.tableData, {
                    keys: ['name'],
                    threshold: 0.0
                });
            }
        },
        userClients(value) {
            if (value !== '') {
                this.composeData();
            }
        }
    }
};
</script>