<template>
    <div class="card reporting other-media" ref="nice">
    <div class="d-flex justify-content-between">
            <h2 class="reporting-title">{{title}}</h2>
            <span class="sub-setting">
                 <el-tooltip v-if="editable" content="Edit" placement="top">
                    <span @click="edit" :class="['icon-edit sub-setting-icon', isEditing ? 'sub-setting-icon-editing' :'']">
                        <i class="tim-icons icon-pencil"></i>
                    </span>
                </el-tooltip>
                <slot name="action-buttons"></slot>
            </span>
        </div>

        <div>
            <el-table class="other-media-table" :data="value['otherMedia']">
                <el-table-column label="Media" :sortable="true">
                    <template slot-scope="scope">
                        <el-input placeholder="+ Add media" class="other-media-input"
                            :disabled="!editable || editable && !isEditing" v-model="scope.row.media"></el-input>
                    </template>
                </el-table-column>

                <el-table-column label="Format" :sortable="true">
                    <template slot-scope="scope">
                        <el-input placeholder="+ Add format" class="other-media-input"
                            :disabled="!editable || editable && !isEditing" v-model="scope.row.format"></el-input>
                    </template>
                </el-table-column>

                <el-table-column label="Media Spend" :sortable="true">
                    <template slot-scope="scope">
                        <el-input placeholder="+ Add spend" class="other-media-input"
                            :disabled="!editable || editable && !isEditing" v-model="scope.row.spend"></el-input>
                    </template>
                </el-table-column>

                <el-table-column label="Units" :sortable="true">
                    <template slot-scope="scope">
                        <el-input placeholder="+ Add units" class="other-media-input"
                            :disabled="!editable || editable && !isEditing" v-model="scope.row.units"></el-input>
                    </template>
                </el-table-column>

                <el-table-column label="Reach" :sortable="true">
                    <template slot-scope="scope">
                        <el-input placeholder="+ Add reach" class="other-media-input"
                            :disabled="!editable || editable && !isEditing" v-model="scope.row.reach"></el-input>
                    </template>
                </el-table-column>

                <el-table-column label="Frequency" :sortable="true">
                    <template slot-scope="scope">
                        <el-input placeholder="+ Add frequency" class="other-media-input"
                            :disabled="!editable || editable && !isEditing" v-model="scope.row.frequency"></el-input>
                    </template>
                </el-table-column>

                <el-table-column align="right" v-if="editable">
                    <template slot-scope="scope">

                        <slide-x-right-transition :duration="600">
                            <i v-if="isEditing" @click="removeRow(scope.$index)"
                                class="tim-icons icon-simple-remove other-media-edit"></i>
                        </slide-x-right-transition>

                    </template>
                </el-table-column>
            </el-table>

            <slide-x-left-transition :duration="600">
                <b-button v-if="isEditing" @click="addRow" style="height: 20px; background:#333 !important;"
                    class="btn-sm">+ Add row</b-button>
            </slide-x-left-transition>

        </div>

        <slide-x-right-transition :duration="600" :delay="2000">
            <div v-if="isEditing" class="reporting-edit-save">
                <b-button @click="saveEdits" class="btn btn-primary">Save</b-button>
            </div>
        </slide-x-right-transition>

    </div>
</template>

<script>
import { SlideXRightTransition, SlideXLeftTransition } from 'vue2-transitions';
import KeyLearnings from '@/components/KeyLearnings/KeyLearnings.vue'

export default {
    name: 'reporting-other-media',
    components: {
        SlideXRightTransition,
        SlideXLeftTransition,
        KeyLearnings,
    },
    props: {
        value:{
            type: Object ,
            default: function () {
                return {
                    tvKeyFigures: [],
                    otherMedia: [],
                    trpOverview: []
                }
            },
        },
        editable: false,
        title: {
            type: String,
            default: 'Briefs',
            description: 'List title'
        },
        tableData: {
            type: Array,
            default: function () {
                return []
            },
        },
        tableColumns: {
            type: Array,
            default: function () {
                return []
            },
        },
        campaignTitle: {
            type: String,
            default: '',
            description: 'Campaign title'
        },
        help: {
            type: String,
            description: 'Help text'
        },
    },
    data() {
        return {
            isEditing: false,
        }
    },
    methods: {
        addRow() {
            this.value["otherMedia"].push({
                'media': '',
                'format': '',
                'spend': '',
                'units': '',
                'reach': '',
                'frequency': ''
            })
        },
        removeRow(index) {
            this.value["otherMedia"].splice(index, 1);
        },
        saveEdits() {
            this.$emit("save");
        },
        edit() {
            this.isEditing = !this.isEditing;
        },
    },
};
</script>

<style>
.reporting-title{
    margin-bottom: 15px;
}

.reporting .other-media .el-table__body .el-table__row {
  height: 10px;
}
/* 
.other-media-table {
  padding-top: 23px;
} */

/* .other-media-input {
  margin-left: -14px
} */

.other-media-input:disabled {
  background-color: transparent;
  cursor: default;
}

.el-input.is-disabled .el-input__inner {
  background-color: transparent !important; 
  cursor: default;
}

.other-media-edit {
  cursor: pointer;
  font-weight: 600;
  padding: 14px 12px 14px 42px; 
}

.other-media-edit:hover {
  transition: color .5s;
  color: #FF8994;
  font-weight: 900;
}

</style>
