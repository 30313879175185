<template>
    <div ref="reporting">
        <div class="row">
            <div class="col">
                <zoom-center-transition :duration="200" mode="out-in">
                    <router-view :campaigns="campaigns" :client="selectedClient" :country="userSelectedCountry"
                        :campaign="campaign" :clientsFilter="userSelectedMultipleClients" :getRoute="getRoute" />
                </zoom-center-transition>
            </div>
        </div>
    </div>
</template>

<script>
import ClientCountrySelect from '../../components/ClientCountrySelect/ClientCountrySelect.vue'

import {
    ZoomCenterTransition
} from 'vue2-transitions';
import { mapActions, mapState } from 'vuex'

export default {
    inject: ['formatCampaignNumbers'],
    data() {
        return {
            campaign: "",
            campaigns: [],
            reset: null,
        };
    },
    components: {
        [ClientCountrySelect.name]: ClientCountrySelect,
        ZoomCenterTransition,
    },
    methods: {
        getRoute(row, type) {
            if (type === "campaign") {
                return {
                    name: this.$constants.routeNames.reporting.detail,
                    params: {
                        marathonCountryID: row.marathonCountryID,
                        clientID: row.clientID,
                        campaignID: row.id,
                    },
                };
            }            
        },
        goToOverview(){
            if(this.$route.name !== this.$constants.routeNames.reporting.root){
                this.$router.push({ name: this.$constants.routeNames.reporting.root});
            }
            this.updateCampaignName("");
        },
        setCampaign(label) {
            this.campaign = label;
        },
        getCampaigns() {
            const params = new URLSearchParams();
            // params.append('status', 'FINISHED')
            params.append('status', this.$constants.campaignStates.FINISHED.value);
            this.axios(this.$constants.apiPaths.planning.campaigns(), {params: params})
                .then((res) => {
                    if (res.data) {
                        this.campaigns = res.data;
                        this.formatCampaignNumbers(this.campaigns);
                    }
                    //this.$swal.close();
                })
                .catch((error) => {
                    this.handleRequestError(error);
                    //this.$swal.close();
                });
        },
        handleRequestError(error) {
            console.error(error);
        },
        ...mapActions([
            'updateCampaignName',
            'updateSelectedClient',
        ]),
    },
    mounted() {
        this.reset = () =>{
            this.goToOverview();
        }
        this.$root.$on('reset-route', this.reset);
        //this.getClients();
        this.getCampaigns();
    },
    beforeDestroy() {
        this.$root.$off('reset-route', this.reset);
    },
    computed:{
        ...mapState([
            'selectedClient',
            'userSelectedCountry',
            'userSelectedMultipleClients'
        ]),
    },
};
</script>
<style scoped>
.pdf {
    margin-top: -4px;
    margin-left: 18px;
    min-width: 40px;
    cursor: pointer;
}
</style>