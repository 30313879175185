import jsPDF from 'jspdf';
import html2canvas from "html2canvas"
import dayjs from 'dayjs';

const generatePDF = async (items, that) => {
    const doc = new jsPDF("landscape", "px", "a4");
    const today = dayjs().format('DD/MM/YYYY');
    
    let promises = [];
    const docTitle =  ' | ' + that.campaignName + ' | ' + today;
    for (let i = 0; i < items.length; i++) {
        if(items[i].ref !== "mapping"){
            const el = that.$refs[items[i].ref];
            await addImageToPDF(doc, el, items[i].title, docTitle);
            if(el && i < items.length-1){
                doc.addPage();
            }
        }
    }
    doc.save("connect_export_" + that.campaignName.replace(/\s/g, '') + ".pdf");
}

const addImageToPDF = async(pdfDoc, el, imageTitle, docTitle) => {
    if(el){
        
        const doc = pdfDoc;

        const image = await generateImage(el);
        const imgData = image.toDataURL('image/png');
        const imgProps = doc.getImageProperties(imgData);

        const border = 30;
        const offset = 30;

        const pageHeight = doc.getPageHeight() - (border + offset);
        const pageWidth = doc.getPageWidth() - border;
        const pageAspectRadio = pageWidth / pageHeight;
        
        const imageWidth = imgProps.width;
        const imageHeight = imgProps.height;
        const imageAspectRatio = imageWidth / imageHeight;
        let renderWidth = imageWidth;
        let renderHeight = imageHeight;

        let scale = 1;

        if(imageWidth > pageWidth || imageHeight > pageHeight){
            if(imageAspectRatio > pageAspectRadio){
                renderWidth = pageWidth;
                scale = renderWidth/imageWidth
                renderHeight = Math.floor(scale * imageHeight)
            }
            else{
                renderHeight = pageHeight;
                scale = renderHeight/imageHeight
                renderWidth = Math.floor(scale * imageWidth)
            }
        }

        doc.setFontSize(16);
        doc.setTextColor(40);
        doc.text(imageTitle + docTitle, border/2, (offset - 8));

        doc.addImage(imgData, 'JPEG', border/2, offset, renderWidth, renderHeight, "", "MEDIUM");
    }
}

const generateImage = (el) => {
    return new Promise(async function(resolve, reject){
        el.classList.add('white-content');

        //TODO findout how, like this the chart is empty
        // if(cardDefinition.type === "barChart"){
        //     await that.$refs[cardDefinition.chart].setChartLegendColor("#000")
        // }

        let output = await html2canvas(el, {
            logging: false,
            ignoreElements: function(element){
                let ignore = 
                element.classList.contains("sub-setting") 
                || element.classList.contains("comment-btn")
                || element.classList.contains("key-learnings-input")

                if(ignore){
                    return true;
                }
                return false;
            }
        });
        el.classList.remove('white-content');

        // if(cardDefinition.type === "barChart"){
        //     await this.$refs[cardDefinition.chart].setChartLegendColor("#000");
        // }

        resolve(output);
    });
}

export {
    generatePDF,
    addImageToPDF,
    generateImage
}