<template>
    <div class="comment-input">
         <b-modal id="modal-comments-notify" :hide-footer="true" :centered="true" no-close-on-backdrop ref="modalCommentsNotify">
                <template #modal-header>
                    <b-button-close @click="closeModalNotifyUsers" />
                    <h2>Post</h2>
                </template>

                <span class="notify-users-content">
                  Please select users you'd like to notify.<br>
                  Leave empty to notify none.<br><br>
                
                <group-multiselect :options="groups" v-model="selectedUsers" group-values="users" group-label="groupName" item-label="name" itemKey="email" unit="user"
                        placeholder="No users selected" searchPhrase="Search for users"/>
                <br>
                <base-button class="notify-users-button" v-on:click="sendMessage">{{ selectedUsers.length > 0 ? 'Post & notify users' : 'Post comment'}}</base-button>              
                </span>
            </b-modal>

        <div class="row">
            <div class="col-12">
                <span class="user-portrait" :style="userColor">
                    {{ getUserInitials(username) }}
                </span>
                <div class="editor">
                    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                        <div class="menubar">
                            <span @click="commands.bold" style="font-weight: 900">B</span>

                            <span @click="commands.italic"><em>I</em></span>

                            <span @click="commands.underline" style="text-decoration: underline">U</span>

                            <span @click="commands.strike" style="text-decoration: line-through;">S</span>

                            <span @click="commands.bullet_list">
                                <li class="tim-icons icon-bullet-list-67"></li>
                            </span>

                            <span @click="commands.ordered_list">
                                <li class="tim-icons icon-bullet-list-67"></li>
                            </span>

                            <span @click="commands.undo">
                                <li class="tim-icons icon-minimal-left"></li>
                            </span>

                            <span @click="commands.redo">
                                <li class="tim-icons icon-minimal-right"></li>
                            </span>

                        </div>
                    </editor-menu-bar>

                    <editor-content class="editor__content" :editor="editor" />
                </div>
                <base-button :disabled="!contentValid && !isPostingComment" :loading="isPostingComment" class="comment-btn post-comment-button"
                    v-on:click="postComment">Post comment</base-button>
            </div>
        </div>

    </div>
</template>

<script>
import UserHelpers from '@/helpers/UserHelpers.js';
import GroupMultiselect from '@/components/GroupMultiselect/GroupMultiselect.vue'

import {
    Editor,
    EditorContent,
    EditorMenuBar
} from 'tiptap'
import {
    Select,
    Option
} from 'element-ui'
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions';

import { fetchRecipientsGroups } from '@/helpers/fetch.notifications.js';
import { mapGetters, mapState } from 'vuex'

export default {
    name: "comment-input",
    data() {
        return {
            usersReady: false,
            groups: null,
            selectedUsers: [],
            refreshComment: 0,
            isPostingComment: false,
            username: localStorage.username,
            comment: "",
            contentValid: false,
            //json: "",
            html: "",
            editor: new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                ],
                content: ``,
                onUpdate: ({ getJSON, getHTML }) => {
                    //this.json = getJSON()
                    this.html = getHTML()
                    this.contentValid = this.html.length >= 8
                },
            }),
        };
    },
    components: {
        EditorContent,
        EditorMenuBar,
        [Select.name]: Select,
        [Option.name]: Option,
        GroupMultiselect
    },
    props: ['objectOfOriginType', 'objectOfOrigin', 'getChat'],
    computed: {
        userColor() {
            if(localStorage.userID){
                return 'background-color: ' + UserHelpers.colorFromId(localStorage.userID) + ";";
            }
            return "";
        },
        ...mapGetters([
            'internalUser',
        ]),
        ...mapState([
            'campaignName'
        ]),
    },
    methods: {
        getUserInitials(username) {
            return UserHelpers.initialsFromUsername(username);
        },
        closeDialog() {
            this.dialogVisible = !this.dialogVisible;
        },
        closeModalNotifyUsers(){
            this.$refs.modalCommentsNotify.hide();
            this.selectedUsers = [];
        },
        openModalNotifyUsers(){
            fetchRecipientsGroups(this.axios).then((groups) => {
                if (groups && groups.length > 0) {
                    this.groups = groups;
                    this.$refs.modalCommentsNotify.show();
                }}).catch((error) => {
                console.error("Failed to get recipients")
                console.error(error)
            });
        },
        // getRecipientsGroups() {
        //     fetchRecipientsGroups(this.axios).then((groups) => {
        //         if (groups && groups.length > 0) {
        //             this.groups = groups;
        //         }}).catch((error) => {
        //         console.error("Failed to get recipients")
        //         console.error(error)
        //     });
        // },
        postComment() {
            if(this.contentValid){
                this.openModalNotifyUsers();
                // if (this.internalUser && this.$route.meta.routeGroup === "campaigns") {
                //     this.openModalNotifyUsers();
                // }               
                // else {
                //     this.sendMessage()
                // } 
            }
            else {
                this.$swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Please enter a comment before posting",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            
        },
        sendMessage() {
            if (this.editor.getHTML().length > 7) {
                this.isPostingComment = true;
                const path = this.$constants.apiPaths.comments();
                
                const payload = {
                    comment: this.editor.getHTML(),
                    objectOfOrigin: this.objectOfOrigin,
                    objectOfOriginType: this.objectOfOriginType,
                    userEmail: localStorage.currentUserEmail
                };
                this.axios.post(path, payload)
                    .then((res) => {
                        this.comment = "";
                        this.getChat();
                        this.isPostingComment = false;
                        this.editor.clearContent();
                        this.contentValid = false;
                        
                        this.$swal.fire({
                            position: "top-end",
                            icon: "success",
                            //toast: true,
                            title: "Your comment has been posted",
                            showConfirmButton: false,
                            timer: 1500
                        });

                        this.notify(this.selectedUsers);
                        
                        this.closeModalNotifyUsers();
                    })
                    .catch((error) => {
                        this.isPostingComment = false;
                        console.error(error);
                    });
            } else {
                this.$swal.fire({
                    position: "top-end",
                    icon: "error",
                    //toast: true,
                    title: "Please enter a comment before posting",
                    showConfirmButton: false,
                    timer: 1500
                });
            }

        },
        notify(users) {
            if(users && users.length > 0){
                const url = this.$constants.apiPaths.notifications.notify;
                let notificationObject =  {
                    marathonCountryID: this.objectOfOrigin.marathonCountryID,
                    clientID: this.objectOfOrigin.clientID,
                    campaignID: this.objectOfOrigin.campaignID,
                    campaign: this.campaignName,
                    objectType: this.objectOfOriginType,
                    notificationType: "comment"
                }
                if(this.objectOfOrigin.planID){
                    notificationObject["planID"] = this.objectOfOrigin.planID;
                }
                const content = {
                    users: users,
                    notificationObject: notificationObject

                }
                return this.axios.post(url, content).then(() => {
                    console.log("notfication sent")
                }).catch((error) => {
                    console.error(error)
                });
            }
        }
    },
    // mounted() {
    //     this.getUserContent();
    // },
    beforeDestroy() {
        this.editor.destroy()
    }
};
</script>

<style lang="scss">

.comments{
    
    .editor>.btn {
    margin-top: 12px;
    background: #696969;
    min-width: 160px;
    height: 32px;
    }

    .comment-input .user-portrait {
    color: black;
    margin-top: 6px;
    height: 40px;
    width: 40px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-weight: 900;
    }

    .menubar {
    border: 1px solid #333;
    border-radius: 6px 6px 0 0;
    padding: 8px;
    margin-bottom: -1px;
    }

    .menubar span {
    color: white;
    padding: 6px;
    cursor: pointer;
    }

    .menubar span:hover {
    color: #376EE2;
    padding: 6px;
    cursor: pointer;
    }

    .comment-input .editor__content {
    border: 1px solid #333333;
    border-radius: 0 0 6px 6px;
    padding: 12px;
    outline-width: 0;
    height: 130px;
    min-height: 130px;
    overflow-y: auto;
    }

    .comment-input  .editor {
    margin-right: 38px;
    margin-top: -42px;
    margin-left: 56px;
    }

    .comment-input  .ProseMirror {
    min-height: 100%;
    }

    .comment-input  .ProseMirror-focused {
    outline: none;
    }

    .comment-input {
    /* position: absolute; */
    width: 100%;
    }

    .comment-input  .post-comment-button {
    margin-right: 38px; 
    float:right; 
    min-width: 160px; 
    margin-top: 12px;
    }

    .comment-input .notify-users-content {
        color: white;
        margin-top: 500px;

        .notify-users-button {
            float: right;
            min-width: 150px;
            margin-top: 24px;
        }
    }

    #modal-notify-users___BV_modal_body_ {
        margin-top: -40px;
    }

    .comment-input  .select-info.el-select .el-input .el-input__icon{
        color: white;
    }
}

.white-content{
    .comments{
        
        .editor>.btn {
        background: #696969;
        }

        .menubar {
        border: 1px solid #333;
        }

        .menubar span {
            color: #000;
        }

        .menubar span:hover {
        color: #376EE2;
        }

        .comment-input .editor__content {
        border: 1px solid #333333;
        }

        .comment-input .notify-users-content {
            color: #000;
        }

        #modal-notify-users___BV_modal_body_ {
            margin-top: -40px;
        }

        .comment-input  .select-info.el-select .el-input .el-input__icon{
            color: #000;
        }
    }
}

</style>