<template>
    <div class="el-table-wrapper">
        <el-table :data="queriedData" @row-click="handleRowClick" class="planning-tool-gantt" row-class-name="planning-tool-table-row"
            :cell-class-name="cellClassName" @sort-change="customSort" v-if="queriedData.length > 0">

            <el-table-column align="left" label="" width="50" fixed="left" v-if="linkType" class-name="linked">
                 <template slot-scope="scope">
                    <router-link :to="getRoute(scope.row, linkType)" v-on:click.native="handleRowClick(scope.row)">
                        <span>
                            <base-button class="btn-link" type="info" size="sm" icon><i class="tim-icons icon-double-right"></i></base-button>
                        </span>
                    </router-link>
                 </template>
            </el-table-column>
            <el-table-column align="left" label="" width="50" fixed="left" v-else>
                <base-button class="btn-link" type="info" size="sm" icon><i class="tim-icons icon-double-right"></i></base-button>
            </el-table-column>

            <el-table-column :width="fixedColumns.status.width" prop="status" label="Status" className="status"
                sortable="custom"
                :sort-method="disableSortWithoutOtherPages" fixed="left">
                <template slot-scope="scope">
                    <span slot="reference">
                        <el-tooltip :content="$constants.campaignStates[scope.row.status] ? $constants.campaignStates[scope.row.status].label : (scope.row.status && scope.row.status.length > 1 ? (scope.row.status[0].toUpperCase() +  scope.row.status.slice(1).toLowerCase()) : 'Not defined')" placement="right" :disabled="hideStatusHover" :visible-arrow="true">
                            <span :class="[ 'state', showStatusLabel ? (scope.row.status ? 'active' : '') : 'without-text', scope.row.status.toLowerCase()]">
                                <span v-if="showStatusLabel">{{ scope.row.status ? "Active" : "Deactivated"}}</span>
                            </span>
                        </el-tooltip>
                    </span>
                </template>
            </el-table-column>

            <el-table-column :width="fixedColumns.id.width" align="left" label="Id" prop="id" 
                sortable="custom"
                :sort-method="disableSortWithoutOtherPages" fixed="left">

            </el-table-column>

            <el-table-column :min-width="nameMinWidth" align="left" :label="nameLabel" prop="name"
                sortable="custom"
                :sort-method="disableSortWithoutOtherPages"
                 fixed="left" v-if="linkType" class-name="linked">
                 <template slot-scope="scope">
                    <router-link :to="getRoute(scope.row, linkType)" v-on:click.native="handleRowClick(scope.row)"><span>{{scope.row["name"]}}</span></router-link>
                 </template>
            </el-table-column>
            <el-table-column :min-width="nameMinWidth" align="left" :label="nameLabel" prop="name"
                sortable="custom"
                :sort-method="disableSortWithoutOtherPages"
                 fixed="left" v-else>
            </el-table-column>

            <template v-if="!collapsed && linkType">
                 <el-table-column v-for="column in additionalColumns" :key="column.label" :min-width="column.minWidth" :width="column.width" :prop="column.prop"
                    :label="column.label" 
                    sortable="custom"
                    :sort-method="disableSortWithoutOtherPages" fixed="left" class-name="linked">
                    <template slot-scope="scope">
                        <router-link :to="getRoute(scope.row, linkType)" v-on:click.native="handleRowClick(scope.row)"><span>{{scope.row[column.prop]}}</span></router-link>
                    </template>
                </el-table-column>
            </template>

            <template v-if="!collapsed && !linkType">
                 <el-table-column v-for="column in additionalColumns" :key="column.label" :min-width="column.minWidth" :width="column.width" :prop="column.prop"
                    :label="column.label" 
                    sortable="custom"
                    :sort-method="disableSortWithoutOtherPages" fixed="left">
                </el-table-column>
            </template>

            <el-table-column :width="ganttTableDayWidth * 31" align="left" :label="ganttStart.year() +' Jan'" prop="month01">
                <div slot-scope="scope">
                    <el-popover trigger="hover" placement="right">
                        <div>
                            <div class="popover-header">
                                <b>Campaign:</b> {{scope.row.name}}
                            </div>
                            <div class="popover-body">
                                <p><b>Date:</b> {{ scope.row.startDate }} - {{ scope.row.endDate }}</p>
                                <p><b>Duration:</b> {{ scope.row.length}} {{ scope.row.length == 1 ? 'day' : 'days'}} </p>
                            </div>
                        </div>
                        <div class="line" slot="reference" :class="lineClassName(scope.row, scope.$index)" :style="lineStyle(scope.row, scope.$index)"></div>
                    </el-popover>
                    <div class="current-date" :style="currentDateLineStyle"/>
                </div>
            </el-table-column>
           
             <el-table-column v-for="column in ganttColumns" :key="column.prop" :min-width="column.minWidth" :width="column.width" :prop="column.prop"
                :label="column.label">
            </el-table-column>

            <el-table-column :width="fixedColumns.edit.width" align="left" label="" fixed="right" className="fill-background edit">
                <template slot-scope="props">
                    <base-button @click.native.stop="$emit('edit-item', props.$index, props.row)" class="edit btn-link" type="warning" size="sm" icon>
                        <i class="tim-icons icon-pencil"></i>
                    </base-button>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="queriedData.length < 1" class="row">
            <div class="col d-flex justify-content-center">No data</div>
        </div>
        <div class="collapse-button" id="collapseButton" ref="collapseButton" v-on:click="collapsed = !collapsed" :class="{'expanded' : !collapsed, 'small' : ganttData.length < 2, 'medium' : ganttData.length < 4}" v-if="!loading && ganttData && ganttData.length > 0">
            <span class="arrow">></span>
        </div>
        <div slot="footer" class="table-footer justify-content-sm-between">
            <div class="pagination-info">
                Showing {{ from + 1 }} - {{ to }} (total {{ total }})
            </div>
            <div class="note-info">
                    <span class="note-item" v-for="([key, value], index) in Object.entries($constants.campaignStates)" :key="index">
                    <span :class="['circle', key.toLowerCase()]"></span><span class="text">{{ value.label }}</span>
                </span>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total">
            </base-pagination>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import {
    BasePagination
} from 'src/components';

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(dayOfYear)
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)


export default {
    props: {
        ganttData: {
            type: Array,
            default: () => [],
        },
        additionalColumns: {
            type: Array,
            default: () => [],
        },
        showStatusLabel: {
            type: Boolean,
            default: true
        },
        hideStatusHover: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        nameLabel: {
            type: String,
            default: ''
        },
        nameMinWidth: {
            type: Number,
            default: 150
        },
        bus: {
            type: Object,
        },
        defaultSort: {
            type: Object,
            default:  function() {
                return {
                    prop: "campaign",
                    order: "ascending",
                };
            },
        },
        pagination: {
            type: Object,
            default:  function() {
                return {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                };
            },
        },
        linkType:{
            type: String,
            default: ""
        },
        getRoute:{
            type: Function,
            required: true,
            default: function(){
                console.warn("getRoute default")
                return {
                    name: this.$constants.routeNames.campaigns.root,
                };
            }
        }
    },
    data() {
        return {
            paginationValue: this.pagination,
            fixedColumns: this.$constants.fixedColumns,
            ganttTableDayWidth: this.$constants.ganttTableDayWidth,
            popover: {
                offset: 0,
                visible: false,
            },
            collapsed: false,
            sortValue: this.defaultSort
        };
    },
    mounted(){
        if(this.bus){
            this.bus.$on("sort", this.customSort);
        }
    },
    components: {
        BasePagination,
    },
    computed: {
        queriedData() {
            let result = this.sortedTableData;
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.paginationValue.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.paginationValue.perPage * (this.paginationValue.currentPage - 1);
        },
        total() {
            return this.ganttData.length;
        },
        ganttStart(){
            var oldest = dayjs().year((dayjs().year() - 1)).dayOfYear(1);
            var limit = oldest.subtract(5, 'year');
            for(var i=0; i < this.ganttData.length; i++){
                if(dayjs.utc(this.ganttData[i].startDate).isBefore(oldest)){
                    oldest = dayjs.utc(this.ganttData[i].startDate);
                }
            }
            if(oldest.isBefore(limit)){
                oldest = limit;
            }
            return  dayjs(oldest.dayOfYear(1).format('YYYY-MM-DD'));
        },
        ganttEnd(){
            var newest = dayjs().utc().year((dayjs().year() + 1)).dayOfYear(1);
            for(var i=0; i < this.ganttData.length; i++){
                if(dayjs.utc(this.ganttData[i].endDate).isAfter(newest)){
                    newest = dayjs.utc(this.ganttData[i].endDate);
                }
            }
            return dayjs(newest.dayOfYear(365).format('YYYY-MM-DD'));
        },
        currentDateLineStyle(){
            var startOffsetDays = dayjs().diff(this.ganttStart, 'd');
            var left = startOffsetDays * this.$constants.ganttTableDayWidth;
            return 'left: ' + left + 'px;'
        },
        ganttLengthYears(){
            return this.ganttEnd.year() - this.ganttStart.year();
        },
        ganttColumns(){
            var whole = [
                {
                    prop: 'month02',
                    label: 'Feb',
                    width: this.$constants.ganttTableDayWidth * 28
                },
                {
                    prop: 'month03',
                    label: 'Mar',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month04',
                    label: 'Apr',
                    width: this.$constants.ganttTableDayWidth * 30
                }, {
                    prop: 'month05',
                    label: 'May',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month06',
                    label: 'Jun',
                    width: this.$constants.ganttTableDayWidth * 30
                }, {
                    prop: 'month07',
                    label: 'Jul',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month08',
                    label: 'Aug',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month09',
                    label: 'Sep',
                    width: this.$constants.ganttTableDayWidth * 30
                }, {
                    prop: 'month10',
                    label: 'Oct',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month11',
                    label: 'Nov',
                    width: this.$constants.ganttTableDayWidth * 30
                }, {
                    prop: 'month12',
                    label: 'Dec',
                    width: this.$constants.ganttTableDayWidth * 31
                },
            ];

            for(var i = 1; i <= this.ganttLengthYears; i++){
                var thisYear = this.ganttStart.add(i, 'year');
                
                var template = this.ganttColumnsTemplate(i);
                if(thisYear.isLeapYear()){
                    template[1].width =  this.$constants.ganttTableDayWidth * 29;
                }
                whole = whole.concat(template);
            }


            return whole;
        },
        sortedTableData(){
            var copy = JSON.parse(JSON.stringify(this.ganttData));
            var that = this;
            copy.sort(
                //this.sortBy(value.prop, value.order === "ascending")
                function(a,b){
                    var orderAsc = that.sortValue.order ? that.sortValue.order === "ascending" : null;
                    if(orderAsc !== null){
                        if (a[that.sortValue.prop] < b[that.sortValue.prop]) {
                            return orderAsc ? -1 : 1;
                        }
                        if (a[that.sortValue.prop] > b[that.sortValue.prop]) {
                            return orderAsc ? 1 : -1;
                        }
                    }
                       
                    return 0;
                }
            );

            return copy;
        },
    },
    watch:{
        ganttData(){
            this.scrollToCurrentDate();
        },
        loading(newVal){
            if(newVal === false){
                this.scrollToCurrentDate();
            }
        },
        collapsed(){
            var that = this;
            setTimeout(function() {
                that.resizeWrappers();
            }, 200);
        },
        additionalColumns: {
            deep: true,
            handler(){
                var that = this;
                setTimeout(function() {
                    that.resizeWrappers();
                }, 200);
            }
        },
        paginationValue: {
            deep: true,
            handler(newVal){
                this.$emit("update:pagination", newVal);
            },
        }
    },
    methods: {
        scrollToCurrentDate() {
            const that = this;
            setTimeout(function() {
                const line = that.$el.getElementsByClassName('current-date')[0];
                const tableBodyWrapper = that.$el.getElementsByClassName('el-table__body-wrapper')[0];
                that.resizeWrappers();
                if(tableBodyWrapper && line){
                    if(that.$route.path && that.$route.path === "/reporting"){
                        tableBodyWrapper.scrollTo((line.offsetLeft + 100) - tableBodyWrapper.offsetWidth, 0);
                    }
                    else{
                        tableBodyWrapper.scrollTo(line.offsetLeft - 50, 0);
                    }
                }
            }, 400);
        },
        customSort(value){
            this.sortValue = value;            
        },
        resizeWrappers(){
            this.$nextTick(function () {
                const fixed = this.$el.getElementsByClassName('el-table__fixed')[0];
                
                const tableHeaderWrapper = this.$el.getElementsByClassName('el-table__header-wrapper')[0];
                const tableBodyWrapper = this.$el.getElementsByClassName('el-table__body-wrapper')[0];

                const collapseButton = this.$refs.collapseButton;
                
                if(tableHeaderWrapper && tableBodyWrapper && fixed){
                    const tableHeader = tableHeaderWrapper.getElementsByClassName('el-table__header')[0];
                    const tableBody = tableBodyWrapper.getElementsByClassName('el-table__body')[0];

                    const fixedTableHeaderWrapper = fixed.getElementsByClassName('el-table__header')[0];
                    const fixedTableBodyWrapper = fixed.getElementsByClassName('el-table__body')[0];

                    var left = fixed.style.width;
                    var width = 'calc(100% - ' + left + ')';

                    tableBodyWrapper.style.left = left;
                    tableBodyWrapper.style.width = width;
                    
                    tableHeaderWrapper.style.left = left;
                    tableHeaderWrapper.style.width = width;
                
                    if(collapseButton){
                        collapseButton.style.left = left;
                    }

                    if(fixedTableHeaderWrapper){
                        fixedTableHeaderWrapper.style.width = left;
                    }

                    if(fixedTableBodyWrapper){
                        fixedTableBodyWrapper.style.width = left;
                    }

                    if(tableHeader){
                        tableHeader.style.left = '-' + left;
                    }

                    if(tableHeader && tableBody){
                        tableBody.style.left = '-' + left;
                    }
                }
            });
            
        },
        ganttColumnsTemplate(year){
            return  [
                {
                    prop: 'month' + ((12 * year) + 1),
                    label: this.ganttStart.year() + year +' Jan',
                    width: this.$constants.ganttTableDayWidth * 31
                },
                {
                    prop: 'month' + ((12 * year) + 2),
                    label: 'Feb',
                    width: this.$constants.ganttTableDayWidth * 28
                },
                {
                    prop: 'month' + ((12 * year) + 3),
                    label: 'Mar',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month' + ((12 * year) + 4),
                    label: 'Apr',
                    width: this.$constants.ganttTableDayWidth * 30
                }, {
                    prop: 'month' + ((12 * year) + 5),
                    label: 'May',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month' + ((12 * year) + 6),
                    label: 'Jun',
                    width: this.$constants.ganttTableDayWidth * 30
                }, {
                    prop: 'month' + ((12 * year) + 7),
                    label: 'Jul',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month' + ((12 * year) + 8),
                    label: 'Aug',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month' + ((12 * year) + 9),
                    label: 'Sep',
                    width: this.$constants.ganttTableDayWidth * 30
                }, {
                    prop: 'month' + ((12 * year) + 10),
                    label: 'Oct',
                    width: this.$constants.ganttTableDayWidth * 31
                }, {
                    prop: 'month' + ((12 * year) + 11),
                    label: 'Nov',
                    width: this.$constants.ganttTableDayWidth * 30
                }, {
                    prop: 'month' + ((12 * year) + 12),
                    label: 'Dec',
                    width: this.$constants.ganttTableDayWidth * 31
                },
            ]
        },
        lineClassName(row, rowIndex) {
            var color = rowIndex % 8;
            return "timeline color-0" + color + ' ' + row.status.toLowerCase();
        },
        lineStyle(row, rowIndex) {
            var calculated = this.calculateTimelineLine(row, rowIndex);
            if (calculated) {
                return calculated;
            }
        },
        cellClassName({
            row,
            column,
            rowIndex,
            columnIndex
        }) {
            if (column && column.property) {
                if (column.property.toString().indexOf('month') > -1) {
                    return 'month ' + column.property;
                } else {
                    return column.property.toString();
                }
            }

        },
        calculateTimelineLine(row, index) {
            if(row.startDate && row.endDate){
                var start = dayjs.utc(row.startDate);
                var end = dayjs.utc(row.endDate);
                //var thisYearStart = dayjs().dayOfYear(1);
                //var thisYearEnd = new Date(Date.UTC(new Date().getFullYear(), 11, 31));
                
                var left = 0;
                if (start.isAfter(end) || end.isBefore(this.ganttStart)) {
                    this.scrollToCurrentDate();
                    return "width: 0px; display: none;";
                }
                else if (start.isSameOrBefore(this.ganttStart) && end.isSameOrAfter(this.ganttEnd)){
                    let lengthDays = this.ganttEnd.diff(this.ganttStart, 'd')
                    if(lengthDays === 0){
                        lengthDays = 1;
                    }
                    let width = lengthDays * this.ganttTableDayWidth;

                    this.scrollToCurrentDate();
                    return "left: 0px; width: " + width + "px";
                }
                else{
                    var startOffsetDays = start.diff(this.ganttStart, 'd');
                    left = startOffsetDays * this.ganttTableDayWidth;
                    let lengthDays = end.diff(start, 'd')
                    if(lengthDays === 0){
                        lengthDays = 1;
                    }
                    let width = lengthDays * this.ganttTableDayWidth;
                    this.scrollToCurrentDate();
                    return "left: " + left + "px; width: " + width + "px";
                }
            }
            else{
                this.scrollToCurrentDate();
                return "width: 0px; display: none;";
            }
        },
        handleRowClick(row, column, event) {
            this.$emit('row-click', row, row.$index);
        },
        handleHover(row, state, event) {
            this.popover.offset = event.clientX - event.target.getBoundingClientRect().left; + 'px';
            row.visible = state;
        },
        disableSortWithoutOtherPages(a,b){
            return 0;
        },
    },
};
</script>