<template>
    <div>
        <PlansOverview v-if="internalUser && dataModels!==null" :internalUser="internalUser" :dataModels="dataModels" :userClients="userClients" :getRoute="getRoute"/>
        <ClientCampaignDetail v-else-if="dataModels!==null" :internalUser="internalUser" :getRoute="getRoute"/>
    </div>
    
</template>

<script>

import ClientCampaignDetail from '@/pages/Campaigns/ClientCampaignDetail.vue'
import PlansOverview from '@/pages/Campaigns/PlansOverview.vue'
import { mapState, mapGetters } from 'vuex'

export default {
    props: ['getRoute', 'userClients'],
    methods: {
        handleRequestError(error) {
            console.error(error);
        },
        // openDetail(type, row){
        //     this.$emit('open-detail', type, row);
        // },
        getDataModels(force) {            
            this.$store.dispatch("getDataModels", [this.axios, force]);
        },
        // getUserContent(force) {            
        //     this.$store.dispatch("getUserContent", [this.axios, force]);
        // },
    },
    components: {
        ClientCampaignDetail,
        PlansOverview
    },
    computed:{
        ...mapState([
            'dataModels',
        ]),
        ...mapGetters([
            'internalUser',
        ]),
    },
    mounted(){
        this.getDataModels();
    },
};
</script>
