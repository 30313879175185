<template>
    <div class="key-learnings-input comments-input">
        <div class="editor">
            <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                <div class="menubar">
                    <span @click="commands.bold" style="font-weight: 900">B</span>

                    <span @click="commands.italic"><em>I</em></span>

                    <span @click="commands.underline" style="text-decoration: underline">U</span>

                    <span @click="commands.strike" style="text-decoration: line-through;">S</span>

                    <span @click="commands.bullet_list">
                        <li class="tim-icons icon-bullet-list-67"></li>
                    </span>

                    <span @click="commands.ordered_list">
                        <li class="tim-icons icon-bullet-list-67"></li>
                    </span>

                    <span @click="commands.undo">
                        <li class="tim-icons icon-minimal-left"></li>
                    </span>

                    <span @click="commands.redo">
                        <li class="tim-icons icon-minimal-right"></li>
                    </span>

                </div>
            </editor-menu-bar>

            <editor-content style="overflow-y: scroll;" class="editor__content" :editor="editor" />
        </div>

        <base-button :loading="isPostingComment" class="submit-btn btn-round" v-on:click="sendMessage"
        :disabled="!contentValid && !isPostingComment">
            {{learning.length > 0 ? 'Save' : 'Add key learning'}}
        </base-button>

        <base-button v-if="learning.length > 0" :loading="isPostingComment" class="submit-btn btn-round" v-on:click="edit" :disabled="isPostingComment">
            Cancel
        </base-button>
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions';


export default {
    name: "KeyLearningInput",
    data() {
        return {
            isPostingComment: false,
            username: localStorage.username,
            comment: "",
            contentValid: false,
            json: "",
            html: "",
            editor: new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                ],
                content: this.learning,
                onUpdate: ({ getJSON, getHTML }) => {
                    this.json = getJSON()
                    this.html = getHTML()
                    this.contentValid = this.html.length >= 8
                },
            }),
        };
    },
    components: {
        EditorContent,
        EditorMenuBar,
    },
    props: ['objectOfOriginType', 'objectOfOrigin', 'learning', 'edit'],
    methods: {
        sendMessage() {
            if (this.editor.getHTML().length >= 8) {
                this.isPostingComment = true;

                //const path = this.$constants.apiPaths.comments;
                
                let path = this.$constants.apiPaths.comments(
                    //this.objectOfOriginType,
                    this.objectOfOrigin.marathonCountryID,
                    this.objectOfOrigin.clientID,
                    this.objectOfOrigin.campaignID
                );

                const payload = {
                    comment: this.editor.getHTML(),
                    objectOfOriginType: this.objectOfOriginType,
                    objectOfOrigin: this.objectOfOrigin,
                    userEmail: localStorage.currentUserEmail,
                    userName: localStorage.username
                };

                this.axios.post(path, payload)
                    .then((res) => {
                        this.comment = "";
                        this.$emit("reload");
                        this.isPostingComment = false;
                        this.editor.content = ``;
                        this.$swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Key learning was saved",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    },
    beforeDestroy() {
        this.editor.destroy()
    },
};
</script>

<style scoped lang="scss">
.editor{
    
    
}
.editor__content {    
    height: 90px;
}
</style>