<template>
<div class="comments key-learnings">
    <h3 class="learnings-title mb-3">
        Key learnings
    </h3>
    <div class="row">
        <div class="col-md-12">
            <keyLearningsInput v-if="isEditing && editable" :edit="edit" :objectOfOriginType="objectOfOriginType"
                :objectOfOrigin="objectOfOrigin" v-on:reload="getChat" :learning="learning" />
            <div class="key-learnings-holder">
                <span v-if="comments.length > 0 && !isEditing" class="key-learnings-last-edited">Last Edited:
                    {{comments[comments.length -1].timestamp}}</span>
                <span v-if="comments.length < 1 && !editable" class="key-learnings-last-edited">No key learnings
                    added</span>

                <div v-if="!isEditing" class="post-learning">
                    <span v-html="learning"></span>
                </div>

                <base-button v-if="!isEditing && editable" class="comment-btn" v-on:click="edit">Edit key learning
                </base-button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import KeyLearningsInput from "./KeyLearningsInput.vue";

export default {
    name: "KeyLearnings",
    components: {
        KeyLearningsInput,
    },
    data() {
        return {
            learning: '',
            isPostingLearning: false,
            comments: [
                {'comment':'',}
            ],
            isEditing: false,
            isReady: false,
        };
    },
    props:['objectOfOriginType', 'objectOfOrigin', 'editable'],
    methods: {
        edit() {
            this.isEditing = this.isEditing ? false : true;
        },
        getChat(){
            this.isReady = false;
            //const path = config.apiUrl + 'v1.0/comments?objectOfOriginType='+this.objectOfOriginType+'&objectOfOrigin='+JSON.stringify(this.objectOfOrigin);
            //const path = this.$constants.apiPaths.comments();
            let path = this.$constants.apiPaths.comments(
                this.objectOfOriginType,
                this.objectOfOrigin.marathonCountryID,
                this.objectOfOrigin.clientID,
                this.objectOfOrigin.campaignID
            );
      
            // const params = new URLSearchParams();
            // params.append('objectOfOriginType', this.objectOfOriginType);
            // params.append('objectOfOrigin', JSON.stringify(this.objectOfOrigin));

            // this.axios.get(path, {
            //     params: params
            // })

            this.axios.get(path)
                .then((res) => {
                    this.isEditing = true;
                    this.comments = res.data;
                    try {
                        this.learning = res.data[res.data.length-1].comment
                    } catch (e) {
                        this.learning = '';
                    }
                    if (this.learning.length > 1) {
                        this.isEditing = false;
                    }
                    this.isReady = true;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    mounted(){
        this.getChat();
    //console.log(this.editable);
    },
};
</script>


<style lang="scss" scoped>
    .messages-main {
        overflow-y: scroll;
        height: 90%;
    }

    .users {
        padding: 0px !important;
        border: 1px solid gray;
    }

    .no-margin {
        margin: 0px;
    }

    .messages-area {
        border: 1px solid gray;
        padding: 0px !important;
    }

    .input-message {
        height: 40px;
    }

    .select-chat {
        margin-top: 35vh;
        padding: 8px;
    }

    .main-area {
        margin: 0px;
        min-height: calc(100vh - 15em) !important;
    }

    .logged_user {
        color: white;
    }

    // .key-learnings-holder {
    //     margin-top: -12px;
    // }

    .key-learnings-holder span {
        color: #ddd;
    }

    .post-learning {
        margin-left: 0px;
        margin-top: 4px;
        max-height: 214px;
        //overflow: scroll;
        overflow-y: auto;
        margin-bottom: 8px;

        h1, h2, h3, h4, .h1, .h2, .h3, .h4{
            margin-bottom: 0.3rem;
        }
    }

    .learningedit {
        height: 290px;
    }

    .learnings-title {
        margin-top: 12px;
        margin-bottom: 50px;
    }

    .white-content{
        
        .key-learnings-holder span {
            color: #000;
        }
    }
</style>