<template>
    <div class="insights">
        <div class="row">
            <div class="col">
                <router-view
                    :selectedClient="selectedClient"
                    :userSelectedCountry="userSelectedCountry"
                    :loading="loading"
                    :userSelectedMultipleClients="userSelectedMultipleClients"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ClientCountrySelect from "@/components/ClientCountrySelect/ClientCountrySelect.vue";
import { mapActions, mapState } from 'vuex'

export default {
    data() {
        return {
            apiData: null,
            loading: false,
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        goToOverview(){
            if(this.$route.name !== this.$constants.routeNames.insights.root){
                this.updateSelectedClient({client:null})
                this.$router.push({ name: this.$constants.routeNames.insights.root});
            }
        },
    },
    computed:{
        ...mapState([
            'selectedClient',
            'userSelectedCountry',
            'userSelectedMultipleClients'
            
        ]),
    },
    mounted() {
        this.reset = () =>{
            this.goToOverview();
        }
        this.$root.$on('reset-route', this.reset);
        //this.getCampaigns();
    },
    beforeDestroy() {
        this.$root.$off('reset-route', this.reset);
    },
    components: {
        [ClientCountrySelect.name]: ClientCountrySelect,
    },
}
</script>