import dayjs from "dayjs";

import { getBorderDates, createDaysArray } from "./helpers.dates";

const convertOrder = (order) => {
    let startDate = dayjs(order.startDate)
    let endDate = dayjs(order.endDate);

    const insertions = order.insertions || [];
    const surcharges = {};
    insertions.forEach((ins) => {
        const insertionStartDate = dayjs(ins.startDa);
        const insertionEndDate = dayjs(ins.endDate);

        // This is now being handled in the backend.
        // if (insertionStartDate.isBefore(startDate)) startDate = insertionStartDate;
        // if (insertionEndDate.isAfter(endDate)) endDate = insertionEndDate;

        ins.priceRows.forEach((priceRow) => {
            //surcharges.push(createSurcharge(priceRow));
            const sur = createSurcharge(priceRow)
            if (surcharges[sur.surchargeID + ""]){
                surcharges[sur.surchargeID + ""].net+= sur.net;
                surcharges[sur.surchargeID + ""].ctc+= sur.ctc;
            } else {
                surcharges[sur.surchargeID + ""] = sur;
            }

        })
    })

    order.startDate = startDate.format("YYYY-MM-DD");
    order.endDate = endDate.format("YYYY-MM-DD");
    // This is now being handled in the backend.
    // order.surcharges = surcharges;
    return order;
}

const createSurcharge = (priceRow) => {
    return {
        'surchargeID': priceRow.surchargeID,
        'surcharge': priceRow.surcharge,
        'net': priceRow.net || 0,
        'ctc': priceRow.ctc || 0
    }
}

const convertOrders = (orders) => {
    return orders.map(convertOrder);
}

const createOrdersDays = (orders) => {
    const { startDate, endDate } = getBorderDates(orders);

    const days = {};

    orders.forEach((order) => {
        days[order.orderID] = createDaysArray(startDate, endDate, order);
    })

    return days;
}

const createOrderMap = (orders) => {
    return orders.reduce((acc, curr) => {
        acc[curr.orderID] = curr
        return acc;
    }, {})
}

/** DEPREACTED - different source of medias ! */
const createMediaStructure = (orders) => {

    const mediaItems = orders.reduce((acc, curr) => {
        const mediaChannelID = curr.mediaChannelID;
        const channel = acc[mediaChannelID]
            ? acc[mediaChannelID]
            : { media: curr.media, mediaID: curr.mediaID, mediaChannel: curr.mediaChannel, mediaChannelID: mediaChannelID, items: [] };

        channel.items.push(curr.orderID);

        acc[mediaChannelID] = channel;

        return acc;
    }, {});

    return Object.keys(mediaItems).map((key) => mediaItems[key]);
}

const buildMediaStructure = (medias, orders) => {

    const finalMedias = medias.map((m) => {
        return {
            // not part of data from BE
            // media: m.media,
            // mediaID: m.mediaID,
            mediaChannel: m.mediaChannel,
            mediaChannelID: m.mediaChannelID,
            items: []
        }
    }).reduce((acc, curr) => {
        acc[curr.mediaChannelID] = curr;
        return acc;
    }, {})

    orders.forEach((order) => {
        const mc = finalMedias[order.mediaChannelID];

        if (!mc) return; // skip? or create new entry?

        mc.items.push(order.orderID);
    });

    return Object.keys(finalMedias).map((key) => finalMedias[key]);
}

const repeatIterate = (values, startPoint) => {

    let i = (startPoint || 0) - 1;

    return () => {
        i = i + 1;
        if (i == values.length) i = 0;

        return values[i];
    }
}

const buildSurchargeStructure = (surcharges) => {
    return surcharges.reduce((acc, curr) => {
        acc[curr.surchargeID] = curr;
        return acc;
    }, {})
}

const addSurchargeToOrder = (order, surcharge, force) => {
    const userForce = force || false;
    
    if(!order.surcharges){
        order.surcharges = {};
    }
    
    if (!order.surcharges[surcharge.surchargeID] || userForce)
        order.surcharges[surcharge.surchargeID] = surcharge;

    return order;
}

const calculateAdditionalCosts = (order, list) => {
    var costs = 0;
    list.forEach(element => {
        if(order[element]){
            costs += order[element];
        }
    });
    return costs;
}; 

const recountOrderNetCtc = (orderOrig) => {
    let order = {};
    order = Object.assign(order, orderOrig);
    const surchargesKeys = Object.keys(order.surcharges);
    const surchargesItems = surchargesKeys.map(key => order.surcharges[key]);
    const additionalCostList = ['clientOwnAdserving', 'clientOwnProgrammaticSpend', 'clientOwnTechCost']
    const additionalCosts = calculateAdditionalCosts(order, additionalCostList)

    const finalData = surchargesItems.reduce((acc, curr) => {
        return {
            net: acc.net + Number(curr.net),
            ctc: acc.ctc + Number(curr.ctc)
        }
    }, {
        net: 0,
        ctc: 0
    });

    order.net = finalData.net;
    order.ctc = finalData.ctc + additionalCosts;

    return order;
}

const crateOrderArrayMap = (orders) => {
    return orders.reduce((acc, curr, idx) => {
        acc[curr.orderID] = idx;
        return acc;
    }, {});
}

const createAgreementMediaMap = (agreements, mediaItems, marathonCountryID) => {

    const finalMap = {};

    const filteredMediaItems = mediaItems.filter((media) => media.marathonCountryID === marathonCountryID)

    // mediaTypeID is not unique!
    const mediaStructure = filteredMediaItems.reduce((acc, curr) => {
        const items = acc[curr.mediaTypeID] || [];
        items.push(curr);
        acc[curr.mediaTypeID] = items
        return acc;
    }, {})

    const agreementStructure = agreements.reduce((acc, curr) => {
        acc[curr.agreementID] = curr.allowedMediaTypeIDS;
        return acc;
    }, {})

    Object.keys(agreementStructure).forEach((agreementID) => {
        const items = agreementStructure[agreementID];

        if(items.length == 0) {
            finalMap[agreementID] = filteredMediaItems // all of them -_-
        }else{
            finalMap[agreementID] = items.map((item) => {
                return mediaStructure[item]
            }).filter(Boolean).flat();
        }
    });

    return finalMap;
}

export {
    convertOrders,
    createOrderMap,
    repeatIterate,
    createMediaStructure,
    createOrdersDays,
    createSurcharge,
    addSurchargeToOrder,
    recountOrderNetCtc,
    crateOrderArrayMap,
    buildMediaStructure,
    createAgreementMediaMap,

    buildSurchargeStructure,
}