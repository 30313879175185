<template>
    <div class="order-table-container">
        <div class="order-table-wrapper">
            <table class="orders-table">
                <thead>
                    <!-- month -->
                    <tr>
                        <th :colspan="dataColumnsAmount" class="invisible">&nbsp;</th>
                        <template v-for="(entry, index) in calendar">
                            <th class="month" :colspan="entry.items" :key="'m_' + index">
                                {{ entry.month.name }}
                            </th>
                        </template>
                    </tr>
                    <!-- weeks -->
                    <tr>
                        <th
                            v-for="(header, i) in buildHeaders"
                            :key="'h_' + i"
                            :colspan="header.span"
                            :class="[header.label == '&nbsp;' ? 'invisible' : 'with-title']"
                            v-html="header.label"
                        ></th>
                        <template v-for="(entry, i) in calendar">
                            <th
                                v-for="(week, i2) in entry.weeks"
                                class="week"
                                :colspan="week.length"
                                :key="'m_' + i + '_w_' + i2"
                            >
                                {{ createWeekLabel(i2) }}
                            </th>
                        </template>
                    </tr>
                    <!-- columns -->
                    <tr>
                        <slot name="header">
                            <!-- <th v-for="column in dataColumns" :key="column.key" class="data-column" rowspan="2">
                                {{ column.label }}
                            </th> -->
                        </slot>

                        <!-- days in month -->
                        <template v-for="(entry, i) in calendar">
                            <template v-for="(week, i2) in entry.weeks">
                                <th v-for="day in week" :key="'m_' + i + '_w_' + i2 + '_d_' + day" class="day">
                                    {{ day }}
                                </th>
                            </template>
                        </template>
                    </tr>
                    <tr>
                        <template v-for="(entry, i) in calendar">
                            <th
                                v-for="(label, i2) in entry.labels"
                                :key="'m_' + i + '_w_' + i2 + '_d_' + label"
                                class="day"
                            >
                                {{ label }}
                            </th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="mediaItem in mediaItems.filter(mediaAllowed)">
                        <tr :key="'k_' + mediaItem.mediaChannelID">
                            <td :colspan="dataColumnsAmount + calendarWidth" class="media-channel-title">
                                <slot name="media" v-bind:media="mediaItem">
                                    {{ mediaItem.mediaChannel }}
                                </slot>
                            </td>
                        </tr>
                        <tr :key="'m_' + (order.orderID || date())" v-for="order in getOrdersByIDs(mediaItem.items)">
                            <slot name="row" v-bind:order="order" v-bind:mediaItem="mediaItem">
                                <!-- <td v-for="(column, index) in dataColumns" :key="'o_' + order.orderID + '_' + index">
                                    {{ renderColumnValue(order, column) }}
                                </td> -->
                            </slot>
                            <td
                                v-for="(status, i2) in ordersDays[order.orderID]"
                                :class="['cal-day', 'cal-day-' + status]"
                                :key="'o_' + (order.orderID || date) + '_d_' + i2"
                                >
                                &nbsp;
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import WeekOfYear from "dayjs/plugin/weekOfYear";

import {
    buildMediaStructure,
    createOrdersDays,
    createOrderMap
} from "./helper";
import { getBorderDates, getDaysStructure } from "./helpers.dates";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(WeekOfYear);

export default {
    props: {
        orders: {
            type: Array,
            default: () => [],
        },
        headers: {
            type: Array,
            default: () => [],
        },
        dynamicColumnsAmount: {
            type: Number,
            default: 0,
        },
        mediaFilterAllowed: {
            type: Array,
            default: () => ["*"],
        },
        dataColumnsAmount: {
            type: Number,
            default: 0,
        },
        sourceMedias: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            mediaItems: [],
            calendar: [],
            finalOrders: {},
            ordersDays: {},
        };
    },

    methods: {
        createWeekLabel(label) {
            const weekNumber = label.slice(2);
            return "Week " + weekNumber;
        },

        mediaAllowed(mediaEntry) {
            const id = mediaEntry.mediaChannelID;

            if (this.mediaFilterAllowed[0] == "*" || this.mediaFilterAllowed.length == 0) return true;
            if (this.mediaFilterAllowed.includes(id)) return true;

            return false;
        },

        renderColumnValue(entry, definition) {
            const key = definition.key;

            switch (typeof key) {
            case "function":
                return key(entry);
            case "string":
                return entry[key];
            default:
                return "???";
            }
        },

        getOrdersByIDs(orderIDs) {
            return orderIDs.map((id) => this.finalOrders[id]);
        },
    },

    computed: {
        calendarWidth() {
            return this.calendar.reduce((acc, curr) => {
                return acc + curr.items;
            }, 0);
        },

        buildHeaders() {
            const totalSpan = this.dataColumnsAmount;
            const header = this.headers;
            let totalWidth = 0;
            const finalHeader = [];

            const create = (label, span) => {
                return {
                    label,
                    span,
                };
            };

            if (header.length == 0) return [create("&nbsp;", totalSpan)];

            header.forEach((h) => {
                const keys = Object.keys(h);
                const label = keys[0];

                const span = h[label].span || 0;
                const offset = h[label].offset || 0;

                totalWidth += span + offset;

                if (offset != 0) finalHeader.push(create("&nbsp;", offset));

                finalHeader.push(create(label, span));
            });

            const lastOne = totalSpan - totalWidth;

            if (lastOne != 0) finalHeader.push(create("&nbsp;", lastOne));

            return finalHeader;
        },
    },

    watch: {
        orders(newValue) {
            const structOrders = newValue; // convertOrders(newValue);

            const { startDate, endDate } = getBorderDates(structOrders);

            this.finalOrders = createOrderMap(structOrders);
            this.ordersDays = createOrdersDays(structOrders);
            //this.mediaItems = createMediaStructure(structOrders);
            this.mediaItems = buildMediaStructure(this.sourceMedias, structOrders);
            this.calendar = getDaysStructure(startDate, endDate);
        },

        sourceMedias(newSourceMedias) {
            this.mediaItems = buildMediaStructure(newSourceMedias, this.orders);
        }
    },
};
</script>
<style lang="scss">

$blue:#376EE2;

.order-table-container {
    width: 100%;
}

.order-table-wrapper {
    width: 100%;
    overflow-x: scroll;
    max-height: 50vh;
}

.orders-table {
    font-size: 10px;
    user-select: none; // ?? remove
    min-width: 100%;
    width: max-content;
    margin-bottom: 40px; // adding extra space for menu-popup

    thead {
        th {
            position: sticky;
            top: -1px; // becasue of the border
            z-index: 2;
        }
    }

    th,
    td {
        border: 1px solid #ddd;
        padding: 4px 10px;

        font-weight: 600;
        line-height: 140%;
        color: #fff;
        background-color: #1b1b1b;

        &.data-column {
            min-width: 120px;
            max-width: 160px;
        }

        span {
            display: block;
        }

        &.hide-column {
            width: 0;
            overflow: hidden;
            max-width: 0;
            min-width: 0;
            padding: 0;
            border: 0;
            font-size: 0;
        }
    }

    th {
        background-color: #3f3f3f;

        &.month,
        &.week,
        &.day {
            background-color: #2a34ff;
            text-align: center;
            padding: 4px 10px;
            text-transform: uppercase;
        }

        &.invisible {
            background-color: transparent;
            border: none;
        }

        &.day {
            padding: 0;
            width: 3ch;
        }

        &.with-title {
            background-color: #262626;
            text-align: center;
            text-transform: uppercase;
        }
    }

    td {
        &.cal-day {
            // &.cal-day-0 {
            // }

            &.cal-day-1 {
                background-color: $blue;
            }

            &.cal-day-2 {
                background-color: $blue;
            }
        }
    }

    .media-channel-title {
        background-color: #252525;
        padding: 10px;
    }

    .el-select .el-input .el-input__inner {
        background-color: transparent;
    }

    select {
        color: #fff;
        border: 0;
        background-color: #1b1b1b;
        box-shadow: none;
        cursor: pointer;

        background: {
            image: url("/img/asc.gif");
            repeat: no-repeat;
            size: auto;
            position: right 10px top auto;
        }
    }

    input, .label-input {
        padding-top: 0;
        border: 0;
        margin-bottom: 0;
    }

    .dot-menu {
        display: inline-block;
        position: relative;
        margin: 0 -10px;

        span {
            //color: #4f4f4f;
            color: #fff;
            font-size: 2rem;
            display: inline-block;
            line-height: 0;
            padding: 0 10px;
            height: 20px;
            cursor: pointer;
        }

        &:hover ul {
            display: block;
        }

        ul {
            display: none;
            list-style-type: none;

            position: absolute;
            top: 0px;
            left: 0;
            margin: 0;
            padding: 0;
            padding-top: 29px;
            z-index: 10;

            width: max-content;

            a {
                line-height: 1.4;
                color: #fff;
                display: inline-block;
                background-color: #252525;
                padding: 5px 24px 5px 12px;
                border: {
                    bottom: 1px solid #fff;
                    right: 1px solid #fff;
                }
            }
        }

        & + span {
            display: inline-block;
            margin-left: 30px;
        }
    }
}
</style>
