<template>
    <div class="brief-detail">
        <div class="row briefs-header page-header">
            <div class="col">
                <div class="row first-row">
                    <div class="col d-flex justify-content-between align-items-start">
                        <div class="page-title-wrapper">
                            <h1 class="page-title">                            
                                {{ !internalUser && $router.currentRoute.meta.alternativePageName ? $router.currentRoute.meta.alternativePageName : $router.currentRoute.meta.pageName }}
                            </h1>
                            <h1 class="campaigns-title page-subtitle">
                                {{ campaignName && campaignName.length > 0 ? " | " + campaignName : "" }}
                                {{ detailName && detailName.length > 0 ? " | " + detailName : "" }}
                            </h1>
                            <div v-if="campaignName" class="page-title_action">
                                <el-tooltip content="Download Brief as Excel Sheet" placement="top">
                                    <img class="download-icon icon clickable up-5" :src="'/img/icons/icon-download.svg'" v-on:click="downloadBriefAsExcel" alt="Download Brief as Excel Sheet" />
                                </el-tooltip>
                            </div>
                        </div>
                        <steps-navigation :steps="steps" :current-step="currentStep" @item-click="setStep" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div @click="goBack" class="back-button" v-show="$router.currentRoute.name !== $constants.routeNames.briefs.root">
                            <span class="btn btn-link btn-back"><i class="icon tim-icons icon-double-left" aria-hidden="true"></i>
                                <div class="label">Back</div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <zoom-center-transition :duration="500" mode="out-in">
            <router-view :dataModels="dataModels" :clientSelectOptions="clientSelectOptions" :countriesOptions="countriesOptions" :internalUser="internalUser" 
                :client="client" :steps="steps" :currentStep="currentStep" :campaign="campaign" v-if="campaign" :eventBus="eventBus" :campaignTouched.sync="campaignTouched">
            </router-view>
        </zoom-center-transition>
       
    </div>
</template>

<script>
import StepsNavigation from '@/components/Navbar/StepsNavigation.vue'
import {
    ZoomCenterTransition
} from 'vue2-transitions'
import { mapActions, mapState } from 'vuex'

export default {
    props: ['clientSelectOptions', 'countriesOptions', 'client', 'eventBus'],
    data: function () {
        return {
            currentStep: 0,
            campaign: null,
            steps: [{
                title: '1. Brief in short',
                groupA: '1. Brief in short',
                groupB: '1. IMDB Info'
            },
            {
                title: '2. About the movie',
                groupA: '2. About the movie',
                groupB: ''
            },
            {
                title: '3. Campaign Objective & Target Group',
                groupA: '3. Target Group Information',
                groupB: '3. Campaign Objectives'
            },
            {
                title: '4. Campaign Material & Data Strategy',
                groupA: '4. Campaign Material',
                groupB: '4. Data Strategy'
            },
            {
                title: '5. Supporting Documents & Brief Submission',
                groupA: '5. Supporting Documents & Brief Submission',
                groupB: ''
            }
            ],
            isFormValid: false,
            campaignTouched: false,
            clientID: null,
            marathonCountryID: null,
            campaignID: null
        }
    },
    mounted(){
        if(this.eventBus){
            this.eventBus.$on("set-step", this.setStep);
            this.eventBus.$on("next-page", this.nextPage);
            this.eventBus.$on("previous-page", this.previousPage);
            this.eventBus.$on("reload-brief", this.reloadBrief);
        }

        var page = parseInt(this.$route.query.p);
        if(page && typeof page === 'number' && Number.isInteger(page)){
            this.currentStep = page;
        }

        this.handleRoutes();
        this.getBrief();
    },
    methods:{
        reloadBrief(){
            this.handleRoutes()
            if(this.campaignID){
                this.getBrief().then(()=> {
                    this.campaignTouched = false;
                });
            }
        },
        getBrief() {
            var campaignModel = JSON.parse(JSON.stringify(this.dataModels.campaign));
            if (this.marathonCountryID && this.clientID && this.campaignID) {
                return this.axios(this.$constants.apiPaths.planning.campaign(this.marathonCountryID, this.clientID, this.campaignID))
                    .then((res) => {
                        if (res && res.data) {
                            this.campaign = Object.assign(campaignModel, res.data);
                            this.updateCampaignName(this.campaign.campaign);
                            // campaign endpoint now returns on item
                            // if (res.data.length === 1) {
                            //     this.campaign = Object.assign(campaignModel, res.data[0]);
                            //     this.updateCampaignName(this.campaign.campaign)
                            // } else if (res.data.length > 1) {
                            //     var campaignList = res.data.filter(element => element.campaignID === this.campaign.campaignID);

                            //     if (campaignList.length === 1) {
                            //         this.campaign = Object.assign(campaignModel, campaignList[0]);
                            //         this.updateCampaignName(this.campaign.campaign)
                            //     }
                            // }
                        }
                    })
                    .catch((error) => {
                        this.handleRequestError(error);
                    });
            }
            else{
                campaignModel.marathonCountryID = this.marathonCountryID;
                campaignModel.clientID = this.clientID;
                campaignModel.campaignID = this.campaignID;
                campaignModel.campaignCurrency = this.countriesOptions.filter(element => element.value === this.marathonCountryID)[0].currency;

                this.campaign = campaignModel;
                return Promise.resolve()
            }
        },
        downloadBriefAsExcel(){            
            const path = this.$constants.apiPaths.planning.exportBriefToExcel(this.campaign.marathonCountryID, this.campaign.clientID, this.campaign.campaignID);
            
            this.axios
                .get(path, {responseType: 'blob'})
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'brief_' + this.campaign.campaign  + "_" + this.campaign.campaignID + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.handleRequestError(error);
                });
        },
        handleRequestError(error) {
            console.error(error);
        },
        handleRoutes(){
            if (this.$route.params.campaignID) {
                this.campaignID = this.$route.params.campaignID;
            }
            if (this.$route.params.clientID) {
                this.clientID = this.$route.params.clientID;
            }
            if (this.$route.params.marathonCountryID) {
                this.marathonCountryID = this.$route.params.marathonCountryID;
            }
        },
        nextPage() {
            if (this.currentStep < 4) {
                this.currentStep += 1;
                this.$router.replace({query: {p: this.currentStep}});
            }
        },
        previousPage() {
            if (this.currentStep > 0) {
                this.currentStep -= 1;
                this.$router.replace({query: {p: this.currentStep}});
            }
        },
        setStep(step) {
            if(this.currentStep !== step){
                this.currentStep = step;
                this.$router.replace({query: {p: this.currentStep}});
                this.eventBus.$emit('step-update');
            }
        },
        goBack() {
            //this.$router.go(-1);
            this.$emit('close');
            //this.eventBus.$emit("reload-campaigns");
        },
        ...mapActions([
            'updateCampaignName'
        ]),
    },
    watch:{
        campaignModel(newVal, oldVal){
            if(JSON.stringify(newVal) !== JSON.stringify(oldVal) && oldVal !== null && !this.campaignTouched){  
                this.campaignTouched = true;
            }
        },
    },
    computed: {
        briefed() {
            return this.campaign !== null && this.campaign.briefStatus === this.$constants.campaignStates.BRIEFED.value;
        },
        campaignModel(){
            if(this.campaign){
                return Object.assign({}, this.campaign);
            }
            else{
                return null;
            }
        },
        ...mapState([
            'campaignName',
            'detailName',
            'internalUser',
            'dataModels',
        ]),
    },
    beforeDestroy() {
        this.eventBus.$off("set-step", this.setStep);
        this.eventBus.$off("next-page", this.nextPage);
        this.eventBus.$off("previous-page", this.previousPage);
        this.eventBus.$off("reload-brief", this.reloadBrief);
    },
    components: {
        [StepsNavigation.name]: StepsNavigation,
        ZoomCenterTransition,
    }
};
</script>
<style lang="scss" scoped>
.page-title_action{
    display: inline-block;
}
</style>
