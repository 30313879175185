<template>
    <b-modal ref="groupModal" id="group-modal" hide-footer size="xl" :centered="true" no-close-on-backdrop>
        <b-form>
                <div class="row">
                    <div class="col">
                        <form-input type="text" v-model="group.groupName" label="Name" :required="true" placeholder="Enter Name" />
                        <!-- <b-form-group id="form-name-group" label="Name:" label-for="form-name-input">
                            <b-form-input id="form-name-input" type="text" v-model="group.groupName" required
                                placeholder="Enter Name">
                            </b-form-input>
                        </b-form-group> -->
                    </div>
                        <!-- <div class="col">
                        <el-select 
                        v-model="groupContentAllowedResources" 
                        v-on:change="setGroupContentResources"
                        multiple
                        placeholder="Allowed resources:">
                        <el-option
                            v-for="(resource, index) in availableResourcesList"
                            :key="index"
                            :value="resource.value"
                            :label="resource.label">
                        </el-option>
                    </el-select>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col">
                        <!-- <div class="row mb-3">
                            <div class="col">Content</div>
                            <div class="col text-right">Access type</div>
                            <div class="col text-right">Allowed resources</div>
                        </div>
                        <hr>
                        <div class="row mb-3" v-for="(contentResource, index) in contentResources" :key="index">
                            <div class="col">{{contentResource.page}}</div>
                            <div class="col text-right">
                                <el-select id="form-content-resources-select"
                                    v-model="group.access[contentResource.page].accessType"
                                    placeholder="Access Type:">
                                    <el-option
                                        v-for="accessType in contentResource.accessTypes"
                                        :key="accessType.value" :value="accessType.value"
                                        :label="accessType.name">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col text-right">
                                <el-select 
                                    v-model="group.access[contentResource.page].allowedResources"
                                    multiple filterable collapse-tags
                                    placeholder="Allowed resources:" value-key="clientID">
                                    <el-option
                                        v-for="(resource, index) in availableContentResources[contentResource.page]"
                                        :key="resource.clientID"
                                        :value="resource.value"
                                        :label="resource.text +'(' + resource.value.marathonCountryID + ', ' + resource.value.marathonCountryID + ')'">
                                    </el-option>
                                </el-select>
                            </div>
                        </div> -->
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Content</th>
                                    <th scope="col" class="text-right">Access type</th>
                                    <th scope="col" class="text-right">Allowed resources</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(contentResource, index) in contentResources" :key="index">
                                    <td class="text-left"><span class="mr-3">{{contentResource.page}}</span></td>
                                    <td class="text-right">
                                        <el-select id="form-content-resources-select"
                                            v-model="group.access[contentResource.page].accessType"
                                            placeholder="Access Type:">
                                            <el-option
                                                v-for="accessType in contentResource.accessTypes"
                                                :key="accessType.value" :value="accessType.value"
                                                :label="accessType.name">
                                            </el-option>
                                        </el-select>
                                    </td>
                                    <td class="text-right">
                                        <el-select 
                                            class="tags ml-3"
                                            v-model="group.access[contentResource.page].allowedResources"
                                            multiple collapse-tags
                                            placeholder="Allowed resources:" value-key="clientID">
                                            <el-option
                                                v-for="(resource, index) in availableContentResources[contentResource.page]"
                                                :key="resource.clientID"
                                                :value="resource.value"
                                                :label="resource.text +'(' + resource.value.marathonCountryID + ', ' + resource.value.marathonCountryID + ')'">
                                            </el-option>
                                        </el-select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-between">
                                <base-button type="submit" variant="primary" v-on:click="submitGroup">
                                    Submit
                                </base-button>
                                <base-button v-on:click="deleteGroup" type="danger" v-if="group && group._id">Delete Group</base-button>
                                <base-button type="submit" variant="primary" class="btn btn-neutral btn-regular" v-on:click="closeModal">
                                    Cancel
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
        </b-form>
    </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: ['group'],
    computed:{
        ...mapState([
            'contentResources',
            'availableContentResources',
            'isLoadingContentResources',
        ]),
    },
    methods: {
        deleteConfirmation(){
            this.$swal.fire({
                title: 'Are you sure you want to delete ' + this.group.groupName + '?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete group!'
            }).then((result) => {
                //console.log(result)
                if (result.value) {
                    this.deleteGroup();
                }
            });

        },
        getGroups(force){
            console.log(force);
            this.$store.dispatch("getGroups", [this.axios, force]);
        },
        deleteGroup() {
            const url = this.$constants.apiPaths.administration.groups;
            this.axios.delete(url, {params: { _id: this.group._id}}).then((result) => {
                this.getGroups(true);
                this.$swal.fire(
                    'Deleted!',
                    'Group ' + this.group.groupName + ' deleted.',
                    'success').then(() => {
                    this.closeModal();
                });
            })
                .catch((error) => {
                });
        },
        submitGroup(evt) {
            evt.preventDefault();
            this.sendGroupRequest(this.group);
        },
        sendGroupRequest(group) {
            const path = this.$constants.apiPaths.administration.groups;
            const payload = group;
            if (group._id) {
                this.axios.put(path, payload)
                    .then((res) => {
                        this.getGroups(true);
                        this.$swal.fire(
                            'Group has been saved',
                            this.group.name + ' has been saved',
                            'success').then(() => {
                            this.closeModal();
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
               
            } else {
                this.axios.post(path, payload)
                    .then((res) => {
                        this.getGroups(true);
                        this.$swal.fire(
                            'Group has been saved',
                            this.group.name + ' has been saved',
                            'success').then(() => {
                            this.closeModal();
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

        },
        closeModal(){
            this.$refs.groupModal.hide();
        },
    },
};
</script>
