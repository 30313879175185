<template>
    <div>
        <programmatic-detail v-if="pacingType === $constants.pacingTypes.PROGRAMMATIC" :data="data" />
        <direct-buys-detail v-if="pacingType === $constants.pacingTypes.DIRECT" :data="data" />
    </div>
</template>

<script>
import ProgrmmaticDetail from './ProgrammaticDetail.vue'
import DirectBuysDetail from './DirectBuysDetail.vue'
import { mapActions, mapState } from 'vuex'

export default {
    data() {
        return {
            clientID: null,
            marathonCountryID: null,
            campaignID: null,
            pacingType: null,
            siteID: null,
            insertionOrderID: null,
            dcmCampaignID: null,
            data: {},
        }
    },
    watch: {

    },
    mounted(){
        this.handleRoutes();
        this.getData();
    },
    methods: {
        handleRequestError(error) {
            console.error(error);
        },
        handleRoutes(){
            if (this.$route.params.campaignID) {
                this.campaignID = this.$route.params.campaignID;
            }
            if (this.$route.params.clientID) {
                this.clientID = this.$route.params.clientID;
            }
            if (this.$route.params.marathonCountryID) {
                this.marathonCountryID = this.$route.params.marathonCountryID;
            }
            if (this.$route.params.type) {
                this.pacingType = this.$route.params.type;
            }
            if (this.$route.params.insertionOrderID) {
                this.insertionOrderID = this.$route.params.insertionOrderID;
            }
            if (this.$route.params.siteID) {
                this.siteID = this.$route.params.siteID;
            }
            if (this.$route.params.dcmCampaignID) {
                this.dcmCampaignID = this.$route.params.dcmCampaignID;
            }
        },
        getData() {
            if(this.pacingType){
                this.fetchData(this.pacingType).then((data) => {
                    this.data = data;
                    if(this.data.site){
                        this.updateDetailName(this.data.site);
                    }
                    if(this.data.insertionOrder && this.detailName === ''){
                        this.updateDetailName(this.data.insertionOrder);
                    }
                });
            } else {
                console.log("cannot fetch data without pacingType");
            }
        },
        fetchData(type) {
            
            let path = this.$constants.apiPaths.liveDashboardV2[type](this.insertionOrderID);
            if (type === this.$constants.pacingTypes.DIRECT) {
                path = this.$constants.apiPaths.liveDashboardV2[type](this.siteID, this.dcmCampaignID);
            }

            return this.axios.get(path)
                .then((res) => {
                    return res.data;
                })
                .catch((error) => {
                    this.handleRequestError(error);
                    return error;
                });

        },
        ...mapActions([
            'updateCampaignName',
            'updateDetailName'
        ]),
    },
    computed:{
        ...mapState([
            'detailName'
        ]),
    },
    components: {
        [ProgrmmaticDetail.name]: ProgrmmaticDetail,
        [DirectBuysDetail.name]: DirectBuysDetail,
    },

};
</script>
