<template>
    <div>
        <div class="row mb-5">
            <div class="col-12">        
                    <h3 class="group-admin-header">Group Management</h3>
                </div>
                <div class="col-9 group-admin-description">
                    <p>Add and edit system groups. 
                    From the edit menu you can reset password, resend invitation and update metadata.<br>
                    Use the groups window to edit group access rights.</p>
                </div>
                 <div class="col-3 d-flex justify-content-end">
                    <base-button type="button"
                        class="btn btn-success"
                        @click="createGroup"
                        v-b-modal.group-modal>
                            <i class="el-icon-plus"/>
                            <span>New group</span>
                    </base-button>
                </div>
            </div>
            <div class="row">
                <div class="col-12" v-if="isLoadingGroups">
                     <div class="spinner">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                    </div>
                    <div class="spinner-text">
                        Loading groups
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="planning-tool-table-header">
                        <div class="col-12 d-flex justify-content-start no-gutters">
                        <div class="row">
                            <div class="header-item-wrapper pagination-wrapper" size="sm" icon>
                                <el-select class="select-primary pagination-select" v-model="pagination.perPage" popper-class="pagination-dropdown"
                                    placeholder="Per page">
                                    <el-option class="select-primary" v-for="item in perPageOptions" :key="item" :label="item"
                                    :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="header-item-wrapper search-wrapper">
                                <el-input type="search" class="search-input" clearable prefix-icon="el-icon-search"
                                    placeholder="Search" v-model="searchQuery" aria-controls="datatables">
                                </el-input>
                            </div>
                        </div>
                    </div>
            </div>
                <el-table :data="queriedData"
                    class="planning-tool-table" 
                    row-class-name="planning-tool-table-row">

                    <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                    :prop="column.prop" :label="column.label" :className="column.prop" :align="column.align">
                    </el-table-column>
                
                    <el-table-column :min-width="100" fixed="right" align="right" label=" ">
                        <div slot-scope="props">
                            <span>
                                <base-button
                                    @click.native="editGroup(props.row._id)"
                                    v-b-modal.group-modal
                                    class="edit btn-link"
                                    type="warning"
                                    size="sm"
                                    icon>
                                    <i class="tim-icons icon-pencil"></i>
                                </base-button>
                            </span>
                        </div>
                    </el-table-column>

                </el-table>

                <div slot="footer" class="table-footer justify-content-sm-between">
                    <div class="pagination-info">
                        Showing {{ from + 1 }} - {{ to }} (total {{ total }} )
                    </div>
                    <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
                        :total="total">
                    </base-pagination>
                </div>
            </div>
        </div>
        <groupModal v-if="group" :group="group"/>
    </div>
</template>

<script>
import axios from 'axios';
import GroupModal from './GroupModal.vue';
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';

import Fuse from 'fuse.js';
import { mapState } from 'vuex'

export default {
    data() {
        return {
            search: '',
            group: '',
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
            searchQuery: '',
            propsToSearch: ['countryID', 'clientID', 'client'],
            searchedData: [],
            fuseSearch: null,
            tableColumns: [
                {
                    prop: '_id',
                    label: 'Group ID',
                    minWidth: 180
                },
                {
                    prop: 'groupName',
                    label: 'Name',
                    minWidth: 140
                },
            ],
        };
    },
    props: ['perPageOptions'],
    components: {
        groupModal: GroupModal,
        BasePagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    methods: {
        handleEdit(index) {
            var groupIndex = ((this.pagination.currentPage-1)*this.pagination.perPage) + index;
            this.group = this.groups[groupIndex];
            if (this.searchedData.length > 0) {
                for (var i in this.searchedData) {
                    if (this.searchedData[i]._ === this.searchedData[groupIndex]._id) {
                        for (var k in this.groups) {
                            if (this.groups[k]._id === this.searchedData[groupIndex]._id) {
                                this.group = this.groups[k];
                            }
                        }
                    }
                }
            } else {
                for (var i in this.groups) {
                    if (this.groups[i]._id === this.groups[groupIndex]._id) {
                        this.group = this.groups[i];
                    }
                }
            }
        },
        // setGroup(_id) {
        //     if (_id === undefined) {
        //         this.group = JSON.parse(JSON.stringify(this.groupDataModel));
        //     } else {
        //         for(var i in this.groups){
        //             if(this.groups[i]._id == _id){
        //                 this.group = this.groups[i];
        //                 break;
        //             }
        //         }
        //     }
        // },
        createGroup(){
            this.group = JSON.parse(JSON.stringify(this.groupDataModel));
        },
        editGroup(_id){
            if (_id) {
                for(var i in this.groups){
                    if(this.groups[i]._id == _id){
                        this.group = this.groups[i];
                        break;
                    }
                }
            }
        },
        // sendGroupRequest(group) {
        //     const path = this.$constants.apiPaths.administration.groups;
        //     const payload = group;
        //     this.isLoadingSave = true;
        //     if(group._id==null){
        //         this.axios.post(path, payload)
        //             .then((res) => {
        //                 document.querySelector('#group-modal').classList.remove("show")
        //                 this.isLoadingSave = false;
        //                 this.$swal.fire(
        //                     'Group has been saved',
        //                     'A new group has been created with name: ' + this.group.name,
        //                     'success').then(() => {
        //                 });
        //                 this.getGroups(true);
        //             })
        //             .catch((error) => {
        //                 this.isLoadingSave = false;
        //                 console.log(error);
        //             });
        //     } else {
        //         this.axios.put(path, payload)
        //             .then((res) => {
        //                 this.isLoadingSave = false;
        //                 this.$swal.fire(
        //                     'Group has been saved',
        //                     this.group.name + ' has been saved',
        //                     'success').then(() => {
        //                 });

        //                 this.getGroups(true);
        //             })
        //             .catch((error) => {
        //                 this.isLoadingSave = false;
        //                 console.log(error);
        //             });
        //     }
        // },
        getGroups(force){
            this.$store.dispatch("getGroups", [this.axios, force]);
        },
        getContentResources(force){
            this.$store.dispatch("getContentResources", [this.axios, force]);
        },
    },
    computed: {
        groupDataModel(){
            if(this.dataModels && this.dataModels.group){
                return this.dataModels.group;
            }
        },
        ...mapState([
            'dataModels'
        ]),
        queriedData() {
            let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        },
        tableData(){
            return this.groups ? this.groups : [];
        },
        ...mapState([
            'groups',
            'isLoadingGroups'
        ]),
    },
    created() {
    },
    mounted() {
        this.fuseSearch = new Fuse(this.tableData, {
            keys: ['countryID', 'clientID', 'client'],
            threshold: 0.0
        });
        this.getContentResources();
        this.getGroups();

    },
    watch: {
        searchQuery(value) {
            let result = this.tableData;
            if (value !== '') {
                result = this.fuseSearch.search(this.searchQuery).map(x => x.item);
            }
            this.searchedData = result;

            this.fuseSearch = new Fuse(this.tableData, {
                keys: ['name', 'email'],
                threshold: 0.0
            });
        },
    }
};
</script>