<template>
    <div>
        <div class="client-campaign-overview">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body campaings-table">
                            <div class="d-flex justify-content-between">
                                <h2 class="campaings-table-title">Plan overview</h2>
                                <base-button type="success" class="create-button" @click="createPlan">Create plan</base-button>
                            </div>
                            <div class="campaings-table-header">
                                <div class="col-12 d-flex justify-content-start no-gutters">
                                    <div class="row">
                                        <div class="header-item-wrapper filter-wrapper with-label">
                                            <el-dropdown :hide-on-click="false" trigger="click" placement="bottom-start">
                                                <base-button class="btn-link" size="sm">
                                                    Filter <i class="el-icon el-icon-caret el-icon-caret-bottom"></i>
                                                </base-button>
                                                <el-dropdown-menu slot="dropdown" class="filter-dropdown-menu">
                                                    <h4 class="menu-title">Filter</h4> 
                                                    <div class="row">
                                                        <div class="col">
                                                            <checkbox-group label="Status" :options="filterOptions" v-model="filterValues"/>
                                                        </div>
                                                        <div class="col" v-if="internalUser">
                                                            <checkbox-group label="Columns" :options="tableColumns" v-model="chosenColumns" @change="filterColumns" optionValueKey="prop"/>
                                                        </div>
                                                    </div>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </div>
                                        <div class="header-item-wrapper pagination-wrapper mr-2">
                                            <el-select class="pagination-select" v-model="pagination.perPage" placeholder="Per page"  popper-class="pagination-dropdown">
                                                <el-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="header-item-wrapper search-wrapper">
                                            <el-input type="search" class="search-input" clearable prefix-icon="el-icon-search" placeholder="Search"
                                                v-model="searchQuery" aria-controls="datatables">
                                            </el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="content">
                                <transition name="collapse">
                                    <gantt-table 
                                        :ganttData="queriedData"
                                        nameLabel="Title"
                                        :nameMinWidth="190"

                                        :getRoute="getRoute"
                                        
                                        @edit-item="editPlan"
                                         :pagination.sync="pagination"
                                         link-type="plan"
                                        :loading="loading" v-if="internalUser" :additional-columns="activeTableColumns" :showStatusLabel="false"/>
                                </transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-4 brief-informations" v-if="briefInfo">
                <brief-info-card v-if="briefInfo" :brief="briefInfo" v-on:click="openBrief" class="h-100"/>
            </div>
            <div class="col-4 file-upload-holder">
                <card class="mb-0"> 
                    <h2>File upload</h2>
                    <connect-box-file-upload :type="'campaign'" :params="{'campaignID':boxValues.id, 'clientID':boxValues.client, 'marathonCountryID':boxValues.country}"/>
                </card>
            </div>
        </div>
        <plan-modal :plan="plan" v-if="plan && selectedClient" :client="selectedClient" @reload="getPlans" :id="planModalId" :data-models="dataModels"/>
    </div>
</template>

<script>
import {
    CollapseTransition,
} from 'vue2-transitions';

import Fuse from 'fuse.js';

import SimpleGanttTable from '@/components/SimpleGanttTable/SimpleGanttTable.vue';
import GanttTable from '@/components/GanttTableView/GanttTableView';
import PlanModal from './PlanModal.vue'
import ConnectBoxFileUpload from '@/components/ConnectFileUpload/ConnectBoxFileUpload.vue'
import CheckboxGroup from '@/components/Inputs/CheckboxGroup';

import BaseButton from '../../components/BaseButton.vue';
import BriefInfoCard from '@/components/Cards/BriefInfoCard.vue';

import UserHelpers from '@/helpers/UserHelpers.js';
import { mapGetters, mapActions } from 'vuex'

export default {
    props: ['getRoute', 'dataModels', 'userClients'],
    data() {
        return {
            plan: this.dataModels.plan,
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            searchQuery: '',
            searchedData: [],
            filterShown: false,
            chosenColumns: ['net', 'ctc',],
            loading: false,
            filterValues: Object.values(this.$constants.campaignStates).map((item) => {
                return item.value
            }),
            activeTableColumns: [
                {
                    minWidth: 80,
                    prop: 'net',
                    label: 'Net'
                },
                {
                    minWidth: 80,
                    prop: 'ctc',
                    label: 'CTC'
                },
                {
                    minWidth: 120,
                    prop: 'startDate',
                    label: 'Start Date'
                },
                {
                    minWidth: 120,
                    prop: 'endDate',
                    label: 'End Date'
                },
            ],
            tableColumns: [
                {
                    minWidth: 80,
                    prop: 'net',
                    label: 'Net'
                },
                {
                    minWidth: 80,
                    prop: 'ctc',
                    label: 'CTC'
                },
                {
                    minWidth: 120,
                    prop: 'startDate',
                    label: 'Start Date'
                },
                {
                    minWidth: 120,
                    prop: 'endDate',
                    label: 'End Date'
                },
            ],
            marathonCountryID: null,
            showChat: false,
            fuseSearch: null,
            plans: [],
            campaignID: null,
            clientID: null,
            briefInfo: null,
        };
    },
    components: {
        BaseButton,
        CollapseTransition,
        [SimpleGanttTable.name]: SimpleGanttTable,
        GanttTable,
        [PlanModal.name]: PlanModal,
        ConnectBoxFileUpload,
        [CheckboxGroup.name]: CheckboxGroup,
        [BriefInfoCard.name]: BriefInfoCard
    },
    computed: {
        ...mapGetters([
            'internalUser'
        ]),
        planModalId(){ 
            return "plan-modal";
        },
        filterOptions(){
            return Object.values(this.$constants.campaignStates);
        },
        boxValues() {
            return {"client":this.$route.params.clientID, "id":this.$route.params.campaignID, "country":this.$route.params.marathonCountryID}
        },
        routePath() {
            const {
                path
            } = this.$route;
            return path;
        },
        queriedData() {
            let result = this.ganttData;
            if (this.searchedData && this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        selectedClient() {
            if(this.clientID && this.userClients){                
                return this.userClients.filter((item) => {
                    return item.clientID === this.clientID
                })[0];
            }
            else{
                return { products: [], agreements: []}
            }
        },
        ganttData() {
            var plans = JSON.parse(JSON.stringify(this.plans));
            var clientID = this.clientID;
            var marathonCountryID = this.marathonCountryID;
            var ganttData = [];
            var dataTest = function () {};
            if (clientID && marathonCountryID) {
                dataTest = function (item, clientID, marathonCountryID) {
                    return item.clientID === clientID && item.marathonCountryID === marathonCountryID;
                }
            } else if (clientID) {
                dataTest = function (item, clientID) {
                    return item.clientID === clientID;
                }
            } else if (marathonCountryID) {
                dataTest = function (item, clientID, marathonCountryID) {
                    return item.marathonCountryID === marathonCountryID;
                }
            } else {
                dataTest = function () {
                    return true;
                }
            }
            for (var i = 0; i < plans.length; i++) {
                if (dataTest(plans[i], clientID, marathonCountryID)) {
                    if(this.filterValues && this.filterValues.length > 0){
                        if(this.filterValues.length === this.filterOptions.length || this.filterValues.includes(this.plans[i].status)){
                            ganttData.push({
                                marathonCountryID: this.plans[i].marathonCountryID,
                                clientID: this.plans[i].clientID,
                                status: this.plans[i].status,
                                campaignID: this.plans[i].campaignID,
                                id: this.plans[i].planID,
                                name: this.plans[i].plan,
                                ctc: this.plans[i].ctc,
                                net: this.plans[i].net,
                                startDate: this.plans[i].startDate,
                                endDate: this.plans[i].endDate,
                                length: (new Date(this.plans[i].endDate).getTime() - new Date(this.plans[i].startDate).getTime()) / (1000 * 3600 * 24)
                            });
                        }
                    }
                }
            }
            return ganttData;
        },
        propsToSearch(){
            return ['name', 'id'];
        },
    },
    methods: {
        ...mapActions([
            'updateCampaignName',
            'updateDetailName',
            'clearDetailName',
        ]),
        editPlan(index){
            //this.plan = JSON.parse(JSON.stringify(this.plans[index]));
            this.plan = this.plans[index];
            this.openModal();
        },
        openModal(){
            this.$bvModal.show(this.planModalId);
        },
        createPlan(){
            this.plan = JSON.parse(JSON.stringify(this.dataModels.plan));
            this.plan.clientID = this.clientID;
            this.plan.marathonCountryID = this.marathonCountryID;
            this.plan.campaignID = this.campaignID;
            this.openModal();
        },
        filterColumns(rows) {
            this.activeTableColumns = [];
            for (var i = 0; i < rows.length; i++) {
                for (var k = 0; k < this.tableColumns.length; k++) {
                    if (this.tableColumns[k].prop === rows[i]) {
                        this.activeTableColumns[i] = this.tableColumns[k];
                    }
                }
            }
        },
        getPlans() {
            if (this.campaignID) {
                this.loading = true;
                
                const path = this.$constants.apiPaths.planning.plans(this.marathonCountryID, this.clientID, this.campaignID);
                
                this.axios.get(path)
                    .then((res) => {
                        if (res.data && res.data.plans) {
                            let plans = res.data.plans;

                            for (var i = 0; i < plans.length; i++) {
                                plans[i]["net"] = plans[i]["net"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                plans[i]["ctc"] = plans[i]["ctc"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            }

                            this.plans = plans;
                        }
                        
                        this.loading = false;
                        this.getBriefInfo();
                    })
                    .catch((error) => {
                        this.handleRequestError(error);
                        this.loading = false;
                    });
            } else {
                console.error("cannot fetch data without campaignID")
            }
        },
        getBriefInfo(){
            if (this.campaignID) {
                this.loading = true;
                const path = this.$constants.apiPaths.planning.campaign(this.marathonCountryID, this.clientID, this.campaignID);
                this.axios.get(path)
                    .then((res) => {
                        if (res.data) {
                            this.briefInfo = UserHelpers.enrichUserInfo(res.data);
                            this.updateCampaignName(this.briefInfo.campaign);
                        }
                        
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.handleRequestError(error);
                        this.loading = false;
                    });
            } else {
                console.error("cannot fetch data without campaignID")
            }
        },
        openBrief(){
            this.$emit('open-detail', 'brief', { campaignID: this.campaignID, marathonCountryID: this.marathonCountryID, clientID: this.clientID});
        },
        search(array, attribute, value) {
            var elements = []
            for (var i = 0; i < array.length; i++) {
                if (array[i][attribute] === value) {
                    elements.push(array[i])
                }
            }
            return elements;
        },
        handleRoutes(){
            if (this.$route.params.campaignID) {
                this.campaignID = this.$route.params.campaignID;
            }
            if (this.$route.params.clientID) {
                this.clientID = this.$route.params.clientID;
            }
            if (this.$route.params.marathonCountryID) {
                this.marathonCountryID = this.$route.params.marathonCountryID;
            }
        },
        
        handleRequestError(error) {
            console.error(error);
        },
    },
    watch: {
        searchQuery(newVal) {
            let result = this.ganttData;
            if (newVal !== '') {
                result = this.fuseSearch.search(newVal).map(x => x.item);
            }
            this.searchedData = result;

            this.fuseSearch = new Fuse(this.ganttData, {
                keys: this.propsToSearch,
                threshold: 0.0
            });
        },
    },
    mounted() {
        this.handleRoutes();
        this.getPlans();
        this.fuseSearch = new Fuse(this.ganttData, {
            keys: this.propsToSearch,
            threshold: 0.0
        });

    },
};
</script>