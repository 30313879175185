<template>
    <b-modal :id="id" :centered="true" no-close-on-backdrop>
        <template #modal-header>
            <b-button-close @click="closePlanModal(id)" />
            <h2>{{planClone && planClone.planID ? 'Edit Plan' : 'Create Plan'}}</h2>
        </template>

        <div class="formula-drawer">
            <div class="row" v-show="showAlert">
                <div class="col">
                    <el-alert :title="marathonCallStatus + ' ' + marathonResponse" :type="marathonCallStatus=='OK' ? 'success' : 'error'"  v-if="marathonCallStatus && marathonResponse"></el-alert>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <form-input label="Plan" v-model="planClone.plan" required />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-select
                        id="form-agreement-group"
                        label="Agreement:"
                        v-if="agreements.length > 0"
                        label-for="form-product-select"
                        v-model="planClone.planAgreementID"
                        required
                        :isValid="true">
                        <option v-for="agreement in agreements" :key="agreement.agreementID" :value="agreement.agreementID">
                                {{agreement.agreement}}
                        </option>
                    </base-select>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-select
                        id="form-product-group"
                        label="Product:"
                        v-if="products.length > 0"
                        label-for="form-product-select"
                        v-model="planClone.productID"
                        required
                        :isValid="true">
                        <option v-for="product in products" :key="product.productID" :value="product.productID">
                            {{product.product}}
                        </option>
                    </base-select>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end mt-2">
                    <base-button :round="true" v-on:click="editProduct" v-if="planClone && planClone.productID" class="mr-2">
                        <i class="el-icon-edit" />Edit product</base-button>
                    <base-button :round="true" v-on:click="createProduct">
                        <i class="el-icon-plus" />Create new product</base-button>
                </div>
            </div>
        </div>
        <template #modal-footer="{ cancel }">
            <div class="w-100">
                <div class="row" v-if="plan.planID">
                    <div class="col d-flex justify-content-end mb-2 mt-3">
                        <el-checkbox v-model="saveNewVersion">Save as new version</el-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-between">
                        <base-button :disabled="isSubmittingPlan" variant="primary" @click="cancel">
                            Cancel
                        </base-button>
                        <base-button :disabled="isSubmittingPlan" variant="primary" @click="submitPlan">
                            Save
                        </base-button>
                    </div>
                </div>
            </div>
        </template>
        <b-modal :id="productModalId" :centered="true" no-close-on-backdrop>
            <template #modal-header="{ close }">
                <b-button-close @click="close()" />
                <h2>{{product && product.productID ? ('Edit Product | ' + product.product) : 'Create Product'}}</h2>
            </template>
            <div class="row" v-if="product">
                <div class="col">
                    <form-input id="form-name-product" type="text" v-model="product.product" required placeholder="Enter Product Name"  label="Name" />
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <base-button :round="true" @click="closeProductModal" class="btn-neutral" >Cancel</base-button>
                <base-button :round="true" type="primary" @click="submitProduct">Save</base-button>
            </template>
        </b-modal>
    </b-modal>
</template>

<script>

export default {
    name: 'plan-modal',
    props: ['client', 'plan', 'id', 'dataModels'],
    data() {
        return {
            isSubmittingPlan: false,
            showAlert: false,
            marathonCallStatus: '',
            marathonResponse: '',
            product: null,
            planClone: JSON.parse(JSON.stringify(this.plan)),
            saveNewVersion: false,

        };
    },
    computed: {
        products(){
            return this.client.products;
        },
        agreements(){
            return this.client.agreements;
        },
        productModalId(){
            return "product-modal";
        },
        planProductID(){
            return this.planClone.productID;
        }
    },
    watch:{
        plan:{
            deep: true,
            handler(newVal){
                this.planClone = JSON.parse(JSON.stringify(newVal));
            },
        },
        planProductID(newVal){
            let product = this.products.filter((product) => product.productID === newVal);
            if(product.length === 1){
                this.planClone.product = product[0].product;
            }
        }
    },
    methods: {
        closeProductModal(){
            this.$bvModal.hide(this.productModalId);
        },
        closePlanModal(id){
            this.$bvModal.hide(id);
        },
        editProduct(){
            let product = this.products.filter((product) => product.productID === this.planClone.productID);
            if(product.length === 1){
                this.product = product[0];
            }
            this.$bvModal.show("product-modal");
        },
        createProduct(){
            this.product = this.dataModels.product;
            this.$bvModal.show("product-modal");
        },
        submitProduct() {
            const path = this.$constants.apiPaths.planning.product;
            let product = Object.assign(this.product, {clientID: this.plan.clientID});
            if(product.productID==null){
                product = Object.assign(this.product, {marathonCountryID: this.plan.marathonCountryID});
                const payload = JSON.parse(JSON.stringify(product));
                this.axios.post(path, payload)
                    .then((res) => {
                        if(res && res.data){
                            this.$store.dispatch("getClients", [this.axios, true]).then(() => {
                                this.changePlanProduct(res.data.productID);
                            });
                            this.$bvModal.hide("product-modal");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                const payload = JSON.parse(JSON.stringify(product));
                this.axios.put(path, payload)
                    .then((res) => {
                        if(res && res.data){
                            this.$store.dispatch("getClients", [this.axios, true]).then(() => {
                                this.changePlanProduct(res.data.productID);
                            });
                            this.$bvModal.hide("product-modal");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

        },
        changePlanProduct(responseProductID){
            this.planClone.productID = responseProductID;
        },
        submitPlan() {
            this.sendPlanRequest(this.planClone);
        },
        handleSuccess(res){
            this.marathonCallStatus = res.data.status;

            if (this.marathonCallStatus == 'OK') {
                this.marathonResponse = '- ' + res.data.planID;
                this.plan.planID = res.data.planID;
                this.saveNewVersion = false;
            } else {
                this.marathonResponse = res.data.code + '-' + res.data.message;
            }
            this.showAlert = true;
            this.$emit('reload');
            const that = this;
            setTimeout(function () {
                that.showAlert = false;
                that.$bvModal.hide(that.id);
                setTimeout(function () {
                    that.$bvModal.hide(that.id);
                }, 1500);
            }, 1500);
            this.isSubmittingPlan = false;
        },
        handleRequestError(error){
            //this.isSubmittingPlan = false;
            let text = error;
            if (error.response.data) {
                if (error.response.data.status) {
                    this.marathonCallStatus = error.response.data.status;
                    this.showAlert = true;
                    setTimeout(function () {
                        this.showAlert = false
                    }, 1500);
                }
                if (error.response.data.code) {
                    this.marathonResponse = '- ' + error.response.data.code;
                }
                if (error.response.data.message) {
                    this.marathonResponse += '- ' + error.response.data.message;
                    text += " \n";
                    text += error.response.data.message;
                }
            }
            this.$swal.fire({
                title: 'Operation failed',
                icon: 'error',
                allowOutsideClick: false,
                text: text,
            });
        },
        sendPlanRequest(plan) {
            const path = this.$constants.apiPaths.planning.plan();
            const parameters = new URLSearchParams();
            if(this.saveNewVersion){
                parameters.append("saveNewVersion", this.saveNewVersion)
            }
            const payload = plan;
            this.isSubmittingPlan = true;
            if (plan.planID == null) {
                this.axios.post(path, payload)
                    .then((res) => {
                        this.handleSuccess(res);
                    })
                    .catch((error) => {
                        this.isSubmittingPlan = false;
                        this.handleRequestError(error);
                    });
            } else {
                this.axios.put(path, payload, { params: parameters})
                    .then((res) => {
                        this.handleSuccess(res);
                    })
                    .catch((error) => {
                        this.isSubmittingPlan = false;
                        this.handleRequestError(error);
                    });

            }
        },
    },
};
</script>
